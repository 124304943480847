$__primarycolor: #09e5ab;
$__primarycolor-hover: #09dca4;
$__secondarycolor: #9a9a9a;
$__deep__skyblue: #42c0fb;
$__blue: #15558d;
$__white: #ffffff;
$__white__01: #f5f8f9;
$__white__02: #eaeaea;
$__white__03: #f6faff;
$__white__04: #f4fcff;
$__white__05: #f3f8ff;
$__white__06: #f7faff;
$__white__07: #f2f7ff;
$__white__08: #f5f5f7;
$__white__09: #e6e9f4;
$__dark-blue: #2e3842;
$__slate-blue: #2d3b48;
$__dark-slate-blue: #474648;
$__gray: #333333;
$__gray__10: #737373;
$__dark__gray: #888888;
$__mix__gray: #f7f7f7;
$__dark-gray: #484848;
$__light-gray: #dcdcdc;
$__goose-gray: #cccccc;
$__light__goose: #dddddd;
$__light-goose-gray: #dedfe0;
$__light-silver: #f0f0f0;
$__black: #000000;
$__blac__blue: #515783;
$__mix__ash: #abb0be;
$__ash__water: #e6f4fd;
$__smokey-gray: #757575;
$__gray__smoke: #a2a1a1;
$__light__smokey-gray: #666;
$__dark__smokeygray: #727272;
$__gray__smokey: #64627c;
$__gray__09: #948ca0;
$__gray__01: #dcd0ff;
$__gray__02: #f5faff;
$__gray__light__03: #eef2ff;
$__celeste: #0de0fe;
$__dark-celeste: #01cae4;
$__light__celeste: #0ce0ff;
$__sky__light__01: #a9d4ff;
$__sky__light__02: #8ec4ff;
$__white__celeste: #c2d6f3;
$__light-pale-blue: #f8f9fa;
$__aqua__blue: #e9f5f7;
$__gray__08: #808080;
$__light__gray__01: #b4abca;
$__red: #fb1612;
$__dis__red: #e30303;
$__ash__red: #dd2d4e;
$__green: #28a745;
$__light__green: #f3f9ff;
$__dull-gold: #f4c150;
$__dark__blue: #272b41;
$__sky__blue: #20c0f3;
$__gray__cloud: #999;
$__gray__dark: #4b4b4b;
$__dark__gray__cloud: #9f9f9f;
$__ash__blue: #00bcd1;
$__steel__blue: #1663a6;
$__info__blue: #4d72d0;
$__info_sky: #1e5c92;
$__info__dark: #2264c5;
$__granite: #858585;
$__ash__granite: #a4a4a4;
$__light__granite: #8a8a8a;
$__granite__gary: #797979;
$__granite__01: #7c7c7c;
$__platinum: #f9f9f9;
$__light__platinum: #acc9f5;
$__jet__gray: #c0c0c0;
$__dark__ink: #1860ca;
$__sky__ink: #2868c8;
$__metallic-silver: #e3e3e3;
$__light__metallic: #dfdfdf;
$__dark-inkblue: #3474ac;
$__ash__white: #f8fbff;
$__ash__sky: #e0f2ff;
$__ink__blue: #536cf1;
$__info__light: #1db9aa;
$__primary__light: #2196f3;
$__danger__light: #e63c3c;
$__warning__light: #f39c12;
$__star__yellow: #fed35d;
$__yellow: #ffbd07;
$__yellow__01: #ffd152;
$__success__light: #26af48;
$__purple__light: #c580ff;
$__default__light: #283447;
$__light__metalic: #e9e9e9;
$__metalic__blue: #e2e5e8;
$__blue__angel: #cfcfcf;
$__Light__blue__angel: #dcddea;
$__grapefruit: #d9534f;
$__light__grapefruit: #d43f3a;
$__mild__grapefruit: #e48784;
$__violet__pink: #ff4877;
$__dark__pink: #d84153;
$__ash__gray: #a0a0a0;
$__dark__ash__gray: #555;
$__light__white: #fafafa;
$__dark__light: #f5f5f5;
$__cloud__gray: #eeeeee;
$__gray__wolf: #3e3e3e;
$__gray__01: #e8eeff;
$__gray__02: #9d9d9d;
$__gray__03: #6c6c6c;
$__water: #f5f7fc;
$__lava__red: #dc3545;
$__metalic__gray: #bbb;
$__ash__metalic: #f2f2f2;
$__bright__gold: #f2b600;
$__rose__light: #e74e84;
$__rose: #ffaabd;
$__rose__01: #ffe0e7;
$__water__white: #fcfcfc;
$__light__water__white: #f3f3f3;
$__light__blue__gray: #f5f5f6;
$__baby__blue: #e0e3e4;
$__light__columbia__blue: #d2dde9;
$__light__battle__gary: #aaa;
$__dark__battle__gary: #777;
$__royal__blue: #2c80ff;
$__zombie__green: #00d285;
$__dark__marble__blue: #495463;
$__mix__white: #fbfbfb;
$__night: #3d3d3d;
$__ash__night: #1c1c1c;
$__dark__night: #383838;
$__dark__cloud: #d4d4d4;
$__light__cloud: #e5e5e5;
$__lapis__blue: #3a559f;
$__dark__lapis: #22396b;
$__light__lapis: #0098ae;
$__dark__lava__red: #dd4b39;
$__dark__beige: #b8b8b8;
$__iridium: #676767;
$__light__chilli__pepper: #fc6075;
$__chilli__red: #ffa69e;
$__successcolor: #00e65b;
$__light__orange: #fff1c9;
$__light__orange__01: #ffb88e;
$__successcolor-hover: #00cc52;
$__infocolor: #009efb;
$__infocolor-hover: #028ee1;
$__warningcolor: #ffbc34;
$__warningcolor-hover: #e9ab2e;
$__dangercolor: #ff0100;
$__dangercolor-hover: #e63333;
$__purplecolor: #9368e9;
$__purpletext: #7460ee;
$__cherry__red: #f06060;
$__lime__green: #55ce63;
$__aquamarine: #40dda4;
$__spring__green: #2ab934;
$__dark__ash: #212529;
$__dark__candy: #ababab;
$__light__candy: #e6e6e6;
$__candycolor: #a6a6a6;
$__ligt__carbon: #979797;
$__cantaloupe: #ff9b44;
$__dark__plum__purple: #4e4852;
$__plum__gray: #757578;
$__turquoise: #0db9f2;
$__beige: #dbdbdb;
$__light__beige: #e4e4e4;
$__light__vampire: #f1f1f1;
$__battleship__gray: #959595;
$__banner-btn: #1c5b90;
$__sky__light: #1c71ef;
$__blur__blue: #20d7f2;
$__light__blue: #7289de;
$__blue__01: #216ee1;
$__blue__02: #2467ca;
$__blue__03: #186db5;
$__blue__04: #1b5d92;
$__blue__05: #274782;
$__blue__06: #204892;
$__blue__07: #17418e;
$__blue__08: #1b70ee;
$__blue__09: #2a85d1;
$__blue__10: #2357a6;
$__blue__11: #1376ca;
$__blue__12: #0f4d84;
$__blue__13: #6a5dee;
$__blue__14: #0e82fd;
$__blue__15: #0c4f8a;
$__sky__02: #13a5e5;
$__sky__01: #10defd;
$__sky__03: #28daf4;
$__sky__white: #e8f6ff;
$__sky__lighter: #e5f6fd;
$__mist_white: #f2f6f6;
$__cyan_blue: #e6f1ff;
$__white_tone: #e5e7eb;
$__midnight_black: #1f2937;
$__bodycolor: #6b7280;
$__rock_blue: #4b5563;
$__gulf_gray: #9ca3af;
$__nickel_color: #959595;
$__grey_100: #a4a4a4;
$__grey_200: #e6e6e6;
$__grey_300: #f7f7f7;
$__grey_400: #f5f5f5;
$__grey_500: #ecebff;
$__blue_shade: #3c4758;
$__grey_whitish: #f4f4f4;
$__argent_color: #888888;
$__charcoal_color: #494949;
$__steel_color: #777777;
$__pearl_white: #aeaeb2;
$__athens_grey: #f3f4f6;
$__yellowish_white: #8c97b0;
$__green_color: #008f64;
$__red_color: #ff3535;
$__white_shade: #e4e4e8;
$__white_silver: #a9a9a9;
$__success_color: #22c550;
$__gray_goose: #edf1f1;
$__jet_grey: #484848;
$__danger_color: #ff3333;
$__mint_chiffon: #dcfce5;
$__cement_white: #fff6d8;
$__dark_yellow: #f3db00;
$__platinum_color: #e3e4e8;
$__grey: #8894ae;
$__whisper_color: #e5e5e5;
$__blue_color: #2f353c;
$__grey_600: #8c8c8c;
$__grey_700: #e9eaf6;
$__grey_800: #8d8d8d;
$__grey_900: #ababab;
$__orange: #ffbc11;
$__link_water: #d1d5db;
$__work__blue: #e0e7ff;
$__black_shade: #0a0b0d;
$__flash_color: #eff0f7;
$__fog_color: #d9dbe9;
$__broken_blue: #3b9cf6;
$__tag__blue: rgba(255, 255, 255, 0.2);
$__light_white: #ededed;
$__white_smoke: #f3f3f3;
$__grey_white: #f8f8f8;
$__honeydew_color: #e2e8f0;
$__zinc_color: #27272a;
$__royal_blue: #1e3259;
$__navey__blue: #15558d;
$__caribbean__green: #09dca4;
$__caribbean__dark: #09e5ab;
$__celeste: #0de0fe;
$__dark__celeste: #01cae4;
$__celtic_blue: #0071dc;
$__alto_color: #d9d9d9;
$__basalt_grey: #999999;
$__ebony_clay: #28283c;
$__grey_dark: #4a4a4a;
$__zambesi_grey: #5b5b5b;
$__davy_grey: #595959;
$__babe_grey: #b9b9b9;
$__darkish_grey: #d4d4d4;
$__silver_grey: #aaaaaa;
$__ghost_white: #f8fbff;
$__cyan_color: #0ce0ff;
$__mine_grey: #3f3f3f;

$__errigal_white: #f1f1f3;
$__quartz_color: #71717a;
$__rich_black: #040404;
$__trolley_grey: #818181;
$__bright_grey: #efefef;
$__text_danger: #ff0000;
$__pastel_orange: #ffb54a;
$__green_shade: #159f46;
$__grayish_blue: #e4e6ef;
$__light_green: #4caf50;
$__tone_grey: #9e9e9e;
$__dimgrey_color: #696969;
$__black_mana: #838383;
$__emperor_grey: #525252;
$__silver_chalice: #acacac;
$__zambesi_grey: #5b5b5b;
$__sonic_silver: #717171;
$__chinse_white: #e0e0e0;
$__arc_light: #c9e3fe;
$__congo_brown: #494545;
$__misty_rose: #ffe6e1;
$__misty__green: #00cc45;
$__dove_grey: #6b6b6b;
$__light_grey: #c2c2c2;
$__fog_silver: #7c7c7c;
$__blue_color1: #1c5b91;
$__dark_cyan: #1b5a90;
$__lightblue_shade: #4183bb;
$__grey_shase: #2b3d51;
$__home__12__section__even: #f9fafb;
$__cool_blue: #bec4d9;
$__cherry_grey: #2b3d51;
$__lite_dark_grey: #707070;
$__blue_color1: #1c5b91;
$__greymax: #e7e8e6;
$__lite__gray: #f6f7f5;
$__laysgreen: #2dd4bf;
$__thunderblue: #1e5d8a;
$__blue__20: #0f78e7;
$__greymax: #e7e8e6;
$__lite_orange: #ffaf14;
$__green__blue: #ccfbf1;
$__blue_color1: #1c5b91;
$__dark_cyan: #1b5a90;
$__pattens_blue: #f0f4f6;
$__color_warning: #ffaf14;
$__blue_shade1: #2369a6;
$__white_shade1: #d6eeff;
$__white_shade2: #f9fdff;
$__white_shade3: #e6fcff;
$__white_shade4: #f0f4f7;
$__black_shade1: #131218;
$__blue_shade2: #3e50ae;
$__gold_color: #ddbc5b;
$__grey_color: #666666;
$__sand_color: #fdeec3;
$__greenish_color: #13c2c2;
$__greenish_color1: #13a0ca;
$__greenish_color2: #1c5c92;
$__greenish_color3: #97f0ff;
$__greenish_color4: #0cbcda;
$__greenish_color5: #328cd9;
$__home__12__banner__section: #f9f9f9;
$__home__12__section__even: #f9fafb;
$__home__12__section__odd: #f3f4f6;
$__laysgreen: #2dd4bf;
$__sky__04: #f8fafc;
$__blue__16: #dbeffe;
$__puregold: #ffc001;
$__light-white-grey: #f8fafc;
$__wonder-blue: #bfe0fe;
$__blue__17: #1d7ed8;
$__grey_white__01: #94a3b8;
$__baby__blue: #eef6ff;
$__laysgreen: #2dd4bf;
$__sky__04: #f8fafc;
$__blue__16: #dbeffe;
$__light-white-grey: #f8fafc;
$__wonder-blue: #bfe0fe;
$__blue__17: #1d7ed8;
$__grey_white__01: #94a3b8;
$__laysgreen: #2dd4bf;
$__lite_dark_grey: #707070;
$__little_skyblue: #e3fbff;
$__ass_grey: #eeeef3;
$__little_grey_blue: #b0d6ff;
$__Dark_purple: #4c58a0;
$__dark_sandstone: #515151;
$__little_assgrey: #a2a2a2;
$__dark_brown: #626262;
$__lite_shadow: #7d7d7d;
$__my_color_green: #2a9d36;

.primary {
  background-color: $__primarycolor;
}
