.avatar {
	position: $__relative;
	display: $__inline__block;
	width: 3rem;
	height: 3rem;
	& > img {
		width: $__full__width;
		height: $__full__width;
		-o-object-fit: $__cover;
		object-fit: $__cover;
	}
	.avatar-title {
		width: $__full__width;
		height: $__full__width;
		background-color: $__primarycolor;
		color: $__white;
		@extend %display-flex;
		@extend %align-items-center;
		@extend %justify-content-center;
	}
	&.avatar-online::before {
		width: 25%;
	  	height: 25%;
        border-radius: 50%;
	  	content: '';
	  	border: 2px solid $__white;
	  	@include position($__absolute, null, 0, 0, null);
		background-color: $__successcolor;
	}
	&.avatar-offline::before {
		width: 25%;
	  	height: 25%;
        border-radius: 50%;
	  	content: '';
	  	border: 2px solid $__white;
	  	@include position($__absolute, null, 0, 0, null);
		background-color: $__dangercolor;
	}
	&.avatar-away::before {
		width: 25%;
	  	height: 25%;
        border-radius: 50%;
	  	content: '';
	  	border: 2px solid $__white;
	  	@include position($__absolute, null, 0, 0, null);
		background-color: $__warningcolor;
	}
	.border {
		border-width: 3px !important;
	}
	.rounded {
        border-radius: 6px !important;
	}
	.avatar-title {
		font-size: $__font__size__18;
	}
	.avatar-xs {
		width: 1.65rem;
		height: 1.65rem;
		.border {
			border-width: 2px !important;
		}
		.rounded {
            border-radius: 4px !important;
		}
		.avatar-title {
			font-size: $__font__size__12;
		}
		&.avatar-away::before, &.avatar-offline::before, &.avatar-online::before {
			border-width: 1px;
		}
	}
}
.avatar-sm {
	width: 2.5rem;
	height: 2.5rem;
	.border {
		border-width: 3px !important;
	}
	.rounded {
        border-radius:4px;
	}
	.avatar-title {
		font-size: $__font__size__15;
	}
	&.avatar-away::before, &.avatar-offline::before, &.avatar-online::before {
		border-width: 2px;
	}
}
.avatar-lg {
	width: 3.75rem;
	height: 3.75rem;
	.border {
		border-width: 3px !important;
	}
	.rounded {
        border-radius: 8px !important;
	}
	.avatar-title {
		font-size: $__font__size__24;
	}
	&.avatar-away::before, &.avatar-offline::before, &.avatar-online::before {
		border-width: 3px;
	}
}
.avatar-xl {
	width: 5rem;
	height: 5rem;
	.border {
		border-width: 4px !important;
	}
	.rounded {
        border-radius: 8px !important;
	}
	.avatar-title {
		font-size: $__font__size__28;
	}
	&.avatar-away::before, &.avatar-offline::before, &.avatar-online::before {
		border-width: 4px;
	}
}
.avatar-xxl {
	width: 5.125rem;
	height: 5.125rem;
	@include respond-above(md) {
		width: 8rem;
    	height: 8rem;
	}
	.border {
		border-width: 6px !important;
		@include respond-above(md) {
			border-width: 6px !important;
		}
	}
	.rounded {
        border-radius: 8px !important;
		@include respond-above(md) {
			border-width: 12px !important;
		}
	}
	.avatar-title {
		font-size: $__font__size__30;
		@include respond-above(md) {
			font-size: $__font__size__42;
		}
	}
	&.avatar-away::before, &.avatar-offline::before, &.avatar-online::before {
		border-width: 4px;
		@include respond-above(md) {
			border-width: 4px;
		}
	}
}
.avatar-group {
	@extend %inline-flex;
	.avatar {
		& + .avatar {
			margin-left: -.75rem;
		}
		&:hover {
			z-index: 1;
		}
	}
	.avatar-xs {
		& + .avatar-xs {
			margin-left: -.40625rem;
		}
	}
	.avatar-sm {
		& + .avatar-sm {
			margin-left: -.625rem;
		}
	}
	.avatar-lg {
		& + .avatar-lg {
			margin-left: -1rem;
		}
	}
	.avatar-xl {
		& + .avatar-xl {
			margin-left: -1.28125rem;
		}
	}
}
.avatar-xs {
    width: 1.65rem;
    height: 1.65rem;
}