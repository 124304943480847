/*** default values ***/
$__full__width:100%;
$__half__width:50%;
$__full_height_window:100vh;
$__full__height:100%;
$__half__height:50%;
$__left:left;
$__right:right;
$__center:center;
$__block:block;
$__inline__block:inline-block;
$__inline:inline;
$__table:table;
$__flex:flex;
$__webkit__flex:-webkit-flex;
$__none:none;
$__absolute:absolute;
$__relative:relative;
$__static:static;
$__fixed:fixed;
$__unset:unset;
$__uppercase:uppercase;
$__lowercase:lowercase;
$__capitalize:capitalize;
$__inherit:inherit;
$__initial:initial;
$__auto:auto;
$__normal:normal;
$__margin__auto:0 auto;
$__null:null;
$__bottom:bottom;
$__middle:middle;
$__top:top;
$__transparent:transparent;
$__repeat: repeat;
$__no_repeat:no-repeat;
$__cover:cover;
$__pointer:pointer;
$__not-allowed:not-allowed;
$__hidden:hidden;
$__show:show;
$__visible:visible;
$__both:both;
$__ellipsis:ellipsis;
$__nowrap:nowrap;
$__inline__flex:inline-flex;
$__underline:underline;
$__column: column;
$__collapse: collapse;
$__table__cell:table-cell;
$__column:column;
$__space_between:space-between;
$__wrap:wrap;
$__flex__start: flex-start;
$__start: start;
$__end: end;
$__collapse: collapse;
$__contain: contain; 

/***** custom values *****/
$__grid__padding__left:15px;
$__grid__padding__right:15px;
$__container__width:1140px;


/***** Grid Values *****/
$__col__1:8.33333333%;
$__col__2:16.66666667%;
$__col__3:25%;
$__col__4:33.33333333%;
$__col__5:41.66666667%;
$__col__6:50%;
$__col__7:58.33333333%;
$__col__8:66.66666667%;
$__col__9:75%;
$__col__10:83.33333333%;
$__col__11:91.66666667%;
$__col__12:100%;


