.notifications {
	@include margin-padding(null, 0);
	.notification-time {
		font-size: $__font__size__12;
		line-height: 1.35;
		color: $__sandstone;
	}
	.media {
		margin-top: 0;
		border-bottom: 1px solid $__dark__light;
		&:last-child {
			border-bottom: $__none;
		}
		a {
			display: $__block;
			@include margin-padding(null, 10px 15px);
			@include rounded(2px);
			&:hover {
				background-color: $__light__white;
			}
		}
		& > .avatar {
			margin-left: 10px;
		}
	}
	.media-list {
		.media-left {
			padding-left: 8px;
		}
	}
	.noti-content {
		height: 290px;
		width: 350px;
		overflow-y: $__auto;
		position: $__relative;
	}
	ul {
		&.notification-list {
			& > li {
				margin-top: 0;
				border-bottom: 1px solid $__dark__light;
				&:last-child {
					border-bottom: $__none;
				}
				a {
					display: $__block;
					text-decoration: none;
					@include rounded(2px);
					@include margin-padding(null, 10px 15px);
					&:hover {
						background-color: $__light__white;
					}
				}
				.list-item {
				    border: 0;
				    position: $__relative;
				    @include margin-padding(null, 0);
				}
			}
		}
	}
}
.topnav-dropdown-header {
	border-bottom: 1px solid $__cloud__gray;
	text-align: $__center;
	.notification-title {
	    color: $__gray;
	    display: $__block;
	    float: $__left;
	    font-size: $__font__size__14;
	}
	.clear-noti {
	    color: $__fire__red;
	    float: $__right;
	    font-size: $__font__size__12;
	    text-transform: $__uppercase;
	}
}
.topnav-dropdown-header, .topnav-dropdown-footer {
    font-size: $__font__size__14;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
}
.topnav-dropdown-footer {
	border-top: 1px solid $__cloud__gray;
	a {
		display: $__block;
		text-align: $__center;
		color: $__gray;
	}
}
.noti-details {
	color: $__frog__blue;
    margin-bottom: 0;
}
.noti-title {
	color: $__gray;
}
.notification-list {
	list-style: $__none;
	@include margin-padding(0, 0);
}
.noti-time {
    @include margin-padding(0, null);
}