@charset "UTF-8";
/****** UTILS ******/
/*** default values ***/
/***** custom values *****/
/***** Grid Values *****/
/** Extends **/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.upload-wrap,
.login-page .login-right,
.listing-day,
.doctor-action,
.doc-times,
.css-equal-content,
.css-equal-heights {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.css-equal-content {
  color: red;
  float: left;
}

.time-slot li .timing,
.consultation-types a,
.coming-soon-info,
.widget-setcount .step-icon,
.widget-setcount .step-box,
.signup-arrow,
.why-choose-icon span,
.about-contact-icon span,
.pricing-icon span,
.plan-choose-info,
.product-custom .cart-icon,
.section-search-pharmacy .banner-wrapper2 .search-box1 form,
.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-content-two,
.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-sixteen-img
  .facts-content-sixteen
  .facts-content-one,
.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-sixteen-img,
.facts-section-sixteen .facts-section-all .facts-content-all ul li,
.item.item-fifteen .doctor-profile-widget .doc-pro-img .doctor-amount .fav-icon,
.doc-content-fift .rate-fifteen ul li,
.doc-content-fift .rate-fifteen ul,
.doc-content-fift .rate-fifteen .rate-four,
.Patients-section-fifteen .service-patient-inflict .clients-fifteen-span,
.Patients-section-fifteen .service-patient-inflict .service-patient-inflictimg,
.banner-section-fifteen
  .banner-content-fifteen
  .aboutus-companyimg
  a
  .playicon
  span,
.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-top-content,
.section-header-thirteen,
.main-wrapper.home-twelve.home-thirteen
  .choose-us-section-thirteen
  ul
  li
  .choose-us-content-thirteen
  .chooseus-contents,
.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  p
  i,
.main-wrapper.home-twelve.home-thirteen
  .services-section-thirteen
  .service-thirteen-main
  .service-thirteen-all,
.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-top-content,
.testi-img .testi-icon,
.faq-wrap ul li a span,
.count-box .count-icon,
.center-img .center-service span,
.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-img
  .hexogen,
.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-item,
.book-section .book-best-doctors .book-header .img-overlay,
.browse-section .brower-box > div .brower-img,
.clinic-specialities .special-box:hover .special-body,
.doctor-category .pop-box .body-section,
.doctor-category .pop-box .top-section i,
.doctor-category .pop-box .top-section,
.doctor-widget-one .clinic-booking .btn-primary-light,
.view-icons a,
.voice-call-avatar,
.toggle-password-sub,
.pass-group .toggle-password,
.login-content-info .account-content .social-login-btn .btn,
.increment-decrement .input-groups input[type="button"],
.profile-pic-upload .cam-col,
.step-list ul li a,
.call-box .call-wrapper .call-items,
.chat-attachment-item,
.chat-window .chat-cont-right .chat-header .media .media-img-wrap,
.chat-window .chat-cont-right .chat-header,
.chat-window,
.signature,
.btn.btn-danger.trash,
.time-slot.time-slot-blk li .timing,
.visits span.visit-rsn:before,
.hours-info .btn.btn-danger.trash,
.blogs-section .blog-wrapper .wrap-content .date-cart,
.partners-slider li a,
.work-info .work-icon span,
.specialities-section-one .specialities-img span,
.specialities-section-one .specialities-item,
.profile-widget .fav-btn,
.section-specialities
  .specialities-slider
  .speicality-item
  .speicality-img
  span,
.footer.footer-one.footer-sixteen
  .footer-bottom
  .copyright
  .copyright-menu
  ul
  li
  a,
.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-fift-selects
  .social-icon
  ul
  li
  a,
.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .user-details-fift
  ul
  li
  .contact-fifteen
  .contact-form-fift,
.footer-contacts .footer-wrap .footer-icon,
.footer-three .social-icon ul li a,
.footer-one .footer-top .footer-widget .footer-contact-info .footer-address p,
.header.header-fourteen .header-nav .header-navbar-rht li.searchbar-fourteen a,
.header.header-fourteen.header-twelve
  .header-nav
  .header-navbar-rht
  li.searchbar-fourteen,
.header.header-ten .main-menu-wrapper .main-nav .register-btn,
.header.header-ten .main-menu-wrapper .main-nav li .btn.log-btn,
.header.header-ten .main-menu-wrapper .main-nav li .btn.reg-btn,
.header-one .main-menu-wrapper .main-nav .register-btn,
.header-one .main-menu-wrapper .main-nav li .btn.log-btn,
.header-one .main-menu-wrapper .main-nav li .btn.reg-btn,
.header.custom .header-navbar-rht.right-menu .header-contact-img i,
.header .header-nav,
.custom-medicalrecord-modal .modal-content .upload-medical-records .dropzone,
.brower-box a,
.clinic-slider .profile-widget .brower-box .arrow,
.browse-slider .profile-widget .brower-box .arrow,
.book-slider .profile-widget .brower-box .arrow,
.clinic-slider .profile-widget .brower-box a,
.browse-slider .profile-widget .brower-box a,
.book-slider .profile-widget .brower-box a,
.slick-dots .slick-dots li,
.dot-slider .slick-dots li,
.align-center {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.form-group-flex,
.otp-info,
.otp-box .form-group,
.signup-option-btns .signup-btn-info,
.booking-list,
.doc-content-fift .fift-bottom-content,
.doc-content-fift .rate-fifteen,
.services-section-fifteen
  .doctor-profile-widget-fifteen
  .doc-content-bottom
  .doc-content-split,
.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  .our-doctor-content-inner,
.store-item .store-content .price-tag,
.testi-users,
.our-blog-section.eye-blog .our-blogs .blogs-img .blogs-overlay,
.doctor-filter-inner,
.booking-doctor-details,
.faq-patients-count,
.doc-pro-info,
.footer-three .footer-bottom .copyright .footer-language,
.header.header-ten .header-nav,
.header-one .header-nav {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.terms-btn .btn,
.otp-sec p,
.testimonial-slider .slick-prev,
.testimonial-slider .slick-next,
.special-item .special-icon a,
.doctor-profile-widget.doc-grid .favourite-btn .favourite-icon,
.doctor-widget-one .favourite-btn .favourite-icon,
.doctor-filter-sort .nav li a,
.faq-info .accordion-button:after,
.reviews-ratings span,
.slide-nav-1 button.owl-next,
.slide-nav-1 button.owl-prev,
.slide-nav-2 button.owl-next,
.slide-nav-2 button.owl-prev {
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.widget-setcount ul,
.onboarding-content .increment-decrement .input-groups input[type="button"],
.relative-form,
.option-set,
.select-gender-option,
.onboarding-contents,
.opt-resend,
.set-path,
.prefer-box,
.call-lable.verify-lable.verify-options,
.call-lable.verify-lable.verify-lable,
.call-lable,
.consult-option,
.save-detail,
.upload-pic.patient-photo-upload,
.upload-pic,
.onboarding-content-box.content-wrap,
.onboarding-progress,
.on-board-wizard ul li a,
#onboard-slider .owl-dots,
.right-panel,
.left-panel,
.onboard-wrapper,
.full-slide-home .slick-slide .slide-image,
.full-slide-home .slick-slide > div > .col-12,
.contact-section-fourteen .contact-schedule-main .scheduling-left-main ul,
.banner-wrapper .search-box-3 form,
.map-listing .profile-widget,
.doctor-widget-one .doc-info-left,
.doctor-widget-one,
.clinic-services,
.doc-info-left,
.doctor-widget,
.call-timing,
.call-details,
.password-strength,
.increment-decrement .input-groups,
.login-bottom-copyright,
.custom-increment .input-group1,
chat-attachment-list,
.chat-msg-attachments,
.chat-window .chat-msg-info,
.chat-window .chat-cont-right .chat-body .media.sent .media-body,
.chat-window .chat-cont-right .chat-header .chat-options,
.chat-window .chat-cont-left .chat-users-list a.media .media-body,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend,
.chat-window .chat-cont-left .chat-header,
.patient-graph-col .graph-box .graph-status-result,
.patient-graph-col .graph-box,
.patient-graph-box,
.patient-dashboard-top,
.change-avatar,
.profile-info-widget,
.dash-card .dash-widget,
.upload-wrap,
.review-listing > ul li .comment .comment-body .meta-data .review-count,
.review-listing > ul li .comment,
.appointments .appointment-list,
.appointment-list .profile-info-widget,
.booking-device,
.success-page-cont,
.booking-doc-info,
.work-info,
.section-search .banner-wrapper .search-box form,
.footer-two .footer-top .footer-widget .footer-contact-info .footer-address,
.footer-one .footer-top .footer-widget .footer-contact-info .footer-address,
.footer .footer-top .footer-widget .footer-contact-info .footer-address,
.header.header-ten .main-menu-wrapper .main-nav,
.header-one .main-menu-wrapper .main-nav,
.header .header-nav .header-navbar-rht .has-arrow .user-header,
.header .header-nav .header-navbar-rht .dropdown-menu .dropdown-item,
.header .header-nav .main-nav,
.header .header-nav,
.avatar .avatar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.success-btn .btn,
.terms-section .terms-content .terms-text.terms-list p,
.error-content .btn,
.way-inner-header .btn,
.breadcrumb-bar-two .page-breadcrumb ol,
.booking-btn .btn,
.doctor-search-section.doctor-search-eleven .banner-header .btn,
.breadcrumb-bar-one .page-breadcrumb ol,
.specialities-section-one .specialities-btn .btn,
.footer-one .social-icon ul li a,
.footer-one .social-icon ul,
.footer-two
  .footer-top
  .footer-widget
  .footer-about-content
  .social-icon
  ul
  li
  a,
.footer-two .footer-top .footer-widget .footer-about-content .social-icon ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.booking-date,
.consultation-types,
.contact-card .card-body,
.signup-info,
.testimonial-info,
.about-contact,
.pricing-header-info,
.booking-doctor-right p,
.booking-doctor-left,
.faq-wrap ul li a,
.our-blog-section.eye-blog .our-blogs .blogs-img .blogs-overlay .blog-name,
.count-box,
.doctor-widget-one .clini-infos ul li,
.doctor-widget-one .doc-location,
.doctor-filter-sort,
.doctor-filter-option,
.doctor-filter-availability,
.filter-grid h4 a,
.booking-range .btn,
.booking-date.choose-date-book,
.articles-info,
.doc-pro-location p,
.footer-contacts .footer-wrap,
.footer-one .footer-top .footer-widget.footer-menu ul li a,
.flag-dropdown .dropdown-menu .dropdown-item,
.flag-dropdown .dropdown-toggle {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}

.success-dashboard-link a,
.custom_radio,
.booking-date-list ul li span,
.center-img .center-service,
.doctor-widget-one .doc-name,
.login-content-info .account-content .login-back a,
.back-link a,
.articles-content .articles-list li,
.reviews-ratings p {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
  align-items: center;
}

.widget-setcount ul,
.booking-list {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-item,
.chat-window .chat-cont-right .chat-body .media.sent .media-body,
.specialities-section-one .specialities-item {
  flex-direction: column;
}

.flag-dropdown .dropdown-toggle {
  -webkit-justify-content: start;
  justify-content: start;
}

.widget-setcount ul,
.relative-form,
.prefer-box,
.onboarding-content-box.content-wrap,
.left-panel {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.terms-section .terms-content .terms-text.terms-list p,
.contact-section-fourteen .contact-schedule-main .scheduling-left-main ul {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.chat-window .chat-cont-right .chat-header,
.chat-window .chat-cont-left .chat-users-list a.media .media-body,
.chat-window .chat-cont-left .chat-header,
.listing-day,
.header.header-ten .menu-header,
.header-one .menu-header,
.header .header-nav .menu-header,
.header .header-nav {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.store-tab,
.doctor-divison .pat-background,
.doctor-divison .doc-background,
.login-bottom-copyright,
.custom-increment.cart .input-group1,
.btn-file,
.chat-attachment-item,
.chat-window .chat-cont-right .chat-header .chat-options > a,
.success-cont i,
.hours-info .btn.btn-danger.trash,
.profile-widget .fav-btn,
.section-specialities
  .specialities-slider
  .speicality-item
  .speicality-img
  span,
.header .header-nav .header-navbar-rht li,
.avatar .avatar-title,
.btn-icon {
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}

.increment-decrement .input-groups,
.login-page .login-right,
.btn-file,
.chat-window .chat-msg-info,
.chat-window .chat-cont-right .chat-header .chat-options > a,
.chat-window .chat-cont-right .chat-header .media,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend,
.chat-window .chat-cont-left .chat-header,
.signature,
.change-avatar,
.dash-card .dash-widget,
.btn.btn-danger.trash,
.appointments .appointment-action,
.success-cont i,
.success-page-cont,
.header.header-ten .menu-header,
.header-one .menu-header,
.header .header-nav .header-navbar-rht .has-arrow.logged-item .nav-link,
.header .header-nav .header-navbar-rht .has-arrow .logged-item .nav-link,
.header .header-nav .header-navbar-rht .dropdown-menu .dropdown-item,
.header .header-nav .header-navbar-rht li,
.header .header-nav .menu-header,
table.table td h2.table-avatar,
table.table td h2 .table-avatar,
.avatar .avatar-title,
.btn-icon {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.listing-day {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.doctor-divison .pat-background,
.doctor-divison .doc-background,
.btn-file,
.chat-window .chat-cont-right .chat-header .chat-options > a,
.chat-window .chat-cont-left .chat-header .chat-compose,
.appointments .appointment-action,
.success-cont i,
.profile-widget .fav-btn,
.section-specialities
  .specialities-slider
  .speicality-item
  .speicality-img
  span,
.footer .footer-top .footer-widget .footer-about-content .social-icon ul li a,
.footer .footer-top .footer-widget .footer-about-content .social-icon ul,
.header .header-nav .header-navbar-rht .has-arrow.logged-item .nav-link,
.header .header-nav .header-navbar-rht .has-arrow .logged-item .nav-link,
.header .header-nav .header-navbar-rht li,
table.table td h2.table-avatar,
table.table td h2 .table-avatar,
.avatar-group,
.btn-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.widget-setcount ul,
.onboarding-content .increment-decrement .input-groups input[type="button"],
.relative-form,
.option-set,
.select-gender-option,
.onboarding-contents,
.opt-resend,
.set-path,
.prefer-box,
.call-lable.verify-lable.verify-options,
.call-lable.verify-lable.verify-lable,
.call-lable,
.consult-option,
.save-detail,
.upload-pic.patient-photo-upload,
.upload-pic,
.onboarding-content-box.content-wrap,
.onboarding-progress,
.on-board-wizard ul li a,
#onboard-slider .owl-dots,
.right-panel,
.left-panel,
.onboard-wrapper,
.full-slide-home .slick-slide .slide-image,
.full-slide-home .slick-slide > div > .col-12,
.contact-section-fourteen .contact-schedule-main .scheduling-left-main ul,
.banner-wrapper .search-box-3 form,
.map-listing .profile-widget,
.doctor-widget-one .doc-info-left,
.doctor-widget-one,
.clinic-services,
.doc-info-left,
.doctor-widget,
.call-timing,
.call-details,
.password-strength,
.increment-decrement .input-groups,
.login-bottom-copyright,
.custom-increment .input-group1,
chat-attachment-list,
.chat-msg-attachments,
.chat-window .chat-msg-info,
.chat-window .chat-cont-right .chat-body .media.sent .media-body,
.chat-window .chat-cont-right .chat-header .chat-options,
.chat-window .chat-cont-left .chat-users-list a.media .media-body,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend,
.chat-window .chat-cont-left .chat-header,
.patient-graph-col .graph-box .graph-status-result,
.patient-graph-col .graph-box,
.patient-graph-box,
.patient-dashboard-top,
.change-avatar,
.profile-info-widget,
.dash-card .dash-widget,
.upload-wrap,
.review-listing > ul li .comment .comment-body .meta-data .review-count,
.review-listing > ul li .comment,
.appointments .appointment-list,
.appointment-list .profile-info-widget,
.booking-device,
.success-page-cont,
.booking-doc-info,
.work-info,
.section-search .banner-wrapper .search-box form,
.footer-two .footer-top .footer-widget .footer-contact-info .footer-address,
.footer-one .footer-top .footer-widget .footer-contact-info .footer-address,
.footer .footer-top .footer-widget .footer-contact-info .footer-address,
.header.header-ten .main-menu-wrapper .main-nav,
.header-one .main-menu-wrapper .main-nav,
.header .header-nav .header-navbar-rht .has-arrow .user-header,
.header .header-nav .header-navbar-rht .dropdown-menu .dropdown-item,
.header .header-nav .main-nav,
.header .header-nav,
.avatar .avatar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.payment-radio,
.custom_radio,
.custom_check,
.slick-slider {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.right-panel,
.section-search-pharmacy .banner-wrapper2 .search-box1 form,
.full-slide-home .slick-slide > div > .col-12,
.clinic-services,
.chat-window .chat-msg-info,
.patient-graph-col .graph-box .graph-status-result,
.patient-graph-col .graph-box,
.appointments .appointment-list,
.schedule-nav .nav-tabs,
.post-left ul,
.header.header-ten .header-nav,
.header-one .header-nav,
.header .header-nav,
.custom-medicalrecord-modal .modal-content .upload-medical-records .dropzone {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-item,
.chat-window .chat-cont-right .chat-body .media.sent .media-body,
.specialities-section-one .specialities-item {
  -ms-flex-direction: column;
  flex-direction: column;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body,
.patient-graph-col .graph-box .graph-status-result {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body,
.patient-graph-col .graph-box .graph-status-result {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}

.upload-wrap,
.day-week-month-col ul,
.payment-menthods ul,
.step-list ul,
.header-top ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/******* BASE *******/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
}

* {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  height: 100%;
  overflow-x: hidden;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(../fonts/MaterialIcons-Regular.woff2) format("woff2"),
    url(../fonts/MaterialIcons-Regular.woff) format("woff"),
    url(../fonts/MaterialIcons-Regular.ttf) format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

h1 {
  font-weight: normal;
  font-size: 40px;
}

@media (max-width: 767.98px) {
  h1 {
    font-size: 27px;
  }
}

h2 {
  font-weight: normal;
  font-size: 30px;
}

@media (max-width: 767.98px) {
  h2 {
    font-size: 23px;
  }
}

h3 {
  font-weight: normal;
  font-size: 24px;
}

@media (max-width: 1023.98px) {
  h3 {
    font-size: 20px;
  }
}

@media (max-width: 767.98px) {
  h3 {
    font-size: 16px;
  }
}

h4 {
  font-weight: normal;
  font-size: 18px;
}

@media (max-width: 1023.98px) {
  h4 {
    font-size: 18px;
  }
}

@media (max-width: 767.98px) {
  h4 {
    font-size: 18px;
  }
}

h5 {
  font-weight: normal;
  font-size: 19px;
}

@media (max-width: 1023.98px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-weight: normal;
  font-size: 18px;
}

@media (max-width: 1023.98px) {
  h6 {
    font-size: 16px;
  }
}

.primary {
  background-color: #09e5ab;
}

html {
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #ffffff;
  font-size: 0.9375rem;
  color: #6b7280;
  line-height: 1.5;
  height: 100%;
  overflow-x: hidden;
}

@media (max-width: 767.98px) {
  body {
    font-size: 14px;
  }
}

@media (max-width: 575.98px) {
  body {
    font-size: 13px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #2e3842;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
  text-decoration: none;
}

a:hover {
  color: #09e5ab;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
  text-decoration: none;
}

a:focus {
  outline: 0;
}

.green .intro_text a:hover {
  color: #09e5ab;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

p {
  margin-top: 0;
  margin-bottom: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #272b41;
  font-weight: 500;
}

.h1,
h1 {
  font-size: 2.25rem;
}

@media (max-width: 767.98px) {
  .h1,
  h1 {
    font-size: 32px;
  }
}

@media (max-width: 575.98px) {
  .h1,
  h1 {
    font-size: 28px;
  }
}

.h2,
h2 {
  font-size: 30px;
}

@media (max-width: 767.98px) {
  .h2,
  h2 {
    font-size: 28px;
  }
}

@media (max-width: 575.98px) {
  .h2,
  h2 {
    font-size: 24px;
  }
}

.h3,
h3 {
  font-size: 1.5rem;
}

@media (max-width: 767.98px) {
  .h3,
  h3 {
    font-size: 22px;
  }
}

@media (max-width: 575.98px) {
  .h3,
  h3 {
    font-size: 20px;
  }
}

.h4,
h4 {
  font-size: 18px;
}

@media (max-width: 767.98px) {
  .h4,
  h4 {
    font-size: 16px;
  }
}

@media (max-width: 575.98px) {
  .h4,
  h4 {
    font-size: 16px;
  }
}

.h5,
h5 {
  font-size: 1rem;
}

@media (max-width: 767.98px) {
  .h5,
  h5 {
    font-size: 14px;
  }
}

@media (max-width: 575.98px) {
  .h5,
  h5 {
    font-size: 14px;
  }
}

.h6,
h6 {
  font-size: 14px;
}

@media (max-width: 767.98px) {
  .h6,
  h6 {
    font-size: 12px;
  }
}

@media (max-width: 575.98px) {
  .h6,
  h6 {
    font-size: 12px;
  }
}

.btn-primary {
  background-color: #2a9d36;
  border: 1px solid #2a9d36;
}

.dashboard-login-btn {
  background-color: #2a9d36;
  border: 1px solid #2a9d36;
}

.dashboard-login-btn:hover {
  background-color: #c70e2a;
  border: 1px solid #c70e2a;
  color: #ffffff;
}

.btn-primary:hover {
  background-color: #c70e2a;
  border: 1px solid #c70e2a;
}

.container-fluid {
  padding: 0 30px;
}

@media (max-width: 1199.98px) {
  .container-fluid {
    padding: 0 20px;
  }
}

@media (max-width: 991.98px) {
  .container-fluid {
    padding: 0 20px;
  }
}

@media (max-width: 767.98px) {
  .container-fluid {
    padding: 0 15px;
  }
}

.form-group {
  margin-bottom: 20px;
}

.text-muted {
  color: #757575 !important;
}

.dropdown-menu {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  border-radius: 3px;
  -webkit-box-shadow: inherit;
  -moz-box-shadow: inherit;
  box-shadow: inherit;
  transform-origin: left top 0;
  -webkit-transform-origin: left top 0;
  -ms-transform-origin: left top 0;
}

@media (max-width: 1399.98px) {
  .row.row-grid {
    margin-left: -8px;
    margin-right: -8px;
  }
}

@media (max-width: 1399.98px) {
  .row.row-grid > div {
    padding-right: 8px;
    padding-left: 8px;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media (max-width: 1199.98px) {
  .row.row-grid > div {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 849.98px) {
  .row.row-grid > div {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 767.98px) {
  .row.row-grid > div {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 575.98px) {
  .row.row-grid > div {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.text-sm {
  font-size: 14px !important;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

a {
  color: #2e3842;
}

a:hover {
  color: #09dca4;
}

a:hover,
a:focus,
a.active {
  outline: none;
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input,
button,
a {
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

button:focus {
  outline: 0;
}

.btn:focus,
.btn.focus {
  box-shadow: inherit;
}

.form-control {
  border-color: #dcdcdc;
  color: #333333;
  font-size: 15px;
  min-height: 46px;
  padding: 6px 15px;
}

.form-control:focus {
  border-color: #bbb;
  box-shadow: none;
  outline: 0 none;
}

.form-control::-webkit-input-placeholder,
.form-control::-moz-placeholder,
.form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder,
.form-control::placeholder {
  color: #858585;
  font-size: 14px;
}

.form-control::placeholder {
  color: #858585;
  font-size: 14px;
}

.table .form-control {
  font-size: 14px;
  min-height: 38px;
}

.list-group-item {
  border: 1px solid #f0f0f0;
}

@media (max-width: 991.98px) {
  .container {
    max-width: 100%;
  }
}

/* Custom avatar styles */
.custom-avatar {
  font-family: "Lobster", cursive;
  width: 120px;
  height: 120px;
  background-color: #f7f7f7;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  font-weight: bold;
  position: relative;
  border: 6px solid #ebfaec;
}

/* Styles for the first letter */
.custom-avatar .initial {
  color: #c70e2a;
}

/* Styles for the second letter */
.custom-avatar .secondInitial {
  color: #2a9d36;
}

.avatar {
  background-color: #ffffff;
  color: #c70e2a;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

.initial {
  color: #c70e2a;
}

.secondInitial {
  color: #2a9d36;
}

.form-custom {
  position: relative;
}

.form-custom i {
  color: #c70e2a;
  position: absolute;
  top: 35%;
  right: 15px;
}

/*** default values ***/
/***** custom values *****/
/***** Grid Values *****/
/** Extends **/
.upload-wrap,
.login-page .login-right,
.listing-day,
.doctor-action,
.doc-times,
.css-equal-heights,
.css-equal-content {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.css-equal-content {
  color: red;
  float: left;
}

.time-slot li .timing,
.consultation-types a,
.coming-soon-info,
.widget-setcount .step-icon,
.widget-setcount .step-box,
.signup-arrow,
.why-choose-icon span,
.about-contact-icon span,
.pricing-icon span,
.plan-choose-info,
.product-custom .cart-icon,
.section-search-pharmacy .banner-wrapper2 .search-box1 form,
.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-content-two,
.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-sixteen-img
  .facts-content-sixteen
  .facts-content-one,
.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-sixteen-img,
.facts-section-sixteen .facts-section-all .facts-content-all ul li,
.item.item-fifteen .doctor-profile-widget .doc-pro-img .doctor-amount .fav-icon,
.doc-content-fift .rate-fifteen ul li,
.doc-content-fift .rate-fifteen ul,
.doc-content-fift .rate-fifteen .rate-four,
.Patients-section-fifteen .service-patient-inflict .clients-fifteen-span,
.Patients-section-fifteen .service-patient-inflict .service-patient-inflictimg,
.banner-section-fifteen
  .banner-content-fifteen
  .aboutus-companyimg
  a
  .playicon
  span,
.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-top-content,
.section-header-thirteen,
.main-wrapper.home-twelve.home-thirteen
  .choose-us-section-thirteen
  ul
  li
  .choose-us-content-thirteen
  .chooseus-contents,
.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  p
  i,
.main-wrapper.home-twelve.home-thirteen
  .services-section-thirteen
  .service-thirteen-main
  .service-thirteen-all,
.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-top-content,
.testi-img .testi-icon,
.faq-wrap ul li a span,
.count-box .count-icon,
.center-img .center-service span,
.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-img
  .hexogen,
.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-item,
.book-section .book-best-doctors .book-header .img-overlay,
.browse-section .brower-box > div .brower-img,
.clinic-specialities .special-box:hover .special-body,
.doctor-category .pop-box .body-section,
.doctor-category .pop-box .top-section i,
.doctor-category .pop-box .top-section,
.doctor-widget-one .clinic-booking .btn-primary-light,
.view-icons a,
.voice-call-avatar,
.toggle-password-sub,
.pass-group .toggle-password,
.login-content-info .account-content .social-login-btn .btn,
.increment-decrement .input-groups input[type="button"],
.profile-pic-upload .cam-col,
.step-list ul li a,
.call-box .call-wrapper .call-items,
.chat-attachment-item,
.chat-window .chat-cont-right .chat-header .media .media-img-wrap,
.chat-window .chat-cont-right .chat-header,
.chat-window,
.signature,
.btn.btn-danger.trash,
.time-slot.time-slot-blk li .timing,
.visits span.visit-rsn:before,
.hours-info .btn.btn-danger.trash,
.blogs-section .blog-wrapper .wrap-content .date-cart,
.partners-slider li a,
.work-info .work-icon span,
.specialities-section-one .specialities-img span,
.specialities-section-one .specialities-item,
.profile-widget .fav-btn,
.section-specialities
  .specialities-slider
  .speicality-item
  .speicality-img
  span,
.footer.footer-one.footer-sixteen
  .footer-bottom
  .copyright
  .copyright-menu
  ul
  li
  a,
.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-fift-selects
  .social-icon
  ul
  li
  a,
.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .user-details-fift
  ul
  li
  .contact-fifteen
  .contact-form-fift,
.footer-contacts .footer-wrap .footer-icon,
.footer-three .social-icon ul li a,
.footer-one .footer-top .footer-widget .footer-contact-info .footer-address p,
.header.header-fourteen .header-nav .header-navbar-rht li.searchbar-fourteen a,
.header.header-fourteen.header-twelve
  .header-nav
  .header-navbar-rht
  li.searchbar-fourteen,
.header.header-ten .main-menu-wrapper .main-nav .register-btn,
.header.header-ten .main-menu-wrapper .main-nav li .btn.log-btn,
.header.header-ten .main-menu-wrapper .main-nav li .btn.reg-btn,
.header-one .main-menu-wrapper .main-nav .register-btn,
.header-one .main-menu-wrapper .main-nav li .btn.log-btn,
.header-one .main-menu-wrapper .main-nav li .btn.reg-btn,
.header.custom .header-navbar-rht.right-menu .header-contact-img i,
.header .header-nav,
.custom-medicalrecord-modal .modal-content .upload-medical-records .dropzone,
.brower-box a,
.clinic-slider .profile-widget .brower-box .arrow,
.browse-slider .profile-widget .brower-box .arrow,
.book-slider .profile-widget .brower-box .arrow,
.clinic-slider .profile-widget .brower-box a,
.browse-slider .profile-widget .brower-box a,
.book-slider .profile-widget .brower-box a,
.slick-dots .slick-dots li,
.dot-slider .slick-dots li,
.align-center {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.form-group-flex,
.otp-info,
.otp-box .form-group,
.signup-option-btns .signup-btn-info,
.booking-list,
.doc-content-fift .fift-bottom-content,
.doc-content-fift .rate-fifteen,
.services-section-fifteen
  .doctor-profile-widget-fifteen
  .doc-content-bottom
  .doc-content-split,
.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  .our-doctor-content-inner,
.store-item .store-content .price-tag,
.testi-users,
.our-blog-section.eye-blog .our-blogs .blogs-img .blogs-overlay,
.doctor-filter-inner,
.booking-doctor-details,
.faq-patients-count,
.doc-pro-info,
.footer-three .footer-bottom .copyright .footer-language,
.header.header-ten .header-nav,
.header-one .header-nav {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.terms-btn .btn,
.otp-sec p,
.testimonial-slider .slick-prev,
.testimonial-slider .slick-next,
.special-item .special-icon a,
.doctor-profile-widget.doc-grid .favourite-btn .favourite-icon,
.doctor-widget-one .favourite-btn .favourite-icon,
.doctor-filter-sort .nav li a,
.faq-info .accordion-button:after,
.reviews-ratings span,
.slide-nav-1 button.owl-next,
.slide-nav-1 button.owl-prev,
.slide-nav-2 button.owl-next,
.slide-nav-2 button.owl-prev {
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.widget-setcount ul,
.onboarding-content .increment-decrement .input-groups input[type="button"],
.relative-form,
.option-set,
.select-gender-option,
.onboarding-contents,
.opt-resend,
.set-path,
.prefer-box,
.call-lable.verify-lable.verify-options,
.call-lable.verify-lable.verify-lable,
.call-lable,
.consult-option,
.save-detail,
.upload-pic.patient-photo-upload,
.upload-pic,
.onboarding-content-box.content-wrap,
.onboarding-progress,
.on-board-wizard ul li a,
#onboard-slider .owl-dots,
.right-panel,
.left-panel,
.onboard-wrapper,
.full-slide-home .slick-slide .slide-image,
.full-slide-home .slick-slide > div > .col-12,
.contact-section-fourteen .contact-schedule-main .scheduling-left-main ul,
.banner-wrapper .search-box-3 form,
.map-listing .profile-widget,
.doctor-widget-one .doc-info-left,
.doctor-widget-one,
.clinic-services,
.doc-info-left,
.doctor-widget,
.call-timing,
.call-details,
.password-strength,
.increment-decrement .input-groups,
.login-bottom-copyright,
.custom-increment .input-group1,
chat-attachment-list,
.chat-msg-attachments,
.chat-window .chat-msg-info,
.chat-window .chat-cont-right .chat-body .media.sent .media-body,
.chat-window .chat-cont-right .chat-header .chat-options,
.chat-window .chat-cont-left .chat-users-list a.media .media-body,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend,
.chat-window .chat-cont-left .chat-header,
.patient-graph-col .graph-box .graph-status-result,
.patient-graph-col .graph-box,
.patient-graph-box,
.patient-dashboard-top,
.change-avatar,
.profile-info-widget,
.dash-card .dash-widget,
.upload-wrap,
.review-listing > ul li .comment .comment-body .meta-data .review-count,
.review-listing > ul li .comment,
.appointments .appointment-list,
.appointment-list .profile-info-widget,
.booking-device,
.success-page-cont,
.booking-doc-info,
.work-info,
.section-search .banner-wrapper .search-box form,
.footer-two .footer-top .footer-widget .footer-contact-info .footer-address,
.footer-one .footer-top .footer-widget .footer-contact-info .footer-address,
.footer .footer-top .footer-widget .footer-contact-info .footer-address,
.header.header-ten .main-menu-wrapper .main-nav,
.header-one .main-menu-wrapper .main-nav,
.header .header-nav .header-navbar-rht .has-arrow .user-header,
.header .header-nav .header-navbar-rht .dropdown-menu .dropdown-item,
.header .header-nav .main-nav,
.header .header-nav,
.avatar .avatar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.success-btn .btn,
.terms-section .terms-content .terms-text.terms-list p,
.error-content .btn,
.way-inner-header .btn,
.breadcrumb-bar-two .page-breadcrumb ol,
.booking-btn .btn,
.doctor-search-section.doctor-search-eleven .banner-header .btn,
.breadcrumb-bar-one .page-breadcrumb ol,
.specialities-section-one .specialities-btn .btn,
.footer-one .social-icon ul li a,
.footer-one .social-icon ul,
.footer-two
  .footer-top
  .footer-widget
  .footer-about-content
  .social-icon
  ul
  li
  a,
.footer-two .footer-top .footer-widget .footer-about-content .social-icon ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.booking-date,
.consultation-types,
.contact-card .card-body,
.signup-info,
.testimonial-info,
.about-contact,
.pricing-header-info,
.booking-doctor-right p,
.booking-doctor-left,
.faq-wrap ul li a,
.our-blog-section.eye-blog .our-blogs .blogs-img .blogs-overlay .blog-name,
.count-box,
.doctor-widget-one .clini-infos ul li,
.doctor-widget-one .doc-location,
.doctor-filter-sort,
.doctor-filter-option,
.doctor-filter-availability,
.filter-grid h4 a,
.booking-range .btn,
.booking-date.choose-date-book,
.articles-info,
.doc-pro-location p,
.footer-contacts .footer-wrap,
.footer-one .footer-top .footer-widget.footer-menu ul li a,
.flag-dropdown .dropdown-menu .dropdown-item,
.flag-dropdown .dropdown-toggle {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}

.success-dashboard-link a,
.custom_radio,
.booking-date-list ul li span,
.center-img .center-service,
.doctor-widget-one .doc-name,
.login-content-info .account-content .login-back a,
.back-link a,
.articles-content .articles-list li,
.reviews-ratings p {
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
}

.widget-setcount ul,
.booking-list {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-item,
.chat-window .chat-cont-right .chat-body .media.sent .media-body,
.specialities-section-one .specialities-item {
  flex-direction: column;
}

.flag-dropdown .dropdown-toggle {
  justify-content: start;
  -webkit-justify-content: start;
}

.widget-setcount ul,
.relative-form,
.prefer-box,
.onboarding-content-box.content-wrap,
.left-panel {
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.terms-section .terms-content .terms-text.terms-list p,
.contact-section-fourteen .contact-schedule-main .scheduling-left-main ul {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.chat-window .chat-cont-right .chat-header,
.chat-window .chat-cont-left .chat-users-list a.media .media-body,
.chat-window .chat-cont-left .chat-header,
.listing-day,
.header.header-ten .menu-header,
.header-one .menu-header,
.header .header-nav .menu-header,
.header .header-nav {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.store-tab,
.doctor-divison .pat-background,
.doctor-divison .doc-background,
.login-bottom-copyright,
.custom-increment.cart .input-group1,
.btn-file,
.chat-attachment-item,
.chat-window .chat-cont-right .chat-header .chat-options > a,
.success-cont i,
.hours-info .btn.btn-danger.trash,
.profile-widget .fav-btn,
.section-specialities
  .specialities-slider
  .speicality-item
  .speicality-img
  span,
.header .header-nav .header-navbar-rht li,
.avatar .avatar-title,
.btn-icon {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.increment-decrement .input-groups,
.login-page .login-right,
.btn-file,
.chat-window .chat-msg-info,
.chat-window .chat-cont-right .chat-header .chat-options > a,
.chat-window .chat-cont-right .chat-header .media,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend,
.chat-window .chat-cont-left .chat-header,
.signature,
.change-avatar,
.dash-card .dash-widget,
.btn.btn-danger.trash,
.appointments .appointment-action,
.success-cont i,
.success-page-cont,
.header.header-ten .menu-header,
.header-one .menu-header,
.header .header-nav .header-navbar-rht .has-arrow.logged-item .nav-link,
.header .header-nav .header-navbar-rht .has-arrow .logged-item .nav-link,
.header .header-nav .header-navbar-rht .dropdown-menu .dropdown-item,
.header .header-nav .header-navbar-rht li,
.header .header-nav .menu-header,
table.table td h2.table-avatar,
table.table td h2 .table-avatar,
.avatar .avatar-title,
.btn-icon {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.listing-day {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.doctor-divison .pat-background,
.doctor-divison .doc-background,
.btn-file,
.chat-window .chat-cont-right .chat-header .chat-options > a,
.chat-window .chat-cont-left .chat-header .chat-compose,
.appointments .appointment-action,
.success-cont i,
.profile-widget .fav-btn,
.section-specialities
  .specialities-slider
  .speicality-item
  .speicality-img
  span,
.footer .footer-top .footer-widget .footer-about-content .social-icon ul li a,
.footer .footer-top .footer-widget .footer-about-content .social-icon ul,
.header .header-nav .header-navbar-rht .has-arrow.logged-item .nav-link,
.header .header-nav .header-navbar-rht .has-arrow .logged-item .nav-link,
.header .header-nav .header-navbar-rht li,
table.table td h2.table-avatar,
table.table td h2 .table-avatar,
.avatar-group,
.btn-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.widget-setcount ul,
.onboarding-content .increment-decrement .input-groups input[type="button"],
.relative-form,
.option-set,
.select-gender-option,
.onboarding-contents,
.opt-resend,
.set-path,
.prefer-box,
.call-lable.verify-lable.verify-options,
.call-lable.verify-lable.verify-lable,
.call-lable,
.consult-option,
.save-detail,
.upload-pic.patient-photo-upload,
.upload-pic,
.onboarding-content-box.content-wrap,
.onboarding-progress,
.on-board-wizard ul li a,
#onboard-slider .owl-dots,
.right-panel,
.left-panel,
.onboard-wrapper,
.full-slide-home .slick-slide .slide-image,
.full-slide-home .slick-slide > div > .col-12,
.contact-section-fourteen .contact-schedule-main .scheduling-left-main ul,
.banner-wrapper .search-box-3 form,
.map-listing .profile-widget,
.doctor-widget-one .doc-info-left,
.doctor-widget-one,
.clinic-services,
.doc-info-left,
.doctor-widget,
.call-timing,
.call-details,
.password-strength,
.increment-decrement .input-groups,
.login-bottom-copyright,
.custom-increment .input-group1,
chat-attachment-list,
.chat-msg-attachments,
.chat-window .chat-msg-info,
.chat-window .chat-cont-right .chat-body .media.sent .media-body,
.chat-window .chat-cont-right .chat-header .chat-options,
.chat-window .chat-cont-left .chat-users-list a.media .media-body,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend,
.chat-window .chat-cont-left .chat-header,
.patient-graph-col .graph-box .graph-status-result,
.patient-graph-col .graph-box,
.patient-graph-box,
.patient-dashboard-top,
.change-avatar,
.profile-info-widget,
.dash-card .dash-widget,
.upload-wrap,
.review-listing > ul li .comment .comment-body .meta-data .review-count,
.review-listing > ul li .comment,
.appointments .appointment-list,
.appointment-list .profile-info-widget,
.booking-device,
.success-page-cont,
.booking-doc-info,
.work-info,
.section-search .banner-wrapper .search-box form,
.footer-two .footer-top .footer-widget .footer-contact-info .footer-address,
.footer-one .footer-top .footer-widget .footer-contact-info .footer-address,
.footer .footer-top .footer-widget .footer-contact-info .footer-address,
.header.header-ten .main-menu-wrapper .main-nav,
.header-one .main-menu-wrapper .main-nav,
.header .header-nav .header-navbar-rht .has-arrow .user-header,
.header .header-nav .header-navbar-rht .dropdown-menu .dropdown-item,
.header .header-nav .main-nav,
.header .header-nav,
.avatar .avatar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.payment-radio,
.custom_radio,
.custom_check,
.slick-slider {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.right-panel,
.section-search-pharmacy .banner-wrapper2 .search-box1 form,
.full-slide-home .slick-slide > div > .col-12,
.clinic-services,
.chat-window .chat-msg-info,
.patient-graph-col .graph-box .graph-status-result,
.patient-graph-col .graph-box,
.appointments .appointment-list,
.schedule-nav .nav-tabs,
.post-left ul,
.header.header-ten .header-nav,
.header-one .header-nav,
.header .header-nav,
.custom-medicalrecord-modal .modal-content .upload-medical-records .dropzone {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-item,
.chat-window .chat-cont-right .chat-body .media.sent .media-body,
.specialities-section-one .specialities-item {
  -ms-flex-direction: column;
  flex-direction: column;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body,
.patient-graph-col .graph-box .graph-status-result {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body,
.patient-graph-col .graph-box .graph-status-result {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}

.upload-wrap,
.day-week-month-col ul,
.payment-menthods ul,
.step-list ul,
.header-top ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.col {
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  position: relative;
  min-height: 1px;
}

@media (min-width: 320px) {
  .col.col-xs-12 {
    width: 100%;
  }

  .col.col-xs-11 {
    width: 91.66666667%;
  }

  .col.col-xs-10 {
    width: 83.33333333%;
  }

  .col.col-xs-9 {
    width: 75%;
  }

  .col.col-xs-8 {
    width: 66.66666667%;
  }

  .col.col-xs-7 {
    width: 58.33333333%;
  }

  .col.col-xs-6 {
    width: 50%;
  }

  .col.col-xs-5 {
    width: 41.66666667%;
  }

  .col.col-xs-4 {
    width: 33.33333333%;
  }

  .col.col-xs-3 {
    width: 25%;
  }

  .col.col-xs-2 {
    width: 16.66666667%;
  }

  .col.col-xs-1 {
    width: 8.33333333%;
  }
}

@media (min-width: 568px) {
  .col.col-sm-12 {
    width: 100%;
  }

  .col.col-sm-11 {
    width: 91.66666667%;
  }

  .col.col-sm-10 {
    width: 83.33333333%;
  }

  .col.col-sm-9 {
    width: 75%;
  }

  .col.col-sm-8 {
    width: 66.66666667%;
  }

  .col.col-sm-7 {
    width: 58.33333333%;
  }

  .col.col-sm-6 {
    width: 50%;
  }

  .col.col-sm-5 {
    width: 41.66666667%;
  }

  .col.col-sm-4 {
    width: 33.33333333%;
  }

  .col.col-sm-3 {
    width: 25%;
  }

  .col.col-sm-2 {
    width: 16.66666667%;
  }

  .col.col-sm-1 {
    width: 8.33333333%;
  }
}

@media (min-width: 768px) {
  .col.col-md-12 {
    width: 100%;
  }

  .col.col-md-11 {
    width: 91.66666667%;
  }

  .col.col-md-10 {
    width: 83.33333333%;
  }

  .col.col-md-9 {
    width: 75%;
  }

  .col.col-md-8 {
    width: 66.66666667%;
  }

  .col.col-md-7 {
    width: 58.33333333%;
  }

  .col.col-md-6 {
    width: 50%;
  }

  .col.col-md-5 {
    width: 41.66666667%;
  }

  .col.col-md-4 {
    width: 33.33333333%;
  }

  .col.col-md-3 {
    width: 25%;
  }

  .col.col-md-2 {
    width: 16.66666667%;
  }

  .col.col-md-1 {
    width: 8.33333333%;
  }
}

@media (min-width: 1024px) {
  .col.col-lg-12 {
    width: 100%;
  }

  .col.col-lg-11 {
    width: 91.66666667%;
  }

  .col.col-lg-10 {
    width: 83.33333333%;
  }

  .col.col-lg-9 {
    width: 75%;
  }

  .col.col-lg-8 {
    width: 66.66666667%;
  }

  .col.col-lg-7 {
    width: 58.33333333%;
  }

  .col.col-lg-6 {
    width: 50%;
  }

  .col.col-lg-5 {
    width: 41.66666667%;
  }

  .col.col-lg-4 {
    width: 33.33333333%;
  }

  .col.col-lg-3 {
    width: 25%;
  }

  .col.col-lg-2 {
    width: 16.66666667%;
  }

  .col.col-lg-1 {
    width: 8.33333333%;
  }
}

/******* COMPONENTS ******/
.view-btn {
  color: #0de0fe;
  font-size: 13px;
  border: 2px solid #0de0fe;
  text-align: center;
  display: block;
  font-weight: 500;
  padding: 6px;
}

.view-btn:hover,
.view-btn:focus {
  background-color: #0de0fe;
  color: #ffffff;
}

.book-btn {
  background-color: #0de0fe;
  border: 2px solid #0de0fe;
  color: #ffffff;
  font-size: 13px;
  text-align: center;
  display: block;
  font-weight: 500;
  padding: 6px;
}

.book-btn:hover,
.book-btn:focus {
  background-color: #01cae4;
  border-color: #01cae4;
  color: #ffffff;
}

.submit-btn-bottom {
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .submit-btn-bottom {
    margin-bottom: 20px;
  }
}

@media (max-width: 575.98px) {
  .submit-btn-bottom {
    margin-bottom: 0.9375rem;
  }
}

.bg-info-light {
  background-color: #ebfaec !important;
  color: #2a9d36 !important;
}

.bg-primary-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}

.bg-danger-light {
  background-color: #fde7ea !important;
  color: #c70e2a !important;
}

.bg-warning-light {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}

.bg-success-light {
  background-color: #ebfaec !important;
  color: #2a9d36 !important;
}

.bg-purple-light {
  background-color: rgba(197, 128, 255, 0.12) !important;
  color: #c580ff !important;
}

.bg-default-light {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}

.change-photo-btn {
  background-color: #20c0f3;
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  text-align: center;
  width: 220px;
  -webkit-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50px;
  margin: 0 auto;
  padding: 10px 15px;
}

.btn-icon {
  text-align: center;
  font-size: 10px;
  width: 20px;
  height: 20px;
  padding: 0;
  position: absolute;
  top: 3px;
  right: 3px;
}

.btn-facebook {
  background-color: #3a559f;
  color: #ffffff;
  font-size: 13px;
  padding: 8px 12px;
}

.btn-google {
  background-color: #dd4b39;
  color: #ffffff;
  font-size: 13px;
  padding: 8px 12px;
}

.view-inv-btn {
  font-size: 16px;
  font-weight: 700;
  padding: 12px 30px;
}

@media (max-width: 767.98px) {
  .view-inv-btn {
    font-size: 14px;
    padding: 10px 30px;
  }
}

.banner-btn {
  background: #c70e2a;
  border-radius: 130px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  padding: 20px 30px;
  width: 100%;
}

.pdf-viewer {
  width: 100%; 
  max-width: none;
  display: block;

}
.pdf-container{
position: relative;
  overflow: hidden;

}

.pdf-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7); 
  z-index: 1; 
}



.banner-btn:hover {
  background: #2a9d36;
  color: #ffffff;
}

.book-btn1 {
  background-color: #0de0fe;
  border: 2px solid #0de0fe;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  padding: 6px 10px;
}

.book-btn1:hover {
  background-color: #01cae4;
  border-color: #01cae4;
  color: #ffffff;
}

.search-btn {
  width: 100%;
  margin-top: 15px;
}

.mouse-cursor {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  border-radius: 50%;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  visibility: hidden;
}

.cursor-inner {
  margin-left: -4px;
  margin-top: -4px;
  width: 10px;
  height: 10px;
  z-index: 10000001;
  background: #0e82fd !important;
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.cursor-inner.cursor-hover {
  margin-left: -20px;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background: #0e82fd !important;
  opacity: 0.1;
}

.cursor-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10000000;
  opacity: 0.5;
  border: 2px solid #0e82fd;
  transform: all 0.08s ease-out;
  -webkit-transform: all 0.08s ease-out;
  -ms-transform: all 0.08s ease-out;
}

.cursor-outer.cursor-hover {
  opacity: 0;
}

.mim_tm_all_wrap[data-magic-cursor="hide"] .mouse-cursor {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1111;
}

.progress-wrap {
  position: fixed;
  right: 25px;
  bottom: 25px;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px rgba(75, 64, 237, 0.2);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  transform: translateY(15px);
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  content: "\f062";
  font-family: "FontAwesome";
  text-align: center;
  line-height: 35px;
  font-size: 17px;
  color: #0e82fd;
  left: 0;
  top: 0;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #0e82fd;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.check:checked + .checktoggle {
  background-color: #008f64;
  border: 1px solid #008f64;
}

.check:checked + .checktoggle:after {
  background-color: #ffffff;
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
  -webkit-transform: translate(calc(-100% - 5px), -50%);
  -ms-transform: translate(calc(-100% - 5px), -50%);
}

.checktoggle {
  background-color: #ff3535;
  border: 1px solid #ff3535;
  border-radius: 12px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 42px;
}

.checktoggle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  -webkit-transform: translate(5px, -50%);
  -ms-transform: translate(5px, -50%);
  width: 14px;
  height: 14px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: left 300ms ease, transform 300ms ease;
}

.status-toggle .check:checked + .checktoggle {
  background-color: #28a745;
  border: 1px solid #28a745;
}

.status-toggle .check:checked + .checktoggle:after {
  transform: translate(calc(-100% - 2px), -50%);
  -webkit-transform: translate(calc(-100% - 2px), -50%);
  -ms-transform: translate(calc(-100% - 2px), -50%);
}

.status-toggle .checktoggle {
  width: 41px;
  height: 21px;
}

.status-toggle .checktoggle::after {
  width: 17px;
  height: 17px;
  transform: translate(2px, -50%);
  -webkit-transform: translate(2px, -50%);
  -ms-transform: translate(2px, -50%);
}

.status-tog .checktoggle {
  height: 28px;
  width: 50px;
  border-radius: 999px;
  background-color: #f3f4f6;
  border-color: #f3f4f6;
}

.status-tog .checktoggle:before {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  background-image: url(../images/icons/check.svg);
  background-repeat: no-repeat;
  background-size: 11px 20px;
  width: 20px;
  height: 20px;
  transform: translate(3px, -50%);
}

.status-tog .checktoggle:after {
  width: 24px;
  height: 24px;
  background-image: url(../images/icons/x-icon.svg);
  background-repeat: no-repeat;
  background-size: 12px 21px;
  transform: translate(1px, -50%);
  background-position: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.06);
}

.status-tog .check:checked + .checktoggle:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  background-image: url(../images/icons/x-icon.svg);
  background-repeat: no-repeat;
  background-size: 13px 21px;
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
}

.status-tog .check:checked + .checktoggle:after {
  background-image: url(../images/icons/check.svg);
  background-repeat: no-repeat;
  background-size: 12px 21px;
  transform: translate(calc(-100% - 1px), -50%);
  background-position: center;
}

.bootstrap-tagsinput {
  display: block;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  height: 46px;
  margin: 0;
  padding: 4px 10px 0 10px;
  width: 100%;
  box-shadow: none;
  overflow-y: auto;
}

.bootstrap-tagsinput .tag {
  margin-right: 5px;
  margin-bottom: 2px;
  color: #a9a9a9;
  background: #f4f4f4 !important;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
}

.bootstrap-tagsinput .tag:hover {
  color: #a9a9a9;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  position: relative;
  left: 7px;
  cursor: pointer;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  margin: 0;
  padding: 0px 2px;
}

.bootstrap-tagsinput input {
  line-height: 36px;
  border: 1px solid transparent;
  background: transparent;
}

.bootstrap-tagsinput input:focus-visible {
  border: 1px solid transparent;
}

/****** LAYOUT ******/
.btn.focus,
.btn:focus {
  box-shadow: unset;
}

.btn.btn-rounded {
  border-radius: 50px;
}

.btn-white {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  color: #272b41;
}

.bg-primary,
.badge-primary {
  background-color: #09e5ab !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #09dca4 !important;
}

.bg-success,
.badge-success {
  background-color: #00e65b !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #00cc52 !important;
}

.bg-info,
.badge-info {
  background-color: #009efb !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #028ee1 !important;
}

.bg-warning,
.badge-warning {
  background-color: #ffbc34 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #e9ab2e !important;
}

.bg-danger,
.badge-danger {
  background-color: #ff0100 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #e63333 !important;
}

.bg-white {
  background-color: #ffffff;
}

.bg-purple,
.badge-purple {
  background-color: #9368e9 !important;
}

.text-primary {
  color: #09e5ab !important;
}

.dropdown-menu li > a.text-primary {
  color: #09e5ab !important;
}

.dropdown-menu li > a.text-success {
  color: #00cc52 !important;
}

.dropdown-menu li > a.text-danger {
  color: #ff0100 !important;
}

.dropdown-menu li > a.text-info {
  color: #009efb !important;
}

.dropdown-menu li > a.text-warning {
  color: #ffbc34 !important;
}

.dropdown-menu li > a.text-purple {
  color: #7460ee !important;
}

.text-success {
  color: #00cc52 !important;
}

.text-danger {
  color: #ff0100 !important;
}

.text-info {
  color: #009efb !important;
}

.text-warning {
  color: #ffbc34 !important;
}

.text-purple {
  color: #7460ee !important;
}

.text-muted {
  color: #757575 !important;
}

.btn-primary {
  background-color: #2a9d36;
  border: 1px solid #2a9d36;
}

.btn-primary:active,
.btn-primary.active {
  color: #ffffff;
  background-color: #2a9d36;
  border-color: #2a9d36;
}

.btn-primary:focus,
.btn-primary:hover {
  color: #ffffff;
  /*something more to be added*/
}

.btn-primary:focus .open > .dropdown-toggle.btn-primary,
.btn-primary:hover .open > .dropdown-toggle.btn-primary,
.btn-primary:active .open > .dropdown-toggle.btn-primary,
.btn-primary.active .open > .dropdown-toggle.btn-primary {
  background-color: #2a9d36;
  border: 1px solid #2a9d36;
}

.btn-primary.active.focus .open > .dropdown-toggle.btn-primary.focus,
.btn-primary.active.focus .open > .dropdown-toggle.btn-primary:focus,
.btn-primary.active.focus .open > .dropdown-toggle.btn-primary:hover,
.btn-primary.active:focus .open > .dropdown-toggle.btn-primary.focus,
.btn-primary.active:focus .open > .dropdown-toggle.btn-primary:focus,
.btn-primary.active:focus .open > .dropdown-toggle.btn-primary:hover,
.btn-primary.active:hover .open > .dropdown-toggle.btn-primary.focus,
.btn-primary.active:hover .open > .dropdown-toggle.btn-primary:focus,
.btn-primary.active:hover .open > .dropdown-toggle.btn-primary:hover,
.btn-primary .focus.focus .open > .dropdown-toggle.btn-primary.focus,
.btn-primary .focus.focus .open > .dropdown-toggle.btn-primary:focus,
.btn-primary .focus.focus .open > .dropdown-toggle.btn-primary:hover,
.btn-primary .focus:focus .open > .dropdown-toggle.btn-primary.focus,
.btn-primary .focus:focus .open > .dropdown-toggle.btn-primary:focus,
.btn-primary .focus:focus .open > .dropdown-toggle.btn-primary:hover,
.btn-primary .focus:hover .open > .dropdown-toggle.btn-primary.focus,
.btn-primary .focus:hover .open > .dropdown-toggle.btn-primary:focus,
.btn-primary .focus:hover .open > .dropdown-toggle.btn-primary:hover {
  background-color: #2a9d36;
  border: 1px solid #2a9d36;
}

.btn-primary:active:focus,
.btn-primary:active:hover {
  background-color: #2a9d36;
  border: 1px solid #2a9d36;
}

.btn-primary .active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled) {
  background-color: #2a9d36;
  border-color: #2a9d36;
  color: #ffffff;
}

.btn-primary .active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.btn-primary .show > .btn-primary.dropdown-toggle {
  background-color: #2a9d36;
  border-color: #2a9d36;
  color: #ffffff;
}

.btn-primary .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #2a9d36;
  border-color: #2a9d36;
  color: #ffffff;
}

.btn-secondary.active:focus :not(:disabled):not(.disabled),
.btn-secondary:active:focus :not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}

.show > .btn-success.dropdown-toggle {
  background-color: #00cc52;
  border-color: #00cc52;
  color: #ffffff;
}

.show > .btn-success.dropdown-toggle:focus {
  box-shadow: unset;
}

.show > .btn-info.dropdown-toggle {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #ffffff;
}

.show > .btn-info.dropdown-toggle:focus {
  box-shadow: unset;
}

.show > .btn-warning.dropdown-toggle {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #ffffff;
}

.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: unset;
}

.show > .btn-danger.dropdown-toggle {
  background-color: #e63333;
  border-color: #e63333;
  color: #ffffff;
}

.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: unset;
}

.show > .btn-light.dropdown-toggle:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: unset;
}

.show > .btn-outline-primary.dropdown-toggle {
  background-color: #09e5ab;
  border-color: #09e5ab;
}

.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.show > .btn-outline-success.dropdown-toggle {
  background-color: #00e65b;
  border-color: #00e65b;
}

.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.show > .btn-outline-info.dropdown-toggle {
  background-color: #009efb;
  border-color: #009efb;
}

.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.show > .btn-outline-danger.dropdown-toggle {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success {
  background-color: #00e65b;
  border: 1px solid #00e65b;
}

.btn-success:hover,
.btn-success:focus .btn-success.active,
.btn-success:active {
  background-color: #00cc52;
  border: 1px solid #00cc52;
  color: #ffffff;
}

.btn-success .open > .dropdown-toggle.btn-success {
  background-color: #00cc52;
  border: 1px solid #00cc52;
  color: #ffffff;
}

.btn-success .open > .dropdown-toggle.btn-success.focus,
.btn-success .open > .dropdown-toggle.btn-success:hover,
.btn-success .open > .dropdown-toggle.btn-success:focus {
  background-color: #00cc52;
  border: 1px solid #00cc52;
}

.btn-success.active:focus,
.btn-success.active.focus,
.btn-success.active:hover,
.btn-success.focus,
.btn-success:active:focus,
.btn-success:active:hover {
  background-color: #00cc52;
  border: 1px solid #00cc52;
}

.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled) {
  background-color: #00cc52;
  border-color: #00cc52;
  color: #ffffff;
}

.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #00e65b;
  border-color: #00e65b;
  color: #ffffff;
}

.btn-info {
  background-color: #009efb;
  border: 1px solid #009efb;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-info.active:focus,
.btn-info.active.focus,
.btn-info.active:hover,
.btn-info.focus,
.btn-info:active:focus,
.btn-info:active:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled) {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #ffffff;
}

.btn-info.active:not(:disabled):not(.disabled):focus,
.btn-info:active:not(:disabled):not(.disabled):focus {
  box-shadow: unset;
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #009efb;
  border-color: #009efb;
  color: #ffffff;
}

.btn-warning {
  background-color: #ffbc34;
  border: 1px solid #ffbc34;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-warning.active:focus,
.btn-warning.active.focus,
.btn-warning.active:hover,
.btn-warning.focus,
.btn-warning:active:focus,
.btn-warning:active:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled) {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #ffffff;
}

.btn-warning.active:not(:disabled):not(.disabled):focus,
.btn-warning:active:not(:disabled):not(.disabled):focus {
  box-shadow: unset;
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffbc34;
  border-color: #ffbc34;
  color: #ffffff;
}

.btn-danger {
  background-color: #ff0100;
  border: 1px solid #ff0100;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.btn-danger.active:focus,
.btn-danger.active.focus,
.btn-danger.active:hover,
.btn-danger.focus,
.btn-danger:active:focus,
.btn-danger:active:hover {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled) {
  background-color: #e63333;
  border-color: #e63333;
  color: #ffffff;
}

.btn-danger.active:not(:disabled):not(.disabled):focus,
.btn-danger:active:not(:disabled):not(.disabled):focus {
  box-shadow: unset;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #ff0100;
  border-color: #ff0100;
  color: #ffffff;
}

.open > .dropdown-toggle.btn-info {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.open > .dropdown-toggle.btn-warning {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.open > .dropdown-toggle.btn-danger {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.btn-outline-primary {
  color: #09e5ab;
  border-color: #09e5ab;
}

.btn-outline-primary:hover {
  background-color: #09e5ab;
  border-color: #09e5ab;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: none;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #09e5ab;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #09e5ab;
  border-color: #09e5ab;
}

.btn-outline-success {
  color: #00e65b;
  border-color: #00e65b;
}

.btn-outline-success:hover {
  background-color: #00e65b;
  border-color: #00e65b;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: none;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #00e65b;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active {
  background-color: #00e65b;
  border-color: #00e65b;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #009efb;
  border-color: #009efb;
}

.btn-outline-info:hover {
  color: #ffffff;
  background-color: #009efb;
  border-color: #009efb;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  color: #009efb;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: none;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active {
  background-color: #009efb;
  border-color: #009efb;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  background-color: transparent;
  color: #ffbc34;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: none;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #ff0100;
  border-color: #ff0100;
}

.btn-outline-danger:hover {
  color: #212529;
  background-color: #ff0100;
  border-color: #ff0100;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  background-color: transparent;
  color: #ff0100;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: none;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: #ff0100;
  border-color: #ff0100;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: #ababab;
  border-color: #e6e6e6;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #ababab;
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  background-color: #20c0f3;
  border-color: #20c0f3;
}

.pagination > .active > a,
.pagination > .active > span {
  color: #20c0f3;
}

.pagination > li > a,
.pagination > li > span {
  color: #20c0f3;
}

.page-link:hover {
  color: #20c0f3;
}

.page-link:focus {
  box-shadow: unset;
}

.page-item.active .page-link {
  background-color: #20c0f3;
  border-color: #20c0f3;
}

.dropdown-menu {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inherit;
  font-size: 14px;
  border-radius: 3px;
  transform-origin: left top 0;
  -webkit-transform-origin: left top 0;
  -ms-transform-origin: left top 0;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #0de0fe;
}

.navbar-nav__.open--.dropdown-menu {
  border: 0;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.btn-light {
  border-color: #e6e6e6;
  color: #a6a6a6;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #20c0f3;
  text-shadow: unset;
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: #20c0f3;
}

.bg-info-light {
  background-color: #ebfaec !important;
  color: #2a9d36 !important;
}

.bg-primary-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}

.bg-danger-light {
  background-color: #fde7ea !important;
  color: #c70e2a !important;
}

.bg-warning-light {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}

.bg-success-light {
  background-color: #ebfaec !important;
  color: #2a9d36 !important;
}

.bg-purple-light {
  background-color: rgba(197, 128, 255, 0.12) !important;
  color: #c580ff !important;
}

.bg-default-light {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}

.text-xs {
  font-size: 12px !important;
}

.text-sm {
  font-size: 14px !important;
}

.text-lg {
  font-size: 20px !important;
}

.text-xl {
  font-size: 24px !important;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}

.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar .avatar-title {
  width: 100%;
  height: 100%;
  background-color: #20c0f3;
  color: #ffffff;
}

.avatar.avatar-online::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #00e65b;
}

.avatar.avatar-offline::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #ff0100;
}

.avatar.avatar-away::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #ffbc34;
}

.avatar .border {
  border-width: 3px !important;
}

.avatar .rounded {
  border-radius: 6px !important;
}

.avatar .avatar-title {
  font-size: 18px;
}

.avatar .avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

.avatar .avatar-xs .border {
  border-width: 2px !important;
}

.avatar .avatar-xs .rounded {
  border-radius: 4px !important;
}

.avatar .avatar-xs .avatar-title {
  font-size: 12px;
}

.avatar .avatar-xs.avatar-away::before,
.avatar .avatar-xs.avatar-offline::before,
.avatar .avatar-xs.avatar-online::before {
  border-width: 1px;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-sm .border {
  border-width: 3px !important;
}

.avatar-sm .rounded {
  border-radius: 4px !important;
}

.avatar-sm .avatar-title {
  font-size: 15px;
}

.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
  border-width: 2px;
}

.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}

.avatar-lg .border {
  border-width: 3px !important;
}

.avatar-lg .rounded {
  border-radius: 8px !important;
}

.avatar-lg .avatar-title {
  font-size: 24px;
}

.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
  border-width: 3px;
}

.avatar-xl {
  width: 5rem;
  height: 5rem;
}

.avatar-xl .border {
  border-width: 4px !important;
}

.avatar-xl .rounded {
  border-radius: 8px !important;
}

.avatar-xl .avatar-title {
  font-size: 28px;
}

.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
  border-width: 4px;
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}

@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }
}

.avatar-xxl .border {
  border-width: 6px !important;
}

@media (min-width: 768px) {
  .avatar-xxl .border {
    border-width: 4px !important;
  }
}

.avatar-xxl .rounded {
  border-radius: 8px !important;
}

@media (min-width: 768px) {
  .avatar-xxl .rounded {
    border-radius: 12px !important;
  }
}

.avatar-xxl .avatar-title {
  font-size: 30px;
}

@media (min-width: 768px) {
  .avatar-xxl .avatar-title {
    font-size: 42px;
  }
}

.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
  border-width: 4px;
}

@media (min-width: 768px) {
  .avatar-xxl.avatar-away::before,
  .avatar-xxl.avatar-offline::before,
  .avatar-xxl.avatar-online::before {
    border-width: 4px;
  }
}

.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}

.avatar-group .avatar:hover {
  z-index: 1;
}

.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.40625rem;
}

.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.625rem;
}

.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}

.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}

.avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

.select2-results__option {
  padding: 6px 15px;
}

.select2-container .select2-selection--single {
  border: 1px solid #dcdcdc;
  height: 46px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 15px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 44px;
  right: 7px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #dcdcdc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  margin-left: -10px;
  margin-top: -2px;
  width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #272b41;
  font-size: 15px;
  font-weight: normal;
  line-height: 44px;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #dcdcdc;
  border-width: 0 6px 6px;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #2a9d36;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid #dcdcdc;
  min-height: 46px;
}

.select2-dropdown {
  border-color: #dcdcdc;
}

.breadcrumb-bar {
  background-color: #15558d;
  padding: 15px 0;
}

@media (max-width: 767.98px) {
  .breadcrumb-bar {
    height: auto;
  }
}

.breadcrumb-bar .breadcrumb-title {
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  margin: 5px 0 0;
}

@media (max-width: 767.98px) {
  .breadcrumb-bar .breadcrumb-title {
    font-size: 18px;
  }
}

.breadcrumb-bar .page-breadcrumb ol {
  background-color: transparent;
  font-size: 12px;
  margin-bottom: 0;
  padding: 0;
}

.breadcrumb-bar .page-breadcrumb ol li.active {
  color: #ffffff;
}

.breadcrumb-bar .page-breadcrumb ol li a {
  color: #ffffff;
}

.breadcrumb-bar .page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: #ffffff;
  font-size: 10px;
}

.form-focus {
  height: 50px;
  position: relative;
}

.form-focus .focus-label {
  font-size: 14px;
  font-weight: 400;
  pointer-events: none;
  transform: translate3d(0, 22px, 0) scale(1);
  -webkit-transform: translate3d(0, 22px, 0) scale(1);
  -ms-transform: translate3d(0, 22px, 0) scale(1);
  transform-origin: left top;
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  -webkit-transition: 240ms;
  -ms-transition: 240ms;
  transition: 240ms;
  z-index: 1;
  color: #b8b8b8;
  margin-bottom: 0;
  position: absolute;
  top: -8px;
  left: 12px;
}

.form-focus.focused .focus-label {
  opacity: 1;
  top: -18px;
  font-size: 12px;
  z-index: 1;
}

.form-focus.focused .form-control::-webkit-input-placeholder {
  color: #bbb;
}

.form-focus .form-control {
  height: 50px;
  padding: 21px 12px 6px;
}

.form-focus .form-control:focus ~ .focus-label,
.form-focus .form-control:-webkit-autofill ~ .focus-label {
  opacity: 1;
  font-weight: 400;
  top: -18px;
  font-size: 12px;
  z-index: 1;
}

.form-focus .form-control::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: 240ms;
  -ms-transition: 240ms;
  transition: 240ms;
}

.form-focus .form-control:focus::-webkit-input-placeholder {
  transition: none;
}

.form-focus.select-focus .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -20px;
  font-size: 12px;
  z-index: 1;
}

.form-focus .select2-container .select2-selection--single {
  border: 1px solid #e3e3e3;
  height: 50px;
}

.form-focus
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 15px;
}

.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 48px;
  right: 7px;
}

.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #cccccc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  margin-left: -10px;
  margin-top: -2px;
  width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #676767;
  font-size: 14px;
  font-weight: normal;
  line-height: 38px;
}

.form-focus
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #e3e3e3;
  border-width: 0 6px 6px;
}

.form-focus
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #2a9d36;
}

#loader {
  background-color: #ffffff;
  z-index: 999999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.loader {
  display: inline-block;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.loader span {
  -webkit-animation: loader 1.3s linear infinite;
  animation: loader 1.3s linear infinite;
  background-color: #20c0f3;
  border-radius: 100%;
  display: inline-block;
  height: 50px;
  position: absolute;
  width: 50px;
}

.loader span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
}

@keyframes loader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

table.table {
  color: #272b41;
  max-width: 100%;
  margin-bottom: 0;
  width: 100%;
}

table.table thead {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

table.table thead tr th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

table.table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

table.table tbody tr:last-child {
  border-color: transparent;
}

table.table.table-center td,
table.table.table-center th {
  vertical-align: middle;
}

table.table > tbody > tr > th {
  border-top: 0;
  padding: 10px 8px;
}

table.table .no-border > tbody > tr > th {
  border-top: 0;
  padding: 10px 8px;
}

table.table .no-border > tbody > tfoot > tr > th,
table.table .no-border > tbody > tfoot > tr > td,
table.table .no-border > tbody thead > tr > th,
table.table .no-border > tbody thead > tr > td {
  border-top: 0;
  padding: 10px 8px;
}

table.table .dataTable {
  border-collapse: collapse !important;
}

table .table-striped thead tr {
  border-color: transparent;
}

table .table-striped tbody tr {
  border-color: transparent;
}

table .table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0.3);
}

table .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(235, 235, 235, 0.4);
}

table .table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f8f9fa;
}

table .table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}

table .table-bordered th,
table .table-bordered td {
  border-color: rgba(0, 0, 0, 0.05);
}

table .table-nowrap td,
table .table-nowrap th {
  white-space: nowrap;
}

table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

table.table td h2 .table-avatar {
  font-size: inherit;
  font-weight: 400;
  vertical-align: middle;
  white-space: nowrap;
  margin: 0;
  padding: 0;
}

table.table td h2 a {
  color: #272b41;
}

table.table td h2 a:hover {
  color: #2a9d36;
}

table.table td h2 span {
  color: #888888;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}

table.table td h2.table-avatar {
  font-size: inherit;
  font-weight: 400;
  vertical-align: middle;
  white-space: nowrap;
  margin: 0;
  padding: 0;
}

table .table-hover tbody tr:hover {
  background-color: #f7f7f7;
}

table .table-hover tbody tr:hover td {
  color: #474648;
}

.table-hover tbody tr:hover {
  background-color: #f7f7f7;
}

.table-hover tbody tr:hover td {
  color: #474648;
}

.card-table card-body {
  padding: 0;
}

.card-table card-body .table > thead > tr > th {
  border-top: 0;
}

.card-table card-body .table tr th,
.card-table card-body .table tr td {
  border-top: 1px solid #e2e5e8;
  padding: 1rem 0.75rem;
  white-space: nowrap;
}

.card-table card-body .table tr th:first-child,
.card-table card-body .table tr td:first-child {
  padding-left: 1.5rem;
}

.card-table card-body .table tr th:last-child,
.card-table card-body .table tr td:last-child {
  padding-right: 1.5rem;
}

.card-table .card-body {
  padding: 0;
}

.card-table .card-body .table > thead > tr > th {
  border-top: 0;
}

.card-table .card-body .table tr th,
.card-table .card-body .table tr td {
  border-top: 1px solid #e2e5e8;
  white-space: nowrap;
  padding: 1rem 0.75rem;
}

.card-table .card-body .table tr th:first-child,
.card-table .card-body .table tr td:first-child {
  padding-left: 1.5rem;
}

.card-table .card-body .table tr th:last-child,
.card-table .card-body .table tr td:last-child {
  padding-right: 1.5rem;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  margin: auto;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  display: table;
  content: "";
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 40px;
  height: 40px;
  background: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;
  background: #ffffff;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: absolute;
  top: 50%;
  padding: 0;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -webkit-box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: #0de0fe;
  color: #ffffff;
  opacity: 1;
  outline: none;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  color: #ffffff;
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #383838;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 0;
  z-index: 1;
}

@media (max-width: 767.98px) {
  .slick-prev {
    left: -10px;
  }
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: 0;
}

@media (max-width: 767.98px) {
  .slick-next {
    right: -10px;
  }
}

@media (max-width: 575.98px) {
  .slick-next {
    right: 0px;
  }
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.dot-slider .slick-slide {
  height: 600px;
}

@media (max-width: 991.98px) {
  .dot-slider .slick-slide {
    height: 375px;
  }
}

@media (max-width: 767.98px) {
  .dot-slider .slick-slide {
    height: 100%;
  }
}

.dot-slider .slick-slide img {
  width: 100%;
}

.dot-slider .profile-widget {
  padding: 0;
  border: none;
}

@media (max-width: 991.98px) {
  .dot-slider .profile-widget {
    margin-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .dot-slider .profile-widget {
    margin-bottom: 0;
  }
}

.dot-slider .slick-dots {
  width: unset;
  position: absolute;
  top: 30%;
  right: 50px;
  bottom: unset;
}

.dot-slider .slick-dots li {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 8px 0;
}

.dot-slider .slick-dots li.slick-active button {
  background-color: #1860ca;
}

.dot-slider .slick-dots li button {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #ffffff;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 5px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #c0c0c0;
}

.slick-dots .slick-dots {
  width: unset;
  position: absolute;
  top: 30%;
  right: 50px;
  bottom: unset;
}

.slick-dots .slick-dots li {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 8px 0;
}

.slick-dots .slick-dots li button {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #ffffff;
}

.slick-dots .slick-dots li button:hover,
.slick-dots .slick-dots li button:focus {
  outline: none;
}

.slick-dots .slick-dots li button:hover::before,
.slick-dots .slick-dots li button:focus::before {
  opacity: 1;
}

.slick-dots .slick-dots li.slick-active button {
  background-color: #1860ca;
  background-color: #0de0fe;
}

.slick-dots .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #0de0fe;
}

.clinic-slider .slick-prev,
.browse-slider .slick-prev,
.book-slider .slick-prev {
  right: 80px;
  left: unset;
  top: 0;
  width: 35px;
  height: 35px;
  border-radius: 5px 12px 5px 12px;
  background-color: #e74e84;
  transform: translateX(0%) rotate(-45deg);
  -webkit-transform: translateX(0%) rotate(-45deg);
  -ms-transform: translateX(0%) rotate(-45deg);
  z-index: 999;
}

.clinic-slider .slick-prev:before,
.browse-slider .slick-prev:before,
.book-slider .slick-prev:before {
  content: "";
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  font-family: "slick";
  font-size: 10px;
  line-height: 1;
  opacity: 0.75;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.clinic-slider .slick-prev:hover,
.browse-slider .slick-prev:hover,
.book-slider .slick-prev:hover {
  background-color: #ff4877;
}

.clinic-slider .slick-next,
.browse-slider .slick-next,
.book-slider .slick-next {
  right: 15px;
  left: unset;
  top: 0;
  width: 35px;
  height: 35px;
  border-radius: 5px 12px 5px 12px;
  background-color: #e74e84;
  transform: translateX(0%) rotate(-45deg);
  -webkit-transform: translateX(0%) rotate(-45deg);
  -ms-transform: translateX(0%) rotate(-45deg);
  z-index: 999;
}

.clinic-slider .slick-next:before,
.browse-slider .slick-next:before,
.book-slider .slick-next:before {
  content: "";
  font-family: "slick";
  font-size: 10px;
  line-height: 1;
  opacity: 0.75;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
}

.clinic-slider .slick-next:hover,
.browse-slider .slick-next:hover,
.book-slider .slick-next:hover {
  background-color: #ff4877;
}

.clinic-slider .slick-list,
.browse-slider .slick-list,
.book-slider .slick-list {
  padding-top: 50px;
}

.clinic-slider .slick-slide,
.browse-slider .slick-slide,
.book-slider .slick-slide {
  display: block;
  margin-left: 0;
  width: 280px;
  padding: 10px 10px 0;
}

.clinic-slider .profile-widget,
.browse-slider .profile-widget,
.book-slider .profile-widget {
  padding: 0;
  border: none;
  background-color: transparent;
  border-radius: 0;
}

.clinic-slider .profile-widget .brower-box,
.browse-slider .profile-widget .brower-box,
.book-slider .profile-widget .brower-box {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  box-shadow: 0px 4px 44px rgba(238, 238, 238, 0.25);
  border-radius: 10px;
  margin: 0;
  padding: 30px;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.clinic-slider .profile-widget .brower-box:hover,
.browse-slider .profile-widget .brower-box:hover,
.book-slider .profile-widget .brower-box:hover {
  background-color: #1860ca;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.clinic-slider .profile-widget .brower-box:hover a,
.browse-slider .profile-widget .brower-box:hover a,
.book-slider .profile-widget .brower-box:hover a {
  background-color: #ffffff;
}

.clinic-slider .profile-widget .brower-box:hover .img-fluid,
.browse-slider .profile-widget .brower-box:hover .img-fluid,
.book-slider .profile-widget .brower-box:hover .img-fluid {
  display: none;
}

.clinic-slider .profile-widget .brower-box:hover .img-fluid.hover,
.browse-slider .profile-widget .brower-box:hover .img-fluid.hover,
.book-slider .profile-widget .brower-box:hover .img-fluid.hover {
  display: block;
}

.clinic-slider .profile-widget .brower-box:hover h4,
.browse-slider .profile-widget .brower-box:hover h4,
.book-slider .profile-widget .brower-box:hover h4 {
  color: #ffffff;
}

.clinic-slider .profile-widget .brower-box:hover p,
.browse-slider .profile-widget .brower-box:hover p,
.book-slider .profile-widget .brower-box:hover p {
  color: #ffffff;
}

.clinic-slider .profile-widget .brower-box:hover p span,
.browse-slider .profile-widget .brower-box:hover p span,
.book-slider .profile-widget .brower-box:hover p span {
  color: #ffffff;
}

.clinic-slider .profile-widget .brower-box:hover a.arrow,
.browse-slider .profile-widget .brower-box:hover a.arrow,
.book-slider .profile-widget .brower-box:hover a.arrow {
  background-color: #2868c8;
}

.clinic-slider .profile-widget .brower-box a,
.browse-slider .profile-widget .brower-box a,
.book-slider .profile-widget .brower-box a {
  height: 85px;
  width: 85px;
  background-color: #1860ca;
  border-radius: 50% 50% 50% 50%/60% 60% 35% 35%;
  margin: 0 auto 15px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.clinic-slider .profile-widget .brower-box img,
.browse-slider .profile-widget .brower-box img,
.book-slider .profile-widget .brower-box img {
  width: 70px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.clinic-slider .profile-widget .brower-box .img-fluid.hover,
.browse-slider .profile-widget .brower-box .img-fluid.hover,
.book-slider .profile-widget .brower-box .img-fluid.hover {
  display: none;
}

.clinic-slider .profile-widget .brower-box h4,
.browse-slider .profile-widget .brower-box h4,
.book-slider .profile-widget .brower-box h4 {
  color: #000000;
  font-size: 20px;
  margin-bottom: 13px;
}

.clinic-slider .profile-widget .brower-box p,
.browse-slider .profile-widget .brower-box p,
.book-slider .profile-widget .brower-box p {
  margin-bottom: 20px;
}

.clinic-slider .profile-widget .brower-box .arrow,
.browse-slider .profile-widget .brower-box .arrow,
.book-slider .profile-widget .brower-box .arrow {
  background-color: #0ce0ff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  transform: unset;
  margin: 0 auto;
}

.clinic-slider .profile-widget .brower-box .arrow i,
.browse-slider .profile-widget .brower-box .arrow i,
.book-slider .profile-widget .brower-box .arrow i {
  color: #ffffff;
}

.brower-box {
  text-align: center;
  border-radius: 10px;
  border: 6px solid #ffffff;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.brower-box h4 {
  font-size: 16px;
  font-weight: 600;
  color: #2264c5;
}

.brower-box p {
  font-size: 14px;
  font-weight: 500;
  color: #e74e84;
  margin: 0;
}

.brower-box p span {
  color: #000000;
}

.brower-box a {
  height: 85px;
  width: 85px;
  background-color: #1860ca;
  border-radius: 50% 50% 50% 50%/60% 60% 35% 35%;
  margin: 0 auto 15px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.aval-slider .slick-slide {
  display: block;
  margin-left: 0;
  padding: 10px;
  width: 280px;
}

.aval-slider .slick-list {
  padding: 50px 0;
}

@media (max-width: 767.98px) {
  .aval-slider .slick-list {
    padding: 30px 0;
  }
}

.aval-slider .slick-prev {
  left: 5px;
  width: 35px;
  height: 35px;
}

.aval-slider .slick-prev:before {
  font-family: "slick";
  font-size: 10px;
  line-height: 1;
  opacity: 0.75;
  color: #1860ca;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: solid #1860ca;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  content: "";
  transform: rotate(140deg);
  -webkit-transform: rotate(140deg);
  -ms-transform: rotate(140deg);
}

.aval-slider .slick-next {
  right: 5px;
  width: 35px;
  height: 35px;
}

.aval-slider .slick-next:before {
  font-family: "slick";
  font-size: 10px;
  line-height: 1;
  opacity: 0.75;
  color: #1860ca;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: solid #1860ca;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  content: "";
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

.content {
  min-height: 200px;
  padding: 80px 0 60px;
}

@media (max-width: 991.98px) {
  .content {
    padding: 60px 0 40px;
  }
}

@media (max-width: 767.98px) {
  .content {
    padding: 50px 0 30px;
  }
}

.top-space {
  padding-top: 140px;
}

.account-page .content.top-space {
  padding-top: 140px;
}

.card {
  border: 1px solid #f0f0f0;
  margin-bottom: 1.875rem;
}

@media (max-width: 767.98px) {
  .card {
    margin-bottom: 20px;
  }
}

@media (max-width: 575.98px) {
  .card {
    margin-bottom: 0.9375rem;
  }
}

.card .card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
}

@media (max-width: 575.98px) {
  .card .card-header {
    padding: 0.75rem 1.25rem;
  }
}

.card .card-header .card-title {
  margin-bottom: 0;
}

.card-body {
  padding: 1.5rem;
}

@media (max-width: 575.98px) {
  .card-body {
    padding: 1.25rem;
  }
}

.card-title {
  margin-bottom: 15px;
}

.card-header {
  border-bottom: 1px solid #f0f0f0;
  padding: 1rem 1.5rem;
}

@media (max-width: 575.98px) {
  .card-header {
    padding: 0.75rem 1.25rem;
  }
}

.card-footer {
  background-color: #ffffff;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 1.5rem;
}

@media (max-width: 575.98px) {
  .card-footer {
    padding: 0.75rem 1.25rem;
  }
}

.custom-modal .modal-content {
  border: 0;
  border-radius: 10px;
}

.custom-modal .modal-content .modal-header {
  padding: 1.25rem;
}

.custom-modal .modal-content .modal-header .modal-title {
  font-size: 20px;
}

.custom-modal .close {
  background-color: #a0a0a0;
  color: #ffffff;
  font-size: 17px;
  height: 20px;
  line-height: 20px;
  opacity: 1;
  width: 20px;
  z-index: 99;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 26px;
  right: 20px;
}

.custom-modal .modal-body {
  padding: 1.25rem;
}

.custom-modal .modal-footer {
  padding: 1.25rem;
}

.custom-modal .modal-footer .btn-outline {
  margin: 0.25rem auto;
}

.custom-medicalrecord-modal .modal-content .modal-header {
  border-color: #e5e5e5;
  margin: 1.5rem 1.5rem 0 1.5rem;
  padding: 0 0 1rem;
}

.custom-medicalrecord-modal .modal-content .form-control,
.custom-medicalrecord-modal .modal-content .bootstrap-tagsinput {
  background-color: #f5f5f7;
  border-color: #f5f5f7;
}

.custom-medicalrecord-modal
  .modal-content
  .select2-container
  .select2-selection--single {
  background-color: #f5f5f7;
  border-color: #f5f5f7;
}

.custom-medicalrecord-modal .modal-content .upload-medical-records {
  position: relative;
}

.custom-medicalrecord-modal
  .modal-content
  .upload-medical-records
  input[type="file"] {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.custom-medicalrecord-modal .modal-content .upload-medical-records .dropzone {
  min-height: 180px;
}

.custom-medicalrecord-modal
  .modal-content
  .upload-medical-records
  .dropzone
  h5 {
  color: #7c7c7c;
  margin-bottom: 3px;
}

.custom-medicalrecord-modal
  .modal-content
  .upload-medical-records
  .dropzone
  h6 {
  color: #7c7c7c;
  margin-bottom: 3px;
}

.custom-medicalrecord-modal
  .modal-content
  .upload-medical-records
  .dropzone
  h6
  span {
  text-decoration: underline;
}

.header .header-nav {
  background-color: #ffffff;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
  height: 85px;
  margin-bottom: 0;
  padding: 0 30px;
}

@media (max-width: 1199.98px) {
  .header .header-nav {
    padding: 0 20px;
  }
}

@media (max-width: 575.98px) {
  .header .header-nav {
    padding: 0 15px;
  }
}

@media (max-width: 991.98px) {
  .header .header-nav .navbar-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

@media (max-width: 575.98px) {
  .header .header-nav .navbar-header {
    width: 100%;
  }
}

.header .header-nav .navbar-header .bar-icon {
  display: inline-block;
  width: 31px;
}

.header .header-nav .navbar-header .bar-icon span {
  background-color: #0de0fe;
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 7px;
  width: 31px;
  border-radius: 2px;
}

.header .header-nav .navbar-header .bar-icon span:nth-child(2) {
  width: 16px;
}

.header .header-nav .navbar-header .bar-icon span:nth-child(3) {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .header .header-nav .navbar-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

@media (max-width: 575.98px) {
  .header .header-nav .navbar-header {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .header .header-nav .main-menu-wrapper {
    order: 3;
    width: 260px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1060;
    transform: translateX(-260px);
    -webkit-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
    background-color: #15558d;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }
}

.header .header-nav .menu-header {
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #f0f0f0;
  display: none;
  padding: 0 20px;
}

@media (max-width: 991.98px) {
  .header .header-nav .menu-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.header .header-nav .menu-header .menu-logo img {
  height: 40px;
}

.header .header-nav .menu-header .menu-close {
  font-size: 18px;
}

.header .header-nav .main-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 991.98px) {
  .header .header-nav .main-nav {
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 0;
    list-style: none;
    padding: 0;
  }
}

@media (max-width: 991.98px) {
  .header .header-nav .main-nav ul {
    background-color: #3474ac;
    display: none;
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .header
    .header-nav
    .main-nav
    > .has-submenu
    > .submenu
    > .has-submenu
    > .submenu::before {
    top: 20px;
    margin-left: -35px;
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
    border-color: transparent #ffffff #ffffff transparent;
  }
}

.header .header-nav .main-nav > .has-submenu.active .submenu li.active > a {
  color: #2a9d36;
}

@media (min-width: 992px) {
  .header .header-nav .main-nav > .has-submenu.active .submenu li.active > a {
    color: #09dca4;
  }
}

.header .header-nav .main-nav > .has-submenu.active > a {
  color: #09dca4;
}

@media (min-width: 992px) {
  .header .header-nav .main-nav > .has-submenu.active > a {
    color: #09dca4;
  }
}

@media (max-width: 1199.98px) {
  .header .header-nav .main-nav > li {
    margin-right: 20px;
  }
}

@media (max-width: 1999.98px) {
  .header .header-nav .main-nav > li {
    margin-right: 30px;
  }
}

@media (max-width: 1399.98px) {
  .header .header-nav .main-nav > li {
    margin-right: 16px;
  }
}

@media (max-width: 991.98px) {
  .header .header-nav .main-nav > li {
    border-bottom: 1px solid #1663a6;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .header .header-nav .main-nav > li > a {
    line-height: 85px;
    padding: 0 !important;
  }
}

@media (max-width: 991.98px) {
  .header .header-nav .main-nav > li > a {
    line-height: 1.5;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 20px !important;
  }
}

@media (min-width: 992px) {
  .header .header-nav .main-nav > li > a > i {
    font-size: 12px;
    margin-left: 3px;
  }
}

@media (max-width: 991.98px) {
  .header .header-nav .main-nav > li > a > i {
    float: right;
    margin-top: 5px;
  }
}

.header .header-nav .main-nav > li.active > a {
  color: #09dca4;
}

.header .header-nav .main-nav > li .submenu li {
  position: relative;
}

@media (min-width: 992px) {
  .header .header-nav .main-nav > li .submenu li:first-child a {
    border-top: 0;
  }
}

.header .header-nav .main-nav > li .submenu li a {
  display: block;
  clear: both;
  white-space: nowrap;
  font-size: 14px;
  color: #2d3b48;
  width: 100%;
  border-top: 1px solid #f0f0f0;
  -webkit-transition: all 0.35s ease;
  -ms-transition: all 0.35s ease;
  transition: all 0.35s ease;
  padding: 10px 15px;
}

@media (max-width: 991.98px) {
  .header .header-nav .main-nav > li .submenu li a {
    border-top: 0;
    color: #ffffff;
    padding: 10px 15px 10px 35px;
  }
}

@media (min-width: 992px) {
  .header .header-nav .main-nav > li .submenu li .submenu {
    left: 100%;
    top: 0;
    margin-top: 10px;
  }
}

.header .header-nav .main-nav > li .submenu li .submenu li {
  position: relative;
}

.header .header-nav .main-nav > li .submenu li .submenu li a {
  display: block;
  clear: both;
  white-space: nowrap;
  font-size: 14px;
  color: #2d3b48;
  width: 100%;
  border-top: 1px solid #f0f0f0;
  padding: 10px 15px;
  -webkit-transition: all 0.35s ease;
  -ms-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

@media (max-width: 991.98px) {
  .header .header-nav .main-nav > li .submenu li .submenu li a {
    border-top: 0;
    color: #ffffff;
    padding: 10px 15px 10px 45px;
  }
}

.header
  .header-nav
  .main-nav
  > li
  .submenu
  li
  .submenu
  li
  .has-submenu
  > a::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 13px;
  position: absolute;
  top: 12px;
  right: 15px;
}

@media (min-width: 992px) {
  .header .header-nav .main-nav > li .submenu > li .submenu {
    left: 100%;
    top: 0;
    margin-top: 10px;
  }
}

.header .header-nav .main-nav > li .submenu > li.has-submenu > a::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 13px;
  position: absolute;
  top: 12px;
  right: 15px;
}

@media (max-width: 991.98px) {
  .header .header-nav .main-nav > li .submenu > li.has-submenu > a::after {
    content: "\f078";
  }
}

@media (min-width: 992px) {
  .header .header-nav .main-nav > li:last-child {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .header
    .header-nav
    .main-nav
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: -1px;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .header .header-nav .main-nav li {
    display: block;
    position: relative;
  }
}

@media (min-width: 992px) {
  .header .header-nav .main-nav li > ul {
    background-color: #ffffff;
    display: block;
    font-size: 14px;
    min-width: 200px;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  }
}

.header .header-nav .main-nav li a {
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.header .header-nav .main-nav li.login-link {
  display: none;
}

@media (max-width: 575.98px) {
  .header .header-nav .main-nav li.login-link {
    display: block;
  }
}

@media (min-width: 992px) {
  .header .header-nav .main-nav li .submenu a:hover {
    color: #09dca4 !important;
    letter-spacing: 0.5px;
    padding-left: 20px !important;
  }
}

@media (min-width: 992px) {
  .header .header-nav .main-nav li .submenu::before {
    border: 7px solid #ffffff;
    border-color: transparent transparent #ffffff #ffffff;
    content: "";
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    position: absolute;
    top: 2px;
    left: 45px;
    -webkit-box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 991.98px) {
  .header .header-nav .main-nav li + li {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .header .header-nav .main-nav li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
  }
}

.header .header-nav .main-nav .has-submenu.active > a {
  color: #09dca4;
}

@media (min-width: 992px) {
  .header .header-nav .main-nav .has-submenu.active > a {
    color: #09dca4;
  }
}

.header .header-nav .main-nav .has-submenu.active .submenu li.active > a {
  color: #09dca4;
}

@media (min-width: 992px) {
  .header .header-nav .main-nav .has-submenu.active .submenu li.active > a {
    color: #09dca4;
  }
}

@media (min-width: 992px) {
  .header .header-nav .main-nav .has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
  }
}

.header
  .header-nav
  .main-nav
  .has-submenu
  > .submenu__
  > .has-submenu--.submenu::before {
  top: 20px;
  margin-left: -35px;
  border-color: transparent #ffffff #ffffff transparent;
  -webkit-box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
}

.header .header-nav .logo {
  display: inline-block;
  margin-right: 30px;
  width: 160px;
}

@media (max-width: 1199.98px) {
  .header .header-nav .logo {
    margin-right: 21px;
  }
}

@media (max-width: 575.98px) {
  .header .header-nav .logo {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
}

@media (max-width: 575.98px) {
  .header .header-nav .logo img {
    height: 40px;
  }
}

.header .header-nav .header-navbar-rht {
  margin: 0 0 0 auto;
  padding: 0;
}

@media (max-width: 575.98px) {
  .header .header-nav .header-navbar-rht {
    display: none;
  }
}

.header .header-nav .header-navbar-rht li {
  padding-right: 20px;
}

.header .header-nav .header-navbar-rht li:last-child {
  padding-right: 0px;
}

.header .header-nav .header-navbar-rht li .dropdown-menu {
  border: 0;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .header .header-nav .header-navbar-rht li .dropdown-menu {
    min-width: 200px;
    visibility: hidden;
    opacity: 0;
    display: block;
    margin: 0;
    padding: 0;
    border-radius: 5px;
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
  }
}

.header .header-nav .header-navbar-rht li .dropdown-menu::before {
  content: "";
  border: 7px solid #ffffff;
  border-color: transparent transparent #ffffff #ffffff;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  position: absolute;
  top: -5px;
  right: 17px;
  -webkit-box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
}

.header .header-nav .header-navbar-rht li .dropdown-menu .dropdown-item {
  border-top: 1px solid #f0f0f0;
  padding: 10px 15px;
}

.header
  .header-nav
  .header-navbar-rht
  li
  .dropdown-menu
  .dropdown-item:first-child {
  border-top: 0;
  border-radius: 5px 5px 0 0;
}

.header
  .header-nav
  .header-navbar-rht
  li
  .dropdown-menu
  .dropdown-item:last-child {
  border-radius: 0 0 5px 5px;
}

.header .header-nav .header-navbar-rht li .dropdown-menu a:hover {
  color: #09dca4;
  letter-spacing: 0.5px;
  padding-left: 20px;
  background-color: #ffffff;
}

.header .header-nav .header-navbar-rht li a.header-login {
  border: 2px solid #09e5ab;
  text-align: center;
  font-size: 15px;
  color: #09e5ab;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px 15px !important;
}

.header .header-nav .header-navbar-rht li a.header-login:hover {
  background-color: #09e5ab;
  border-color: #09e5ab;
  color: #ffffff;
}

.header .header-nav .header-navbar-rht li .header-contact-img {
  display: inline-block;
}

.header .header-nav .header-navbar-rht li .header-contact-img i {
  color: rgba(0, 0, 0, 0.5);
  font-size: 30px;
}

.header .header-nav .header-navbar-rht li .header-contact-detail {
  display: inline-block;
  padding-left: 10px;
}

.header
  .header-nav
  .header-navbar-rht
  li
  .header-contact-detail
  p.contact-header {
  color: #484848;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 2px;
  text-align: left;
}

.header
  .header-nav
  .header-navbar-rht
  li
  .header-contact-detail
  p.contact-info-header {
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}

.header
  .header-nav
  .header-navbar-rht
  li
  .header-contact-detail
  p.contact-info-header.white-font {
  color: #ffffff;
}

@media (min-width: 992px) {
  .header .header-nav .header-navbar-rht li.show > .dropdown-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
  }
}

.header .header-nav .header-navbar-rht li .dropdown-menu.show {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

@media (min-width: 992px) {
  .header .header-nav .header-navbar-rht li .dropdown-menu {
    min-width: 200px;
    visibility: hidden;
    opacity: 0;
    display: block;
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    border-radius: 5px;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 991.98px) {
  .header .header-nav .header-navbar-rht li.contact-item {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .header .header-nav .header-navbar-rht li.contact-item {
    display: none;
  }
}

.header .header-nav .header-navbar-rht .dropdown-toggle::after {
  display: none;
}

.header .header-nav .header-navbar-rht .dropdown-menu {
  min-width: 200px;
  padding: 0;
}

.header .header-nav .header-navbar-rht .dropdown-menu .dropdown-item {
  border-top: 1px solid #e3e3e3;
  padding: 10px 15px;
}

.header .header-nav .header-navbar-rht .dropdown-menu .dropdown-item:hover {
  color: #0e82fd;
}

.header .header-nav .header-navbar-rht .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #757575;
  border-right: 2px solid #757575;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  width: 8px;
  vertical-align: 2px;
  margin-left: 10px;
  transform-origin: 66% 66%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.header
  .header-nav
  .header-navbar-rht
  .has-arrow
  .dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
}

.header .header-nav .header-navbar-rht .has-arrow .dropdown-toggle .user-img {
  display: inline-block;
  position: relative;
}

.header
  .header-nav
  .header-navbar-rht
  .has-arrow
  .dropdown-toggle
  .user-img
  > img {
  height: 31px;
  object-fit: cover;
  width: 31px;
}

.header .header-nav .header-navbar-rht .has-arrow .user-header {
  padding: 10px 15px;
}

.header .header-nav .header-navbar-rht .has-arrow .user-header .user-text {
  margin-left: 10px;
}

.header .header-nav .header-navbar-rht .has-arrow .user-header .user-text h6 {
  font-size: 15px;
  margin-bottom: 2px;
}

.header .header-nav .header-navbar-rht .has-arrow .logged-item .nav-link {
  font-size: 14px;
  line-height: 85px;
  padding: 0 10px;
}

.header .header-nav .header-navbar-rht .has-arrow.logged-item .nav-link {
  font-size: 14px;
  line-height: 85px;
  padding: 0 10px;
}

.header .header-nav .header-navbar-rht .view-cart-header {
  position: relative;
}

.header .header-nav .header-navbar-rht .view-cart-header a#cart {
  position: relative;
  font-size: 18px;
  color: #6b7280;
}

.header .header-nav .header-navbar-rht .view-cart-header a#cart:hover {
  color: #0e82fd;
}

.header .header-nav .header-navbar-rht .noti-nav .nav-link {
  font-size: 18px;
  color: #6b7280;
}

.header .header-nav .header-navbar-rht .noti-nav .nav-link .badge {
  background-color: #0e82fd;
  border-radius: 2px;
  color: #ffffff;
  font-size: 10px;
  font-style: normal;
  text-align: center;
  position: absolute;
  top: 19px;
  right: -6px;
  padding: 0 5px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-weight: normal;
}

.header .header-nav .header-navbar-rht .noti-nav .dropdown-menu {
  min-width: 370px;
  padding: 0;
  font-weight: normal;
}

.header
  .header-nav
  .header-navbar-rht
  .noti-nav
  .dropdown-menu
  .topnav-dropdown-header {
  border-bottom: 1px solid #eef1fe;
  height: 44px;
  line-height: 44px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.header .header-nav .header-navbar-rht .noti-nav .dropdown-menu .noti-content {
  height: 260px;
  width: 420px;
  overflow-y: auto;
  position: relative;
}

.header
  .header-nav
  .header-navbar-rht
  .noti-nav
  .dropdown-menu
  .notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header
  .header-nav
  .header-navbar-rht
  .noti-nav
  .dropdown-menu
  ul.notification-list
  > li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
  padding: 0;
  margin: 0;
  width: 100%;
}

.header
  .header-nav
  .header-navbar-rht
  .noti-nav
  .dropdown-menu
  ul.notification-list
  > li
  a {
  display: block;
  padding: 10px 15px;
  border-radius: 2px;
  width: 100%;
}

.header
  .header-nav
  .header-navbar-rht
  .noti-nav
  .dropdown-menu
  ul.notification-list
  > li
  a:hover {
  letter-spacing: normal;
}

.header
  .header-nav
  .header-navbar-rht
  .noti-nav
  .dropdown-menu
  .media:last-child {
  border-bottom: none;
}

.header
  .header-nav
  .header-navbar-rht
  .noti-nav
  .dropdown-menu
  .media
  > .avatar {
  margin-right: 10px;
  width: auto;
  height: auto;
}

.header
  .header-nav
  .header-navbar-rht
  .noti-nav
  .dropdown-menu
  .media
  > .avatar
  > img {
  width: 46px;
  height: 46px;
  border-radius: 5px;
  margin-right: 8px;
}

.header .header-nav .header-navbar-rht .noti-nav .dropdown-menu .media-body {
  width: 100%;
}

.header .header-nav .header-navbar-rht .noti-nav .dropdown-menu .media-body h6 {
  margin-bottom: 3px;
}

.header .header-nav .header-navbar-rht .noti-nav .dropdown-menu .noti-title {
  color: #0e82fd;
}

.header
  .header-nav
  .header-navbar-rht
  .noti-nav
  .dropdown-menu
  .notification-time {
  font-size: 12px;
  line-height: 1.35;
  float: right;
}

.header .nav-transparent {
  background-color: transparent !important;
  border-bottom: 0 !important;
}

@media (min-width: 992px) {
  .header .nav-transparent .white-font > li > a {
    color: #ffffff;
  }
}

.header.custom {
  background-color: transparent;
  border-bottom: 0 !important;
}

.header.custom .no-border {
  border: none !important;
}

@media (min-width: 992px) {
  .header.custom .white-font > li > a {
    color: #ffffff;
  }
}

.header.custom .header-navbar-rht.right-menu .header-contact-img i {
  width: 30px;
  background-color: #ffffff;
  height: 30px;
  border-radius: 50%;
  font-size: 11px;
  color: #1860ca;
  border: 2px solid #acc9f5;
}

.header.custom
  .header-navbar-rht.right-menu
  .header-contact-detail
  p.contact-info-header {
  color: #ffffff;
}

.header.custom .header-navbar-rht.right-menu li {
  border-right: 0;
}

.header.custom .header-navbar-rht.right-menu li a.header-login {
  width: 120px;
  padding: 10px !important;
}

.header.custom .header-navbar-rht.right-menu li:last-child {
  border-right: 0;
  padding: 0;
}

.header.header-five .no-border {
  border: none !important;
}

.header.header-five .main-menu-wrapper {
  margin-left: 40px !important;
}

@media (max-width: 991.98px) {
  .header.header-five .main-menu-wrapper {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .header.header-five .main-menu-wrapper .main-nav .has-submenu.active > a {
    color: #28daf4;
  }
}

.header.header-five .main-menu-wrapper .main-nav .has-submenu.active > a:after {
  content: "";
  width: 70px;
  background: #28daf4;
  height: 2px;
  z-index: 9;
  position: absolute;
  bottom: 25px;
  left: -7px;
}

.header.header-five .main-menu-wrapper .main-nav.blue-font > li > a {
  color: #2a85d1;
  text-transform: uppercase;
}

.header.header-five .header-navbar-rht li a.header-login.white-bg {
  background-color: #ffffff;
  color: #28daf4 !important;
  border: 1px solid #28daf4;
  font-size: 12px;
}

.header .user-menu {
  float: right;
  position: relative;
  z-index: 99;
  margin: 0;
}

.header .user-menu.nav li a {
  color: #ffffff;
  font-size: 14px;
  line-height: 58px;
  height: 60px;
  padding: 0 15px;
}

.header .user-menu.nav li a:hover,
.header .user-menu.nav li a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.header .user-menu.nav li a:hover i,
.header .user-menu.nav li a:focus i {
  color: #ffffff;
}

.header .user-menu.nav li a.mobile_btn {
  border: 0;
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.header.header-four .header-nav {
  background-color: #2264c5;
  border: none;
}

@media (min-width: 992px) {
  .header.header-four .main-nav .has-submenu.active > a {
    color: #ffffff;
    border-top: 3px solid #e74e84;
  }
}

@media (min-width: 992px) {
  .header.header-four .main-nav > li > a {
    line-height: 80px;
    color: #ffffff;
  }
}

.header.header-four .nav-item {
  line-height: 60px;
  align-items: center;
  display: flex !important;
}

.header.header-four .nav-item a.header-login {
  border: 2px solid #ffffff;
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 15px !important;
}

.header.header-four .nav-item a.header-login:hover {
  background-color: #10defd;
  border: 2px solid #10defd;
}

.header.header-four .nav-item a.header-login i {
  font-size: 14px;
  margin: 0;
  margin-right: 3px;
}

.header.header-trans {
  position: fixed;
  display: block;
  width: 100%;
  z-index: 9999;
  background: transparent;
}

.header.header-trans .header-nav {
  background: transparent;
  border-bottom: none;
}

.header.header-trans .main-menu-wrapper {
  margin-left: auto;
}

@media (max-width: 1399.98px) {
  .header.header-trans .main-menu-wrapper {
    margin-right: auto;
    margin-left: unset;
  }
}

.header.header-trans .header-navbar-rht li {
  height: 20px;
  padding-right: 15px;
}

@media (max-width: 1399.98px) {
  .header.header-trans .header-navbar-rht li {
    padding-right: 5px;
  }
}

.header.header-trans .header-navbar-rht li .header-contact-img i {
  color: #1c5b90;
  font-size: 14px;
}

.header.header-trans
  .header-navbar-rht
  li
  .header-contact-detail
  p.contact-info-header {
  color: #1c5b90;
}

.header.header-trans .header-navbar-rht li:last-child {
  border-right: 0;
  padding-left: 15px;
}

@media (max-width: 1199.98px) {
  .header.header-trans .header-navbar-rht li:last-child {
    padding-left: 0;
  }
}

.header.header-trans .header-navbar-rht li a.header-login {
  text-align: center;
  font-size: 13px;
  color: #ffffff;
  text-transform: uppercase;
  background: #1c5b91;
  box-shadow: 0px 4px 4px rgba(189, 189, 189, 0.25);
  border-radius: 47px;
  border: 0;
}

.header.header-trans .header-navbar-rht li a.header-login.white-bg {
  background-color: #ffffff;
  color: #2264c5;
}

.header.header-trans .header-navbar-rht li a.header-login:hover {
  background-color: #1c5b91;
  color: #ffffff;
}

.header-one {
  width: 100%;
  z-index: 1040;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background-color: transparent;
  box-shadow: 0px 4px 4px rgba(198, 198, 198, 0.25);
}

.header-one .header-nav .main-nav .has-submenu.active .submenu li.active > a {
  color: #0e82fd;
}

.header-one.min-header {
  width: 100%;
  z-index: 1040;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background-color: transparent;
}

.header-one.header-fixed {
  position: fixed;
  display: block;
  width: 100%;
  z-index: 9999;
  background: #f2f6f6;
  box-shadow: inherit;
}

.header-one.header-space .header-nav {
  height: 80px;
}

.header-one .header-nav {
  background-color: transparent;
  border: 0;
  position: relative;
  margin: 0;
  padding: 0;
  height: 100px;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media (max-width: 991.98px) {
  .header-one .header-nav {
    height: 70px;
  }
}

@media (max-width: 991.98px) {
  .header-one .header-nav {
    margin: 0;
    padding: 0 0;
  }
}

@media (max-width: 991.98px) {
  .header-one .header-nav .menu-header {
    height: 70px;
  }
}

@media (max-width: 991.98px) {
  .header-one .header-nav .main-menu-wrapper {
    background-color: #0e82fd;
  }
}

@media (max-width: 991.98px) {
  .header-one .navbar-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
  }
}

.header-one .navbar-header #mobile_btn {
  display: none;
  margin-right: 30px;
}

@media (max-width: 991.98px) {
  .header-one .navbar-header #mobile_btn {
    display: inline-block;
    margin: 0;
    padding: 0 0;
  }
}

@media (max-width: 767.98px) {
  .header-one .navbar-header #mobile_btn {
    margin-right: 0;
  }
}

.header-one .navbar-header #mobile_btn .bar-icon {
  display: inline-block;
  width: 31px;
}

.header-one .navbar-header #mobile_btn .bar-icon span {
  background-color: #0e82fd;
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 7px;
  width: 31px;
  border-radius: 2px;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  transition: ease all 0.5s;
  position: relative;
}

.header-one .navbar-header #mobile_btn .bar-icon span:nth-child(2) {
  width: 16px;
}

.header-one .navbar-header #mobile_btn .bar-icon span:nth-child(3) {
  margin-bottom: 0;
}

.header-one .main-menu-wrapper {
  margin: 0;
  padding: 0;
}

.header-one .main-menu-wrapper .main-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 991.98px) {
  .header-one .main-menu-wrapper .main-nav ul {
    background-color: #e6f1ff;
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
  }
}

.header-one .main-menu-wrapper .main-nav li .btn {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  padding: 7px 8px !important;
  border: 1px solid #0e82fd;
  border-radius: 6px;
}

.header-one .main-menu-wrapper .main-nav li .btn:hover {
  color: #0e82fd;
  border: 1px solid #0e82fd;
}

.header-one .main-menu-wrapper .main-nav li .btn i {
  font-size: 15px;
  margin-right: 5px;
  margin-left: 0;
}

.header-one .main-menu-wrapper .main-nav li .btn.reg-btn {
  min-width: 108px;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  color: #1f2937;
  box-shadow: inset 0 0 0 0 #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.header-one .main-menu-wrapper .main-nav li .btn.reg-btn:hover {
  color: #ffffff;
  background-color: #0e82fd;
  border: 1px solid #0e82fd;
  box-shadow: inset 0 0 0 50px #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.header-one .main-menu-wrapper .main-nav li .btn.log-btn {
  min-width: 87px;
  color: #ffffff;
  background-color: #0e82fd;
  border: 1px solid #0e82fd;
  box-shadow: inset 0 0 0 0 #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.header-one .main-menu-wrapper .main-nav li .btn.log-btn:hover {
  color: #0e82fd;
  background-color: #ffffff;
  border: 1px solid #0e82fd;
  box-shadow: inset 0 0 0 50px #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.header-one .main-menu-wrapper .main-nav li a {
  font-size: 15px;
  font-weight: 500;
  color: #1f2937;
  display: block;
}

@media (max-width: 1199.98px) {
  .header-one .main-menu-wrapper .main-nav li a {
    font-size: 14px;
  }
}

.header-one .main-menu-wrapper .main-nav li.login-link {
  display: none;
}

@media (max-width: 991.98px) {
  .header-one .main-menu-wrapper .main-nav li.login-link {
    display: block;
  }
}

@media (min-width: 992px) {
  .header-one .main-menu-wrapper .main-nav li > ul {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.25);
    border-radius: 0px 0px 6px 5px;
    display: block;
    font-size: 14px;
    min-width: 200px;
    opacity: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    visibility: hidden;
    z-index: 1000;
  }
}

@media (min-width: 992px) {
  .header-one .main-menu-wrapper .main-nav li {
    display: block;
    position: relative;
  }
}

.header-one .main-menu-wrapper .main-nav .register-btn {
  margin-right: 10px;
}

.header-one .main-menu-wrapper .main-nav .register-btn:last-child {
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .header-one .main-menu-wrapper .main-nav .register-btn {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .header-one .main-menu-wrapper .main-nav > li {
    border-bottom: 1px solid #e6f1ff;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 991.98px) {
  .header-one .main-menu-wrapper .main-nav > li > a i {
    float: right;
    margin-top: 5px;
  }
}

@media (max-width: 991.98px) {
  .header-one .main-menu-wrapper .main-nav > li > a {
    line-height: 1.5;
    padding: 15px 20px !important;
    color: #ffffff !important;
    font-size: 14px;
    background: transparent !important;
    border-radius: 0 !important;
  }
}

.header-one .main-menu-wrapper .main-nav > li:last-child {
  margin-right: 0;
}

.header-one .main-menu-wrapper .main-nav > li.active > a {
  color: #0e82fd !important;
}

@media (max-width: 991.98px) {
  .header-one .main-menu-wrapper .main-nav > li.active > a {
    color: #ffffff !important;
  }
}

.header-one .main-menu-wrapper .main-nav > li > a {
  position: relative;
}

.header-one .main-menu-wrapper .main-nav > li > a:hover {
  color: #0e82fd;
}

@media (min-width: 992px) {
  .header-one .main-menu-wrapper .main-nav > li > a {
    padding: 23px 0 28px !important;
    line-height: normal;
  }
}

.header-one .main-menu-wrapper .main-nav > li > a > i {
  font-size: 10px;
  margin-left: 6px;
}

.header-one .main-menu-wrapper .main-nav > li.searchbar a {
  color: #6b7280;
}

.header-one .main-menu-wrapper .main-nav > li.searchbar a i {
  font-size: 16px;
}

@media (max-width: 1199.98px) {
  .header-one .main-menu-wrapper .main-nav > li.searchbar {
    display: none;
  }
}

.header-one
  .main-menu-wrapper
  .main-nav
  > li.has-submenu
  .submenu
  .has-submenu
  .submenu::before {
  top: 20px;
  margin-left: -35px;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
  border-color: transparent #ffffff #ffffff transparent;
}

@media (min-width: 992px) {
  .header-one .main-menu-wrapper .main-nav > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
  }

  .header-one
    .main-menu-wrapper
    .main-nav
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    margin-right: 0;
  }
}

.header-one .main-menu-wrapper .main-nav > li .submenu li {
  position: relative;
}

.header-one .main-menu-wrapper .main-nav > li .submenu li a {
  display: block;
  padding: 10px 15px;
  clear: both;
  white-space: nowrap;
  font-size: 14px;
  color: #2d3b48;
  -webkit-transition: all 0.35s ease;
  -ms-transition: all 0.35s ease;
  transition: all 0.35s ease;
  width: 100%;
  position: relative;
  border-top: 1px solid #f0f0f0;
}

.header-one .main-menu-wrapper .main-nav > li .submenu li a.active {
  color: #0e82fd;
}

.header-one .main-menu-wrapper .main-nav > li .submenu li a:hover {
  color: #0e82fd !important;
}

@media (max-width: 991.98px) {
  .header-one .main-menu-wrapper .main-nav > li .submenu li a {
    padding: 10px 15px 10px 35px;
  }
}

.header-one
  .main-menu-wrapper
  .main-nav
  > li
  .submenu
  > li.has-submenu
  > a::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 13px;
}

@media (min-width: 992px) {
  .header-one .main-menu-wrapper .main-nav > li .submenu > li .inner-submenu {
    left: 100%;
    top: 0;
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .header-one .main-menu-wrapper .main-nav > li .submenu::before {
    border: 7px solid #ffffff;
    border-color: transparent transparent #ffffff #ffffff;
    box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
    content: "";
    position: absolute;
    top: 2px;
    left: 45px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
  }
}

@media (max-width: 991.98px) {
  .header-one .main-menu-wrapper .main-nav {
    padding: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
}

@media (max-width: 991.98px) {
  .header-one .main-menu-wrapper {
    order: 3;
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1060;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
    transform: translateX(-260px);
    -webkit-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    background-color: #09e5ab;
  }
}

.header-one .logo {
  display: inline-block;
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .header-one .logo.navbar-brand {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
  }
}

.header-one .header-nav .header-navbar-rht {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  margin-right: 2px;
}

.header-one .header-nav .header-navbar-rht li {
  margin-right: 10px;
}

.header-one .header-nav .header-navbar-rht li .btn {
  color: #ffffff;
  font-weight: 600;
  font-size: 15px;
  padding: 6px 12px;
  border: 1px solid #09e5ab;
  border-radius: 6px;
}

.header-one .header-nav .header-navbar-rht li .btn:hover {
  color: #09e5ab;
  border: 1px solid #09e5ab;
}

.header-one .header-nav .header-navbar-rht li .btn i {
  margin-right: 5px;
}

.header-one .header-nav .header-navbar-rht li .btn.reg-btn {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  color: #1f2937;
  box-shadow: inset 0 0 0 0 #09e5ab;
}

.header-one .header-nav .header-navbar-rht li .btn.reg-btn:hover {
  color: #ffffff;
  background-color: #09e5ab;
  border: 1px solid #09e5ab;
}

.header-one .header-nav .header-navbar-rht li:last-child {
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .header-one .header-nav .header-navbar-rht {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .header-one .header-nav .header-navbar-rht {
    display: none;
  }
}

.header-one .menu-header {
  background-color: #ffffff;
  height: 72px;
  padding: 0 15px;
  border-bottom: 2px solid #0e82fd;
  display: none;
}

.header-one .menu-header .menu-close {
  font-size: 20px;
}

.header-one .menu-header .menu-close i {
  color: #0e82fd;
}

.header-one .menu-header .menu-close i:hover {
  color: #000000;
}

@media (max-width: 991.98px) {
  .header-one .menu-header {
    display: flex;
  }
}

.togglesearch {
  position: absolute;
  top: 74px;
  right: 8%;
  width: 280px;
  display: none;
  z-index: 999;
  background: #ffffff;
  padding: 10px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(183, 183, 183, 0.25);
}

.togglesearch input[type="text"] {
  width: 182px;
  padding: 5px 10px;
  border: 1px solid #0e82fd;
  outline: none;
  height: 39px;
  border-radius: 5px 0 0 5px;
}

.togglesearch button {
  background: #0e82fd;
  color: #ffffff;
  border: 1px solid #0e82fd;
  outline: none;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
  padding: 10px 12px;
  font-size: 14px;
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  width: 100%;
  z-index: 1040;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 991.98px) {
  .sidebar-overlay.opened {
    display: block;
  }
}

.menu-opened .main-menu-wrapper {
  transform: translateX(0) !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
}

html.menu-opened body {
  overflow: hidden;
}

#mobile_btn {
  display: none;
  margin-right: 30px;
}

@media (max-width: 991.98px) {
  #mobile_btn {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  #mobile_btn {
    margin-right: 0;
    z-index: 99;
    position: absolute;
    left: 0;
    padding: 0;
  }
}

.header-two .header-nav .navbar-header #mobile_btn,
.header-eight .header-nav .navbar-header #mobile_btn,
.custom .header-nav .navbar-header #mobile_btn {
  margin-left: 15px;
}

@media (max-width: 575.98px) {
  .navbar-brand.logo {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
}

@media (max-width: 575.98px) {
  .navbar-brand.logo img {
    height: 40px;
  }
}

.header-top {
  display: flex;
  background: #09e5ab;
  color: #ffffff;
  padding: 8px 30px;
}

@media (max-width: 991.98px) {
  .header-top {
    display: none;
  }
}

.header-top ul {
  display: flex;
}

.header-top ul li {
  padding-right: 25px;
  font-size: 13px;
}

.header-top ul li .top-icon {
  margin-right: 5px;
}

.header-top ul li:last-child {
  padding-right: 0;
}

.header-top .right-top {
  margin-left: auto;
}

.login-header-info {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: unset;
  background: #ffffff;
  border-bottom: 2px solid #f1f1f3;
  width: 100%;
  z-index: 11;
}

@media (max-width: 991.98px) {
  .login-header-info {
    display: none;
  }
}

.login-header-info .header-nav {
  height: 83px;
  margin: 0;
  padding: 0 30px;
}

@media (max-width: 991.98px) {
  .login-header-info .header-nav {
    height: 70px;
    margin: 0;
    padding: 0 20px;
  }
}

@media (max-width: 767.98px) {
  .login-header-info .header-nav {
    margin: 0;
    padding: 0 15px;
  }
}

.login-header-info .header-nav .main-nav li a {
  font-size: 15px;
  font-weight: 500;
  color: #6b7280;
}

.login-header-info .header-nav .main-nav li a:hover {
  color: #0e82fd;
}

.login-header-info .header-navbar-rht {
  display: none;
}

@media (max-width: 991.98px) {
  .login-header-info .header-navbar-rht {
    display: inherit;
  }
}

@media (max-width: 767.98px) {
  .login-header-info .header-navbar-rht {
    display: none;
  }
}

.login-header-info .flag-dropdown {
  margin: 0;
  padding: 18px 0;
}

@media (max-width: 991.98px) {
  .login-header-info .flag-dropdown {
    margin: 0;
    padding: 12px 0;
  }
}

.flag-dropdown .dropdown-toggle {
  color: #1f2937 !important;
  font-size: 14px !important;
  padding: 11px 15px;
  min-width: 146px;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  border-radius: 8px;
  position: relative;
  display: flex !important;
}

.flag-dropdown .dropdown-toggle.show + .dropdown-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  border-radius: 0 0 5px 5px;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
}

.flag-dropdown .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #000000;
  border-right: 2px solid #000000;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
  margin-left: 0;
  position: absolute;
  top: 15px;
  right: 15px;
}

.flag-dropdown .dropdown-toggle:after:hover {
  border-bottom: 2px solid #000000;
  border-right: 2px solid #000000;
}

.flag-dropdown .dropdown-toggle .flag-img {
  margin-right: 10px;
  border-radius: 50px;
  width: 20px;
}

.flag-dropdown.show + .dropdown-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  border-radius: 0 0 5px 5px;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
}

.flag-dropdown .dropdown-menu {
  border-radius: 5px;
  margin: 0;
  padding: 0;
  min-width: 200px;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  left: auto !important;
  right: 0 !important;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: block;
  transform: translateY(40px);
  -webkit-transform: translateY(40px);
  -ms-transform: translateY(40px);
}

.flag-dropdown .dropdown-menu .dropdown-item {
  font-size: 14px !important;
  color: #000000;
  margin: 0;
  padding: 10px 15px;
  font-weight: 600;
}

.flag-dropdown .dropdown-menu .dropdown-item img {
  margin-right: 10px;
  width: 20px;
}

.flag-dropdown .dropdown-menu .dropdown-item:hover {
  color: #000000 14px !important;
}

.flag-dropdown .dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
}

.main-nav li .flag-dropdown .submenu::before {
  display: none;
}

.flag-dropdown .dropdown-menu .dropdown-item.active,
.flag-dropdown .dropdown-menu .dropdown-item:active {
  color: #ffffff;
  background-color: #09e5ab;
}

.noti-nav .nav-link {
  font-size: 18px;
  color: #6b7280;
}

.noti-nav .nav-link .badge {
  background-color: #0e82fd;
  border-radius: 2px;
  color: #ffffff;
  font-size: 10px;
  font-style: normal;
  text-align: center;
  position: absolute;
  top: 19px;
  right: -6px;
  padding: 0 5px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-weight: normal;
}

.noti-nav .dropdown-menu {
  min-width: 420px;
  padding: 0;
}

.main-nav li.megamenu > ul {
  width: 100%;
}

.megamenu-wrapper {
  padding: 25px 25px 0;
}

.single-demo {
  transition: 0.4s;
  margin-bottom: 25px;
}

.single-demo:hover {
  transform: translateY(-20px);
}

.header
  .main-menu-wrapper
  .main-nav
  > li
  .submenu
  li
  .single-demo.active
  .demo-info
  .inner-demo-img {
  color: #1c5b91;
}

.header.header-sixteen
  .main-menu-wrapper
  .main-nav
  > li
  .submenu
  li
  .single-demo.active
  .demo-info
  .inner-demo-img {
  color: #1c5b91;
}

.header.header-thirteen
  .main-menu-wrapper
  .main-nav
  > li
  .submenu
  li
  .single-demo.active
  .demo-info
  .inner-demo-img {
  color: #1c5b91;
}

.header.header-twelve
  .main-menu-wrapper
  .main-nav
  > li
  .submenu
  li
  .single-demo.active
  .demo-info
  .inner-demo-img {
  color: #1c5b91;
}

.single-demo.active .demo-img {
  box-shadow: 0px 4.8px 24.4px -12px rgba(19, 16, 34, 0.1),
    0px 4px 13px -2px rgba(19, 16, 34, 0.06);
}

.single-demo:hover .demo-info {
  opacity: 1;
  bottom: -30px;
}

.demo-info {
  /* position: absolute;
  bottom: -30px; */
  width: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  /* opacity: 0; */
  transition: 0.4s;
  margin-top: 10px;
}

@media (min-width: 992px) {
  .header .header-nav .main-nav li .submenu a.inner-demo-img:hover {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .header .header-nav .main-nav > li.megamenu {
    position: unset;
  }
}

.single-demo a {
  padding: 0 !important;
}

@media (min-width: 992px) {
  .header .header-nav .main-nav li > ul.mega-submenu {
    padding: 0;
    border: 0;
    display: block;
    opacity: 0;
    visibility: hidden;
    min-width: 250px;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform-origin: center top 0;
    -webkit-transform-origin: center top 0;
    -moz-transform-origin: center top 0;
    -ms-transform-origin: center top 0;
    -o-transform-origin: center top 0;
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    box-shadow: 0 16px 50px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 16px 50px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 16px 50px rgba(0, 0, 0, 0.08);
    -ms-box-shadow: 0 16px 50px rgba(0, 0, 0, 0.08);
    -o-box-shadow: 0 16px 50px rgba(0, 0, 0, 0.08);
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
  }

  .header .header-nav .main-nav li.has-submenu:hover > .submenu {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
  }

  .header .header-nav .main-nav li .submenu.mega-submenu::before {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .megamenu-wrapper {
    padding: 0;
  }

  .demo-img {
    display: none;
  }

  .demo-info {
    opacity: 1;
    position: relative;
    bottom: 0;
    text-align: left;
    margin-top: 0;
  }

  .single-demo a {
    padding: 10px 15px 10px 35px !important;
  }

  .single-demo:hover .demo-info {
    bottom: 0;
  }

  .single-demo .demo-info a {
    padding: 10px 15px 10px 35px;
  }

  .single-demo:hover {
    transform: translateY(0);
  }

  .single-demo:hover .demo-info {
    bottom: 0;
  }

  .single-demo {
    margin-bottom: 0;
  }
}

.header.header-ten {
  width: 100%;
  z-index: 1040;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background-color: transparent;
  box-shadow: 0px 4px 4px rgba(198, 198, 198, 0.25);
}

.header.header-ten
  .header-nav
  .main-nav
  .has-submenu.active
  .submenu
  li.active
  > a {
  color: #0e82fd;
}

.header.header-ten.min-header {
  width: 100%;
  z-index: 1040;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background-color: transparent;
}

.header.header-ten.header-fixed {
  display: block;
  width: 100%;
  z-index: 9999;
  background: #0e82fd;
  box-shadow: inherit;
  position: relative;
}

.header.header-ten.header-space {
  background: #0e82fd;
  position: fixed;
}

.header.header-ten.header-space .header-nav {
  height: 80px;
}

.header.header-ten .header-nav {
  background-color: transparent;
  border: 0;
  position: relative;
  margin: 0;
  padding: 0;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media (max-width: 991.98px) {
  .header.header-ten .header-nav {
    height: 70px;
  }
}

@media (max-width: 991.98px) {
  .header.header-ten .header-nav {
    margin: 0;
    padding: 0 0;
  }
}

@media (max-width: 991.98px) {
  .header.header-ten .header-nav .menu-header {
    height: 70px;
  }
}

@media (max-width: 991.98px) {
  .header.header-ten .header-nav .main-menu-wrapper {
    background-color: #0e82fd;
  }
}

@media (max-width: 991.98px) {
  .header.header-ten .navbar-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
  }
}

.header.header-ten .navbar-header #mobile_btn {
  display: none;
  margin-right: 30px;
}

@media (max-width: 991.98px) {
  .header.header-ten .navbar-header #mobile_btn {
    display: inline-block;
    margin: 0;
    padding: 0 0;
  }
}

@media (max-width: 767.98px) {
  .header.header-ten .navbar-header #mobile_btn {
    margin-right: 0;
  }
}

.header.header-ten .navbar-header #mobile_btn .bar-icon {
  display: inline-block;
  width: 31px;
}

.header.header-ten .navbar-header #mobile_btn .bar-icon span {
  background-color: #ffffff;
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 7px;
  width: 31px;
  border-radius: 2px;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  transition: ease all 0.5s;
  position: relative;
}

.header.header-ten .navbar-header #mobile_btn .bar-icon span:nth-child(2) {
  width: 16px;
}

.header.header-ten .navbar-header #mobile_btn .bar-icon span:nth-child(3) {
  margin-bottom: 0;
}

.header.header-ten .main-menu-wrapper {
  margin: 0;
  padding: 0;
}

.header.header-ten .main-menu-wrapper .main-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 991.98px) {
  .header.header-ten .main-menu-wrapper .main-nav ul {
    background-color: #e6f1ff;
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
  }
}

.header.header-ten .main-menu-wrapper .main-nav li .btn {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  padding: 7px 8px !important;
  border: 1px solid #0e82fd;
  border-radius: 6px;
}

.header.header-ten .main-menu-wrapper .main-nav li .btn:hover {
  color: #0e82fd;
  border: 1px solid #0e82fd;
  background: transparent;
}

.header.header-ten .main-menu-wrapper .main-nav li .btn i {
  font-size: 15px;
  margin-right: 5px;
  margin-left: 0;
}

.header.header-ten .main-menu-wrapper .main-nav li .btn.reg-btn {
  min-width: 108px;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  color: #1f2937;
  box-shadow: inset 0 0 0 0 #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.header.header-ten .main-menu-wrapper .main-nav li .btn.reg-btn:hover {
  color: #ffffff;
  background-color: #0e82fd;
  border: 1px solid #0e82fd;
  box-shadow: inset 0 0 0 50px #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.header.header-ten .main-menu-wrapper .main-nav li .btn.log-btn {
  min-width: 87px;
  color: #ffffff;
  background-color: #0e82fd;
  border: 1px solid #0e82fd;
  box-shadow: inset 0 0 0 0 #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.header.header-ten .main-menu-wrapper .main-nav li .btn.log-btn:hover {
  color: #0e82fd;
  background-color: #ffffff;
  border: 1px solid #0e82fd;
  box-shadow: inset 0 0 0 50px #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.header.header-ten .main-menu-wrapper .main-nav li a {
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  display: block;
}

@media (max-width: 1199.98px) {
  .header.header-ten .main-menu-wrapper .main-nav li a {
    font-size: 14px;
  }
}

.header.header-ten .main-menu-wrapper .main-nav li.login-link {
  display: none;
}

@media (max-width: 991.98px) {
  .header.header-ten .main-menu-wrapper .main-nav li.login-link {
    display: block;
  }
}

@media (min-width: 992px) {
  .header.header-ten .main-menu-wrapper .main-nav li > ul {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.25);
    border-radius: 0px 0px 6px 5px;
    display: block;
    font-size: 14px;
    min-width: 200px;
    opacity: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    visibility: hidden;
    z-index: 1000;
  }
}

@media (min-width: 992px) {
  .header.header-ten .main-menu-wrapper .main-nav li {
    display: block;
    position: relative;
  }
}

.header.header-ten .main-menu-wrapper .main-nav .register-btn {
  margin-right: 10px;
}

.header.header-ten .main-menu-wrapper .main-nav .register-btn:last-child {
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .header.header-ten .main-menu-wrapper .main-nav .register-btn {
    display: none;
  }
}

@media (min-width: 992px) {
  .header.header-ten .main-menu-wrapper .main-nav > li {
    margin-right: 8px;
  }
}

@media (min-width: 1200.98px) {
  .header.header-ten .main-menu-wrapper .main-nav > li {
    margin-right: 20px;
  }
}

@media (max-width: 991.98px) {
  .header.header-ten .main-menu-wrapper .main-nav > li {
    border-bottom: 1px solid #e6f1ff;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 991.98px) {
  .header.header-ten .main-menu-wrapper .main-nav > li > a i {
    float: right;
    margin-top: 5px;
  }
}

@media (max-width: 991.98px) {
  .header.header-ten .main-menu-wrapper .main-nav > li > a {
    line-height: 1.5;
    padding: 15px 20px !important;
    color: #ffffff !important;
    font-size: 14px;
    background: transparent !important;
    border-radius: 0 !important;
  }
}

.header.header-ten .main-menu-wrapper .main-nav > li:last-child {
  margin-right: 0;
}

.header.header-ten .main-menu-wrapper .main-nav > li.active > a {
  color: #1c5b91 !important;
}

@media (max-width: 991.98px) {
  .header.header-ten .main-menu-wrapper .main-nav > li.active > a {
    color: #ffffff !important;
  }
}

.header.header-ten .main-menu-wrapper .main-nav > li > a {
  position: relative;
}

.header.header-ten .main-menu-wrapper .main-nav > li > a:hover {
  color: #09e5ab;
}

@media (min-width: 992px) {
  .header.header-ten .main-menu-wrapper .main-nav > li > a {
    padding: 10px 0 !important;
    line-height: normal;
  }
}

.header.header-ten .main-menu-wrapper .main-nav > li > a > i {
  font-size: 10px;
  margin-left: 6px;
}

.header.header-ten .main-menu-wrapper .main-nav > li.searchbar a {
  color: #6b7280;
}

.header.header-ten .main-menu-wrapper .main-nav > li.searchbar a i {
  font-size: 20px;
  color: #ffffff;
}

@media (max-width: 1199.98px) {
  .header.header-ten .main-menu-wrapper .main-nav > li.searchbar {
    display: none;
  }
}

.header.header-ten
  .main-menu-wrapper
  .main-nav
  > li.has-submenu
  .submenu
  .has-submenu
  .submenu::before {
  top: 20px;
  margin-left: -35px;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
  border-color: transparent #ffffff #ffffff transparent;
}

@media (min-width: 992px) {
  .header.header-ten
    .main-menu-wrapper
    .main-nav
    > li.has-submenu:hover
    > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
  }

  .header.header-ten
    .main-menu-wrapper
    .main-nav
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    margin-right: 0;
  }
}

.header.header-ten .main-menu-wrapper .main-nav > li .submenu li {
  position: relative;
}

.header.header-ten .main-menu-wrapper .main-nav > li .submenu li a {
  display: block;
  padding: 10px 15px;
  clear: both;
  white-space: nowrap;
  font-size: 14px;
  color: #2d3b48;
  -webkit-transition: all 0.35s ease;
  -ms-transition: all 0.35s ease;
  transition: all 0.35s ease;
  width: 100%;
  position: relative;
  border-top: 1px solid #f0f0f0;
}

.header.header-ten .main-menu-wrapper .main-nav > li .submenu li a.active {
  color: #0e82fd;
}

.header.header-ten .main-menu-wrapper .main-nav > li .submenu li a:hover {
  color: #0e82fd !important;
}

@media (max-width: 991.98px) {
  .header.header-ten .main-menu-wrapper .main-nav > li .submenu li a {
    padding: 10px 15px 10px 35px;
  }
}

.header.header-ten
  .main-menu-wrapper
  .main-nav
  > li
  .submenu
  > li.has-submenu
  > a::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 13px;
}

@media (min-width: 992px) {
  .header.header-ten
    .main-menu-wrapper
    .main-nav
    > li
    .submenu
    > li
    .inner-submenu {
    left: 100%;
    top: 0;
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .header.header-ten .main-menu-wrapper .main-nav > li .submenu::before {
    border: 7px solid #ffffff;
    border-color: transparent transparent #ffffff #ffffff;
    box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
    content: "";
    position: absolute;
    top: 2px;
    left: 45px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
  }
}

@media (max-width: 991.98px) {
  .header.header-ten .main-menu-wrapper .main-nav {
    padding: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
}

@media (max-width: 991.98px) {
  .header.header-ten .main-menu-wrapper {
    order: 3;
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1060;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
    transform: translateX(-260px);
    -webkit-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    background-color: #09e5ab;
  }
}

.header.header-ten .logo {
  display: inline-block;
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .header.header-ten .logo.navbar-brand {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
  }
}

.header.header-ten .header-nav .header-navbar-rht {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  margin-right: 2px;
}

.header.header-ten .header-nav .header-navbar-rht li {
  margin-right: 10px;
  padding-right: 0;
}

.header.header-ten .header-nav .header-navbar-rht li .btn {
  color: #ffffff;
  font-weight: 600;
  font-size: 15px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 35px;
  box-shadow: inset 0 0 0 0 #ffffff;
  transition: all 0.7s;
}

.header.header-ten .header-nav .header-navbar-rht li .btn:hover {
  color: #0e82fd;
  border: 1px solid #ffffff;
  box-shadow: inset 0 0 0 50px #ffffff;
}

.header.header-ten .header-nav .header-navbar-rht li .btn i {
  margin-right: 8px;
}

.header.header-ten .header-nav .header-navbar-rht li .btn.reg-btn {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  color: #1f2937;
  box-shadow: inset 0 0 0 0 #0e82fd;
}

.header.header-ten .header-nav .header-navbar-rht li .btn.reg-btn:hover {
  color: #ffffff;
  background-color: #0e82fd;
  border: 1px solid #ffffff;
  box-shadow: inset 0 0 0 50px #0e82fd;
}

.header.header-ten .header-nav .header-navbar-rht li:last-child {
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .header.header-ten .header-nav .header-navbar-rht {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .header.header-ten .header-nav .header-navbar-rht {
    display: none;
  }
}

.header.header-ten .menu-header {
  background-color: #ffffff;
  height: 72px;
  padding: 0 15px;
  border-bottom: 2px solid #0e82fd;
  display: none;
}

.header.header-ten .menu-header .menu-close {
  font-size: 20px;
}

.header.header-ten .menu-header .menu-close i {
  color: #0e82fd;
}

.header.header-ten .menu-header .menu-close i:hover {
  color: #000000;
}

@media (max-width: 991.98px) {
  .header.header-ten .menu-header {
    display: flex;
  }
}

.header.header-ten .header-nav .main-nav > li.megamenu {
  position: unset;
}

.header-trans.header-eleven .header-nav {
  border-bottom: 0;
}

@media (max-width: 575.98px) {
  .header-trans.header-eleven .header-nav .navbar-header #mobile_btn {
    left: 15px;
  }
}

@media (max-width: 1199.98px) {
  .header-trans.header-eleven .header-nav .main-nav > li {
    margin-right: 17px;
  }
}

.header-trans.header-eleven .header-nav .main-nav > li > a {
  color: #ffffff;
}

.header-trans.header-eleven .header-nav .main-nav > li.has-submenu.active > a {
  color: #0ce0ff;
}

.header-trans.header-eleven .header-nav .main-nav > li.submenu a:hover {
  color: #0ce0ff;
}

.header-trans.header-eleven .header-navbar-rht li {
  border-right: 0;
  padding-right: 0;
}

.header-trans.header-eleven .header-navbar-rht li a.header-login {
  background: #0ce0ff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: normal;
  padding: 13px 15px !important;
  text-transform: none;
  box-shadow: inset 0 0 0 0 #ffffff;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}

.header-trans.header-eleven .header-navbar-rht li a.header-login:hover {
  background: #ffffff;
  color: #1c5b91;
  box-shadow: inset 0 60px 0 0 #ffffff;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}

.header-trans.header-eleven .header-navbar-rht li a.header-login.white-bg {
  color: #1c5b91;
  box-shadow: inset 0 0 0 0 #1c5b91;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}

.header-trans.header-eleven
  .header-navbar-rht
  li
  a.header-login.white-bg:hover {
  background: #1c5b91;
  color: #ffffff;
  box-shadow: inset 0 60px 0 0 #1c5b91;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}

.header.header-fourteen.header-twelve .header-nav {
  background-color: transparent;
  border-bottom: none;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
}

@media (max-width: 1199.98px) {
  .header.header-fourteen.header-twelve .header-nav .logo {
    margin-right: 0;
  }
}

@media (max-width: 1199.98px) {
  .header.header-fourteen.header-twelve .header-nav {
    justify-content: start;
  }
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-twelve .header-nav {
    justify-content: space-between;
  }
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-twelve .header-nav .main-menu-wrapper {
    background-color: #f8fbff;
  }
}

@media (max-width: 1199.98px) {
  .header.header-fourteen.header-twelve
    .header-nav
    .main-menu-wrapper
    .main-nav
    li {
    margin-right: 10px;
  }
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-twelve
    .header-nav
    .main-menu-wrapper
    .main-nav
    li {
    border-bottom: 1px solid #ffffff;
  }
}

.header.header-fourteen.header-twelve
  .header-nav
  .main-menu-wrapper
  .main-nav
  li
  a {
  color: #1f2937;
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-twelve
    .header-nav
    .main-menu-wrapper
    .main-nav
    li
    .submenu {
    background-color: #f8fbff;
  }
}

.header.header-fourteen.header-twelve
  .header-nav
  .main-menu-wrapper
  .main-nav
  li
  .submenu
  a {
  color: #1f2937;
}

.header.header-fourteen.header-twelve
  .header-nav
  .main-menu-wrapper
  .main-nav
  li.active
  a {
  color: #0e82fd;
  font-weight: 700;
}

.header.header-fourteen.header-twelve
  .header-nav
  .main-menu-wrapper
  .main-nav
  li.active
  .submenu
  a {
  color: #1f2937;
  font-weight: 500;
}

.header.header-fourteen.header-twelve
  .header-nav
  .main-menu-wrapper
  .main-nav
  li.active
  .submenu
  a.active {
  color: #2dd4bf;
  font-weight: 500;
}

.header.header-fourteen.header-twelve
  .header-nav
  .main-menu-wrapper
  .main-nav
  li.active
  .submenu
  a {
  color: #1f2937;
}

.header.header-fourteen.header-twelve .header-nav .header-navbar-rht {
  padding: 0;
  margin: 0;
}

.header.header-fourteen.header-twelve .header-nav .header-navbar-rht li {
  padding: 0 10px 0 0;
}

.header.header-fourteen.header-twelve
  .header-nav
  .header-navbar-rht
  li.login-link {
  display: none;
}

.header.header-fourteen.header-twelve
  .header-nav
  .header-navbar-rht
  li.searchbar-fourteen {
  background: #f6f7f5;
  border: 1px solid #e7e8e6;
  width: 38px;
  height: 38px;
  border-radius: 35px;
  padding: 0;
  transition: 0.5s;
}

.header.header-fourteen.header-twelve
  .header-nav
  .header-navbar-rht
  li.searchbar-fourteen:hover {
  background: #1c5b91;
  transition: 0.5s;
}

.header.header-fourteen.header-twelve
  .header-nav
  .header-navbar-rht
  li.searchbar-fourteen
  a {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0));
}

.header.header-fourteen.header-twelve
  .header-nav
  .header-navbar-rht
  li.login-in-fourteen
  .reg-btn {
  background: transparent;
  color: #1f2937;
  box-shadow: none;
  border: none;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 12px;
  transition: 0.5s;
}

@media (max-width: 1199.98px) {
  .header.header-fourteen.header-twelve
    .header-nav
    .header-navbar-rht
    li.login-in-fourteen
    .reg-btn {
    padding: 10px 5px;
  }
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-twelve
    .header-nav
    .header-navbar-rht
    li.login-in-fourteen
    .reg-btn {
    padding: 10px 12px;
  }
}

.header.header-fourteen.header-twelve
  .header-nav
  .header-navbar-rht
  li.login-in-fourteen
  .reg-btn:hover {
  background: #0e82fd;
  color: #ffffff;
  transition: 0.5s;
}

.header.header-fourteen.header-twelve
  .header-nav
  .header-navbar-rht
  li.login-in-fourteen
  .reg-btn.reg-btn-fourteen {
  background: #0e82fd;
  color: #ffffff;
  transition: 0.5s;
  border: 1px solid #0e82fd;
  font-size: 15px;
  font-weight: 600;
}

.header.header-fourteen.header-twelve
  .header-nav
  .header-navbar-rht
  li.login-in-fourteen
  .reg-btn.reg-btn-fourteen:hover {
  background: #ffffff;
  color: #0e82fd;
  transition: 0.5s;
}

.header.header-fourteen.header-fixed {
  position: fixed;
  display: block;
  width: 100%;
  z-index: 9999;
  box-shadow: inherit;
}

.header.header-fourteen.header-twelve.header-thirteen {
  background-color: #ffffff;
}

.header.header-fourteen.header-twelve.header-thirteen
  .header-nav
  .header-navbar-rht
  li.login-in-fourteen
  .reg-btn-thirteen {
  display: flex;
}

.header.header-fourteen.header-twelve.header-thirteen
  .header-nav
  .header-navbar-rht
  li.login-in-fourteen
  .reg-btn-thirteen
  span {
  padding: 8px 20px;
  border: 1px solid #0e82fd;
  background: #0e82fd;
  color: #ffffff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.5s;
}

.header.header-fourteen.header-twelve.header-thirteen
  .header-nav
  .header-navbar-rht
  li.login-in-fourteen
  .reg-btn-thirteen
  .user-icon-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  border: 1px solid #0e82fd;
  background: #0f78e7;
  color: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: all 0.5s;
}

.header.header-fourteen.header-twelve.header-thirteen
  .header-nav
  .header-navbar-rht
  li.login-in-fourteen
  .reg-btn-thirteen:hover
  span,
.header.header-fourteen.header-twelve.header-thirteen
  .header-nav
  .header-navbar-rht
  li.login-in-fourteen
  .reg-btn-thirteen:hover
  .user-icon-header {
  background: #ffffff;
  color: #0e82fd;
}

.header.header-fourteen .header-nav {
  background-color: transparent;
  border-bottom: none;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
}

@media (max-width: 1199.98px) {
  .header.header-fourteen .header-nav .logo {
    margin-right: 0;
  }
}

@media (max-width: 1199.98px) {
  .header.header-fourteen .header-nav {
    justify-content: start;
  }
}

@media (max-width: 991.98px) {
  .header.header-fourteen .header-nav {
    justify-content: space-between;
  }
}

@media (max-width: 1199.98px) {
  .header.header-fourteen
    .header-nav
    .main-menu-wrapper
    .main-nav
    .has-submenu {
    margin-right: 10px;
  }
}

.header.header-fourteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  .has-submenu
  a {
  color: #ffffff;
}

.header.header-fourteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  .has-submenu
  .submenu
  a {
  color: #1f2937;
}

@media (max-width: 991.98px) {
  .header.header-fourteen
    .header-nav
    .main-menu-wrapper
    .main-nav
    .has-submenu
    .submenu
    a {
    color: #ffffff;
  }
}

.header.header-fourteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  .has-submenu.active
  a {
  color: #2dd4bf;
  font-weight: 700;
}

.header.header-fourteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  .has-submenu.active
  .submenu
  a {
  color: #1f2937;
  font-weight: 500;
}

.header.header-fourteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  .has-submenu.active
  .submenu
  a.active {
  color: #2dd4bf;
  font-weight: 500;
}

.header.header-fourteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  .has-submenu.active
  .submenu
  a {
  color: #1f2937;
}

@media (max-width: 991.98px) {
  .header.header-fourteen
    .header-nav
    .main-menu-wrapper
    .main-nav
    .has-submenu.active
    .submenu
    a {
    color: #ffffff;
  }
}

.header.header-fourteen .header-nav .header-navbar-rht {
  padding: 0;
  margin: 0;
}

.header.header-fourteen .header-nav .header-navbar-rht li {
  padding: 0 10px 0 0;
}

.header.header-fourteen .header-nav .header-navbar-rht li.login-link {
  display: none;
}

.header.header-fourteen .header-nav .header-navbar-rht li.searchbar-fourteen a {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #ffffff;
  border: 1px solid #e7e8e6;
  width: 38px;
  height: 38px;
  border-radius: 35px;
  padding: 0;
  transition: 0.5s;
}

.header.header-fourteen
  .header-nav
  .header-navbar-rht
  li.searchbar-fourteen
  a:hover {
  background: #1c5b91;
  color: #ffffff;
  border: 1px solid #1c5b91;
  transition: 0.5s;
}

.header.header-fourteen
  .header-nav
  .header-navbar-rht
  li.searchbar-fourteen
  a:hover
  a {
  color: #ffffff;
}

.header.header-fourteen
  .header-nav
  .header-navbar-rht
  li.login-in-fourteen
  .reg-btn {
  background: transparent;
  color: #ffffff;
  box-shadow: none;
  border: none;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 12px;
  transition: 0.5s;
}

@media (max-width: 1199.98px) {
  .header.header-fourteen
    .header-nav
    .header-navbar-rht
    li.login-in-fourteen
    .reg-btn {
    padding: 10px 5px;
  }
}

.header.header-fourteen
  .header-nav
  .header-navbar-rht
  li.login-in-fourteen
  .reg-btn:hover {
  background: #2dd4bf;
  color: #1e5d8a;
  transition: 0.5s;
}

.header.header-fourteen
  .header-nav
  .header-navbar-rht
  li.login-in-fourteen
  .reg-btn.reg-btn-fourteen {
  background: #2dd4bf;
  color: #1e5d8a;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}

.header.header-fourteen
  .header-nav
  .header-navbar-rht
  li.login-in-fourteen
  .reg-btn.reg-btn-fourteen:hover {
  background: #ffffff;
  color: #1e5d8a;
  transition: 0.5s;
}

.header.header-fixed {
  position: fixed;
  display: block;
  width: 100%;
  z-index: 9999;
  /* z-index: 1; */
  box-shadow: inherit;
}

@media (max-width: 1199.98px) {
  .header.header-fourteen.header-fifteen .header-nav .logo {
    margin-right: 0;
  }
}

@media (max-width: 1399.98px) {
  .header.header-fourteen.header-fifteen .header-nav {
    justify-content: start;
  }
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-fifteen .header-nav {
    justify-content: space-between;
  }
}

@media (max-width: 1399.98px) {
  .header.header-fourteen.header-fifteen
    .header-nav
    .main-menu-wrapper
    .main-nav
    li {
    margin-right: 20px;
  }
}

@media (max-width: 1199.98px) {
  .header.header-fourteen.header-fifteen
    .header-nav
    .main-menu-wrapper
    .main-nav
    li {
    margin-right: 5px;
  }
}

.header.header-fourteen.header-fifteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  li
  a {
  color: #1f2937;
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-fifteen
    .header-nav
    .main-menu-wrapper
    .main-nav
    li
    a {
    color: #ffffff;
  }
}

.header.header-fourteen.header-fifteen
  .header-nav
  .header-navbar-rht
  .login-in-fourteen
  .reg-btn {
  padding: 18px 15px;
  background: #0ce0ff;
  color: #ffffff;
  border-radius: 10px;
  font-size: 14px;
}

@media (max-width: 1399.98px) {
  .header.header-fourteen.header-fifteen
    .header-nav
    .header-navbar-rht
    .login-in-fourteen
    .reg-btn {
    padding: 13px 10px;
  }
}

@media (max-width: 1199.98px) {
  .header.header-fourteen.header-fifteen
    .header-nav
    .header-navbar-rht
    .login-in-fourteen
    .reg-btn {
    padding: 10px 5px;
  }
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-fifteen
    .header-nav
    .header-navbar-rht
    .login-in-fourteen
    .reg-btn {
    padding: 13px 10px;
  }
}

.header.header-fourteen.header-fifteen
  .header-nav
  .header-navbar-rht
  .login-in-fourteen
  .reg-btn.reg-btn-fourteen {
  background: #ffaf14;
  color: #ffffff;
}

.header.header-fourteen.header-sixteen {
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.08);
}

@media (max-width: 1199.98px) {
  .header.header-fourteen.header-sixteen .header-nav .logo {
    margin-right: 30px;
  }
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-sixteen .header-nav .logo {
    margin-right: 0;
  }
}

@media (max-width: 1399.98px) {
  .header.header-fourteen.header-sixteen .header-nav {
    justify-content: start;
  }
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-sixteen .header-nav {
    justify-content: space-between;
  }
}

.header.header-fourteen.header-sixteen .header-nav .bar-icon span {
  background: #0e82fd;
}

.header.header-fourteen.header-sixteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  li:last-child {
  margin-right: 30px;
}

@media (max-width: 1199.98px) {
  .header.header-fourteen.header-sixteen
    .header-nav
    .main-menu-wrapper
    .main-nav
    li:last-child {
    margin-right: 10px;
  }
}

@media (max-width: 1199.98px) {
  .header.header-fourteen.header-sixteen
    .header-nav
    .main-menu-wrapper
    .main-nav
    li {
    margin-right: 10px;
  }
}

.header.header-fourteen.header-sixteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  li
  a {
  color: #1f2937;
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-sixteen
    .header-nav
    .main-menu-wrapper
    .main-nav
    li
    a {
    color: #ffffff;
  }
}

.header.header-fourteen.header-sixteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  li
  .submenu
  a {
  color: #1f2937;
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-sixteen
    .header-nav
    .main-menu-wrapper
    .main-nav
    li
    .submenu
    a {
    color: #ffffff;
  }
}

.header.header-fourteen.header-sixteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  li.active
  a {
  color: #3b9cf6;
  font-weight: 700;
}

.header.header-fourteen.header-sixteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  li.active
  .submenu
  a {
  color: #1f2937;
  font-weight: 500;
}

.header.header-fourteen.header-sixteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  li.active
  .submenu
  a.active {
  color: #3b9cf6;
  font-weight: 500;
}

.header.header-fourteen.header-sixteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  li.active
  .submenu
  a {
  color: #1f2937;
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-sixteen
    .header-nav
    .main-menu-wrapper
    .main-nav
    li.active
    .submenu
    a {
    color: #ffffff;
  }
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-sixteen
    .header-nav
    .main-menu-wrapper
    .main-nav
    .searchbar {
    display: none;
  }
}

.header.header-fourteen.header-sixteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  .searchbar
  a
  i {
  font-size: 18px;
  color: #6b7280;
}

.header.header-fourteen.header-sixteen
  .header-nav
  .main-menu-wrapper
  .main-nav
  .searchbar
  a
  i:hover {
  color: #1c5b91;
}

.header.header-fourteen.header-sixteen
  .header-nav
  .header-navbar-rht
  .login-in-sixteen
  .reg-btn {
  padding: 12px 14px;
  background: #0e82fd;
  box-shadow: inset 0 0 0 0 #0e82fd;
  color: #ffffff;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}

.header.header-fourteen.header-sixteen
  .header-nav
  .header-navbar-rht
  .login-in-sixteen
  .reg-btn:hover {
  background: #ffffff;
  color: #1c5b91;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
  box-shadow: inset 0 60px 0 0 #ffffff;
}

@media (max-width: 1399.98px) {
  .header.header-fourteen.header-sixteen
    .header-nav
    .header-navbar-rht
    .login-in-sixteen
    .reg-btn {
    padding: 13px 10px;
  }
}

@media (max-width: 1199.98px) {
  .header.header-fourteen.header-sixteen
    .header-nav
    .header-navbar-rht
    .login-in-sixteen
    .reg-btn {
    padding: 10px 5px;
  }
}

@media (max-width: 991.98px) {
  .header.header-fourteen.header-sixteen
    .header-nav
    .header-navbar-rht
    .login-in-sixteen
    .reg-btn {
    padding: 13px 10px;
  }
}

.header.header-fourteen.header-sixteen
  .header-nav
  .header-navbar-rht
  .login-in-sixteen
  .reg-btn.reg-btn-sixteen {
  background: transparent;
  color: #1f2937;
  border: none;
  font-weight: 600;
  box-shadow: inset 0 0 0 0 #1c5b91;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}

.header.header-fourteen.header-sixteen
  .header-nav
  .header-navbar-rht
  .login-in-sixteen
  .reg-btn.reg-btn-sixteen:hover {
  background: #1c5b91;
  color: #ffffff;
  box-shadow: inset 0 60px 0 0 #1c5b91;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}

.nav-tabs {
  border-bottom: 1px solid #f0f0f0;
}

@media (max-width: 767.98px) {
  .nav-tabs {
    border-bottom: 0;
    position: relative;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    padding: 5px 0;
    border-radius: 3px;
  }
}

.nav-tabs > li > a {
  margin-right: 0;
  color: #888888;
  border-radius: 3px;
}

@media (max-width: 767.98px) {
  .nav-tabs > li > a {
    border-width: 2px;
    border-left-color: transparent;
  }
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: transparent;
  color: #272b41;
}

@media (max-width: 767.98px) {
  .nav-tabs > li > a:hover,
  .nav-tabs > li > a:focus {
    background-color: #fafafa;
  }
}

@media (max-width: 767.98px) {
  .nav-tabs > li > a.active {
    border-color: transparent transparent transparent #20c0f3 !important;
    border-left-width: 2px;
  }
}

.nav-tabs > li.open:not(.active) > a,
.nav-tabs > li.open:not(.active) > a:hover,
.nav-tabs > li.open:not(.active) > a:focus {
  background-color: #fafafa;
}

@media (max-width: 767.98px) {
  .nav-tabs .nav-item {
    margin-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .nav-tabs .nav-item.show .nav-link {
    border-color: transparent transparent transparent #20c0f3 !important;
    border-left-width: 2px;
  }
}

.nav-tabs .nav-link {
  border-radius: 0;
}

@media (max-width: 767.98px) {
  .nav-tabs .nav-link {
    border-width: 2px;
  }
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #eeeeee;
  border-color: transparent;
  color: #272b41;
  border-radius: 30px !important;
}

@media (max-width: 767.98px) {
  .nav-tabs.nav-justified {
    border-bottom: 1px solid #dddddd;
  }
}

.nav-tabs.nav-justified > li > a {
  margin-bottom: 0;
  border-radius: 0;
}

.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: #dddddd;
  border-radius: 0px !important;
}

/* .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #ffff !important;
  background-color: #20c0f3 !important;
  border-color: #20c0f3 !important;
  border-radius: 30px !important;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  background-color: #20c0f3 !important;
  border-color: transparent !important;
  color: #ffffff !important;
  border-radius: 30px !important;
} */
@media (max-width: 767.98px) {
  .nav-tabs.nav-justified > li > a.active {
    background-color: #c70e2a !important;
    border-color: transparent !important;
    color: #ffffff !important;
    border-radius: 30px !important;
  }
}

.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  color: #272b41;
}

.nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: 1px solid #dddddd;
}

.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
  border-radius: 0px !important;
}

.nav-tabs.nav-tabs-top > li {
  margin-bottom: 0;
}

.nav-tabs.nav-tabs-top > li > a {
  border-width: 2px 0 0 0;
}

.nav-tabs.nav-tabs-top > li > a.open {
  border-top-color: #dddddd;
}

.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
  border-top-color: #dddddd;
}

.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
  border-top-color: #20c0f3;
}

.nav-tabs.nav-tabs-top > li + li > a {
  margin-left: 1px;
}

.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: #2a9d36;
  background-color: transparent;
  -webkit-transition: none 0s ease 0s;
  -ms-transition: none 0s ease 0s;
  transition: none 0s ease 0s;
}

@media (max-width: 767.98px) {
  .nav-tabs.nav-tabs-bottom > li > a.active,
  .nav-tabs.nav-tabs-bottom > li > a.active:hover,
  .nav-tabs.nav-tabs-bottom > li > a.active:focus {
    background-color: #f5f5f5;
  }
}

@media (max-width: 767.98px) {
  .nav-tabs .nav-tabs-justified {
    border-bottom: 1px solid #dddddd;
  }
}

.nav-tabs .nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}

.nav-tabs .nav-tabs-justified > li > a:hover,
.nav-tabs .nav-tabs-justified > li > a:focus {
  border-bottom-color: #dddddd;
}

.nav-tabs .nav-tabs-justified > li > a.active,
.nav-tabs .nav-tabs-justified > li > a.active:focus,
.nav-tabs .nav-tabs-justified > li > a.active:hover {
  border-width: 0 0 0 2px;
  border-left-color: #20c0f3;
}

.nav-tabs .nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}

.nav-tabs .nav-tabs-justified.nav-tabs-top {
  border-bottom: 1px solid #dddddd;
}

.nav-tabs .nav-tabs-justified.nav-tabs-top > li a,
.nav-tabs .nav-tabs-justified.nav-tabs-top > li a:hover,
.nav-tabs .nav-tabs-justified.nav-tabs-top > li a:focus {
  border-width: 2px 0 0 0;
}

.nav-tabs.nav-tabs-solid {
  background-color: #fafafa;
  border: 0;
}

@media (max-width: 767.98px) {
  .nav-tabs.nav-tabs-solid {
    padding: 5px;
  }
}

.nav-tabs.nav-tabs-solid > li {
  margin-bottom: 0;
}

.nav-tabs.nav-tabs-solid > li a {
  color: #272b41;
}

.nav-tabs.nav-tabs-solid > li a.active,
.nav-tabs.nav-tabs-solid > li a.active:hover,
.nav-tabs.nav-tabs-solid > li a.active:focus {
  background-color: #20c0f3;
  border-color: #20c0f3;
  color: #ffffff;
}

.nav-tabs.nav-tabs-solid > li > a {
  border-color: transparent;
}

@media (max-width: 767.98px) {
  .nav-tabs.nav-tabs-solid > li > a {
    border-left-width: 0 !important;
  }
}

.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
  background-color: #f5f5f5;
}

.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
  background-color: #20c0f3;
  border-color: #20c0f3;
  color: #ffffff;
}

.nav-tabs.nav-tabs-solid .active > a,
.nav-tabs.nav-tabs-solid .active a:hover,
.nav-tabs.nav-tabs-solid .active a:focus {
  background-color: #20c0f3;
  border-color: #20c0f3;
  color: #ffffff;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
}

@media (max-width: 767.98px) {
  .nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 5px;
  }
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
  border-radius: 50px;
}

@media (max-width: 767.98px) {
  .nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
  .nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
  .nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
    border-color: transparent transparent transparent #20c0f3 !important;
    border-left-width: 2px;
  }
}

@media (max-width: 767.98px) {
  .nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 5px;
  }
}

.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
  background-color: #f5f5f5;
  border-color: transparent;
}

@media (max-width: 767.98px) {
  .nav-tabs .nav-link.active {
    border-color: transparent transparent transparent #c70e2a !important;
    border-left-width: 2px;
  }
}

.tab-content {
  padding-top: 20px;
}

.card-header-tabs {
  border-bottom: 0;
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 0.6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active {
  opacity: 1;
}

.carousel-fade .carousel-item-next {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.carousel-fade .carousel-item-next .carousel-item-left {
  opacity: 1;
}

.carousel-fade .carousel-item-prev {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.carousel-fade .carousel-item-prev .carousel-item-right {
  opacity: 1;
}

.carousel-fade .active {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.carousel-fade .active .carousel-item-left,
.carousel-fade .active .carousel-item-right {
  opacity: 0;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.carousel-item {
  height: 75vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: -1 !important;
}

.divider .carousel-caption {
  position: absolute;
  top: 110px;
  right: 0;
  bottom: 20px;
  left: 5%;
}

.carousel-caption {
  z-index: 10;
  padding-bottom: 20px;
  color: #ffffff;
  position: absolute;
  top: 110px;
  right: 0;
  bottom: 20px;
  left: 15%;
}

.carousel-caption span {
  font-size: 32px;
  font-weight: 700;
  color: rgb(46, 56, 66);
}

.carousel-caption h2 {
  font-size: 54px;
  font-weight: 700;
  color: rgb(30, 92, 146);
}

.carousel-caption p {
  font-size: 18px;
  font-weight: 700;
  color: rgb(46, 56, 66);
}

@media (max-width: 991.98px) {
  .carousel-inner {
    height: 430px;
  }
}

@media (max-width: 767.98px) {
  .carousel-inner {
    height: 200px;
  }
}

.slide-nav-1 button.owl-next,
.slide-nav-1 button.owl-prev,
.slide-nav-2 button.owl-next,
.slide-nav-2 button.owl-prev,
.slide-nav-3 button.owl-next,
.slide-nav-3 button.owl-prev,
.slide-nav-4 button.owl-next,
.slide-nav-4 button.owl-prev {
  width: 34px;
  height: 34px;
  background: #10defd;
  text-shadow: none;
  top: 4px;
  cursor: pointer;
  border: 0;
  color: #ffffff;
  margin-bottom: 15px;
  border-radius: 8px;
  -ms-transform: rotate(-44deg);
  -webkit-transform: rotate(-44deg);
  transform: rotate(-44deg);
}

@media (max-width: 767.98px) {
  .slide-nav-1 button.owl-next,
  .slide-nav-1 button.owl-prev,
  .slide-nav-2 button.owl-next,
  .slide-nav-2 button.owl-prev,
  .slide-nav-3 button.owl-next,
  .slide-nav-3 button.owl-prev,
  .slide-nav-4 button.owl-next,
  .slide-nav-4 button.owl-prev {
    width: 28px;
    height: 28px;
  }
}

.owl-theme .owl-dots,
.owl-nav button {
  margin-top: 30px;
}

@media (max-width: 767.98px) {
  .owl-theme .owl-dots,
  .owl-nav button {
    margin-top: 10px;
  }
}

.custom-arrow {
  -ms-transform: rotate(44deg);
  -webkit-transform: rotate(44deg);
  transform: rotate(44deg);
}

.owl-nav button.owl-next {
  margin-left: 20px;
}

.footer {
  background-color: #15558d;
}

.footer.footer-one .footer-bottom .copyright .copyright-text p {
  color: #6b7280;
}

@media (max-width: 767.98px) {
  .footer.footer-one .footer-bottom .copyright .copyright-text p {
    text-align: center;
  }
}

.footer .footer-top {
  padding: 40px 0;
}

@media (max-width: 991.98px) {
  .footer .footer-top {
    padding-bottom: 10px;
  }
}

.footer .footer-top .footer-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 30px;
  text-transform: capitalize;
}

@media (max-width: 767.98px) {
  .footer .footer-top .footer-title {
    font-size: 18px;
  }
}

@media (max-width: 575.98px) {
  .footer .footer-top .footer-title {
    font-size: 18px;
  }
}

@media (max-width: 991.98px) {
  .footer .footer-top .footer-widget {
    margin-bottom: 30px;
  }
}

.footer .footer-top .footer-widget.footer-menu ul {
  list-style: none;
  outline: none;
  margin: 0;
  padding: 0;
}

.footer .footer-top .footer-widget.footer-menu ul li {
  margin-bottom: 10px;
  position: relative;
}

.footer .footer-top .footer-widget.footer-menu ul li:last-child {
  margin-bottom: 0;
}

.footer .footer-top .footer-widget.footer-menu ul li a {
  color: #ffffff;
  font-size: 15px;
  padding-left: 20px;
  position: relative;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.footer .footer-top .footer-widget.footer-menu ul li a:hover {
  color: #ffffff;
  letter-spacing: 0.5px;
  padding-left: 30px;
}

.footer .footer-top .footer-widget.footer-menu ul li a::before {
  content: "\f101";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.footer .footer-top .footer-widget .footer-logo {
  margin: 0 0 30px;
}

.footer .footer-top .footer-widget .footer-about-content p {
  color: #ffffff;
}

.footer .footer-top .footer-widget .footer-about-content p:last-child {
  margin-bottom: 0;
}

.footer .footer-top .footer-widget .footer-about-content .social-icon ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer .footer-top .footer-widget .footer-about-content .social-icon ul li {
  margin-right: 15px;
}

.footer
  .footer-top
  .footer-widget
  .footer-about-content
  .social-icon
  ul
  li:last-child {
  margin-right: 0;
}

.footer .footer-top .footer-widget .footer-about-content .social-icon ul li a {
  color: #ffffff;
  font-size: 20px;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.footer
  .footer-top
  .footer-widget
  .footer-about-content
  .social-icon
  ul
  li
  a:hover {
  color: #09e5ab;
}

.footer .footer-top .footer-widget .footer-contact-info {
  color: #ffffff;
  font-size: 15px;
}

.footer .footer-top .footer-widget .footer-contact-info .footer-address span {
  margin-right: 20px;
}

.footer
  .footer-top
  .footer-widget
  .footer-contact-info
  .footer-address
  span
  i.fa-map-marker-alt {
  font-size: 20px;
}

.footer .footer-top .footer-widget .footer-contact-info .footer-address p {
  margin: 0 0 1rem;
}

.footer .footer-top .footer-widget .footer-contact-info p i {
  margin-right: 15px;
}

.footer .footer-bottom .copyright {
  border-top: 1px solid #1663a6;
  padding: 30px 0;
}

.footer .footer-bottom .copyright .copyright-text p {
  font-size: 15px;
  color: #ffffff;
}

.footer .footer-bottom .copyright .copyright-text p a {
  color: #1f2937;
  font-weight: 500;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.footer .footer-bottom .copyright .copyright-text p a:hover {
  color: #2a9d36;
}

.footer .footer-bottom .copyright .copyright-text p.title {
  font-weight: 400;
  margin: 10px 0 0;
}

.footer .footer-bottom .copyright .policy-menu {
  font-size: 14px;
  text-align: right;
  margin: 0;
  padding: 0;
}

@media (max-width: 767.98px) {
  .footer .footer-bottom .copyright .policy-menu {
    margin-top: 10px;
    text-align: left;
  }
}

.footer .footer-bottom .copyright .policy-menu li {
  display: inline-block;
  margin-right: 15px;
}

.footer .footer-bottom .copyright .policy-menu li:last-child {
  margin-right: 0;
}

.footer .footer-bottom .copyright .policy-menu li a {
  color: #ffffff;
}

.footer .footer-bottom .copyright .policy-menu li a:hover,
.footer .footer-bottom .copyright .policy-menu li a:focus {
  color: #2a9d36;
}

.footer .footer-bottom .copyright .policy-menu li::after {
  color: #ffffff;
  content: "|";
  font-weight: 300;
  position: relative;
  left: 10px;
}

.footer .footer-bottom .copyright .policy-menu li:last-child::after {
  content: "";
}

.footer-one {
  background: #f2f6f6;
}

.footer-one .footer-top {
  margin: 0;
  padding: 50px 0;
}

@media (max-width: 991.98px) {
  .footer-one .footer-top {
    margin: 0;
    padding: 40px 0 10px;
  }
}

@media (max-width: 767.98px) {
  .footer-one .footer-top {
    margin: 0;
    padding: 40px 0 0;
  }
}

.footer-one .footer-top .footer-widget .footer-logo {
  margin-bottom: 20px;
}

.footer-one .footer-top .footer-widget .footer-about-content {
  max-width: 272px;
}

.footer-one .footer-top .footer-widget .footer-about-content p {
  margin-bottom: 0;
  font-size: 14px;
  color: #6b7280;
}

.footer-one .footer-top .footer-widget.footer-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-one .footer-top .footer-widget .footer-title {
  font-size: 18px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 25px;
}

@media (max-width: 991.98px) {
  .footer-one .footer-top .footer-widget {
    margin-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  .footer-one .footer-top .footer-widget {
    margin-bottom: 25px;
  }
}

.footer-one .footer-top .footer-widget.footer-menu ul li {
  margin-bottom: 15px;
}

.footer-one .footer-top .footer-widget.footer-menu ul li:last-child {
  margin-bottom: 0;
}

.footer-one .footer-top .footer-widget.footer-menu ul li a {
  color: #6b7280;
  position: relative;
  overflow: hidden;
  background: url(../images/arrow1.png) no-repeat -10px;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  padding-left: 0;
  font-size: 14px;
}

.footer-one .footer-top .footer-widget.footer-menu ul li a:hover {
  color: #2a9d36;
  background: url(../images/arrow1.png) no-repeat 4px;
  padding-left: 20px;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.footer-one .footer-top .footer-widget.footer-menu ul li a::before {
  display: none;
}

.footer-one .footer-top .footer-widget .footer-contact-info .footer-address {
  margin-bottom: 15px;
}

.footer-one .footer-top .footer-widget .footer-contact-info .footer-address p {
  margin-bottom: 0;
  color: #6b7280;
  font-size: 14px;
}

.footer-one
  .footer-top
  .footer-widget
  .footer-contact-info
  .footer-address
  p
  i {
  font-size: 16px;
  color: #9ca3af;
  margin-right: 10px;
}

.footer-one .footer-bottom .copyright {
  border-top: 1px solid #e5e7eb;
  margin: 0;
  padding: 30px 0;
}

.footer-one .footer-bottom .copyright .policy-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}

.footer-one .footer-bottom .copyright .policy-menu li {
  display: inline-block;
  margin-right: 25px;
  color: #1f2937;
  font-weight: 500;
}

@media (max-width: 767.98px) {
  .footer-one .footer-bottom .copyright .policy-menu li {
    display: block;
    margin-right: 0;
    margin-bottom: 8px;
    text-align: center;
    margin-top: 10px;
  }

  .footer-one .footer-bottom .copyright .policy-menu li:last-child {
    margin-bottom: 0;
  }
}

.footer-one .footer-bottom .copyright .policy-menu li::after {
  font-size: 15px;
  color: #6b7280;
  content: "|";
  font-weight: 500;
  position: relative;
  left: 15px;
}

@media (max-width: 767.98px) {
  .footer-one .footer-bottom .copyright .policy-menu li::after {
    display: none;
  }
}

.footer-one .footer-bottom .copyright .policy-menu li:last-child::after {
  content: "";
}

.footer-one .footer-bottom .copyright .policy-menu li a {
  color: #6b7280;
}

.footer-one .footer-bottom .copyright .policy-menu li a:hover {
  color: #2a9d36;
}

@media (max-width: 991.98px) {
  .footer-one .footer-bottom .copyright {
    margin: 0;
    padding: 25px 0;
  }
}

.subscribe-form {
  position: relative;
  margin-bottom: 20px;
}

.subscribe-form .form-control {
  font-weight: 500;
  font-size: 14px;
  min-height: 46px;
  color: #6b7280;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 6px 0px 0px 6px;
  margin: 0;
  padding: 6px 112px 6px 15px;
}

@media (max-width: 991.98px) {
  .subscribe-form .form-control {
    padding-right: 180px;
  }
}

@media (max-width: 767.98px) {
  .subscribe-form .form-control {
    min-height: 46px;
    padding-right: 145px;
  }
}

.subscribe-form .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #6b7280;
}

.subscribe-form .btn {
  font-weight: normal;
  margin: 0;
  padding: 11px 16px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0 6px 6px 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  color: #ffffff;
  background: #c70e2a;
  border: 1px solid #c70e2a;
  box-shadow: inset 0 0 0 0 #ffffff;
}

.subscribe-form .btn:hover {
  color: #2a9d36;
  border-color: #2a9d36;
  background: #ffffff;
  box-shadow: inset 0 0 0 50px #ffffff;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 767.98px) {
  .subscribe-form .btn {
    font-size: 14px;
  }
}

.subscribe-content p {
  max-width: 360px;
  margin-bottom: 0;
  font-size: 14px;
  color: #6b7280;
}

.footer-two {
  background-color: #15558d;
}

.footer-two .footer-top {
  padding: 40px 0;
}

@media (max-width: 991.98px) {
  .footer-two .footer-top {
    padding-bottom: 10px;
  }
}

.footer-two .footer-top .footer-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 30px;
  text-transform: capitalize;
}

@media (max-width: 767.98px) {
  .footer-two .footer-top .footer-title {
    font-size: 18px;
  }
}

@media (max-width: 575.98px) {
  .footer-two .footer-top .footer-title {
    font-size: 18px;
  }
}

@media (max-width: 991.98px) {
  .footer-two .footer-top .footer-widget {
    margin-bottom: 30px;
  }
}

.footer-two .footer-top .footer-widget.footer-menu ul {
  list-style: none;
  outline: none;
  margin: 0;
  padding: 0;
}

.footer-two .footer-top .footer-widget.footer-menu ul li {
  margin-bottom: 10px;
  position: relative;
}

.footer-two .footer-top .footer-widget.footer-menu ul li:last-child {
  margin-bottom: 0;
}

.footer-two .footer-top .footer-widget.footer-menu ul li a {
  color: #ffffff;
  font-size: 15px;
  padding-left: 20px;
  position: relative;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.footer-two .footer-top .footer-widget.footer-menu ul li a:hover {
  color: #ffffff;
  letter-spacing: 0.5px;
  padding-left: 30px;
}

.footer-two .footer-top .footer-widget.footer-menu ul li a::before {
  content: "\f101";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.footer-two .footer-top .footer-widget .footer-logo {
  margin: 0 0 30px;
}

.footer-two .footer-top .footer-widget .footer-about-content {
  max-width: inherit;
}

.footer-two .footer-top .footer-widget .footer-about-content p {
  color: #ffffff;
  margin-bottom: 15px;
}

.footer-two .footer-top .footer-widget .footer-about-content p:last-child {
  margin-bottom: 0;
}

.footer-two .footer-top .footer-widget .footer-about-content .social-icon ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-two
  .footer-top
  .footer-widget
  .footer-about-content
  .social-icon
  ul
  li {
  margin-right: 15px;
}

.footer-two
  .footer-top
  .footer-widget
  .footer-about-content
  .social-icon
  ul
  li:last-child {
  margin-right: 0;
}

.footer-two
  .footer-top
  .footer-widget
  .footer-about-content
  .social-icon
  ul
  li
  a {
  color: #ffffff;
  font-size: 20px;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.footer-two
  .footer-top
  .footer-widget
  .footer-about-content
  .social-icon
  ul
  li
  a:hover {
  color: #09e5ab;
}

.footer-two .footer-top .footer-widget .footer-contact-info {
  color: #ffffff;
  font-size: 15px;
}

.footer-two
  .footer-top
  .footer-widget
  .footer-contact-info
  .footer-address
  span {
  margin-right: 20px;
}

.footer-two
  .footer-top
  .footer-widget
  .footer-contact-info
  .footer-address
  span
  i.fa-map-marker-alt {
  font-size: 20px;
}

.footer-two .footer-top .footer-widget .footer-contact-info .footer-address p {
  margin: 0;
}

.footer-two .footer-top .footer-widget .footer-contact-info p i {
  margin-right: 15px;
}

.footer-two .footer-bottom .copyright {
  border-top: 1px solid #1663a6;
  padding: 30px 0;
}

.footer-two .footer-bottom .copyright .copyright-text p {
  color: #ffffff;
  font-size: 15px;
}

.footer-two .footer-bottom .copyright .copyright-text p a {
  color: #09e5ab;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.footer-two .footer-bottom .copyright .copyright-text p a:hover {
  color: #ffffff;
}

.footer-two .footer-bottom .copyright .copyright-text p.title {
  font-weight: 400;
  margin: 10px 0 0;
}

.footer-two .footer-bottom .copyright .policy-menu {
  font-size: 14px;
  text-align: right;
  margin: 0;
  padding: 0;
}

@media (max-width: 767.98px) {
  .footer-two .footer-bottom .copyright .policy-menu {
    margin-top: 10px;
    text-align: left;
  }
}

.footer-two .footer-bottom .copyright .policy-menu li {
  display: inline-block;
  margin-right: 15px;
}

.footer-two .footer-bottom .copyright .policy-menu li:last-child {
  margin-right: 0;
}

.footer-two .footer-bottom .copyright .policy-menu li a {
  color: #ffffff;
}

.footer-two .footer-bottom .copyright .policy-menu li a:hover,
.footer-two .footer-bottom .copyright .policy-menu li a:focus {
  color: #2a9d36;
}

.footer-two .footer-bottom .copyright .policy-menu li::after {
  color: #ffffff;
  content: "|";
  font-weight: 300;
  position: relative;
  left: 10px;
}

.footer-two .footer-bottom .copyright .policy-menu li:last-child::after {
  content: "";
}

.footer-three {
  background: #ffffff;
}

.footer-three .footer-top {
  margin: 0;
  padding: 30px 0;
}

.footer-three .footer-widget .footer-title {
  color: #0071dc;
  margin: 0 0 15px;
}

.footer-three .footer-widget .footer-about-content p {
  font-size: 13px;
  color: #000000;
  margin-bottom: 28px;
}

.footer-three .footer-menu ul li {
  margin: 0 0 10px;
}

.footer-three .footer-menu ul li a {
  color: #000000;
  font-size: 16px;
  background: none;
}

.footer-three .footer-menu ul li a:hover {
  background: none;
  padding-left: 0;
}

@media (max-width: 1199.98px) {
  .footer-three .footer-menu ul li a {
    font-size: 15px;
  }
}

@media (max-width: 767.98px) {
  .footer-three .footer-menu ul li a {
    font-size: 14px;
  }
}

.footer-three .footer-link {
  margin-bottom: 20px !important;
}

.footer-three .social-icon ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-three .social-icon ul li {
  display: inline-block;
  margin-right: 10px;
}

.footer-three .social-icon ul li:last-child {
  margin-right: 0;
}

.footer-three .social-icon ul li a {
  width: 45px;
  height: 45px;
  color: #ffffff;
  background: #0071dc;
  border: 1px solid #0071dc;
  border-radius: 50%;
  font-size: 18px;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.footer-three .social-icon ul li a:hover {
  background: #3b9cf6;
  color: #ffffff;
}

@media (max-width: 1199.98px) {
  .footer-three .social-icon ul li a {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }
}

.footer-three .footer-bottom .copyright {
  border-top: 1px solid #d9d9d9;
}

.footer-three .footer-bottom .copyright .copyright-menu p {
  color: #000000;
  margin: 0;
}

@media (max-width: 991.98px) {
  .footer-three .footer-bottom .copyright .copyright-menu {
    margin: 0 0 15px;
  }
}

@media (max-width: 767.98px) {
  .footer-three .footer-bottom .copyright .footer-language > img {
    margin: 0 0 15px;
  }
}

.footer-three .footer-bottom .copyright .footer-language .footer-select {
  width: 164px;
  position: relative;
}

.footer-three
  .footer-bottom
  .copyright
  .footer-language
  .footer-select
  .select-icon {
  position: absolute;
  top: 55%;
  left: 14px;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  color: #999999;
}

.footer-three
  .footer-bottom
  .copyright
  .footer-language
  .footer-select
  .select2-container
  .select2-selection--single {
  border: 1px solid #d9d9d9;
  border-radius: 100px;
}

.footer-three
  .footer-bottom
  .copyright
  .footer-language
  .footer-select
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-left: 30px;
}

.footer-three
  .footer-bottom
  .copyright
  .footer-language
  .footer-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #999999;
  font-weight: 500;
}

@media (max-width: 767.98px) {
  .footer-three .footer-bottom .copyright .footer-language {
    display: block;
  }
}

.footer-one .social-icon ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-one .social-icon ul li {
  margin-right: 15px;
}

.footer-one .social-icon ul li:last-child {
  margin-right: 0;
}

.footer-one .social-icon ul li a {
  color: #2a9d36;
  font-size: 20px;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.footer-one .social-icon ul li a:hover {
  color: #c70e2a;
}

.footer.footer-one.footer-ten {
  background: #f8fafc;
  position: relative;
}

.footer.footer-one.footer-ten .footer-ten-bg img {
  position: absolute;
}

.footer.footer-one.footer-ten .footer-ten-bg img:nth-child(1) {
  left: 0;
}

.footer.footer-one.footer-ten .footer-ten-bg img:nth-child(2) {
  right: 0;
  bottom: 0;
}

.footer.footer-one.footer-ten .footer-ten-bg img:nth-child(3) {
  left: 50%;
  transform: translate(-50%, 100%);
}

.footer.footer-one.footer-ten .footer-details {
  padding-bottom: 50px;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 50px;
}

.footer.footer-one.footer-ten .footer-top .footer-widget.footer-menu ul {
  list-style: disc;
  padding-left: 15px;
}

.footer.footer-one.footer-ten
  .footer-top
  .footer-widget
  .footer-about-content
  p {
  padding-bottom: 24px;
}

.footer.footer-one.footer-ten .mail-note {
  color: #6b7280;
}

.footer.footer-one.footer-ten .footer-map {
  border-radius: 35px;
  overflow: hidden;
}

.footer.footer-one.footer-ten .footer-map img {
  width: 100%;
}

.footer.footer-one.footer-ten .footer-bottom {
  background: #ffffff;
}

.footer.footer-one.footer-ten .footer-bottom .copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.footer.footer-one.footer-ten .footer-bottom .copyright .policy-menu li a {
  color: #6b7280;
  font-weight: 400;
}

.footer.footer-one.footer-ten
  .footer-bottom
  .copyright
  .policy-menu
  li
  a:hover {
  color: #2a9d36;
}

.footer.footer-eleven {
  background: #1b5a90;
}

.footer.footer-eleven .footer-top {
  padding: 50px 0 35px;
}

.footer.footer-eleven .footer-top .footer-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 10px;
}

.footer.footer-eleven .footer-top .footer-content p {
  color: #ffffff;
  margin: 0 0 25px;
}

.footer.footer-eleven .footer-top .subscribe-form {
  margin-bottom: 25px;
}

.footer.footer-eleven .footer-top .subscribe-form .form-control {
  min-height: 59px;
  margin: 0;
  padding: 10px 112px 10px 15px;
  border-radius: 10px;
}

.footer.footer-eleven .footer-top .subscribe-form .form-control::placeholder {
  color: #2b3d51;
}

.footer.footer-eleven .footer-top .subscribe-form .btn {
  margin-right: 10px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: normal;
  padding: 6px 23px;
}

@media (max-width: 767.98px) {
  .footer.footer-eleven .footer-top .footer-payment {
    margin: 0 0 15px 0;
  }
}

.footer.footer-eleven .footer-top .footer-payment h6 {
  font-size: 16px;
  margin: 0 0 15px;
  color: #ffffff;
}

.footer.footer-eleven .footer-bottom .copyright {
  border-top: 1px solid #4183bb;
  padding: 25px 0;
}

.footer.footer-eleven .footer-bottom .copyright .copyright-text p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .footer.footer-eleven .footer-bottom .copyright .copyright-text p {
    margin-bottom: 10px;
  }
}

.footer.footer-eleven .footer-bottom .copyright .copyright-text p a {
  color: #0ce0ff;
}

.footer.footer-eleven .footer-bottom .copyright .policy-menu li {
  font-size: 16px;
}

@media (max-width: 575.98px) {
  .footer.footer-eleven .footer-bottom .copyright .policy-menu li {
    margin: 0 10px 10px 0;
  }
}

.footer.footer-eleven .footer-bottom .copyright .policy-menu li::after {
  color: rgba(255, 255, 255, 0.15);
}

.footer.footer-eleven .footer-bottom .copyright .policy-menu li a:hover {
  color: #2a9d36;
}

@media (max-width: 1399.98px) {
  .footer.footer-eleven .footer-bottom .copyright .policy-menu li {
    font-size: 15px;
  }
}

.footer.footer-eleven .social-icon {
  margin: 0 0 25px;
}

.footer.footer-eleven .social-icon ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;
}

@media (max-width: 767.98px) {
  .footer.footer-eleven .social-icon ul {
    text-align: left !important;
  }
}

.footer.footer-eleven .social-icon ul li {
  display: inline-block;
  margin: 0 20px 0 0;
}

.footer.footer-eleven .social-icon ul li a {
  color: #ffffff;
  font-size: 20px;
}

.footer.footer-eleven .social-icon ul li a:hover {
  color: #0ce0ff;
}

.footer.footer-eleven .social-icon ul li:last-child {
  margin-right: 0;
}

.footer.footer-eleven .footer-widget.footer-menu {
  margin: 0 0 25px;
}

.footer.footer-eleven .footer-widget.footer-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer.footer-eleven .footer-widget.footer-menu ul li {
  display: inline-block;
  margin: 0 45px 0 0;
}

@media (max-width: 991.98px) {
  .footer.footer-eleven .footer-widget.footer-menu ul li {
    margin: 0 9px 0 0;
  }
}

@media (max-width: 575.98px) {
  .footer.footer-eleven .footer-widget.footer-menu ul li {
    margin: 0 10px 10px 0;
  }
}

.footer.footer-eleven .footer-widget.footer-menu ul li a {
  font-size: 16px;
  padding-left: 0;
  color: #ffffff;
}

.footer.footer-eleven .footer-widget.footer-menu ul li a:hover {
  color: #0ce0ff;
}

.footer.footer-eleven .footer-widget.footer-menu ul li a::before {
  content: none !important;
}

.footer.footer-eleven .footer-widget.footer-menu ul li:last-child {
  margin-right: 0;
}

.footer-language {
  position: relative;
  width: 173px;
  float: right;
}

@media (max-width: 767.98px) {
  .footer-language {
    float: left !important;
  }
}

.footer-language .select2-container .select2-selection--single {
  border: 1px solid #ffffff;
  background-color: transparent;
  border-radius: 10px;
  height: 44px;
}

.footer-language
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-left: 34px;
  color: #ffffff;
}

.footer-language .icon-lang {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  color: #ffffff;
  line-height: 0;
}

.footer-contacts {
  margin: 0 0 26px;
}

.footer-contacts .footer-wrap {
  background: rgba(240, 244, 246, 0.16);
  backdrop-filter: blur(50px);
  border-radius: 10px;
  margin: 0 0 24px;
  padding: 15px;
}

.footer-contacts .footer-wrap .footer-icon {
  width: 55px;
  height: 55px;
  background: #ffffff;
  border-radius: 100px;
  margin: 0 15px 0 0;
  flex-shrink: 0;
  color: #0ce0ff;
  font-size: 24px;
}

.footer-contacts .footer-wrap .footer-info h6 {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  margin: 0 0 10px;
}

.footer-contacts .footer-wrap .footer-info p {
  margin: 0;
  color: #ffffff;
}

.footer-one.footer-fourteen.footer-twelve {
  background: #f9fafb;
  padding: 0;
}

@media (max-width: 991.98px) {
  .footer-one.footer-fourteen.footer-twelve
    .footer-bottom
    .copyright
    .policy-menu {
    text-align: start;
  }

  .footer-one.footer-fourteen.footer-twelve
    .footer-bottom
    .copyright
    .policy-menu
    li {
    margin-right: 4px;
    font-weight: 400;
  }

  .footer-one.footer-fourteen.footer-twelve
    .footer-bottom
    .copyright
    .policy-menu
    li::after {
    left: 5px;
  }

  .footer-one.footer-fourteen.footer-twelve
    .footer-bottom
    .copyright
    .policy-menu
    li
    a {
    font-size: 14px;
  }
}

.footer.footer-thirteen {
  background: #ffffff;
  background-image: url(../images/bg/Map-thirteen.png);
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.footer.footer-thirteen .footer-top {
  margin: 0;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .footer.footer-thirteen .footer-top {
    margin: 0;
    padding: 50px 0;
  }
}

.footer.footer-thirteen .footer-top .footer-widget .footer-title {
  font-size: 18px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 22px;
}

.footer.footer-thirteen .footer-top .footer-widget ul li {
  margin-bottom: 4px;
}

.footer.footer-thirteen .footer-top .footer-widget ul li a {
  color: #6b7280;
  font-size: 14px;
  font-weight: 400;
  padding-left: 0;
}

.footer.footer-thirteen .footer-top .footer-widget ul li a::before {
  content: "";
}

.footer.footer-thirteen .footer-top .footer-widget ul li a:hover {
  color: #0e82fd;
  padding-left: 10px;
}

.footer.footer-thirteen .footer-top .footer-widget ul li:first-child a {
  font-weight: 600;
}

.footer.footer-thirteen .footer-top .footer-menu-thirteen {
  margin-top: 43px;
}

.footer.footer-thirteen .footer-top .footer-menu-thirteen .footer-title {
  display: none;
}

@media (max-width: 991.98px) {
  .footer.footer-thirteen .footer-top .footer-menu-thirteen {
    margin-top: 0;
  }

  .footer.footer-thirteen .footer-top .footer-menu-thirteen .footer-title {
    display: block;
  }
}

.footer.footer-thirteen .footer-top .footer-contents-thirteen-main {
  margin: 60px 0 0 0;
}

@media (max-width: 991.98px) {
  .footer.footer-thirteen .footer-top .footer-contents-thirteen-main {
    margin: 20px 0 0 0;
  }
}

@media (max-width: 991.98px) {
  .footer.footer-thirteen
    .footer-top
    .footer-contents-thirteen-main
    .footer-thirt-inner {
    margin-bottom: 10px;
  }
}

.footer.footer-thirteen
  .footer-top
  .footer-contents-thirteen-main
  .footer-thirt-inner
  h4 {
  font-weight: 600;
  color: #00438a;
  margin-bottom: 6px;
}

.footer.footer-thirteen
  .footer-top
  .footer-contents-thirteen-main
  .footer-thirt-inner
  p {
  font-size: 14px;
  color: rgba(147, 163, 180, 0.8);
  font-weight: 400;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .footer.footer-thirteen
    .footer-top
    .footer-contents-thirteen-main
    .footer-thirt-inner
    p {
    margin-bottom: 10px;
  }
}

.footer.footer-thirteen
  .footer-top
  .footer-contents-thirteen-main
  .subscribe-form {
  margin-bottom: 0;
  max-width: 430px;
}

@media (max-width: 1299.98px) {
  .footer.footer-thirteen
    .footer-top
    .footer-contents-thirteen-main
    .subscribe-form {
    max-width: 385px;
  }
}

.footer.footer-thirteen
  .footer-top
  .footer-contents-thirteen-main
  .subscribe-form
  .form-control {
  min-height: 56px;
  border: 1px solid #aab4ee;
  border-radius: 10px;
  padding: 10px 112px 10px 15px;
}

.footer.footer-thirteen
  .footer-top
  .footer-contents-thirteen-main
  .subscribe-form
  .form-control::placeholder {
  color: #bec4d9;
  font-size: 14px;
  font-weight: 600;
}

.footer.footer-thirteen
  .footer-top
  .footer-contents-thirteen-main
  .subscribe-form
  .btn {
  padding: 17px 40px;
  right: -45%;
  border-radius: 10px;
}

@media (max-width: 1299.98px) {
  .footer.footer-thirteen
    .footer-top
    .footer-contents-thirteen-main
    .subscribe-form
    .btn {
    padding: 17px 30px;
  }
}

@media (max-width: 1199.98px) {
  .footer.footer-thirteen
    .footer-top
    .footer-contents-thirteen-main
    .subscribe-form
    .btn {
    padding: 10px 19px;
    right: 6px;
  }
}

.footer.footer-thirteen .footer-bottom .copyright {
  border-top: 1px solid rgba(52, 52, 52, 0.1);
  padding: 20px 0;
}

@media (max-width: 991.98px) {
  .footer.footer-thirteen .footer-bottom .copyright {
    text-align: center;
  }
}

@media (max-width: 991.98px) {
  .footer.footer-thirteen .footer-bottom .copyright .copyright-img {
    margin-bottom: 15px;
  }
}

@media (max-width: 991.98px) {
  .footer.footer-thirteen .footer-bottom .copyright .copyright-text {
    margin-bottom: 15px;
  }
}

.footer.footer-thirteen .footer-bottom .copyright .copyright-text p {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: center;
  color: #6b7280;
}

@media (max-width: 991.98px) {
  .footer.footer-thirteen .footer-bottom .copyright .copyright-menu {
    margin-bottom: 15px;
  }
}

@media (max-width: 991.98px) {
  .footer.footer-thirteen .footer-bottom .copyright .copyright-menu ul {
    text-align: center;
  }
}

.footer.footer-thirteen .footer-bottom .copyright .copyright-menu ul li a {
  color: #00438a;
  font-size: 18px;
}

.footer.footer-thirteen
  .footer-bottom
  .copyright
  .copyright-menu
  ul
  li
  a:hover {
  color: #2a9d36;
}

.footer.footer-thirteen .footer-bg-icon img {
  position: absolute;
  z-index: 0;
}

.footer.footer-thirteen .footer-bg-icon img:nth-child(1) {
  bottom: 20%;
  left: 0;
}

.footer.footer-thirteen .footer-bg-icon img:nth-child(2) {
  top: 20%;
  right: -7%;
}

@media (max-width: 991.98px) {
  .footer.footer-thirteen .footer-bg-icon img:nth-child(2) {
    display: none;
  }
}

.footer-one.footer-fourteen {
  background: #ffffff;
  padding: 200px 0 0 0;
}

.footer-one.footer-fourteen .footer-top {
  padding: 80px 0 50px 0;
}

@media (max-width: 991.98px) {
  .footer-one.footer-fourteen .footer-top {
    padding: 50px 0 10px 0;
  }
}

.footer-one.footer-fourteen
  .footer-top
  .footer-widget
  .subscribe-form
  .form-control {
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px 0px 0px 6px;
}

.footer.footer-fifteen {
  position: relative;
  padding: 52px 0 0 0;
  background-color: #ffffff;
  background-image: url(../images/footer-fift-bg.png);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 50% 100%;
}

@media (max-width: 991.98px) {
  .footer.footer-fifteen {
    background-image: none;
    background-color: #0e82fd;
  }
}

.footer.footer-fifteen .footer-top-fifteen {
  padding: 0;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-left-fifteen
  .footer-fifteen-image {
  margin-bottom: 10px;
}

.footer.footer-fifteen .footer-top-fifteen .footer-left-fifteen p {
  color: #ffffff;
  margin-bottom: 25px;
  line-height: 27px;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-left-fifteen
  .subscribe-fifteen {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 10px;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-left-fifteen
  .subscribe-form-fifteen {
  width: 83%;
  margin-bottom: 25px;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-left-fifteen
  .subscribe-form-fifteen
  .form-control {
  border-radius: 10px;
  min-height: 55px;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 130px 10px 10px;
}

.form-control option:hover {
  background-color: #2a9d36;
}

@media (max-width: 767.98px) {
  .footer.footer-fifteen
    .footer-top-fifteen
    .footer-left-fifteen
    .subscribe-form-fifteen
    .form-control {
    font-size: 14px;
  }
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-left-fifteen
  .subscribe-form-fifteen
  .form-control::placeholder {
  color: #2b3d51;
  font-weight: 400;
  font-size: 16px;
}

@media (max-width: 767.98px) {
  .footer.footer-fifteen
    .footer-top-fifteen
    .footer-left-fifteen
    .subscribe-form-fifteen
    .form-control::placeholder {
    font-size: 14px;
  }
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-left-fifteen
  .subscribe-form-fifteen
  .btn {
  padding: 10px 15px;
  background: #0ce0ff;
  border-radius: 5px;
  border: none;
  right: 6px;
}

@media (max-width: 767.98px) {
  .footer.footer-fifteen
    .footer-top-fifteen
    .footer-left-fifteen
    .subscribe-form-fifteen
    .btn {
    padding: 10px 10px;
  }
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-left-fifteen
  .subscribe-form-fifteen
  .btn:hover {
  background: #0e82fd;
  box-shadow: inset 0 0 0 50px #0e82fd;
  color: #ffffff;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-left-fifteen
  .subscribe-fifteentwo {
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 15px;
}

@media (max-width: 991.98px) {
  .footer.footer-fifteen
    .footer-top-fifteen
    .footer-left-fifteen
    .footer-fifteen-image-2 {
    margin-bottom: 15px;
  }
}

.footer.footer-fifteen .footer-top-fifteen .footer-right-fifteen {
  margin-left: 18px;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .subscribe-fifteenthree {
  font-size: 18px;
  color: #1f2937;
  font-weight: 600;
  margin-bottom: 15px;
}

@media (max-width: 991.98px) {
  .footer.footer-fifteen
    .footer-top-fifteen
    .footer-right-fifteen
    .subscribe-fifteenthree {
    color: #ffffff;
  }
}

.footer.footer-fifteen .footer-top-fifteen .footer-right-fifteen ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.footer.footer-fifteen .footer-top-fifteen .footer-right-fifteen ul li {
  margin: 0 25px 25px 0;
  text-decoration: underline;
  line-height: 19px;
}

@media (max-width: 991.98px) {
  .footer.footer-fifteen .footer-top-fifteen .footer-right-fifteen ul li {
    margin: 0 15px 15px 0;
    text-decoration: none;
  }
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  ul
  li:last-child {
  margin-right: 0;
}

.footer.footer-fifteen .footer-top-fifteen .footer-right-fifteen ul li a {
  color: #6b7280;
}

.footer.footer-fifteen .footer-top-fifteen .footer-right-fifteen ul li a:hover {
  color: #1c5b91;
}

@media (max-width: 991.98px) {
  .footer.footer-fifteen .footer-top-fifteen .footer-right-fifteen ul li a {
    color: #ffffff;
  }
}

.footer.footer-fifteen .footer-top-fifteen .footer-right-fifteen p {
  color: #6b7280;
}

@media (max-width: 991.98px) {
  .footer.footer-fifteen .footer-top-fifteen .footer-right-fifteen p {
    color: #ffffff;
  }
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .user-details-fift
  ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .user-details-fift
  ul
  li {
  text-decoration: none;
  margin: 0 15px 0 0;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .user-details-fift
  ul
  li
  .contact-fifteen {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #e5e5eb;
  border-radius: 10px;
  background: #ffffff;
  margin: 0 0 15px 0;
  padding: 10px;
  overflow: hidden;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .user-details-fift
  ul
  li
  .contact-fifteen
  .contact-form-fift {
  width: 35px;
  height: 35px;
  border-radius: 100px;
  background: #0e82fd;
  font-size: 17px;
  color: #ffffff;
  margin-right: 7px;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .user-details-fift
  ul
  li
  .contact-fifteen
  .contact-content-fift
  h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

@media (max-width: 1399.98px) {
  .footer.footer-fifteen
    .footer-top-fifteen
    .footer-right-fifteen
    .user-details-fift
    ul
    li
    .contact-fifteen
    .contact-content-fift
    h6 {
    font-size: 14px;
  }
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .user-details-fift
  ul
  li
  .contact-fifteen
  .contact-content-fift
  span {
  color: #707070;
  margin-bottom: 0;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-fift-selects {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
}

@media (max-width: 479.98px) {
  .footer.footer-fifteen
    .footer-top-fifteen
    .footer-right-fifteen
    .footer-fift-selects {
    display: block;
  }
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-fift-selects
  .footer-select {
  display: inline-block;
}

@media (max-width: 479.98px) {
  .footer.footer-fifteen
    .footer-top-fifteen
    .footer-right-fifteen
    .footer-fift-selects
    .footer-select {
    margin-bottom: 15px;
  }
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-fift-selects
  .footer-select
  img {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-fift-selects
  .footer-select
  .select2-container
  .select2-selection--single {
  border: 1px solid #e5e5eb;
  border-radius: 10px;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-fift-selects
  .footer-select
  .select2-selection__rendered {
  padding-left: 43px;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-fift-selects
  .footer-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  right: 1px;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-fift-selects
  .footer-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #000000 transparent transparent;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-fift-selects
  .social-icon
  ul
  li {
  margin: 0 10px 0 0;
  text-decoration: none;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-fift-selects
  .social-icon
  ul
  li
  a {
  width: 44px;
  height: 43px;
  border-radius: 100px;
  background: #0e82fd;
  color: #ffffff;
  font-size: 18px;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-fift-selects
  .social-icon
  ul
  li
  a:hover {
  background: #0ce0ff;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-bottom.footer-bottom-fifteen
  .copyright {
  border-top: 1px solid #e5e5eb;
  padding: 25px 0 25px 0;
}

@media (max-width: 767.98px) {
  .footer.footer-fifteen
    .footer-top-fifteen
    .footer-right-fifteen
    .footer-bottom.footer-bottom-fifteen
    .copyright
    .copyright-text {
    text-align: center;
    margin-bottom: 10px;
  }
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-bottom.footer-bottom-fifteen
  .copyright
  .copyright-text
  p {
  margin-bottom: 0;
  font-size: 14px;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-bottom.footer-bottom-fifteen
  .copyright
  .copyright-menu
  .policy-menu {
  justify-content: end;
}

@media (max-width: 767.98px) {
  .footer.footer-fifteen
    .footer-top-fifteen
    .footer-right-fifteen
    .footer-bottom.footer-bottom-fifteen
    .copyright
    .copyright-menu
    .policy-menu {
    justify-content: center;
  }
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-bottom.footer-bottom-fifteen
  .copyright
  .copyright-menu
  .policy-menu
  li {
  text-decoration: none;
  margin: 0 10px 0 0;
}

@media (max-width: 1199.98px) {
  .footer.footer-fifteen
    .footer-top-fifteen
    .footer-right-fifteen
    .footer-bottom.footer-bottom-fifteen
    .copyright
    .copyright-menu
    .policy-menu
    li {
    margin: 0 15px 15px 0;
  }
}

.footer.footer-one.footer-sixteen {
  background: #ffffff;
  border-top: 1px solid #e2e2e2;
  position: relative;
  background-image: url(../images/map-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.footer.footer-one.footer-sixteen
  .footer-top
  .footer-widget
  .footer-about-content {
  max-width: none;
  margin-bottom: 30px;
}

.footer.footer-one.footer-sixteen .footer-top .footer-widget .subscribe-form {
  margin-bottom: 0;
}

.footer.footer-one.footer-sixteen
  .footer-top
  .footer-widget
  .subscribe-form
  .btn {
  border: 1px solid #1c5b91;
  background: #1c5b91;
}

.footer.footer-one.footer-sixteen
  .footer-top
  .footer-widget
  .subscribe-form
  .btn:hover {
  border: 1px solid #0e82fd;
}

.footer.footer-one.footer-sixteen .footer-bottom .copyright .copyright-text p {
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  .footer.footer-one.footer-sixteen .footer-bottom .copyright .copyright-text {
    margin-bottom: 10px;
  }
}

.footer.footer-one.footer-sixteen .footer-bottom .copyright .copyright-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 767.98px) {
  .footer.footer-one.footer-sixteen
    .footer-bottom
    .copyright
    .copyright-menu
    ul {
    justify-content: center;
  }
}

.footer.footer-one.footer-sixteen
  .footer-bottom
  .copyright
  .copyright-menu
  ul
  li {
  margin-right: 10px;
}

.footer.footer-one.footer-sixteen
  .footer-bottom
  .copyright
  .copyright-menu
  ul
  li:last-child {
  margin-right: 0;
}

.footer.footer-one.footer-sixteen
  .footer-bottom
  .copyright
  .copyright-menu
  ul
  li
  a {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #1f2937;
  color: #ffffff;
  font-size: 12px;
}

.footer.footer-one.footer-sixteen
  .footer-bottom
  .copyright
  .copyright-menu
  ul
  li
  a:hover {
  background: #2a9d36;
}

/****** PAGES ******/
.section-search {
  background: #f9f9f9 url(../images/search-bg.png) no-repeat bottom center;
  min-height: 530px;
  background-size: 100% auto;
  position: relative;
  background-blend-mode: Darken;
  padding: 160px 0 80px 0;
}

@media (max-width: 991.98px) {
  .section-search {
    min-height: 330px;
  }
}

@media (max-width: 767.98px) {
  .section-search {
    background: #f9f9f9;
    padding: 100px 0 50px 0;
  }
}

@media (max-width: 479.98px) {
  .section-search {
    min-height: 410px;
    padding: 100px 15px 30px 15px;
  }
}

.section-search .banner-wrapper {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 991.98px) {
  .section-search .banner-wrapper {
    max-width: 720px;
  }
}

.section-search .banner-wrapper .banner-header {
  margin: 0 0 30px;
}

.section-search .banner-wrapper .banner-header h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 10px;
}

@media (max-width: 991.98px) {
  .section-search .banner-wrapper .banner-header h1 {
    font-size: 34px;
  }
}

@media (max-width: 767.98px) {
  .section-search .banner-wrapper .banner-header h1 {
    font-size: 32px;
  }
}

@media (max-width: 575.98px) {
  .section-search .banner-wrapper .banner-header h1 {
    font-size: 28px;
  }
}

.section-search .banner-wrapper .banner-header p {
  color: #757575;
  font-size: 20px;
  margin: 0;
}

@media (max-width: 767.98px) {
  .section-search .banner-wrapper .banner-header p {
    font-size: 16px;
  }
}

@media (max-width: 767.98px) {
  .section-search .banner-wrapper .search-box {
    max-width: 535px;
    margin: 0 auto;
  }
}

@media (max-width: 767.98px) {
  .section-search .banner-wrapper .search-box form {
    -ms-flex-direction: column;
    flex-direction: column;
    word-wrap: break-word;
    background-clip: border-box;
  }
}

@media (max-width: 575.98px) {
  .section-search .banner-wrapper .search-box form {
    display: block;
  }
}

.section-search .banner-wrapper .search-box .form-control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding-left: 35px;
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
}

.section-search .banner-wrapper .search-box .form-control::placeholder {
  font-size: 14px;
}

.section-search .banner-wrapper .search-box .search-location {
  -ms-flex: 0 0 240px;
  flex: 0 0 240px;
  margin-right: 12px;
  position: relative;
  width: 240px;
}

@media (max-width: 767.98px) {
  .section-search .banner-wrapper .search-box .search-location {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .section-search .banner-wrapper .search-box .search-location {
    width: 100% !important;
    -ms-flex: none;
    flex: none;
  }
}

.section-search .banner-wrapper .search-box .search-location .form-control {
  background: #ffffff url(../images/location.png) no-repeat 10px center;
}

.section-search .banner-wrapper .search-box .search-info {
  -ms-flex: 0 0 490px;
  flex: 0 0 490px;
  margin-right: 12px;
  position: relative;
  width: 490px;
}

@media (max-width: 991.98px) {
  .section-search .banner-wrapper .search-box .search-info {
    -ms-flex: 0 0 410px;
    flex: 0 0 410px;
    width: 410px;
  }
}

@media (max-width: 767.98px) {
  .section-search .banner-wrapper .search-box .search-info {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .section-search .banner-wrapper .search-box .search-info {
    width: 100% !important;
    -ms-flex: none;
    flex: none;
  }
}

.section-search .banner-wrapper .search-box .search-info .form-control {
  background: #ffffff url(../images/search.png) no-repeat 10px center;
}

.section-search .banner-wrapper .search-box .search-btn {
  width: 46px;
  -ms-flex: 0 0 46px;
  flex: 0 0 46px;
  height: 46px;
}

@media (max-width: 767.98px) {
  .section-search .banner-wrapper .search-box .search-btn {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    min-height: 46px;
    width: 100%;
  }
}

.section-search .banner-wrapper .search-box .search-btn span {
  display: none;
  font-weight: 500;
}

@media (max-width: 767.98px) {
  .section-search .banner-wrapper .search-box .search-btn span {
    display: inline-block;
    margin-left: 5px;
    text-transform: uppercase;
  }
}

.section-search .banner-wrapper .search-box .form-text {
  color: #757575;
  font-size: 13px;
}

.section-search .banner-wrapper .search-box .search-location {
  -ms-flex: 0 0 240px;
  flex: 0 0 240px;
  margin-right: 12px;
  position: relative;
  width: 240px;
}

@media (max-width: 767.98px) {
  .section-search .banner-wrapper .search-box .search-location {
    flex: auto;
  }
}

@media (max-width: 767.98px) {
  .section-search .banner-wrapper .search-box {
    max-width: 535px;
    margin: 0 auto;
  }
}

.section-header {
  margin-bottom: 60px;
}

.section-header h5 {
  font-weight: 600;
  font-size: 18px;
  color: #0e82fd;
  margin-bottom: 10px;
}

.section-header h2 {
  font-size: 36px;
  font-weight: 500;
  margin: 0;
}

@media (max-width: 767.98px) {
  .section-header h2 {
    font-size: 30px;
  }
}

@media (max-width: 575.98px) {
  .section-header h2 {
    font-size: 24px;
  }
}

.section-header .sub-title {
  color: #757575;
  font-size: 16px;
  max-width: 600px;
  margin: 15px auto 0;
}

@media (max-width: 767.98px) {
  .section-header .sub-title {
    font-size: 14px;
  }
}

@media (max-width: 575.98px) {
  .section-header .sub-title {
    font-size: 14px;
  }
}

.section-header p {
  color: #757575;
  font-size: 16px;
  margin: 15px 0 0 0;
}

@media (max-width: 767.98px) {
  .section-header p {
    font-size: 15px;
  }
}

@media (max-width: 575.98px) {
  .section-header p {
    font-size: 15px;
  }
}

.section-specialities {
  background-color: #ffffff;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .section-specialities {
    padding: 50px 0;
  }
}

@media (max-width: 479.98px) {
  .section-specialities {
    background-color: #ffffff;
    padding: 30px 0;
  }
}

.section-specialities .specialities-slider .slick-slide {
  display: block;
  margin: 10px 20px 0 0;
  padding: 0 12px;
}

@media (max-width: 767.98px) {
  .section-specialities .specialities-slider .slick-slide {
    margin-right: 15px;
  }
}

@media (max-width: 479.98px) {
  .section-specialities .specialities-slider .slick-slide {
    margin-right: 10px;
  }
}

.section-specialities .specialities-slider .slick-dots {
  margin-top: 44px;
  position: unset;
}

.section-specialities .specialities-slider .speicality-item p {
  font-size: 16px;
  font-weight: 500;
  margin: 30px 0 0;
}

@media (max-width: 767.98px) {
  .section-specialities .specialities-slider .speicality-item p {
    font-size: 14px;
  }
}

@media (max-width: 575.98px) {
  .section-specialities .specialities-slider .speicality-item p {
    font-size: 14px;
  }
}

.section-specialities .specialities-slider .speicality-item .speicality-img {
  position: relative;
  height: 150px;
  border-radius: 100%;
  width: 150px;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
}

@media (max-width: 479.98px) {
  .section-specialities .specialities-slider .speicality-item .speicality-img {
    width: 120px;
    height: 120px;
  }
}

.section-specialities
  .specialities-slider
  .speicality-item
  .speicality-img
  img {
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: 0 auto;
}

@media (max-width: 479.98px) {
  .section-specialities
    .specialities-slider
    .speicality-item
    .speicality-img
    img {
    width: 50px;
  }
}

.section-specialities
  .specialities-slider
  .speicality-item
  .speicality-img
  span {
  border-radius: 50%;
  padding: 5px;
  background-color: #ffffff;
  position: absolute;
  right: 10px;
  bottom: 10px;
  -webkit-box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.2);
}

.section-specialities
  .specialities-slider
  .speicality-item
  .speicality-img
  span
  i {
  font-size: 15px;
  color: #0de0fe;
}

.slick-dots {
  display: block;
  width: 100%;
  list-style: none;
  text-align: center;
  position: absolute;
  bottom: -25px;
  margin: 0;
  padding: 0;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 5px;
  cursor: pointer;
  margin: 0 5px;
  padding: 0;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #c0c0c0;
  padding: 0;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  display: none;
  font-family: "slick";
  font-size: 76px;
  line-height: 20px;
  width: 20px;
  height: 20px;
  content: "-";
  text-align: center;
  opacity: 0.25;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button {
  background-color: #0de0fe;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #0de0fe;
}

.slick-dots li.slick-active button .slick-slide {
  outline: none !important;
}

.section-doctor {
  background-color: #f8f9fa;
  padding: 80px 0;
}

@media (max-width: 767.98px) {
  .section-doctor {
    padding: 50px 0;
  }
}

.section-doctor .profile-widget {
  -webkit-box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
}

.section-doctor .section-header {
  margin: 0 0 30px;
}

.section-doctor .section-header p {
  margin-top: 10px;
}

@media (max-width: 991.98px) {
  .section-doctor .about-content {
    margin-bottom: 30px;
  }
}

.section-doctor .about-content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}

.section-doctor .about-content p + p {
  margin-top: 20px;
}

.section-doctor .about-content a {
  background-color: #0de0fe;
  color: #ffffff;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  min-width: 150px;
  margin: 30px 0 0;
  padding: 15px 20px;
  text-align: center;
  border-radius: 4px;
}

@media (max-width: 767.98px) {
  .section-doctor .about-content a {
    padding: 12px 20px;
  }
}

.section-doctor .about-content a:hover,
.section-doctor .about-content a:focus {
  background-color: #01cae4;
  border-color: #01cae4;
  color: #ffffff;
}

@media (max-width: 767.98px) {
  .section-doctor .doctor-slider {
    margin-top: 25px;
  }
}

.section-doctor .doctor-slider .slick-slide {
  display: block;
  width: 280px;
  margin-bottom: 0;
  padding: 10px;
}

.profile-widget {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  position: relative;
  margin-bottom: 30px;
  padding: 15px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.profile-widget .fav-btn {
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  color: #2e3842;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  position: absolute;
  top: 5px;
  right: 5px;
  transform: translate3d(100%, 0, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  border-radius: 3px;
}

.profile-widget:hover .fav-btn {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.profile-widget:hover .fav-btn:hover {
  background-color: #fb1612;
  color: #ffffff;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.profile-widget .verified {
  color: #28a745;
  margin-left: 3px;
}

.profile-widget .rating {
  color: #757575;
  font-size: 14px;
  margin-bottom: 15px !important;
}

.profile-widget .rating i {
  font-size: 14px;
}

.profile-widget .doc-img {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 4px;
}

.profile-widget .doc-img img {
  border-radius: 4px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
}

.profile-widget .doc-img:hover img {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}

.profile-widget .pro-content {
  padding: 15px 0 0;
}

.profile-widget .pro-content .title {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 5px;
}

.profile-widget .pro-content .title a {
  display: inline-block;
}

.profile-widget .pro-content p.speciality {
  font-size: 13px;
  color: #757575;
  margin-bottom: 5px;
  min-height: 40px;
}

.profile-widget .pro-content .rating {
  list-style: none;
  width: 100%;
  margin: 0 0 7px;
  padding: 0;
}

.profile-widget .pro-content .rating i {
  color: #dedfe0;
}

.profile-widget .pro-content .rating i.filled {
  color: #f4c150;
}

.profile-widget .pro-content .available-info {
  font-size: 13px;
  color: #757575;
  font-weight: 400;
  list-style: none;
  margin: auto auto 15px;
  padding: 0;
}

.profile-widget .pro-content .available-info li i {
  width: 22px;
}

.profile-widget .pro-content .available-info li + li {
  margin-top: 5px;
}

.profile-widget .pro-content .row.row-sm {
  margin-left: -3px;
  margin-right: -3px;
}

.profile-widget .pro-content .row.row-sm > div {
  padding-left: 3px;
  padding-right: 3px;
}

.section-features {
  background-color: #ffffff;
  padding: 80px 0;
}

@media (max-width: 767.98px) {
  .section-features {
    padding: 50px 0;
  }
}

@media (max-width: 479.98px) {
  .section-features {
    background-color: #ffffff;
    padding: 30px 0;
  }
}

@media (max-width: 767.98px) {
  .section-features .features-img {
    text-align: center;
    margin: 0 auto;
  }
}

.section-features .features-slider .slick-slide {
  margin-right: 62px;
}

.section-features .features-slider .slick-center {
  opacity: 1;
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
}

.section-features .features-slider .slick-list {
  padding: 16px 50px !important;
}

.section-features .features-slider .slick-dots {
  margin-top: 44px;
  position: unset;
}

@media (max-width: 767.98px) {
  .section-features .features-slider .slick-dots {
    text-align: center;
    margin: 0 auto;
  }
}

.section-features .features-slider .feature-item img {
  box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.2);
  height: 115px;
  object-fit: cover;
  width: 115px;
  border-radius: 100%;
}

.section-features .features-slider .feature-item p {
  font-weight: 500;
  margin: 20px 0 0;
}

.section-blogs {
  background-color: #f8f9fa;
  padding: 80px 0;
}

@media (max-width: 767.98px) {
  .section-blogs {
    padding: 50px 0;
  }
}

@media (max-width: 479.98px) {
  .section-blogs {
    padding: 30px 0;
  }
}

.section-blogs .view-all .btn {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 700;
  padding: 12px 30px;
}

.rating {
  list-style: none;
  width: 100%;
  margin: 0 0 7px;
  padding: 0;
}

.rating i {
  color: #dedfe0;
}

.rating i.filled {
  color: #f4c150;
}

.home-tile-section {
  background-color: #f8f9fa;
  padding: 80px 0;
}

@media (max-width: 767.98px) {
  .home-tile-section {
    padding: 50px 0;
  }
}

@media (max-width: 479.98px) {
  .home-tile-section {
    padding: 30px 0;
  }
}

.home-tile-section .doctor-book-card {
  position: relative;
  max-width: 100%;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.0431372549);
  overflow: hidden;
  margin: 0 auto;
}

@media (max-width: 575.98px) {
  .home-tile-section .doctor-book-card {
    margin-bottom: 0.9375rem;
  }
}

.home-tile-section .doctor-book-card img {
  vertical-align: middle;
  border-radius: 0.25rem;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.home-tile-section .doctor-book-card .doctor-book-card-content {
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 20px;
}

.home-tile-section .doctor-book-card .doctor-book-card-content > div {
  width: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.home-tile-section .doctor-book-card .doctor-book-card-content .book-btn1 {
  font-size: 14px;
}

.home-tile-section .doctor-book-card .tile-card-content-1 {
  background: rgba(13, 13, 13, 0.33);
}

.home-tile-section .doctor-book-card h3 {
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

.home-tile-section .doctor-book-card:hover img {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}

.banner-section {
  background: #f2f6f6;
  padding: 100px 0 30px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.banner-section::after {
  content: "";
  background: url(../images/banner-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.banner-shapes {
  position: relative;
}

.banner-shapes .banner-shape-left {
  position: absolute;
  top: 600px;
  left: 0;
}

.banner-shapes .banner-shape-right {
  position: absolute;
  top: 30px;
  left: 0;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.banner-content {
  padding-bottom: 80px;
  position: relative;
}

.banner-content h1 {
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 25px;
}

.banner-content h1 span {
  color: #0e82fd;
}

@media (max-width: 991.98px) {
  .banner-content h1 {
    font-size: 36px;
  }
}

@media (max-width: 767.98px) {
  .banner-content h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

.banner-content .header-icon {
  position: absolute;
  right: -30px;
  top: -15px;
}

@media (max-width: 1199.98px) {
  .banner-content .header-icon {
    display: none;
  }
}

.banner-content p {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 25px;
}

@media (max-width: 767.98px) {
  .banner-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.banner-content .btn {
  font-weight: 500;
  border-radius: 8px;
  margin: 0;
  padding: 12px 15px;
  min-width: 200px;
  display: inline-block;
  background-color: #0e82fd;
  border: 1px solid #0e82fd;
  color: #ffffff;
  font-size: 18px;
  box-shadow: inset 0 0 0 0 #ffffff;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.banner-content .btn:hover {
  color: #0e82fd;
  border-color: #0e82fd;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 50px #ffffff;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 991.98px) {
  .banner-content .btn {
    font-size: 16px;
    margin: 0;
    padding: 10px 15px;
    min-width: 190px;
  }
}

@media (max-width: 767.98px) {
  .banner-content .btn {
    font-size: 15px;
    margin: 0;
    padding: 9px 15px;
    min-width: 170px;
  }
}

@media (max-width: 991.98px) {
  .banner-content {
    text-align: center;
    padding-bottom: 40px;
  }
}

.banner-arrow-img {
  position: absolute;
  left: 220px;
  top: 180px;
}

@media (max-width: 1199.98px) {
  .banner-arrow-img {
    display: none;
  }
}

.search-box-one {
  min-width: 800px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 3px 53px rgba(197, 197, 197, 0.2);
  border-radius: 7px;
  margin: 0;
  padding: 13px 15px;
  display: table;
  position: relative;
  z-index: 1;
}

.search-box-one form {
  float: left;
  width: 100%;
}

.search-box-one input:hover,
.search-box-one input:focus {
  background-color: #ffffff !important;
}

@media (max-width: 991.98px) {
  .search-box-one {
    min-width: 100%;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .search-box-one {
    margin: 0;
    padding: 0px 15px 15px;
  }
}

.search-box-one .form-control:hover,
.search-box-one .form-control:focus {
  background-color: #ffffff !important;
}

.search-input {
  width: 25%;
  float: left;
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

.search-input.search-line {
  width: 35%;
}

.search-input.search-line::before {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  width: 1px;
  height: 44px;
  background: #e3e4e8;
  content: "";
  z-index: 1;
}

@media (max-width: 767.98px) {
  .search-input.search-line::before {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .search-input.search-line {
    width: 100%;
  }
}

.search-input.search-map-line {
  width: 26%;
}

.search-input.search-map-line::before {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  width: 1px;
  height: 44px;
  background: #e3e4e8;
  content: "";
  z-index: 1;
}

@media (max-width: 767.98px) {
  .search-input.search-map-line::before {
    display: none;
  }
}

.search-input.search-map-line input {
  padding: 0px 36px 0px 36px;
}

@media (max-width: 767.98px) {
  .search-input.search-map-line input {
    padding: 0px 25px 0px 25px;
  }
}

.search-input.search-map-line > i {
  left: 10px;
}

@media (max-width: 767.98px) {
  .search-input.search-map-line > i {
    left: 0;
  }
}

@media (max-width: 767.98px) {
  .search-input.search-map-line {
    width: 100%;
  }
}

.search-input input {
  height: 40px;
  padding: 0 10px 0 25px;
  border: 0;
}

.search-input input::placeholder {
  color: #8c97b0;
}

.search-input.search-calendar-line input {
  padding: 0 36px 0 36px;
}

@media (max-width: 767.98px) {
  .search-input.search-calendar-line input {
    padding: 0px 25px 0px 25px;
  }
}

.search-input.search-calendar-line > i {
  left: 10px;
}

@media (max-width: 767.98px) {
  .search-input.search-calendar-line > i {
    left: 0;
  }
}

.search-input i {
  position: absolute;
  top: 50%;
  right: inherit;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  color: #8894ae;
  font-size: 18px;
}

.search-input .current-loc-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  margin-top: 0px;
  color: #8894ae;
  font-size: 18px;
  line-height: 0;
}

.search-input .current-loc-icon i {
  position: unset;
  transform: unset;
}

.search-input > i.bficon {
  right: inherit;
  left: 0;
  z-index: 1;
}

@media (max-width: 767.98px) {
  .search-input {
    width: 100%;
  }
}

.form-search-btn {
  float: right;
  padding-top: 4px;
}

.form-search-btn .btn {
  font-size: 15px;
  font-weight: 500;
  min-width: 97px;
  padding: 7px 15px;
  border-radius: 10px;
  background-color: #0e82fd;
  border: 1px solid #0e82fd;
  color: #ffffff;
  box-shadow: inset 0 0 0 0 #ffffff;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.form-search-btn .btn:hover {
  color: #0e82fd;
  border-color: #0e82fd;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 50px #ffffff;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 767.98px) {
  .form-search-btn {
    float: left;
  }
}

.banner-img {
  position: relative;
}

.banner-img .banner-img1 {
  position: absolute;
  top: 260px;
  left: -40px;
  -webkit-animation: float 2s ease-in-out infinite;
  animation: float 2s ease-in-out infinite;
}

.banner-img .banner-img2 {
  position: absolute;
  top: 200px;
  right: 20px;
  -webkit-animation: mover 3s infinite alternate;
  animation: mover 3s infinite alternate;
}

.banner-img .banner-img3 {
  position: absolute;
  top: 500px;
  right: -50px;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@keyframes float {
  0% {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
  }

  50% {
    transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
  }

  100% {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
  }
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
  }

  100% {
    transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
  }

  100% {
    transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
  }
}

@media (max-width: 991.98px) {
  .banner-img {
    display: none;
  }
}

.specialities-section-one {
  background-color: #ffffff;
  margin: 0;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .specialities-section-one {
    margin: 0;
    padding: 40px 0;
  }
}

.specialities-section-one .specialities-slider-one .item {
  margin-bottom: 50px;
}

@media (max-width: 767.98px) {
  .specialities-section-one .specialities-slider-one .item {
    margin-bottom: 40px;
  }
}

.specialities-section-one .specialities-slider-one .owl-stage-outer {
  margin-left: -10px;
}

.specialities-section-one .specialities-slider-one .owl-item img {
  width: auto;
}

.specialities-section-one .specialities-item {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  height: 196px;
  margin: 15px 0 0 10px;
  padding: 30px;
  cursor: pointer;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.specialities-section-one .specialities-item:hover {
  background: #ffffff;
  border-color: #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.specialities-section-one .specialities-item:hover .specialities-img span {
  background: #0e82fd;
  border: #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.specialities-section-one .specialities-item:hover .specialities-img span img {
  filter: invert(1) brightness(100);
  transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
  -ms-transform: rotateY(360deg);
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}

.specialities-section-one .specialities-item p {
  font-weight: 600;
  font-size: 16px;
  color: #2f353c;
  margin-bottom: 0;
}

.specialities-section-one .specialities-img {
  padding-bottom: 18px;
}

.specialities-section-one .specialities-img span {
  background: #f2f6f6;
  border: 1px solid #f2f6f6;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.specialities-section-one .specialities-img span img {
  width: auto;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}

.specialities-section-one .specialities-btn {
  text-align: center;
}

.specialities-section-one .specialities-btn .btn {
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  min-width: 170px;
  padding: 11px 15px;
  background: #0e82fd;
  border: 1px solid #0e82fd;
  box-shadow: inset 0 0 0 0 #ffffff;
  color: #ffffff;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.specialities-section-one .specialities-btn .btn:hover {
  color: #0e82fd;
  background: #ffffff;
  border-color: #0e82fd;
  box-shadow: inset 0 0 0 50px #ffffff;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 767.98px) {
  .specialities-section-one .specialities-btn .btn {
    font-size: 14px;
    min-width: 190px;
  }
}

.section-header-one {
  margin-bottom: 60px;
}

.section-header-one h2 {
  font-size: 40px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .section-header-one h2 {
    font-size: 34px;
  }
}

@media (max-width: 767.98px) {
  .section-header-one h2 {
    font-size: 26px;
  }
}

.section-header-one .section-title {
  display: inline-block;
  position: relative;
}

.section-header-one .section-title::before {
  content: "";
  background: url(../images/icons/header-icon.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  position: absolute;
  top: -5px;
  right: -30px;
  width: 35px;
  height: 20px;
}

@media (max-width: 767.98px) {
  .section-header-one .section-title::before {
    display: none;
  }
}

.section-header-one h5 {
  font-weight: 600;
  font-size: 18px;
  color: #0e82fd;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .section-header-one {
    margin-bottom: 40px;
  }
}

.slide-nav-1 button.owl-next,
.slide-nav-1 button.owl-prev,
.slide-nav-2 button.owl-next,
.slide-nav-2 button.owl-prev {
  width: 44px;
  height: 44px;
  font-size: 18px;
  color: #1e3259;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  text-shadow: none;
  top: 0;
  cursor: pointer;
  border: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 15px;
  border-radius: 50px;
}

.slide-nav-1 button.owl-next:hover,
.slide-nav-1 button.owl-prev:hover,
.slide-nav-2 button.owl-next:hover,
.slide-nav-2 button.owl-prev:hover {
  color: #ffffff;
  background: #0e82fd;
}

.doctors-section {
  background: #f2f6f6;
  margin: 0;
  padding: 80px 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.doctors-section::after {
  content: "";
  background: url(../images/bg/doctor-shape-img1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
  top: 0;
  left: 0;
  width: 213px;
  height: 335px;
  opacity: 0.5;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .doctors-section::after {
    display: none;
  }
}

.doctors-section::before {
  content: "";
  background: url(../images/bg/doctor-shape-img2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  width: 213px;
  height: 335px;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .doctors-section::before {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .doctors-section {
    margin: 0;
    padding: 40px 0;
  }
}

@media (max-width: 767.98px) {
  .doctors-section .section-header-slider {
    margin-bottom: 20px;
  }
}

.doctor-shape-img {
  position: relative;
}

.doctor-shape-img .doctor-shape-left {
  position: absolute;
  top: 0;
  left: -35px;
}

.doctor-shape-img .doctor-shape-right {
  position: absolute;
  top: 230px;
  right: 0;
}

@media (max-width: 991.98px) {
  .doctor-shape-img {
    display: none;
  }
}

@-webkit-keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.doctor-profile-widget {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.doc-pro-img {
  position: relative;
}

.doctor-profile-img {
  position: relative;
  overflow: hidden;
  border-radius: 8px 8px 0px 0px;
}

.doctor-profile-img img {
  position: relative;
  border-radius: 8px 8px 0px 0px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
}

.doctor-profile-img:hover img {
  -webkit-transition: scale(1.15);
  -ms-transition: scale(1.15);
  transition: scale(1.15);
}

.doctor-profile-img:hover::before {
  -webkit-animation: circle 1s;
  animation: circle 1s;
}

.doctor-profile-img::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  opacity: 0;
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

.doctor-amount {
  position: absolute;
  top: 20px;
  right: 20px;
}

.doctor-amount span {
  font-weight: 500;
  font-size: 15px;
  color: #2c2c2c;
  padding: 6px 12px;
  background: #ffffff;
  border-radius: 8px;
}

.doc-content {
  padding: 20px;
}

@media (max-width: 767.98px) {
  .doc-content {
    padding: 15px;
  }
}

.doc-pro-info {
  padding-bottom: 15px;
}

.doc-pro-info .doc-pro-name a {
  font-weight: 600;
  font-size: 20px;
}

.doc-pro-info .doc-pro-name a:hover {
  color: #0e82fd;
}

@media (max-width: 1399.98px) {
  .doc-pro-info .doc-pro-name a {
    font-size: 18px;
  }
}

.doc-pro-info .doc-pro-name p {
  color: #9ca3af;
  font-size: 12px;
  margin-bottom: 0;
}

.reviews-ratings p {
  color: #9ca3af;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 0;
}

.reviews-ratings span {
  margin: 0 4px 0 0;
  padding: 2px 3px;
  min-width: 46px;
  color: #ffffff;
  font-size: 12px;
  background: #ffbc11;
  border-radius: 6px;
}

.reviews-ratings span i {
  margin-right: 6px;
}

.doc-pro-location p {
  margin-bottom: 0;
}

.doc-pro-location p i {
  margin-right: 8px;
  font-size: 20px;
  color: #d1d5db;
}

.work-section {
  background: #f2f6f6;
  margin: 0;
  padding: 80px 0 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.work-section::after {
  content: "";
  background: url(../images/bg/doctor-shape-img1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
  top: 0;
  left: 0;
  width: 213px;
  height: 335px;
  opacity: 0.5;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .work-section::after {
    display: none;
  }
}

.work-section::before {
  content: "";
  background: url(../images/bg/doctor-shape-img2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  width: 213px;
  height: 335px;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .work-section::before {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .work-section {
    margin: 0;
    padding: 40px 0 20px;
  }
}

.work-img-info {
  position: relative;
}

@media (max-width: 991.98px) {
  .work-img-info {
    display: none;
  }
}

.work-img-info .work-img {
  position: absolute;
  bottom: 0;
}

.work-details {
  padding-left: 60px;
}

@media (max-width: 1199.98px) {
  .work-details {
    padding-left: 20px;
  }
}

.work-info {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  margin-bottom: 35px;
}

@media (max-width: 991.98px) {
  .work-info {
    margin-bottom: 20px;
  }
}

.work-info .work-icon {
  margin-right: 20px;
}

.work-info .work-icon span {
  min-width: 70px;
  height: 70px;
  background: #e0e7ff;
  border-radius: 10px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
}

.work-info .work-icon span img {
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.work-info:hover .work-icon span {
  background: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.work-info:hover .work-icon span img {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.work-info .work-content h5 {
  font-weight: 600;
  font-size: 18px;
  color: #1f2937;
  margin-bottom: 14px;
}

@media (max-width: 767.98px) {
  .work-info .work-content h5 {
    margin-bottom: 10px;
  }
}

.work-info .work-content p {
  font-size: 16px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .work-info .work-content p {
    font-size: 14px;
  }
}

.work-section {
  background: #f2f6f6;
  margin: 0;
  padding: 80px 0 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.work-section::after {
  content: "";
  background: url(../images/bg/doctor-shape-img1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
  top: 0;
  left: 0;
  width: 213px;
  height: 335px;
  opacity: 0.5;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .work-section::after {
    display: none;
  }
}

.work-section::before {
  content: "";
  background: url(../images/bg/doctor-shape-img2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  width: 213px;
  height: 335px;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .work-section::before {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .work-section {
    margin: 0;
    padding: 40px 0 20px;
  }
}

.work-img-info {
  position: relative;
}

@media (max-width: 991.98px) {
  .work-img-info {
    display: none;
  }
}

.work-img-info .work-img {
  position: absolute;
  bottom: 0;
}

.work-details {
  padding-left: 60px;
}

@media (max-width: 1199.98px) {
  .work-details {
    padding-left: 20px;
  }
}

.work-info {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  margin-bottom: 35px;
}

@media (max-width: 991.98px) {
  .work-info {
    margin-bottom: 20px;
  }
}

.work-info .work-icon {
  margin-right: 20px;
}

.work-info .work-icon span {
  min-width: 70px;
  height: 70px;
  background: #e0e7ff;
  border-radius: 10px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
}

.work-info .work-icon span img {
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.work-info:hover .work-icon span {
  background: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.work-info:hover .work-icon span img {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.work-info .work-content h5 {
  font-weight: 600;
  font-size: 18px;
  color: #1f2937;
  margin-bottom: 14px;
}

@media (max-width: 767.98px) {
  .work-info .work-content h5 {
    margin-bottom: 10px;
  }
}

.work-info .work-content p {
  font-size: 16px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .work-info .work-content p {
    font-size: 14px;
  }
}

.articles-section {
  background-color: #ffffff;
  margin: 0;
  padding: 80px 0 55px;
}

@media (max-width: 991.98px) {
  .articles-section {
    margin: 0;
    padding: 40px 0 15px;
  }
}

.articles-grid {
  border: 2px solid #ededed;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 25px;
}

.articles-info .articles-left {
  margin-right: 20px;
}

.articles-info .articles-left .articles-img {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  width: 205px;
  height: 180px;
}

.articles-info .articles-left .articles-img img {
  position: relative;
  border-radius: 8px;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  width: 100%;
}

.articles-info .articles-left .articles-img:hover img {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}

.articles-info .articles-left .articles-img:hover::before {
  -webkit-animation: circle 1s;
  animation: circle 1s;
}

.articles-info .articles-left .articles-img::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  opacity: 0;
}

@media (max-width: 1199.98px) {
  .articles-info .articles-left .articles-img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1199.98px) {
  .articles-info .articles-left {
    margin: 0 0 20px 0;
    padding: 0;
  }
}

@media (max-width: 1199.98px) {
  .articles-info {
    display: block;
    text-align: center;
  }
}

.articles-content .articles-list {
  margin-bottom: 10px;
}

.articles-content .articles-list li {
  margin-right: 15px;
}

.articles-content .articles-list li:last-child {
  margin-right: 0;
}

.articles-content .articles-list li i {
  font-size: 18px;
  margin-right: 8px;
}

@media (max-width: 1199.98px) {
  .articles-content .articles-list {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
}

.articles-content h4 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  max-width: 320px;
}

@media (max-width: 991.98px) {
  .articles-content h4 {
    max-width: inherit;
  }
}

.articles-content h4 a:hover {
  color: #0e82fd;
}

.articles-content p {
  margin-bottom: 16px;
}

.articles-content .btn {
  font-weight: 500;
  font-size: 15px;
  color: #0e82fd;
  background: #ffffff;
  border: 2px solid #0e82fd;
  box-shadow: inset 0 0 0 0 #0e82fd;
  border-radius: 6px;
  min-width: 112px;
  padding: 7px 12px;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.articles-content .btn:hover {
  color: #ffffff;
  border-color: #0e82fd;
  background: #0e82fd;
  box-shadow: inset 0 0 0 50px #0e82fd;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 767.98px) {
  .articles-content .btn {
    font-size: 14px;
  }
}

.app-section {
  background-color: #ffffff;
  margin: 0;
  padding: 20px 0 80px;
}

@media (max-width: 1199.98px) {
  .app-section {
    margin: 0;
    padding: 0 0 80px;
  }
}

@media (max-width: 991.98px) {
  .app-section {
    margin: 0;
    padding: 20px 0 40px;
  }
}

@media (max-width: 767.98px) {
  .app-section {
    margin: 0;
    padding: 0px 0 40px;
  }
}

.app-bg {
  background: #0e82fd;
  background-image: url(../images/app-bg.png);
  background-position: right top;
  background-repeat: no-repeat;
  border-radius: 25px;
  padding: 40px 0 0 50px;
}

@media (max-width: 1199.98px) {
  .app-bg {
    margin: 0;
    padding: 30px;
  }
}

@media (max-width: 991.98px) {
  .app-bg {
    margin: 0;
    padding: 40px 0;
    background-image: inherit;
  }
}

@media (max-width: 767.98px) {
  .app-bg {
    margin: 0;
    padding: 30px 0;
  }
}

@media (max-width: 991.98px) {
  .app-content {
    text-align: center;
  }
}

.app-header {
  padding-bottom: 40px;
}

.app-header h5 {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .app-header h5 {
    font-size: 22px;
  }
}

@media (max-width: 767.98px) {
  .app-header h5 {
    font-size: 18px;
  }
}

.app-header h2 {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 0;
  max-width: 565px;
}

@media (max-width: 991.98px) {
  .app-header h2 {
    font-size: 34px;
    max-width: 100%;
  }
}

@media (max-width: 767.98px) {
  .app-header h2 {
    font-size: 26px;
  }
}

@media (max-width: 991.98px) {
  .app-header {
    padding-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  .app-header {
    padding-bottom: 20px;
  }
}

.app-scan {
  padding-bottom: 40px;
}

.app-scan p {
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .app-scan {
    padding-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  .app-scan {
    padding-bottom: 20px;
  }
}

.google-imgs a {
  margin-right: 24px;
}

.google-imgs a:last-child {
  margin-right: 0;
}

@media (max-width: 767.98px) {
  .google-imgs a img {
    width: 120px;
  }
}

@media (max-width: 767.98px) {
  .google-imgs a {
    margin-right: 14px;
  }
}

.mobile-img {
  position: relative;
  bottom: 0;
}

@media (max-width: 991.98px) {
  .mobile-img {
    display: none;
  }
}

@media (max-width: 1440.98px) {
  .mobile-img img {
    max-width: 450px;
  }
}

@media (max-width: 1199.98px) {
  .mobile-img img {
    max-width: 370px;
  }
}

.faq-section {
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0 0 140px;
}

@media (max-width: 1199.98px) {
  .faq-section {
    margin: 0;
    padding: 0 0 80px;
  }
}

@media (max-width: 991.98px) {
  .faq-section {
    margin: 0;
    padding: 0 0 40px;
  }
}

.faq-img {
  position: relative;
}

@media (max-width: 991.98px) {
  .faq-img {
    text-align: center;
    margin-bottom: 80px;
  }
}

@media (max-width: 767.98px) {
  .faq-img {
    margin-bottom: 70px;
  }
}

.faq-patients-count {
  background: #ffffff;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03),
    0px 64.81px 46.85px rgba(0, 0, 0, 0.0228),
    0px 38.52px 25.48px rgba(0, 0, 0, 0.0182),
    0px 20px 13px rgba(0, 0, 0, 0.015), 0px 8.15px 6.52px rgba(0, 0, 0, 0.0118),
    0px 1.85px 3.15px rgba(0, 0, 0, 0.0072);
  border-radius: 10px;
  padding: 15px;
  min-width: 222px;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 991.98px) {
  .faq-patients-count {
    justify-content: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
    box-shadow: none;
    border: 1px solid #f3f3f3;
  }
}

.faq-smile-img {
  margin-right: 15px;
}

.faq-patients-content h4 {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 3px;
}

@media (max-width: 767.98px) {
  .faq-patients-content h4 {
    font-size: 20px;
  }
}

.faq-patients-content p {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .faq-patients-content p {
    font-size: 16px;
  }
}

@media (max-width: 991.98px) {
  .faq-patients-content {
    text-align: left;
  }
}

.faq-info .accordion-item {
  background: #f8f8f8;
  border: 0;
  border-radius: 0;
  margin: 0 0 24px;
  padding: 15px;
}

.faq-info .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-info .accordion-item .accordion-body {
  margin-top: 15px;
  padding: 15px 0 0;
  border-top: 1px solid #e2e8f0;
}

@media (max-width: 767.98px) {
  .faq-info .accordion-item {
    margin-bottom: 20px;
  }
}

.faq-info .accordion-header {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
}

.faq-info .accordion-button {
  font-size: 16px;
  font-weight: 500;
  color: #1f2937;
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  position: relative;
  cursor: pointer;
}

.faq-info .accordion-button:hover {
  border: 0;
}

.faq-info .accordion-button:after {
  content: "\e9b1";
  font-family: "feather";
  font-size: 20px;
  background: none;
  color: #0e82fd;
  background: #ffffff;
  width: 32px;
  height: 32px;
  border-radius: 5px;
}

@media (max-width: 767.98px) {
  .faq-info .accordion-button:after {
    position: absolute;
    right: 0;
  }
}

.faq-info .accordion-button:not(.collapsed)::after {
  content: "\e996";
  color: #ffffff;
  background: #0e82fd;
}

@media (max-width: 767.98px) {
  .faq-info .accordion-button {
    font-size: 14px;
    padding-right: 30px;
    line-height: 24px;
  }
}

.faq-info .accordion-content p {
  color: #6b7280;
  margin-bottom: 0;
}

.partners-section {
  background-color: #ffffff;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .partners-section {
    padding: 40px 0;
  }
}

.partners-section .owl-nav {
  display: none;
}

.partners-slider {
  list-style: none;
}

.partners-slider li a {
  background: #ffffff;
  border: 2px solid #f5f5f5;
  padding: 14px 20px;
  text-align: center;
  height: 79px;
  border-radius: 10px;
}

@media (max-width: 767.98px) {
  .partners-slider.owl-carousel .owl-item img {
    width: auto;
    margin: 0 auto;
    height: auto;
  }
}

.footer-1.footer {
  background-color: #f2f6f6;
}

.footer-1.footer .footer-top .footer-title {
  color: #1f2937;
}

.footer-1.footer .footer-top .footer-widget.footer-menu ul li a {
  color: #6b7280;
  position: relative;
  overflow: hidden;
  background: url(../images/arrow1.png) no-repeat -20px 20px;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.footer-1.footer .footer-top .footer-widget .footer-about-content p {
  color: #6b7280;
}

.footer-1.footer .footer-top .footer-widget .footer-contact-info {
  color: #6b7280;
}

.footer-1.footer .subscribe-form {
  position: relative;
}

.footer-1.footer .subscribe-form .form-control {
  font-weight: 500;
  font-size: 14px;
  min-height: 46px;
  color: #6b7280;
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 6px 0px 0px 6px;
  margin: 0;
  padding: 6px 112px 6px 15px;
}

.footer-1.footer .subscribe-form .btn {
  font-weight: normal;
  margin: 0;
  padding: 11px 16px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0 6px 6px 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.blog {
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  position: relative;
  border-radius: 4px;
  margin: 0 0 30px;
  padding: 1.5rem;
}

@media (max-width: 575.98px) {
  .blog {
    padding: 1.25rem;
  }
}

.blog .blog-image {
  margin: 0 0 30px;
  overflow: hidden;
}

.blog .blog-image img {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.blog .blog-image a:hover img {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}

.blog .blog-title {
  font-size: 22px;
  margin: 0 0 10px;
}

@media (max-width: 767.98px) {
  .blog .blog-title {
    font-size: 20px;
  }
}

@media (max-width: 575.98px) {
  .blog .blog-title {
    font-size: 18px;
  }
}

.blog .blog-title a {
  color: #272b41;
}

.blog .blog-title a:hover {
  color: #20c0f3;
}

.blog .blog-content {
  position: relative;
}

.blog .blog-content p {
  color: #666;
  font-size: 14px;
  margin: 0 0 30px;
}

.blog .blog-content .entry-meta {
  color: #757575;
  font-size: 14px;
  list-style: none;
  margin: 0 0 15px;
  padding: 0;
}

.blog .blog-content .entry-meta li {
  display: inline-block;
  margin-right: 15px;
}

.blog .blog-content .entry-meta li:last-child {
  margin-right: 0;
}

.blog.grid-blog {
  padding: 20px;
}

.blog.grid-blog .blog-title {
  font-size: 18px;
  min-height: 43px;
  margin: 0 0 5px;
}

@media (max-width: 767.98px) {
  .blog.grid-blog .blog-title {
    min-height: inherit;
  }
}

.blog.grid-blog .blog-content p {
  margin: 0 0 15px;
}

.blog.grid-blog .blog-info {
  margin: 0 0 15px;
}

.blog.grid-blog .blog-image {
  margin: 0 0 20px;
}

.blog.grid-blog .entry-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0 !important;
}

.blog.grid-blog .entry-meta li {
  margin: 0 0 10px;
}

.blog.grid-blog .entry-meta li:last-child {
  flex: 0 0 100px;
  max-width: 100px;
}

.blog.grid-blog .post-author {
  width: 189px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blog.grid-blog .post-author a:hover {
  color: #20c0f3;
}

.blog-image,
.blog-image > a,
.blog-image img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

.read-more {
  display: inline-block;
  font-size: 16px;
  padding: 0;
  color: #20c0f3;
  text-decoration: underline;
  font-weight: 700;
}

@media (max-width: 575.98px) {
  .read-more {
    font-size: 14px;
  }
}

.blog-info {
  color: #757575;
  font-size: 14px;
  margin: 0 0 15px;
}

.blog-info a {
  color: #757575;
}

.blog-info a:hover {
  color: #757575;
}

.blog-info i {
  color: #20c0f3;
  font-size: 14px;
  margin-right: 5px;
}

@media (max-width: 575.98px) {
  .blog-info i {
    font-size: 14px;
  }
}

.blog-pagination .pagination {
  margin: 0 0 30px;
}

.blog-pagination .pagination li a {
  font-weight: 600;
  color: #2e3842;
  border-radius: inherit !important;
  border-color: #f0f0f0;
  margin: 0 10px 0 0;
  padding: 12px 20px;
}

.blog-pagination .pagination li.disabled a {
  border-color: #f0f0f0;
}

.blog-pagination .pagination li.active a {
  background: #20c0f3;
  border-color: transparent;
  color: #ffffff;
}

.post-left ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.post-left ul li {
  margin-bottom: 5px;
  margin-right: 20px;
}

@media (max-width: 575.98px) {
  .post-left ul li {
    margin-right: 10px;
  }
}

.post-left ul li:last-child {
  margin-right: 0;
}

@media (max-width: 575.98px) {
  .post-left ul li:last-child {
    margin-right: 10px;
  }
}

.post-author a {
  display: flex;
  align-items: center;
}

.video {
  position: relative;
  padding-bottom: 56%;
  height: 0;
  overflow: hidden;
}

.video iframe,
.video object,
.video embed {
  width: 100%;
  height: 100%;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
}

.latest-posts {
  margin: 0;
  padding: 0;
}

.latest-posts li {
  display: table;
  width: 100%;
  margin: 0 0 20px;
}

.latest-posts li:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}

.post-thumb {
  width: 80px;
  float: left;
  overflow: hidden;
  border-radius: 4px;
}

.post-thumb a img {
  border-radius: 4px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.post-thumb a:hover img {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}

.post-info {
  margin-left: 95px;
}

.post-info h4 {
  font-size: 15px;
  margin: 0 0 5px;
}

.post-info h4 a {
  color: #272b41;
}

.post-info h4 a:hover {
  color: #20c0f3;
}

.post-info p {
  color: #757575;
  font-size: 12px;
  margin: 0;
}

.post-info p i {
  color: #20c0f3;
  font-size: 14px;
  margin-right: 4px;
}

.category-widget .categories {
  list-style: none;
  margin: 0;
  padding: 0;
}

.category-widget .categories li {
  margin: 0 0 20px;
}

.category-widget .categories li:last-child {
  border-bottom: none;
  margin: 0;
  padding: 0;
}

.category-widget .categories li a {
  color: #2e3842;
  font-size: 15px;
  font-weight: 500;
}

.category-widget .categories li a span {
  float: right;
  color: #757575;
}

.category-widget .categories li a:hover {
  color: #20c0f3;
}

.category-widget .categories > li > a > i {
  color: #20c0f3;
  font-size: 18px;
  margin-right: 10px;
}

.tags-widget .card-body {
  padding-bottom: 1rem;
}

.tags {
  list-style: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.tags li {
  float: left;
}

.tag {
  background-color: #ffffff;
  color: #272b41;
  position: relative;
  font-size: 14px;
  border: 1px solid #cccccc;
  display: inline-block;
  border-radius: 4px;
  margin: 0 0.5rem 0.5rem 0;
  padding: 5px 10px;
}

.tag:hover {
  background-color: #20c0f3;
  border-color: #20c0f3;
  color: #ffffff;
}

.comment-by {
  display: block;
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 10px;
}

.comments-list .comment-block p {
  font-size: 14px;
  margin: 0 0 5px;
}

/*-----------------
	Blog Details
-----------------------*/
.blog-view .blog-title {
  font-size: 24px;
}

@media (max-width: 767.98px) {
  .blog-view .blog-title {
    font-size: 22px;
  }
}

@media (max-width: 575.98px) {
  .blog-view .blog-title {
    font-size: 20px;
  }
}

.blog-view .blog-info {
  border: 0;
  margin: 0 0 20px;
  padding: 0;
}

.blog-content p:last-child {
  margin: 0;
}

.social-share {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
}

.social-share > li {
  display: inline-block;
  float: none;
  margin-left: 10px;
  text-align: center;
}

.social-share > li:first-child {
  margin-left: 0;
}

.social-share > li a:hover {
  background-color: #20c0f3;
  color: #ffffff;
  border-color: #20c0f3;
}

.social-share > li > a {
  border: 1px solid #dfdfdf;
  color: #666;
  display: inline-block;
  font-size: 22px;
  height: 40px;
  line-height: 40px;
  width: 40px;
}

.blog-reply > a {
  color: #999;
  font-size: 12px;
  font-weight: 500;
}

.blog-date {
  color: #999;
  font-size: 12px;
}

.blog-comments .comments-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
}

.blog-comments .comments-list li {
  clear: both;
  padding-left: 80px;
}

@media (max-width: 575.98px) {
  .blog-comments .comments-list li {
    padding: 10px 0 0 50px;
  }
}

.blog-comments .comments-list li .comment {
  margin: 0 0 20px;
}

.blog-comments .comments-list li .comment-author {
  position: absolute;
  left: 0;
}

.blog-comments .comments-list li img.avatar {
  height: 58px;
  width: 58px;
  border-radius: 58px;
}

@media (max-width: 575.98px) {
  .blog-comments .comments-list li img.avatar {
    height: 40px;
    width: 40px;
    border-radius: 40px;
  }
}

.blog-comments .comment-btn {
  color: #20c0f3;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
}

.blog-author-name {
  color: #272b41;
  font-size: 16px;
  font-weight: 600;
}

.new-comment label {
  font-weight: 500;
}

.comment-submit .btn {
  background-color: #20c0f3;
  border-color: #20c0f3;
  font-size: 18px;
  padding: 8px 26px;
  color: #ffffff;
  border-radius: 0;
}

.about-author-img {
  background-color: #ffffff;
  height: 100px;
  overflow: hidden;
  position: absolute;
  width: 100px;
}

@media (max-width: 575.98px) {
  .about-author-img {
    height: 60px;
    width: 60px;
  }
}

.author-details {
  margin: 0 0 0 120px;
}

@media (max-width: 575.98px) {
  .author-details {
    margin-left: 80px;
  }
}

.author-details .blog-author-name {
  display: inline-block;
  margin: 0 0 10px;
}

.about-author {
  min-height: 100px;
}

.post-author img {
  width: 28px;
  margin-right: 5px;
  border-radius: 100%;
}

.blogs-section {
  padding: 50px 0;
  background-color: #ffffff;
}

@media (max-width: 767.98px) {
  .blogs-section {
    padding: 30px 0;
  }
}

.blogs-section .blog-wrapper {
  background: #ffffff;
  border: 1px solid #e8eeff;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 30px;
}

.blogs-section .blog-wrapper .wrap-image img {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.blogs-section .blog-wrapper .wrap-content {
  padding: 10px;
}

.blogs-section .blog-wrapper .wrap-content .sp-title {
  font-size: 13px;
  color: #2264c5;
  font-weight: 600;
  margin-right: 5px;
}

.blogs-section .blog-wrapper .wrap-content .post-author a {
  display: flex;
  align-items: center;
}

.blogs-section .blog-wrapper .wrap-content .post-author a img {
  width: 20px;
  flex: 0 0 20px;
  margin: 0 5px;
}

.blogs-section .blog-wrapper .wrap-content .post-author a span {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
}

.blogs-section .blog-wrapper .wrap-content .date-cart {
  background-color: #1860ca;
  font-size: 28px;
  color: #ffffff;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  width: 65px;
  height: 60px;
  flex-direction: column;
  position: absolute;
  right: 25px;
  margin: -40px 0 0;
  padding: 10px 8px;
}

@media (max-width: 991.98px) {
  .blogs-section .blog-wrapper .wrap-content .date-cart {
    font-size: 26px;
  }
}

@media (max-width: 767.98px) {
  .blogs-section .blog-wrapper .wrap-content .date-cart {
    font-size: 22px;
    width: 60px;
    height: 55px;
    padding: 5px;
  }
}

.blogs-section .blog-wrapper .wrap-content .date-cart span {
  font-size: 11px;
  font-weight: 500;
}

@media (max-width: 767.98px) {
  .blogs-section .blog-wrapper .wrap-content .date-cart span {
    font-size: 10px;
  }
}

.blogs-section .blog-wrapper .wrap-content-body {
  margin-top: 10px;
}

@media (max-width: 767.98px) {
  .blogs-section .blog-wrapper .wrap-content-body h3 {
    font-size: 17px;
  }
}

.blogs-section .blog-wrapper .wrap-content-body h3 a {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  line-height: 1.5;
}

@media (max-width: 767.98px) {
  .blogs-section .blog-wrapper .wrap-content-body h3 a {
    font-size: 17px;
  }
}

.blogs-section .blog-wrapper .wrap-content-body p {
  font-size: 12px;
  color: #000000;
}

.blogs-section .blog-wrapper .wrap-content-body a.read-txt {
  width: 110px;
  padding: 10px;
  background: #0ce0ff;
  border-radius: 30px;
  display: block;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.blogs-section .blog-wrapper .wrap-content-body a.read-txt:hover {
  background-color: #0e82fd;
}

.blogs-section .view-btn {
  background-color: #20c0f3;
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  border-radius: 30px;
  border: 1px solid #20c0f3;
  padding: 6px 20px;
}

@media (max-width: 767.98px) {
  .blogs-section .view-btn {
    font-size: 14px;
  }
}

.blogs-section .view-btn:hover {
  background-color: #0e82fd;
  border: 1px solid #0e82fd;
}

.our-blog-section {
  padding: 60px 0;
  background: #ffffff;
}

@media (max-width: 991.98px) {
  .our-blog-section {
    padding: 40px 0;
  }
}

@media (max-width: 767.98px) {
  .our-blog-section {
    padding: 30px 0;
  }
}

.our-blog-section .our-blogs {
  display: block;
  transition: 0.6s;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}

.our-blog-section .our-blogs .blogs-img {
  position: relative;
  transition: 0.6s;
}

.our-blog-section .our-blogs .blogs-img .blogs-overlay {
  align-items: center;
  background: #ffffff;
  border-radius: 30px 0px 0px 30px;
  padding: 5px;
  position: absolute;
  right: 0;
  bottom: 10px;
  transition: 0.6s;
}

.our-blog-section .our-blogs .blogs-img .blogs-overlay img {
  height: 35px;
  width: 35px !important;
  border-radius: 50%;
  margin-right: 10px;
}

.our-blog-section .our-blogs .blogs-img .blogs-overlay .blogs-writter {
  display: none;
}

.our-blog-section .our-blogs .blogs-info {
  background: #ffffff;
  border: 1px solid #e8eeff;
  padding: 15px;
}

.our-blog-section .our-blogs .blogs-info span {
  color: #20c0f3;
}

.our-blog-section .our-blogs .blogs-info h4 {
  color: #000000;
  line-height: 28px;
}

.our-blog-section .our-blogs .blogs-info h4:hover {
  color: #1c5b91;
}

.our-blog-section .our-blogs .blogs-info p {
  font-size: 13px;
}

.our-blog-section .our-blogs .blogs-nav {
  border: 1px solid #e8eeff;
  padding: 10px;
  border-top: 0;
}

.our-blog-section .our-blogs .blogs-nav .blogs-btn {
  background: #1c5b90;
  border-radius: 30px;
  color: #ffffff;
  padding: 8px 10px;
  display: block;
  width: 110px;
  text-align: center;
  font-size: 12px;
}

.our-blog-section .our-blogs .blogs-nav .blogs-btn:hover {
  background-color: #0e82fd;
}

.our-blog-section .our-blogs:hover .blogs-img .blogs-overlay .blogs-writter {
  display: block;
}

.blogs-time {
  color: #17418e !important;
}

.booking-doc-info .booking-doc-img {
  width: 80px;
  margin-right: 15px;
}

@media (max-width: 767.98px) {
  .booking-doc-info .booking-doc-img {
    width: 75px;
  }
}

@media (max-width: 575.98px) {
  .booking-doc-info .booking-doc-img {
    width: 70px;
  }
}

.booking-doc-info .booking-doc-img img {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 4px;
}

@media (max-width: 767.98px) {
  .booking-doc-info .booking-doc-img img {
    height: 75px;
    width: 75px;
  }
}

@media (max-width: 575.98px) {
  .booking-doc-info .booking-doc-img img {
    height: 70px;
    width: 70px;
  }
}

.schedule-widget {
  min-height: 100px;
  border-radius: 4px;
}

.schedule-widget .schedule-header {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
}

.schedule-widget .schedule-header h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 3px 0 0;
}

.day-slot ul {
  float: left;
  list-style: none;
  position: relative;
  width: 100%;
  margin: auto -5px 0 -5px;
  padding: 0;
}

.day-slot ul li {
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  width: 14.28%;
}

.day-slot ul li span {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
}

@media (max-width: 767.98px) {
  .day-slot ul li span {
    font-size: 16px;
    text-transform: unset;
  }
}

.day-slot ul li span.slot-date {
  display: block;
  color: #757575;
  font-size: 14px;
}

.day-slot ul li small.slot-year {
  color: #757575;
  font-size: 14px;
}

@media (max-width: 767.98px) {
  .day-slot ul li small.slot-year {
    display: none;
  }
}

.day-slot ul li.left-arrow {
  text-align: center;
  width: 20px !important;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 0;
  padding: 0;
}

@media (max-width: 991.98px) {
  .day-slot ul li.left-arrow {
    left: -10px;
  }
}

.day-slot ul li.right-arrow {
  text-align: center;
  width: 20px !important;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: -11px;
  padding: 0;
}

.schedule-cont {
  padding: 20px;
}

.time-slot ul {
  list-style: none;
  margin: auto -5px 0 -5px;
  padding: 0;
}

.time-slot ul li {
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  width: 14.28%;
}

.time-slot ul li .timing {
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  color: #757575;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 8px 8px;
  text-align: center;
  position: relative;
}
.menu-available {
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  color: #757575;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 8px 8px;
  text-align: center;
  position: relative;
}

.menu-booked {
  background-color: #2a9d36;
  border: 1px solid #2a9d36;
  color: #ffffff;
  border-radius: 3px;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 8px 8px;
  text-align: center;
  position: relative;
}

.menu-past {
  background-color: transparent;
  border: 1px solid #42c0fb;
  color: #757575;
  border-radius: 3px;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 8px 8px;
  text-align: center;
  position: relative;
}

.menu-late {
  color: #ffffff;
  background-color: #c70e2a;
  border-color: #ff0100;
  border-radius: 3px;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 8px 8px;
  text-align: center;
  position: relative;
}

.time-slot ul li .timing:hover {
  color: #000000;
  background-color: #ffffff;
}

.time-slot ul li .timing:last-child {
  margin-bottom: 0;
}

.time-slot ul li .timing.selected {
  background-color: #2a9d36;
  border: 1px solid #2a9d36;
  color: #ffffff;
}

.time-slot ul li .timing.selected:hover {
  background-color: #2a9d36;
  border: 1px solid #2a9d36;
  color: #000000;
}

.time-slot ul li .timing.booked {
  background-color: #2a9d36;
  border: 1px solid #2a9d36;
  color: #ffffff;
}
.time-slot ul li .timing.passed {
  background-color: transparent;
  border-color: #42c0fb;
  color: #757575;
}
.time-slot ul li .timing.night {
  color: #ffffff;
  background-color: #c70e2a;
  border-color: #ff0100;
}

.time-slot ul li .timing.night:hover {
  background-color: #2a9d36;
  border: 1px solid #2a9d36;
  color: #000000;
}

.time-slot ul li .timing.selected::before {
  color: #ffffff;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  font-weight: 900;
  position: absolute;
  top: 6px;
  right: 6px;
}

@media (max-width: 991.98px) {
  .time-slot ul li .timing.selected::before {
    display: none;
  }
}

.schedule-list {
  border-bottom: 1px solid #cfcfcf;
  margin-bottom: 50px;
  padding-bottom: 50px;
}

.schedule-list:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.submit-section.proceed-btn {
  margin: 0 0 30px;
}

@media (max-width: 767.98px) {
  .submit-section.proceed-btn {
    margin-bottom: 20px;
  }
}

.tab-content.schedule-cont .card-title {
  margin-bottom: 10px;
}

.doc-slot-list {
  background-color: #d9534f;
  border: 1px solid #d43f3a;
  color: #ffffff;
  font-size: 14px;
  margin: 10px 10px 0 0;
  padding: 6px 15px;
  border-radius: 4px;
}

.doc-slot-list a {
  color: #e48784;
  display: inline-block;
  margin-left: 5px;
}

.doc-slot-list a:hover {
  color: #ffffff;
}

.schedule-nav .nav-tabs {
  border: 0 !important;
}

.schedule-nav .nav-tabs li {
  display: inline-block;
  margin: 5px 15px 5px 0;
}

@media (max-width: 479.98px) {
  .schedule-nav .nav-tabs li {
    display: block;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-right: 0;
  }
}

.schedule-nav .nav-tabs li:last-child {
  margin-right: 0;
}

.schedule-nav .nav-tabs li a.active {
  background: #ff4877;
  border: 1px solid #ff4877 !important;
  color: #ffffff;
}

.schedule-nav .nav-tabs > li > a {
  border: 1px solid #dcddea;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 6px 15px;
}

.hours-info .form-control {
  min-height: auto;
}

.hours-info .btn.btn-danger.trash {
  height: 38px;
  width: 100%;
}

.add-more a {
  color: #20c0f3;
}

.edit-link {
  color: #20c0f3;
  font-size: 16px;
  margin-top: 4px;
}

@media (max-width: 575.98px) {
  .edit-link {
    font-size: 14px;
    margin-top: 2px;
  }
}

.submit-section .submit-btn {
  padding: 12px 30px;
  font-weight: 600;
  font-size: 16px;
  min-width: 120px;
}

@media (max-width: 767.98px) {
  .submit-section .submit-btn {
    padding: 10px 20px;
    font-size: 15px;
    min-width: 105px;
  }
}

.success-card .card-body {
  padding: 50px 20px;
}

.success-cont {
  text-align: center;
}

.success-cont i {
  color: #ffffff;
  width: 60px;
  height: 60px;
  border: 2px solid #09e5ab;
  font-size: 30px;
  margin-bottom: 30px;
  background-color: #09e5ab;
  border-radius: 50%;
}

.success-cont h3 {
  font-size: 24px;
}

@media (max-width: 767.98px) {
  .success-cont h3 {
    font-size: 22px;
  }
}

.success-cont p {
  margin-bottom: 30px;
}

.success-cont strong {
  font-weight: 700;
}

@media (max-width: 575.98px) {
  .booking-schedule.schedule-widget {
    overflow-x: auto;
  }
}

@media (max-width: 575.98px) {
  .booking-schedule.schedule-widget > div {
    width: 730px;
  }
}

@media (max-width: 575.98px) {
  .booking-schedule .day-slot ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (max-width: 575.98px) {
  .booking-schedule .day-slot ul li {
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    width: 100px;
  }
}

@media (max-width: 575.98px) {
  .booking-schedule .day-slot ul li.right-arrow {
    right: -20px;
  }
}

@media (max-width: 575.98px) {
  .booking-schedule .time-slot ul {
    display: flex;
  }
}

@media (max-width: 575.98px) {
  .booking-schedule .time-slot ul li {
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    width: 100px;
  }
}

@media (max-width: 767.98px) {
  .booking-schedule .time-slot ul li .timing span {
    display: block;
  }
}

@media (max-width: 575.98px) {
  .booking-schedule .time-slot ul li .timing span {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  .time-slot ul {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .time-slot ul li {
    flex: 0 0 100px;
    width: 100px;
    white-space: nowrap;
  }
}

@media (max-width: 767.98px) {
  .time-slot ul li .menu-available span,
  .time-slot ul li .menu-booked span,
  .time-slot ul li .menu-past span,
  .time-slot ul li .menu-late span {
    display: block;
  }
}

@media (max-width: 575.98px) {
  .time-slot ul li .menu-available span,
  .time-slot ul li .menu-booked span,
  .time-slot ul li .menu-past span,
  .time-slot ul li .menu-late span {
    display: inline-block;
  }
}

.visits .visit-btns {
  color: #272b41;
  background-color: #ffffff;
  width: 80%;
  margin-bottom: 10px;
  display: block;
  outline: unset;
  cursor: pointer;
}

.visits input:checked ~ .visit-rsn {
  background-color: #09e5ab;
  color: #ffffff;
  border-radius: 4px;
}

.visits input:checked ~ .visit-rsn:before {
  opacity: 1;
}

.visits input:disabled ~ .visit-rsn {
  background-color: #e30303;
  color: #ffffff;
  border-radius: 5px;
}

.visits input.form-check-input {
  opacity: 0;
  visibility: hidden;
  margin-left: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.visits .form-check-input {
  opacity: 0;
}

.visits span.visit-rsn {
  background: #f3f9ff;
  border: 0;
  border-radius: 5px;
  color: #64627c;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0px;
  text-align: center;
  position: relative;
  font-weight: 600;
  min-width: 133px;
  width: 100%;
  padding: 13px 5px;
}

.visits span.visit-rsn:before {
  color: #09e5ab;
  background-color: #ffffff;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-size: 10px;
  font-weight: 900;
  border-radius: 50px;
  width: 18px;
  height: 18px;
  opacity: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.back-link {
  padding-bottom: 30px;
}

.back-link a {
  font-size: 15px;
  color: #959595;
}

.back-link a:hover {
  color: #09e5ab;
}

.back-link a i {
  margin-right: 10px;
}

@media (max-width: 767.98px) {
  .back-link {
    padding-bottom: 25px;
  }
}

.booking-header {
  padding-bottom: 25px;
}

.booking-header .booking-title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .booking-header {
    padding-bottom: 20px;
  }
}

.booking-date.choose-date-book {
  padding-bottom: 30px;
}

.booking-date.choose-date-book p {
  color: #8c8c8c;
  margin: 0 10px 0 0;
  padding: 0;
  margin-right: 10px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .booking-date.choose-date-book p {
    margin: 0 0 10px 0;
    padding: 0;
  }
}

@media (max-width: 767.98px) {
  .booking-date.choose-date-book {
    display: block;
    padding-bottom: 20px;
  }
}

.booking-date-slider {
  padding-bottom: 30px;
}

.booking-range .btn {
  background: #ffffff;
  border: 1px solid #e4e4e8;
  border-radius: 6px;
  font-size: 14px;
  font-weight: normal;
  color: #4a4a4a;
  margin: 0;
  padding: 10px 15px;
}

@media (max-width: 767.98px) {
  .booking-range .btn {
    font-size: 13px;
    margin: 0;
    padding: 10px;
    position: relative;
  }
}

.booking-range img {
  margin-right: 10px;
}

.booking-range i {
  font-size: 16px;
  margin-left: 10px;
  color: #5b5b5b;
}

.booking-device-img {
  margin-right: 15px;
}

.booking-device-img img {
  max-width: inherit;
}

.booking-doctor-info h3 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.booking-doctor-info .device-text {
  margin-bottom: 12px;
}

.booking-doctor-info .btn {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  padding: 4px 10px;
  min-width: 109px;
  color: #0e82fd;
  background: #ffffff;
  border: 2px solid #0e82fd;
  box-shadow: inset 0 0 0 0 #0e82fd;
  border-radius: 6px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.booking-doctor-info .btn:hover {
  color: #ffffff;
  background: #0e82fd;
  box-shadow: inset 0 0 0 50px #0e82fd;
  border: 2px solid #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.time-slot.time-slot-blk {
  text-align: center;
}

.time-slot.time-slot-blk h4 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}

.time-slot.time-slot-blk ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.time-slot.time-slot-blk ul li {
  display: block;
  margin-bottom: 15px;
  float: inherit;
  width: 100%;
}

.time-slot.time-slot-blk li:last-child {
  margin-bottom: 0;
}

.time-slot.time-slot-blk li .timing {
  background: #f5f5f5;
  border: 2px solid #f3f3f3;
  border-radius: 8px;
  color: #595959;
  margin: 0;
  padding: 10px 15px;
  text-align: center;
  position: relative;
  box-shadow: inset 0 0 0 0 #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.time-slot.time-slot-blk li .timing:hover {
  background: #ffffff;
  box-shadow: inset 0 0 0 50px #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.time-slot.time-slot-blk li .timing.active {
  background: #0e82fd;
  border: 2px solid #0e82fd;
  color: #ffffff;
  box-shadow: inset 0 0 0 0 #0e82fd;
}

.time-slot.time-slot-blk li .timing.active:hover {
  background: #0e82fd;
  border: 2px solid #0e82fd;
  color: #ffffff;
  box-shadow: inset 0 0 0 50px #0e82fd;
}

.time-slot.time-slot-blk li .timing i {
  margin-right: 4px;
}

.time-slot.time-slot-blk li .load-more-timings a {
  font-weight: 500;
  text-decoration: underline;
  color: #0e82fd;
}

.time-slot.time-slot-blk li .load-more-timings a:hover {
  color: #000000;
}

.time-slot.time-slot-blk .time-slot-open {
  display: none;
}

@media (max-width: 991.98px) {
  .time-slot.time-slot-blk {
    padding-bottom: 15px;
  }
}

.date-slider {
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.date-slider .slick-slide {
  display: block;
  margin: 0;
  padding: 0 5px;
}

.date-slider li h4 {
  font-weight: 500;
  font-size: 16px;
  color: #b9b9b9;
  margin-bottom: 5px;
}

@media (max-width: 1199.98px) {
  .date-slider li h4 {
    font-size: 13px;
  }
}

@media (max-width: 991.98px) {
  .date-slider li h4 {
    font-size: 16px;
  }
}

.date-slider li p {
  color: #d4d4d4;
  margin-bottom: 0;
}

.date-slider li.active h4 {
  color: #000000;
}

.date-slider li.active p {
  color: #aaaaaa;
}

.date-slider .slick-prev:before {
  content: " \e92f";
}

.date-slider .slick-next:before {
  content: " \e930";
}

.date-slider .slick-prev,
.date-slider .slick-next {
  background: transparent;
  color: #000000;
  line-height: normal;
  box-shadow: none;
  width: 30px;
  height: 30px;
}

.date-slider .slick-prev:hover,
.date-slider .slick-prev:focus,
.date-slider .slick-next:hover,
.date-slider .slick-next:focus {
  background-color: transparent;
  color: #09e5ab;
  opacity: 1;
}

.date-slider .slick-prev:hover:before,
.date-slider .slick-prev:focus:before,
.date-slider .slick-next:hover:before,
.date-slider .slick-next:focus:before {
  color: #09e5ab;
}

.date-slider .slick-prev:before,
.date-slider .slick-next:before {
  font-family: "feather";
  font-size: 26px;
}

.date-slider .slick-prev {
  left: -12px;
}

.date-slider .slick-next {
  right: -12px;
}

.content.content-space {
  padding: 80px 0px;
}

@media (max-width: 991.98px) {
  .content.content-space {
    padding: 50px 0px;
  }
}

.prime-btn {
  background-color: #c70e2a;
  border: 1px solid #c70e2a;
  box-shadow: inset 0 0 0 0 #ffffff;
}

.prime-btn:hover {
  color: #2a9d36;
  background-color: #ffffff;
  border-color: #2a9d36;
  box-shadow: inset 0 0 0 50px #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.prime-btn:hover i {
  color: #2a9d36;
}

.attachment-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  border-radius: 8px;
  padding: 6px 15px;
}

.attachment-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.attachment-icon {
  font-size: 24px;
  color: #9d9d9d;
  margin-right: 12px;
}

.attachment-content p {
  color: #54595e;
  margin-bottom: 0;
}

.attachment-content span {
  font-size: 12px;
  color: #54595e;
}

.attachment-close a {
  font-size: 22px;
  color: #abb5be;
}

.attachment-close a:hover {
  color: #0e82fd;
}

.paitent-appointment .form-group {
  margin-bottom: 25px;
}

.paitent-appointment .form-group .form-group-title {
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}

.paitent-appointment .form-group .form-group-title i {
  font-size: 16px;
}

.paitent-appointment .form-group .form-group-title span {
  font-weight: 400;
  font-size: 12px;
  color: #a3a3a3;
}

.paitent-appointment .form-group .custom_radio {
  margin-bottom: 0;
  padding-left: 25px;
}

.paitent-appointment .form-control::placeholder {
  font-weight: 500;
  color: #9e9e9e;
}

.paitent-appointment textarea.form-control {
  resize: none;
  min-height: 100px;
}

.paitent-appointment .characters-text {
  font-size: 12px;
  color: #9e9e9e;
  text-align: right;
  margin-top: 8px;
  margin-bottom: 0;
}

.form-group-flex .btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #292929;
  padding: 3px 5px;
  margin-bottom: 10px;
  min-width: 60px;
  background: #e0efff;
  border: 1px solid #e0efff;
  border-radius: 4px;
  box-shadow: inset 0 0 0 0 #0e82fd;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

.form-group-flex .btn:hover {
  color: #ffffff;
  border: 1px solid #0e82fd;
  box-shadow: inset 0 0 0 50px #0e82fd;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

.form-group-flex .btn i {
  font-size: 16px;
  margin-right: 2px;
}

.widget-profile {
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
}

.widget-profile .profile-info-widget {
  display: block !important;
  text-align: center !important;
}

.widget-profile .profile-info-widget .booking-doc-img {
  display: inline-block;
  width: auto;
  background-color: #f7f7f7;
  margin: 0 0 15px !important;
  padding: 8px;
  border-radius: 50%;
}

.widget-profile .profile-info-widget .booking-doc-img img {
  height: 120px !important;
  width: 120px !important;
  border-radius: 50% !important;
}

.widget-profile.pat-widget-profile .profile-info-widget .booking-doc-img {
  padding: 0;
}

.widget-profile.pat-widget-profile .profile-info-widget .booking-doc-img img {
  height: 100px !important;
  width: 100px !important;
  border-radius: 50%;
}

.appointment-list .profile-info-widget {
  margin-right: auto;
  text-align: left;
}

@media (max-width: 479.98px) {
  .appointment-list .profile-info-widget {
    text-align: center;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 479.98px) {
  .appointment-list .profile-info-widget .booking-doc-img {
    margin: 0 0 15px;
  }
}

.appointment-list .profile-info-widget .booking-doc-img img {
  height: 120px;
  object-fit: cover;
  width: 120px;
  border-radius: 4px;
}

@media (max-width: 479.98px) {
  .appointment-list .profile-info-widget .booking-doc-img img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
}

@media (max-width: 479.98px) {
  .appointment-list .profile-info-widget .custom-avatar {
    margin: 0 0 15px;
  }
}

.appointment-list .profile-info-widget .custom-avatar {
  height: 120px;
  object-fit: cover;
  width: 120px;
  border-radius: 4px;
}

@media (max-width: 479.98px) {
  .appointment-list .profile-info-widget .custom-avatar {
    height: 120px;
    width: 120px;
    border-radius: 50%;
  }
}

@media (max-width: 479.98px) {
  .appointment-list .profile-det-info {
    margin-bottom: 15px;
  }
}

.appointments .appointment-list {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  margin: 0 0 20px;
  padding: 20px;
  border-radius: 4px;
}

.appointments .appointment-list:last-child {
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {
  .appointments .appointment-list {
    display: block;
  }
}

@media (max-width: 479.98px) {
  .appointments .appointment-list {
    text-align: center;
  }
}

@media (max-width: 479.98px) {
  .appointments .appointment-list .profile-det-info {
    margin-bottom: 15px;
  }
}

@media (max-width: 991.98px) {
  .appointments .appointment-action {
    margin-top: 10px;
  }
}

@media (max-width: 479.98px) {
  .appointments .appointment-action {
    margin-top: 0;
  }
}

.appointments .appointment-action a {
  font-size: 13px;
}

.appointments .appointment-action a + a {
  margin-left: 5px;
}

.info-details {
  list-style: none;
  margin: 0;
  padding: 0;
}

.info-details li {
  margin-bottom: 10px;
}

.info-details li:last-child {
  margin-bottom: 0;
}

.info-details li .title {
  color: #272b41;
  font-weight: 500;
}

.info-details li .text {
  color: #757575;
  display: block;
  font-size: 16px;
  overflow: hidden;
}

.clinic-direction {
  color: #757575;
  font-size: 14px;
  margin-bottom: 25px;
}

.clinic-direction a {
  color: #2a9d36;
  font-weight: 500;
}

.doctor-action {
  margin-bottom: 15px;
}

@media (max-width: 767.98px) {
  .doctor-action {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
}

.doctor-action a + a {
  margin-left: 8px;
}

.doctor-action .fav-btn:hover {
  background-color: #fb1612;
  border-color: #fb1612;
  color: #ffffff;
}

.msg-btn:hover,
.msg-btn:focus {
  background-color: #09e5ab;
  border-color: #09e5ab;
  color: #ffffff;
}

call-btn:hover,
call-btn:focus {
  background-color: #09e5ab;
  border-color: #09e5ab;
  color: #ffffff;
}

@media (max-width: 767.98px) {
  .user-tabs {
    margin-top: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .user-tabs .nav-tabs {
    border-bottom: 0;
    position: relative;
    background-color: #ffffff;
    padding: 5px 0;
    border: 1px solid #dddddd;
    border-radius: 3px;
  }
}

.user-tabs .nav-tabs > li > a {
  border: 0;
  border-bottom: 3px solid transparent;
  color: #3e3e3e;
  font-weight: 700;
  padding: 20px;
}

.user-tabs .nav-tabs > li > a:hover {
  background-color: unset;
  color: #2a9d36;
}

@media (max-width: 767.98px) {
  .user-tabs .nav-tabs > li > a {
    border-left: 2px solid transparent;
    border-bottom: 0;
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 767.98px) {
  .user-tabs .nav-tabs.nav-justified {
    border-bottom: 1px solid #2a9d36;
  }
}

@media (max-width: 767.98px) {
  .user-tabs .nav-tabs .nav-item {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

@media (max-width: 479.98px) {
  .user-tabs .nav-tabs .nav-item {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active,
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover,
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 3px;
  color: #2a9d36;
}

@media (max-width: 767.98px) {
  .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active,
  .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover,
  .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
    border-left-width: 2px;
    color: #2a9d36;
  }
}

.user-tabs .med-records {
  display: inline-block;
  min-width: 130px;
}

@media (max-width: 575.98px) {
  .user-tabs .med-records {
    min-width: 110px;
  }
}

.widget {
  margin-bottom: 30px;
}

.widget-title {
  margin-bottom: 15px;
}

.experience-box {
  position: relative;
}

.experience-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
}

.experience-list::before {
  background: #dddddd;
  bottom: 0;
  content: "";
  width: 2px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.experience-list li {
  position: relative;
}

.experience-list li:last-child .experience-content {
  margin-bottom: 0;
}

.experience-list > li .experience-user {
  background: #ffffff;
  height: 10px;
  width: 10px;
  position: absolute;
  top: 4px;
  left: 4px;
  margin: 0;
  padding: 0;
}

.experience-list > li .experience-content {
  background-color: #ffffff;
  position: relative;
  margin: 0 0 20px 40px;
  padding: 0;
}

.experience-list > li .experience-content .timeline-content {
  color: #757575;
}

.experience-list > li .experience-content .timeline-content a.name {
  font-weight: 500;
}

.experience-list > li .time {
  color: #757575;
  display: block;
  font-size: 13px;
}

.experience-user .avatar {
  height: 32px;
  line-height: 32px;
  width: 32px;
  margin: 0;
}

.before-circle {
  background-color: #ebfaec;
  height: 12px;
  width: 12px;
  border: 2px solid #2a9d36;
  border-radius: 50%;
}

.exp-year {
  color: #2a9d36;
  margin-bottom: 2px;
}

.exp-title {
  font-size: 16px;
}

@media (max-width: 767.98px) {
  .exp-title {
    font-size: 14px;
  }
}

.awards-widget .experience-list > li:last-child p {
  margin-bottom: 0;
}

.service-list {
  margin-bottom: 30px;
}

.service-list:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.service-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.service-list ul li {
  float: left;
  padding-left: 25px;
  position: relative;
  width: 33%;
  margin: 6px 0;
}

@media (max-width: 767.98px) {
  .service-list ul li {
    width: 50%;
  }
}

@media (max-width: 575.98px) {
  .service-list ul li {
    width: 100%;
  }
}

.service-list ul li::before {
  color: #cccccc;
  content: "\f30b";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 0;
}

.location-list {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 20px;
}

.location-list + .location-list {
  margin-top: 20px;
}

.location-list .consult-price {
  font-size: 20px;
  font-weight: 500;
  color: #272b41;
}

.clinic-content .clinic-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 3px;
}

.clinic-content .clinic-direction a {
  display: inline-block;
  margin-top: 8px;
}

.timings-days {
  font-weight: 700;
  color: #272b41;
  margin-bottom: 5px;
}

.timings-times span {
  display: block;
}

.timings-times span:last-child {
  margin-bottom: 1rem;
}

.review-listing {
  border-bottom: 1px solid #f5f7fc;
  margin-top: 20px;
  padding-bottom: 30px;
}

.review-listing > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.review-listing > ul li + li {
  margin-top: 20px;
  border-top: 1px dashed #f0f0f0;
  padding-top: 20px;
}

.review-listing > ul li .comment {
  margin-bottom: 30px;
}

.review-listing > ul li .comment:last-child {
  margin-bottom: 0;
}

.review-listing > ul li .comment .comment-body {
  margin-left: 16px;
}

.review-listing > ul li .comment .comment-body .meta-data {
  position: relative;
  margin-bottom: 10px;
}

.review-listing > ul li .comment .comment-body .meta-data span {
  display: block;
  font-size: 16px;
  color: #757575;
}

.review-listing > ul li .comment .comment-body .meta-data span.comment-author {
  font-weight: 600;
  color: #272b41;
  text-transform: capitalize;
}

.review-listing > ul li .comment .comment-body .meta-data span.comment-date {
  font-size: 14px;
}

@media (max-width: 767.98px) {
  .review-listing > ul li .comment .comment-body .meta-data span.comment-date {
    margin-bottom: 5px;
  }
}

.review-listing > ul li .comment .comment-body .meta-data .review-count {
  width: auto;
  position: absolute;
  top: 3px;
  right: 0;
}

@media (max-width: 767.98px) {
  .review-listing > ul li .comment .comment-body .meta-data .review-count {
    position: unset;
  }
}

.review-listing > ul li .comment .comment-body .comment-content {
  color: #757575;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.review-listing > ul li .comment .comment-body .comment-reply .comment-btn {
  color: #2a9d36;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
}

.review-listing > ul li .comments-reply {
  list-style: none;
  margin-left: 65px;
  padding: 0;
}

@media (max-width: 479.98px) {
  .review-listing > ul li .comments-reply {
    margin-left: 0;
  }
}

.review-listing .recommend-btn {
  float: right;
  color: #757575;
  font-size: 14px;
  padding: 5px 0;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .review-listing .recommend-btn {
    float: none;
  }
}

.review-listing .recommend-btn a {
  border: 1px solid rgba(128, 137, 150, 0.4);
  display: inline-block;
  color: #757575;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 4px;
  margin: 0 3px;
  padding: 4px 12px;
}

.review-listing .recommend-btn a.like-btn:hover {
  background-color: #2a9d36;
  border: 1px solid #2a9d36;
  color: #ffffff;
}

.review-listing .recommend-btn a.dislike-btn:hover {
  background-color: #c70e2a;
  border: 1px solid #c70e2a;
  color: #ffffff;
}

.review-listing .recommend-btn a i {
  font-size: 16px;
}

@media (max-width: 479.98px) {
  .review-listing .recommend-btn span {
    display: block;
    margin-bottom: 10px;
  }
}

.recommended {
  color: #2a9d36;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.all-feedback {
  margin-top: 20px;
}

.star-rating {
  direction: rtl;
}

.star-rating input[type="radio"] {
  display: none;
}

.star-rating input[type="radio"]:checked ~ label {
  color: #f2b600;
}

.star-rating label {
  color: #bbb;
  cursor: pointer;
  font-size: 18px;
  padding: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.star-rating label:hover,
.star-rating label:hover ~ label {
  color: #f2b600;
}

.terms-accept a {
  color: #2a9d36;
  font-weight: 500;
}
.terms-accept a:hover {
  color: #c70e2a;
  font-weight: 500;
}

.business-widget {
  background-color: #fcfcfc;
  border: 1px solid #f0f0f0;
  margin-bottom: 0;
  padding: 20px;
}

.listing-day {
  color: #000000;
  margin-bottom: 10px;
}

.listing-day:last-child {
  margin-bottom: 0;
}

.listing-day.current {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 13px;
  margin-bottom: 13px;
}

.listing-day.current .day {
  font-weight: 700;
}

.listing-day .day {
  font-weight: 500;
}

.listing-day .day span {
  display: block;
  font-weight: normal;
}

.time-items {
  color: #757575;
}

.time-items > span {
  display: block;
  text-align: right;
}

.time-items > span.open-status {
  margin-bottom: 3px;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  display: none;
}

.upload-wrap .upload-images + .upload-images {
  margin-left: 20px;
}

.upload-images {
  position: relative;
  width: 80px;
}

.upload-images img {
  height: 80px;
  width: auto;
  border-radius: 4px;
}

.contact-card .card-body {
  padding-bottom: 0.625rem;
}

.custom_price_cont {
  margin-top: 20px;
}

.btn.btn-danger.trash {
  height: 46px;
  width: 46px;
}

@media (max-width: 767.98px) {
  .btn.btn-danger.trash {
    margin-bottom: 20px;
  }
}

.add-more a {
  color: #20c0f3;
}

.bootstrap-tagsinput {
  border-color: #dcdcdc;
  box-shadow: inherit;
  min-height: 46px;
  width: 100%;
  border-radius: 0;
}

.bootstrap-tagsinput.focus {
  border-color: #bbb;
}

.bootstrap-tagsinput .tag {
  background-color: #20c0f3;
  color: #6b7280;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  margin-right: 2px;
  border-radius: 0;
  padding: 8px 15px;
}

.services-card .bootstrap-tagsinput input {
  width: 160px;
}

.submit-section .submit-btn {
  font-weight: 700;
  font-size: 16px;
  min-width: 120px;
  padding: 12px 30px;
}

.submit-section .submit-btn + .submit-btn {
  margin-left: 15px;
}

@media (max-width: 767.98px) {
  .submit-section .submit-btn {
    font-size: 15px;
    min-width: 105px;
    padding: 10px 20px;
  }
}

.profile-sidebar {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 4px;
}

@media (max-width: 767.98px) {
  .profile-sidebar {
    margin-bottom: 20px !important;
  }
}

.profile-sidebar .pro-widget-content {
  border-bottom: 1px solid #f0f0f0;
  margin: 0;
  padding: 20px;
  text-align: center;
}

.profile-sidebar .dashboard-menu ul {
  color: #757575;
  font-size: 14px;
  line-height: 17px;
  list-style: none;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
}

.profile-sidebar .dashboard-menu ul li {
  line-height: inherit;
}

.profile-sidebar .dashboard-menu ul li a i {
  font-size: 16px;
  margin-right: 10px;
  width: 16px;
}

.profile-sidebar .dashboard-menu ul li a span,
.profile-sidebar .dashboard-menu ul li a i {
  display: inline-block;
  vertical-align: middle;
}

.profile-sidebar .dashboard-menu ul li .unread-msg {
  background-color: #09e5ab;
  color: #272b41;
  font-size: 10px;
  font-style: normal;
  text-align: center;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  border-radius: 2px;
  position: absolute;
  top: 50%;
  right: 20px;
  padding: 0 5px;
}

.profile-sidebar .dashboard-menu > ul > li {
  border-bottom: 1px solid #f0f0f0;
  position: relative;
}

.profile-sidebar .dashboard-menu > ul > li:last-child {
  border-bottom: 0;
}

.profile-sidebar .dashboard-menu > ul > li a {
  color: #757575;
  display: block;
  padding: 16px 20px;
}

.profile-sidebar .dashboard-menu > ul > li:hover > a {
  color: #2a9d36;
}

.profile-sidebar .dashboard-menu > ul > li.active > a {
  color: #2a9d36;
}

@media (max-width: 1199.98px) {
  .dash-card .dash-widget {
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 991.98px) {
  .dash-card .dash-widget {
    flex-direction: unset;
    text-align: left;
  }
}

.dash-card .dash-widget .circle-bar {
  margin-right: 15px;
}

@media (max-width: 1199.98px) {
  .dash-card .dash-widget .circle-bar {
    margin: 0 0 15px;
  }
}

@media (max-width: 991.98px) {
  .dash-card .dash-widget .circle-bar {
    margin: 0 15px 0 0;
  }
}

.dash-card .dash-widget .circle-bar > div {
  display: inline-block;
  position: relative;
  text-align: center;
}

.dash-card .dash-widget .circle-bar > div img {
  text-align: center;
  margin: 0 auto;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
}

.dash-card .dash-widget .circle-bar > div canvas {
  width: 90px !important;
  height: 90px !important;
}

.dash-card .dash-widget.dct-border-rht {
  border-right: 1px solid #f0f0f0;
}

@media (max-width: 991.98px) {
  .dash-card .dash-widget.dct-border-rht {
    border-bottom: 1px solid #f0f0f0;
    border-right: 0;
    margin-bottom: 20px;
    padding-bottom: 15px;
  }
}

.dash-card .dash-widget h6 {
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 767.98px) {
  .dash-card .dash-widget h6 {
    font-size: 15px;
  }
}

.dash-card .dash-widget h3 {
  font-size: 24px;
  margin-bottom: 5px;
}

@media (max-width: 767.98px) {
  .dash-card .dash-widget h3 {
    font-size: 20px;
  }
}

.dash-card .dash-widget p {
  color: #757575;
  font-size: 14px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .dash-card .dash-widget p {
    font-size: 13px;
  }
}

.dash-card .row {
  margin-left: -10px;
  margin-right: -10px;
}

.dash-card .row > div {
  padding-left: 10px;
  padding-right: 10px;
}

.appointment-tab {
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .appointment-tab {
    margin-bottom: 20px;
  }
}

.appointment-tab .nav-tabs {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 0.25rem 0.25rem 0 0 !important;
  border-bottom: 0;
  padding: 1.5rem;
}

@media (max-width: 767.98px) {
  .appointment-tab .nav-tabs {
    padding: 1.5rem;
  }
}

.appointment-tab .tab-content {
  padding-top: 0;
}

.appointment-tab .card {
  border-radius: 0;
}

.profile-det-info {
  overflow: hidden;
}

.profile-det-info h3 {
  font-size: 17px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.profile-det-info .patient-details h5 {
  color: #757575;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profile-det-info .patient-details h5 i {
  width: 18px;
}

.profile-info-widget {
  text-align: left;
}

.profile-info-widget .booking-doc-img {
  margin-right: 15px;
}

.profile-info-widget .booking-doc-img img {
  height: 90px;
  width: 90px;
  object-fit: cover;
  border-radius: 4px;
}

.change-avatar .profile-img {
  margin-right: 15px;
}

.change-avatar .profile-img img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 4px;
}

@media (max-width: 575.98px) {
  .change-avatar .profile-img img {
    height: 80px;
    width: 80px;
  }
}

.change-avatar .change-photo-btn {
  margin: 0 0 10px;
  width: 150px;
}

.change-avatar .change-photo-btn input.upload {
  bottom: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
  width: 220px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.dropzone {
  background-color: #fbfbfb;
  border: 2px dashed rgba(0, 0, 0, 0.1);
}

.profile-image img {
  margin-bottom: 1.5rem;
}

.biller-info,
.billing-info {
  margin-bottom: 15px;
}

.add-more-item {
  margin-bottom: 10px;
}

.add-more-item a {
  color: #0de0fe;
  font-weight: 500;
}

.signature-wrap {
  float: right;
  margin-bottom: 20px;
  text-align: center;
  width: 220px;
}

.signature {
  border: 2px dashed #cccccc;
  color: #272b41;
  cursor: pointer;
  height: 85px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 4px;
}

.signature:hover {
  background-color: #fcfcfc;
}

.signature .sign-name {
  width: 100%;
  float: right;
}

.pat-widget-profile .pro-widget-content {
  padding: 0 0 20px;
}

.pat-widget-profile .booking-date li {
  font-size: 14px;
}

.pat-widget-profile .booking-date li span {
  font-size: 14px;
}

.add-new-btn {
  background-color: #0de0fe;
  color: #ffffff;
  display: inline-block;
  font-weight: 500;
  margin: 0 0 20px;
  padding: 10px 20px;
  border-radius: 30px;
}

.add-new-btn:focus,
.add-new-btn:hover,
.add-new-btn.active {
  background-color: #0de0fe;
  color: #ffffff;
}

.patient-info {
  margin-top: 15px;
}

.patient-info ul {
  list-style: none;
  font-size: 0.875rem;
  margin: 0;
  padding: 0;
}

.patient-info ul li {
  position: relative;
  font-size: 0.875rem;
  font-weight: 500;
  color: #272b41;
  text-transform: capitalize;
}

.patient-info ul li + li {
  margin-top: 15px;
}

.patient-info ul li i {
  width: 18px;
}

.patient-info ul li span {
  color: #757575;
  float: right;
  font-weight: 400;
}

.profile-sidebar {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 4px;
}

.pro-widget-content {
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
  padding: 20px;
}

.profile-info-widget {
  text-align: left;
}

.profile-info-widget .custom-avatar {
  margin-right: 15px;
}

.profile-info-widget .custom-avatar {
  height: 90px;
  width: 90px;
  object-fit: cover;
  border-radius: 4px;
}

.profile-det-info {
  overflow: hidden;
}

.profile-det-info h3 {
  font-size: 17px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.patient-details h5 {
  color: #757575;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-details h5 i {
  width: 18px;
}

.dashboard-menu ul {
  color: #757575;
  font-size: 14px;
  line-height: 17px;
  list-style: none;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
}

.dashboard-menu ul li {
  line-height: inherit;
}

.dashboard-menu ul li a i {
  font-size: 16px;
  margin-right: 10px;
  width: 16px;
  display: inline-block;
  vertical-align: middle;
}

.dashboard-menu ul li a span {
  display: inline-block;
  vertical-align: middle;
}

.dashboard-menu > ul > li {
  border-bottom: 1px solid #f0f0f0;
  position: relative;
}

.dashboard-menu > ul > li :last-child {
  border-bottom: 0;
}

.dashboard-menu > ul > li > a {
  color: #757575;
  display: block;
  padding: 16px 20px;
}

.dashboard-menu > ul > li:hover > a,
.dashboard-menu > ul > li.active > a {
  color: #2a9d36;
}

.unread-msg {
  background-color: #09e5ab;
  color: #272b41;
  font-size: 10px;
  font-style: normal;
  text-align: center;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 20px;
  padding: 0 5px;
  border-radius: 2px;
}

.patient-dashboard-top > div {
  width: 100%;
}

.patient-dashboard-top h6 {
  font-size: 18px;
  margin-bottom: 0;
}

.patient-dashboard-top h6 sub {
  font-size: 12px;
}

.patient-graph-col .graph-box {
  background: #7289de;
  border-bottom: 8px solid #6a5dee;
  width: 100%;
  margin: 15px 0;
  padding: 22px;
}

.patient-graph-col .graph-box.pink-graph {
  background-color: #ffa69e;
  border-bottom: 8px solid #ffa69e;
}

.patient-graph-col .graph-box.sky-blue-graph {
  background-color: #a9d4ff;
  border-bottom: 8px solid #8ec4ff;
}

.patient-graph-col .graph-box.orange-graph {
  background-color: #ffb88e;
  border-bottom: 8px solid #ffb88e;
}

.patient-graph-col .graph-box h4 {
  color: #ffffff;
  margin-bottom: 15px;
  text-align: center;
}

.patient-graph-col .graph-box > div {
  width: 100%;
}

.patient-graph-col .graph-box .graph-img {
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.patient-graph-col .graph-box .graph-status-result h3 {
  color: #ffffff;
  margin-bottom: 0;
  line-height: 0.9;
  width: 100%;
}

.patient-graph-col .graph-box .graph-status-result span.graph-update-date {
  color: #ffffff;
  font-weight: 500;
  opacity: 0.7;
  text-align: center;
  width: 100%;
}

.chat-page .footer {
  display: none;
}

.chat-window {
  position: relative;
}

.chat-window .chat-cont-left {
  border-right: 1px solid #f0f0f0;
  -ms-flex: 0 0 35%;
  flex: 0 0 35%;
  max-width: 35%;
  z-index: 4;
  position: relative;
  left: 0;
}

@media (max-width: 1199.98px) {
  .chat-window .chat-cont-left {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media (max-width: 991.98px) {
  .chat-window .chat-cont-left {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
    width: 100%;
    border-right: 0;
  }
}

@media (max-width: 479.98px) {
  .chat-window .chat-cont-left a.media .media-body > div:first-child .user-name,
  .chat-window
    .chat-cont-left
    a.media
    .media-body
    > div:first-child
    .user-last-chat {
    max-width: 160px;
  }
}

.chat-window .chat-cont-left .chat-header {
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  color: #272b41;
  height: 72px;
  padding: 0 15px;
}

.chat-window .chat-cont-left .chat-header span {
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

.chat-window .chat-cont-left .chat-header .chat-compose {
  color: #8a8a8a;
}

.chat-window .chat-cont-left .chat-search {
  background-color: #f5f5f6;
  border-bottom: 1px solid #f3f3f3;
  padding: 10px 15px;
  width: 100%;
}

.chat-window .chat-cont-left .chat-search .input-group {
  width: 100%;
}

.chat-window .chat-cont-left .chat-search .input-group .form-control {
  background-color: #ffffff;
  padding-left: 36px;
  border-radius: 50px;
}

.chat-window .chat-cont-left .chat-search .input-group .form-control:focus {
  border-color: #cccccc;
  box-shadow: none;
}

.chat-window
  .chat-cont-left
  .chat-search
  .input-group
  .form-control::placeholder {
  font-size: 14px;
}

.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend {
  color: #666;
  pointer-events: none;
  z-index: 4;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
}

.chat-window .chat-cont-left .chat-users-list {
  background-color: #ffffff;
}

.chat-window .chat-cont-left .chat-users-list a.media {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 15px;
  -webkit-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.chat-window .chat-cont-left .chat-users-list a.media:last-child {
  border-bottom: 0;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap {
  margin-right: 15px;
  position: relative;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
  height: 45px;
  width: 45px;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .status {
  height: 10px;
  width: 10px;
  border: 2px solid #ffffff;
  position: absolute;
  right: 4px;
  bottom: 7px;
}

.chat-window
  .chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:first-child
  .user-name {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #272b41;
  text-transform: capitalize;
}

@media (max-width: 1399.98px) {
  .chat-window
    .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-name {
    max-width: 180px;
  }
}

@media (max-width: 1199.98px) {
  .chat-window
    .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-name {
    max-width: 150px;
  }
}

@media (max-width: 767.98px) {
  .chat-window
    .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-name {
    max-width: 250px;
  }
}

@media (max-width: 479.98px) {
  .chat-window
    .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-name {
    max-width: 160px;
  }
}

.chat-window
  .chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:first-child
  .user-last-chat {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #8a8a8a;
  font-size: 14px;
  line-height: 24px;
}

@media (max-width: 1399.98px) {
  .chat-window
    .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-last-chat {
    max-width: 180px;
  }
}

@media (max-width: 1199.98px) {
  .chat-window
    .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-last-chat {
    max-width: 150px;
  }
}

@media (max-width: 767.98px) {
  .chat-window
    .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-last-chat {
    max-width: 250px;
  }
}

@media (max-width: 479.98px) {
  .chat-window
    .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-last-chat {
    max-width: 160px;
  }
}

.chat-window
  .chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:last-child {
  text-align: right;
}

.chat-window
  .chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:last-child
  .last-chat-time {
  color: #8a8a8a;
  font-size: 13px;
}

.chat-window .chat-cont-left .chat-users-list a.media:hover {
  background-color: #f5f5f6;
}

.chat-window
  .chat-cont-left
  .chat-users-list
  a.media.read-chat
  .media-body
  > div:last-child
  .last-chat-time {
  color: #8a8a8a;
}

.chat-window .chat-cont-left .chat-users-list a.media.active {
  background-color: #f5f5f6;
}

.chat-window .chat-scroll {
  min-height: 300px;
  max-height: calc(100vh - 224px);
  overflow-y: auto;
}

.chat-window .chat-cont-right {
  -ms-flex: 0 0 65%;
  flex: 0 0 65%;
  max-width: 65%;
}

@media (max-width: 1199.98px) {
  .chat-window .chat-cont-right {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media (max-width: 991.98px) {
  .chat-window .chat-cont-right {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    right: -100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
    width: 100%;
  }
}

.chat-window .chat-cont-right .chat-header {
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  height: 72px;
  padding: 0 15px;
}

@media (max-width: 991.98px) {
  .chat-window .chat-cont-right .chat-header {
    justify-content: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
  }
}

.chat-window .chat-cont-right .chat-header .back-user-list {
  display: none;
  margin-right: 5px;
  margin-left: -7px;
}

@media (max-width: 991.98px) {
  .chat-window .chat-cont-right .chat-header .back-user-list {
    display: block;
  }
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap {
  position: relative;
  margin-right: 15px;
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap .avatar {
  height: 50px;
  width: 50px;
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap .status {
  border: 2px solid #ffffff;
  height: 10px;
  width: 10px;
  position: absolute;
  right: 3px;
  bottom: 0;
}

@media (max-width: 767.98px) {
  .chat-window .chat-cont-right .chat-header .media .media-body {
    display: none;
  }
}

.chat-window .chat-cont-right .chat-header .media .media-body .user-name {
  color: #272b41;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.chat-window .chat-cont-right .chat-header .media .media-body .user-status {
  color: #666;
  font-size: 14px;
}

@media (max-width: 991.98px) {
  .chat-window .chat-cont-right .chat-header .chat-options {
    margin-left: auto;
  }
}

.chat-window .chat-cont-right .chat-header .chat-options > a {
  border-radius: 50%;
  height: 30px;
  color: #8a8a8a;
  margin-left: 10px;
  width: 30px;
}

.chat-window .chat-cont-right .chat-body {
  background-color: #f5f5f6;
}

.chat-window .chat-cont-right .chat-body ul.list-unstyled {
  width: 100%;
  margin: 0 auto;
  padding: 15px;
}

.chat-window .chat-cont-right .chat-body .media .avatar {
  height: 30px;
  width: 30px;
}

.chat-window .chat-cont-right .chat-body .media .media-body {
  margin-left: 20px;
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box > div {
  display: inline-block;
  position: relative;
  border-radius: 0.25rem;
  padding: 10px 15px;
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box > div p {
  color: #272b41;
  margin-bottom: 0;
}

.chat-window
  .chat-cont-right
  .chat-body
  .media
  .media-body
  .msg-box
  + .msg-box {
  margin-top: 5px;
}

.chat-window .chat-cont-right .chat-body .media.received {
  margin-bottom: 20px;
}

.chat-window .chat-cont-right .chat-body .media.received .media-body {
  position: relative;
}

.chat-window
  .chat-cont-right
  .chat-body
  .media.received
  .media-body
  .msg-box
  > div {
  background-color: #ffffff;
}

.chat-window
  .chat-cont-right
  .chat-body
  .media.received
  .media-body
  .msg-box
  > div
  .chat-time {
  color: rgba(50, 65, 72, 0.4);
}

.chat-window
  .chat-cont-right
  .chat-body
  .media.received
  .media-body
  .msg-box:first-child:before {
  border-bottom: 6px solid transparent;
  border-right: 6px solid #ffffff;
  border-top: 6px solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  top: 8px;
  right: auto;
  left: -6px;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box {
  padding-left: 50px;
  position: relative;
}

.chat-window
  .chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box:first-child:before {
  border-bottom: 6px solid transparent;
  border-left: 6px solid #e3e3e3;
  border-top: 6px solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  top: 8px;
  right: -6px;
  left: auto;
}

.chat-window
  .chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  > div
  .chat-time {
  color: rgba(50, 65, 72, 0.4);
}

.chat-window .chat-cont-right .chat-body .media:last-child {
  margin-bottom: 0;
}

.chat-window .chat-cont-right .chat-body .media.sent {
  margin-bottom: 20px;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body {
  margin-left: 0;
}

.chat-window
  .chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  > div {
  background-color: #e3e3e3;
}

.chat-window
  .chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  > div
  p {
  color: #272b41;
}

.chat-window
  .chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  > div
  .chat-msg-info {
  flex-direction: row-reverse;
}

.chat-window
  .chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  > div
  .chat-msg-info
  li {
  padding-left: 16px;
  padding-right: 0;
  position: relative;
}

.chat-window
  .chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  > div
  .chat-msg-attachments {
  flex-direction: row-reverse;
}

.chat-window
  .chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  > div:hover
  .chat-msg-actions {
  opacity: 1;
}

.chat-window .chat-cont-right .chat-body .chat-date {
  font-size: 14px;
  margin: 1.875rem 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}

.chat-window .chat-cont-right .chat-body .chat-date:before {
  background-color: #eef6ff;
  content: "";
  height: 1px;
  margin-right: 28px;
  width: 100%;
  position: absolute;
  top: 50%;
  right: 50%;
}

.chat-window .chat-cont-right .chat-body .chat-date:after {
  background-color: #eef6ff;
  content: "";
  height: 1px;
  margin-left: 28px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.chat-window .chat-cont-right .chat-footer {
  background-color: #ffffff;
  position: relative;
  border-top: 1px solid #f0f0f0;
  padding: 10px 15px;
}

.chat-window .chat-cont-right .chat-footer .input-group {
  width: 100%;
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control {
  background-color: #f5f5f6;
  border: none;
  border-radius: 50px;
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control:focus {
  background-color: #f5f5f6;
  border: none;
  box-shadow: none;
}

.chat-window
  .chat-cont-right
  .chat-footer
  .input-group
  .form-control::placeholder {
  font-size: 14px;
}

.chat-window .chat-cont-right .chat-footer .input-group .btn {
  background-color: transparent;
  border: none;
  color: #9f9f9f;
}

.chat-window .chat-cont-right .chat-footer .input-group .btn.msg-send-btn {
  background-color: #0de0fe;
  border-color: #0de0fe;
  color: #ffffff;
  margin-left: 10px;
  min-width: 46px;
  font-size: 20px;
  border-radius: 50%;
}

.chat-window .chat-cont-right .msg-typing {
  width: auto;
  height: 24px;
  padding-top: 8px;
}

.chat-window .chat-cont-right .msg-typing span {
  height: 8px;
  width: 8px;
  float: left;
  margin: 0 1px;
  background-color: #a0a0a0;
  display: block;
  opacity: 0.4;
  border-radius: 50%;
}

.chat-window .chat-cont-right .msg-typing span:nth-of-type(1) {
  animation: 1s blink infinite 0.33333s;
}

.chat-window .chat-cont-right .msg-typing span:nth-of-type(2) {
  animation: 1s blink infinite 0.66666s;
}

.chat-window .chat-cont-right .msg-typing span:nth-of-type(3) {
  animation: 1s blink infinite 0.99999s;
}

.chat-window .chat-msg-info {
  clear: both;
  list-style: none;
  margin: 5px 0 0;
  padding: 0;
}

.chat-window .chat-msg-info li {
  font-size: 13px;
  padding-right: 16px;
  position: relative;
}

.chat-window .chat-msg-info li:not(:last-child):after {
  content: "";
  height: 4px;
  width: 4px;
  background: #d2dde9;
  border-radius: 50%;
  transform: translate(50%, -50%);
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  position: absolute;
  top: 50%;
  right: 8px;
}

.chat-window .chat-msg-info li a {
  color: #777;
}

.chat-window .chat-msg-info li a:hover {
  color: #2c80ff;
}

@media (max-width: 991.98px) {
  .chat-window.chat-slide .chat-cont-left {
    left: -100%;
  }
}

@media (max-width: 991.98px) {
  .chat-window.chat-slide .chat-cont-right {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
}

.chat-seen i {
  color: #00d285;
  font-size: 16px;
}

.chat-msg-attachments {
  width: 100%;
  margin: 0 -1px;
  padding: 4px 0;
}

.chat-msg-attachments > div {
  margin: 0 1px;
}

.chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden;
  border-radius: 0.25rem;
}

.chat-attachment img {
  max-width: 100%;
}

.chat-attachment:before {
  background: #000000;
  content: "";
  opacity: 0.4;
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.chat-attachment:hover:before {
  opacity: 0.6;
}

.chat-attachment:hover .chat-attach-caption {
  opacity: 0;
}

.chat-attachment:hover .chat-attach-download {
  opacity: 1;
}

.chat-attach-caption {
  color: #ffffff;
  padding: 7px 15px;
  font-size: 13px;
  opacity: 1;
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.chat-attach-download {
  opacity: 0;
  color: #ffffff;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.chat-attach-download:hover {
  color: #495463;
  background: #ffffff;
}

chat-attachment-list {
  margin: -5px;
}

chat-attachment-list li {
  width: 33.33%;
  padding: 5px;
}

.chat-attachment-item {
  border: 5px solid rgba(230, 239, 251, 0.5);
  height: 100%;
  min-height: 60px;
  text-align: center;
  font-size: 30px;
}

.chat-msg-actions {
  opacity: 0;
  z-index: 2;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
  position: absolute;
  top: 50%;
  left: -30px;
}

.chat-msg-actions > a {
  color: #495463;
  font-size: 24px;
  padding: 0 10px;
}

.chat-msg-actions > a:hover {
  color: #2c80ff;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

.btn-file {
  font-size: 20px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  padding: 0 0.75rem;
}

.btn-file input {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  margin: 0;
  opacity: 0;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.incoming-btns {
  margin-top: 20px;
}

.call-wrapper {
  position: relative;
  height: calc(100vh - 145px);
}

@media (max-width: 991.98px) {
  .call-wrapper {
    height: calc(100vh - 140px);
  }
}

@media (max-width: 767.98px) {
  .call-wrapper {
    height: calc(100vh - 115px);
  }
}

.call-page .footer {
  display: none;
}

.dropdown-action .dropdown-toggle::after {
  display: none;
}

.call-modal .modal-body {
  padding: 40px;
}

.call-modal .modal-content {
  border: 0;
  border-radius: 10px;
}

/*-----------------
	Incoming call
-----------------------*/
.call-box .call-wrapper {
  height: auto;
  text-align: center;
}

.call-box .call-wrapper .call-user {
  margin-bottom: 30px;
}

.call-box .call-wrapper .call-user h4 {
  font-size: 24px;
}

.call-box .call-wrapper .call-user span {
  display: block;
  font-weight: 500;
  text-align: center;
}

.call-box .call-wrapper .call-avatar {
  margin-bottom: 30px;
  cursor: pointer;
  animation: ripple 2s infinite;
}

.call-box .call-wrapper .call-items .call-item {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid transparent;
  border-radius: 100%;
  color: #ffffff;
  line-height: 0;
  margin: 0 5px;
  padding: 15px;
}

.call-box .call-wrapper .call-items .call-item:hover {
  opacity: 0.9;
}

.call-box .call-wrapper .call-items .call-item:first-child,
.call-box .call-wrapper .call-items .call-item:last-child {
  margin-top: -30px;
}

.call-box .call-wrapper .call-items .call-item.call-end {
  background: #f06060;
  border: 1px solid #f06060;
  color: #ffffff;
  line-height: 0;
  border-radius: 100%;
  margin: 30px 20px 0;
  padding: 20px;
}

.call-box .call-wrapper .call-items .call-item.call-start {
  background: #55ce63;
  border: 1px solid #55ce63;
  color: #ffffff;
  line-height: 0;
  border-radius: 100%;
  margin: 30px 20px 0;
  padding: 20px;
}

.call-box.incoming-box .call-wrapper .call-items .call-item.call-start,
.call-box.incoming-box .call-wrapper .call-items .call-item.call-end {
  margin: 0 10px;
}

.call-box .call-avatar {
  height: 140px;
  max-width: 140px;
  min-width: 140px;
  position: relative;
  width: 100%;
  border: 10px solid #fafafa;
  border-radius: 100%;
}

.call-box .btn {
  background: rgba(0, 0, 0, 0);
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  100% {
    -webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
  }
}

@keyframes ripple {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  100% {
    -moz-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
  }
}

.chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  .chat-msg-info
  li:not(:last-child)::after {
  right: auto;
  left: 8px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background: #aaa;
}

.view-cart-header {
  position: relative;
}

.view-cart-header .dropdown-menu {
  min-width: 320px !important;
}

.view-cart-header .shopping-cart {
  padding: 15px;
}

.view-cart-header .shopping-cart.show-cart {
  opacity: 1;
  visibility: visible;
}

.view-cart-header .shopping-cart:after {
  border: 7px solid #ffffff;
  border-color: transparent transparent #ffffff #ffffff;
  box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
  content: "";
  position: absolute;
  top: 2px;
  right: 0;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
}

.view-cart-header .shopping-cart .booking-date {
  display: block;
  padding-bottom: 0;
}

.view-cart-header .shopping-cart .booking-date li span {
  font-size: 14px;
}

.view-cart-header .shopping-cart-total {
  float: right;
}

.view-cart-header .shopping-cart-items {
  padding-top: 0px;
}

.view-cart-header .shopping-cart-items li {
  display: block !important;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
  margin: 0 0 10px 0 !important;
  padding: 0 0 14px;
}

.view-cart-header .shopping-cart-items li a {
  padding-left: 0 !important;
  letter-spacing: normal !important;
}

.view-cart-header .shopping-cart-items .close-icon {
  cursor: pointer;
  color: #6b7280;
  position: absolute;
  top: 10px;
  right: 0;
}

.view-cart-header .shopping-cart-items img {
  float: left;
  width: 70px;
  margin-right: 12px;
  max-width: 100%;
  height: auto;
}

.view-cart-header .shopping-cart-items .item-name {
  display: block;
  padding-top: 10px;
  font-size: 14px;
  padding-right: 20px;
  color: #6b7280;
  font-weight: normal;
}

.view-cart-header .shopping-cart-items .item-price {
  margin-right: 8px;
  color: #0e82fd;
  font-size: 13px;
  font-weight: normal;
}

.view-cart-header .shopping-cart-items .item-quantity {
  color: #abb0be;
  font-size: 13px;
  font-weight: normal;
}

.view-cart-header .cart-icon {
  color: #515783;
  font-size: 24px;
  margin-right: 7px;
  float: left;
}

.view-cart-header .booking-date li {
  display: block !important;
  width: 100%;
  margin: 0 0 10px;
  padding: 0 !important;
}

.view-cart-header .booking-date li span {
  float: right;
}

.view-cart-header .booking-total {
  padding-top: 0;
  text-align: center;
}

.view-cart-header .apt-btn {
  font-size: 13px;
  letter-spacing: normal !important;
  padding: 7px 20px;
}

.view-cart-header .apt-btn:hover {
  border-color: #0e82fd !important;
  background-color: #0e82fd !important;
  color: #ffffff !important;
}

.unread-msg1 {
  background-color: #0e82fd;
  border-radius: 2px;
  color: #ffffff;
  font-size: 10px;
  font-style: normal;
  text-align: center;
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 0 5px;
}

.custom-increment.cart button {
  font-size: 10px;
}

.custom-increment.cart input[type="text"] {
  width: 60px;
}

.custom-increment button {
  font-size: 14px;
  height: 39px;
  background: #dcdcdc;
  color: #000000;
  border: 0;
  display: inline-block;
}

.custom-increment button.btn-danger {
  border-radius: 4px 0px 0px 4px;
}

.custom-increment button:hover,
.custom-increment button:focus,
.custom-increment button:active {
  background: #dcdcdc !important;
  color: #000000 !important;
  border: 0 !important;
}

.custom-increment button.btn-success {
  border-radius: 0 4px 4px 0;
}

.custom-increment button.btn-danger {
  border-radius: 4px 0px 0px 4px;
}

.custom-increment .input-group1 {
  width: 100%;
}

.custom-increment input[type="text"] {
  border: 1px solid #dbdbdb;
  border-radius: 0;
  text-align: center;
  width: 70%;
  padding: 7px 15px;
}

.custom-increment input[type="text"]:focus {
  border-color: transparent;
}

.doc-review.review-listing {
  margin: 0;
}

.review-listing.doc-review > ul > li {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  padding: 20px;
}

.account-page {
  background-color: #ffffff;
}

.account-page .content {
  padding: 50px 0;
}

@media (max-width: 767.98px) {
  .account-page .content {
    padding: 15px 0;
  }
}

.login-right {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 25px;
}

@media (max-width: 767.98px) {
  .login-right {
    margin: 0 15px;
    padding: 15px;
  }
}

.login-right .dont-have {
  color: #3d3d3d;
  margin-top: 20px;
  font-size: 13px;
}

.login-right .dont-have a {
  color: #c70e2a;
}

.login-right .inner-right-login {
  max-width: 355px;
  width: 100%;
  margin: 0 auto;
}

.login-right
  .country-select
  .select-col
  .select2-container--default
  .select2-selection--single {
  background-color: #ffffff;
  border-radius: 10px 0 0 10px;
  width: 79px;
}

.login-right
  .country-select
  .select-col
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #0de0fe;
}

.login-right
  .country-select
  .select-col
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  background-image: url(../../assets/images/icons/down-arrow-gray-dark.svg);
}

@media (max-width: 991.98px) {
  .login-left {
    display: none;
  }
}

.login-header {
  margin-bottom: 20px;
}

.login-header p {
  margin-bottom: 0;
}

.login-header h3 {
  font-size: 18px;
  margin-bottom: 3px;
}

@media (max-width: 479.98px) {
  .login-header h3 {
    text-align: center;
  }
}

.login-header h3 a {
  color: #0de0fe;
  float: right;
  font-size: 15px;
  margin-top: 2px;
}

@media (max-width: 479.98px) {
  .login-header h3 a {
    color: #0de0fe;
    float: none;
    font-size: 15px;
    margin-top: 10px;
    text-align: center;
    display: block;
  }
}

.login-header .logo-icon {
  text-align: center;
  margin: 40px auto 20px;
}

.login-btn {
  font-size: 18px;
  font-weight: 500;
}

.login-or {
  color: #d4d4d4;
  position: relative;
  margin: 20px 0;
  padding: 10px 0;
}

.or-line {
  background-color: #e5e5e5;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}

.span-or {
  background-color: #ffffff;
  display: block;
  margin-left: -20px;
  text-align: center;
  width: 42px;
  position: absolute;
  top: -3px;
  left: 50%;
}

.forgot-link {
  color: #3d3d3d;
  display: inline-block;
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: 400;
}

.social-login .btn:hover,
.social-login .social-login .btn:focus {
  color: #ffffff;
}

span.agree {
  font-size: 16px;
  margin-left: 6px;
}

.terms-and-policy span.terms {
  color: #09dca4;
}

.terms-and-policy a {
  color: #09dca4;
}

.login-page .login-right {
  width: 100%;
  position: relative;
  font-family: "Poppins", sans-serif;
  height: 100vh;
  border: 0;
  border-radius: 0;
  padding: 0;
}

.login-page .login-right .step-process-col label {
  color: #0de0fe;
}

.login-page
  .login-right
  .step-process-col
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #808080;
}

.login-page
  .login-right
  .step-process-col
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  background-image: url(../../assets/images/icons/down-arrow-gray.svg);
}

.login-page .login-right .step-process-col .form-control {
  color: #808080;
}

.login-page .login-right .search-location-input-box input {
  background-image: url(../../assets/images/icons/search-icon.svg);
  background-repeat: no-repeat;
  background-position: 10px 50%;
  background-size: 16px;
  padding-left: 38px;
  color: #948ca0;
}

.login-bottom-copyright {
  color: #a4a4a4;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding-bottom: 25px;
  width: 100%;
  align-self: flex-end;
  -webkit-align-self: flex-end;
}

.step-list {
  text-align: center;
  padding: 10px 0;
}

.step-list ul {
  display: inline-block;
}

.step-list ul li {
  float: left;
  margin: 5px 0;
  padding: 0 30px 0 0;
}

.step-list ul li:last-child {
  padding-right: 0;
}

.step-list ul li a {
  background-color: #e9f5f7;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  font-size: 14px;
  color: #808080;
}

.step-list ul li a:hover,
.step-list ul li a.active {
  background-color: #0de0fe;
  color: #ffffff;
}

.step-list ul li a.active-done {
  background-color: #0de0fe;
  color: #ffffff;
  background-image: url(../../assets/images/icons/tick-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
  font-size: 0;
}

.profile-pic-col {
  padding-top: 40px;
  text-align: center;
}

.profile-pic-col h3 {
  color: #000000;
  font-size: 20px !important;
}

.profile-pic-upload {
  width: 150px;
  background: rgba(175, 213, 254, 0.4);
  border-radius: 20px;
  position: relative;
  margin: 40px auto 0;
  padding: 24px;
}

.profile-pic-upload input[type="file"] {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}

.profile-pic-upload .cam-col {
  width: 70px;
  height: 70px;
  background-color: #ffffff;
  border-radius: 70px;
  overflow: hidden;
  margin: 0 auto;
}

.profile-pic-upload span {
  color: #000000;
  font-size: 13px;
  list-style: none;
  display: inline-block;
  margin-top: 15px;
}

.select-gender-col [type="radio"]:checked,
.select-gender-col [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.select-gender-col [type="radio"]:checked + label,
.select-gender-col [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.select-gender-col [type="radio"]:checked + label:before,
.select-gender-col [type="radio"]:not(:checked) + label:before {
  content: "";
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 5px 20px 60px 10px rgba(0, 4, 107, 0.07);
  position: absolute;
  top: 0;
  left: 0;
}

.select-gender-col [type="radio"]:checked + label:after,
.select-gender-col [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 10px;
  border: 2px solid #0de0fe;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.select-gender-col [type="radio"]:not(:checked) + label:after {
  opacity: 0;
}

.select-gender-col [type="radio"]:checked + label:after {
  opacity: 1;
}

.select-gender-col .gender-icon {
  position: relative;
}

.select-gender-col .gender-icon label {
  text-align: center;
  padding: 15px 20px !important;
}

.select-gender-col .gender-icon img {
  max-height: 90px;
}

.select-gender-col span {
  position: relative;
  color: #000000;
  display: inline-block;
  margin-top: 10px;
  font-size: 16px;
  width: 100%;
}

.select-gender-col.payment-menthods-choose label {
  min-width: 90px;
  text-align: center;
  color: #000000;
  padding: 10px !important;
}

.select-gender-col.payment-menthods-choose label span.credit-card-option {
  min-width: 192px;
  font-size: 15px;
}

.select-gender-col.payment-menthods-choose label img {
  position: relative;
  max-width: 68px;
}

.select-gender-col.payment-menthods-choose
  [type="radio"]:checked
  + label::after,
.select-gender-col.payment-menthods-choose
  [type="radio"]:not(:checked)
  + label::after {
  border: 1px solid #0de0fe;
}

.select-gender-col label {
  text-align: center;
  padding: 15px 20px !important;
}

.pregnant-col .remember-me-col {
  color: #000000;
  font-size: 16px;
}

.pregnant-col .remember-me-col .custom_check .checkmark {
  border-color: #0de0fe;
}

.step-process-col {
  text-align: left;
}

.checklist-col span {
  font-size: 16px;
}

.checklist-col .custom_check .checkmark {
  width: 24px;
  height: 24px;
}

.checklist-col .custom_check .checkmark:after {
  left: 6px;
  top: 3px;
}

.checklist-col .remember-me-col {
  position: relative;
  padding: 10px 0;
}

.checklist-col.pregnant-col .remember-me-col .custom_check {
  padding-left: 25px;
}

.checklist-col.pregnant-col .remember-me-col .custom_check .checkmark {
  border-color: #b4abca;
}

.otp-col {
  color: #ffffff;
}

.otp-col h3 {
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 15px;
}

.dnt-recive-call {
  color: #ffffff;
}

.dnt-recive-call a {
  color: #ffffff;
}

.otp-box {
  margin: 25px 0;
}

.otp-box .digit-group input {
  width: 45px;
  height: 45px;
  background-color: rgba(245, 245, 245, 0.2);
  border: none;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  box-shadow: 5px 20px 60px 10px rgba(0, 4, 107, 0.07);
  border-radius: 8px;
  color: #000000;
  margin: 0 3px;
}

.otp-box .digit-group input:focus {
  background-color: #ffffff;
  border: 0;
  outline: 0;
}

.otp-box .digit-group input[type="text"].active {
  background-color: #ffffff;
  color: #000000;
}

.otp-box .splitter {
  color: #ffffff;
  font-size: 24px;
  padding: 0 5px;
}

.otp-box .prompt {
  font-size: 20px;
  color: #ffffff;
  margin: 0 0 20px;
}

.increment-decrement .input-groups {
  border: 0;
  background-color: transparent;
  height: 35px;
  margin-right: 0px;
  display: block;
}

.increment-decrement .input-groups .quantity-field {
  width: 40px;
  text-align: center;
  background-color: transparent;
  float: left;
  border: 0;
}

.increment-decrement .input-groups .quantity-field:focus {
  border: 0;
  outline: 0;
}

.increment-decrement .input-groups input[type="button"] {
  background-color: transparent;
  font-size: 20px;
  border: 1px solid #0de0fe;
  border-radius: 4px;
  height: 24px;
  width: 24px;
  margin-top: 0px;
  line-height: 0;
  padding: 0;
  color: #0de0fe;
}

.increment-decrement .input-groups input[type="button"]:focus-visible {
  outline: 0;
}

.increment-decrement .input-groups input[type="button"].button-minus {
  float: left;
}

.increment-decrement .input-groups input[type="button"].button-plus {
  float: right;
}

.country-select {
  position: relative;
}

.country-select .select-col {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.country-select .select-col .flagstrap {
  height: 100%;
}

.country-select .select-col .btn-info.dropdown-toggle {
  border: 0;
  color: #0de0fe;
  border-radius: 10px 0px 0px 10px;
  background-color: #ffffff;
  height: 100%;
  font-size: 0;
  width: 79px;
  text-align: left;
  padding: 0 12px;
}

.country-select .select-col .btn-info.dropdown-toggle:after {
  background-image: url(../../assets/images/icons/down-arrow-gray-dark.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 14px;
  height: 7px;
  border: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
}

.country-select .select-col .btn-info.dropdown-toggle span {
  font-size: 0;
}

.country-select .select-col .dropdown-menu {
  font-size: 0;
  width: 79px;
  min-width: inherit;
  margin-top: 0;
  border-radius: 10px;
  padding: 0;
}

.country-select .select-col .dropdown-menu li {
  margin: 14px 13px;
}

.country-select .select-col .dropdown-menu li:first-child {
  display: none;
}

.country-select .select-col .dropdown-menu li a {
  display: inline-block;
  cursor: pointer;
}

.country-select .select-col .flagstrap-icon.flagstrap-in,
.country-select .select-col .flagstrap-icon.flagstrap-ng,
.country-select .select-col .flagstrap-icon.flagstrap-gb,
.country-select .select-col .flagstrap-icon.flagstrap-us {
  background-image: url(../../assets/images/flags/in.png);
  background-position: center;
  width: 33px;
  height: 22px;
  background-size: cover;
  border-radius: 4px;
}

.country-select .select-col .flagstrap-icon.flagstrap-ng {
  background-image: url(../../assets/images/flags/ng.png);
}

.country-select .select-col .flagstrap-icon.flagstrap-gb {
  background-image: url(../../assets/images/flags/gb.png);
}

.country-select .select-col .flagstrap-icon.flagstrap-us {
  background-image: url(../../assets/images/flags/us.png);
}

.country-select .number-col input {
  padding-left: 96px;
}

.location-text h5 {
  color: #000000;
  margin-top: 5px;
}

.location-search {
  margin: 12px 0;
}

.skip-now-col {
  margin-top: 5px;
}

.skip-now-col a {
  color: #000000;
  font-size: 12px;
}

.sort-by-col .select2-container .select2-selection--single {
  border-color: #e8eeff;
  border-radius: 10px;
  width: 160px;
}

.sort-by-col
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  background-image: url(../../assets/images/icons/down-arrow-gray.svg);
}

.premium-text {
  font-size: 18px;
}

.plan-details {
  list-style: none;
  margin: 0;
  padding: 4px 0 0;
}

.plan-details li {
  position: relative;
  min-width: 35%;
}

.plan-details li span {
  color: #b4abca;
  display: inline-block;
  margin-bottom: 3px;
  font-size: 15px;
}

.plan-chooser-col {
  background-color: rgba(175, 213, 254, 0.4);
  border-radius: 20px;
  margin: 0 0 28px;
  padding: 5px 20px;
}

.plan-chooser-col .memeber-img {
  border-radius: 15px;
  margin-bottom: 10px;
  width: 100%;
}

.plan-chooser-col .memeber-img img {
  border-radius: 15px;
}

.plan-chooser-col .subscription-list {
  margin: 15px 0;
}

.memeber-details h4 {
  margin-bottom: 0;
}

.payment-menthods ul {
  text-align: center;
}

.payment-menthods ul li {
  display: inline-block;
  margin: 0;
  padding: 6px;
}

.card-choose-col {
  max-width: 325px;
  margin: 0 auto;
}

.day-week-month-col ul li {
  float: left;
}

.day-week-month-col ul li:first-child a {
  border-radius: 4px 0 0 4px !important;
}

.day-week-month-col ul li:last-child a {
  border-radius: 0px 4px 4px 0px !important;
}

.day-week-month-col ul li a {
  background-color: #9d9d9d;
  border: 1px solid transparent;
  color: #64627c;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 10px 20px;
}

.day-week-month-col ul li a.active {
  border-color: #536cf1;
  color: #536cf1;
  border-radius: 0 !important;
}

.day-week-month-col ul li a:first-child.active {
  border-color: #536cf1;
}

.day-week-month-col ul li a:last-child.active {
  border-color: #536cf1;
}

.login-body {
  background: #ffffff;
}

.login-content-info {
  margin: 0;
  padding: 160px 0 60px;
}

.login-content-info .account-content {
  position: relative;
}

.login-content-info .account-content .login-shapes {
  position: relative;
}

.login-content-info .account-content .login-shapes .shape-img-left {
  position: absolute;
  top: 0;
  left: -70px;
}

.login-content-info .account-content .login-shapes .shape-img-right {
  position: absolute;
  top: 150px;
  left: -70px;
}

@media (max-width: 991.98px) {
  .login-content-info .account-content .login-shapes .shape-img-right {
    top: 67px;
  }
}

@media (max-width: 767.98px) {
  .login-content-info .account-content .login-shapes {
    display: none;
  }
}

.login-content-info .account-content .account-info {
  position: relative;
  z-index: 1;
}

.login-content-info .account-content .account-info .btn {
  width: 100%;
  color: #ffffff;
  background: #0e82fd;
  border: 1px solid #0e82fd;
  box-shadow: inset 0 0 0 0 #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
  font-weight: 600;
  padding: 10px 15px;
}

@media (max-width: 767.98px) {
  .login-content-info .account-content .account-info .btn {
    padding: 8px 15px;
  }
}

.login-content-info .account-content .account-info .btn:hover {
  color: #0e82fd;
  background: #ffffff;
  border: 1px solid #0e82fd;
  box-shadow: inset 0 0 0 50px #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.login-content-info .account-content .account-info .form-control {
  font-size: 14px;
}

.login-content-info .account-content .account-info .back-btn-light .btn {
  color: #000000;
  background-color: #f9f9f9;
  border: 1px solid #f9f9f9;
  box-shadow: inset 0 0 0 0 #ffffff;
}

.login-content-info .account-content .account-info .back-btn-light .btn:hover {
  color: #000;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-shadow: inset 0 0 0 50px #ffffff;
}

.login-content-info .account-content .account-info .back-btn-light .btn i {
  margin-right: 10px;
}

.login-content-info .account-content .login-back {
  padding-bottom: 50px;
}

.login-content-info .account-content .login-back a {
  font-size: 15px;
  color: #959595;
}

.login-content-info .account-content .login-back a:hover {
  color: #0e82fd;
}

.login-content-info .account-content .login-back a i {
  margin-right: 10px;
}

@media (max-width: 991.98px) {
  .login-content-info .account-content .login-back {
    padding-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  .login-content-info .account-content .login-back {
    padding-bottom: 20px;
  }
}

.login-content-info .account-content .login-verify-img {
  padding-bottom: 50px;
}

@media (max-width: 991.98px) {
  .login-content-info .account-content .login-verify-img {
    padding-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  .login-content-info .account-content .login-verify-img {
    padding-bottom: 20px;
  }
}

.login-content-info .account-content .login-title {
  padding-bottom: 50px;
}

.login-content-info .account-content .login-title h3 {
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #1f2937;
}

@media (max-width: 991.98px) {
  .login-content-info .account-content .login-title h3 {
    font-size: 26px;
  }
}

@media (max-width: 767.98px) {
  .login-content-info .account-content .login-title h3 {
    font-size: 20px;
  }
}

.login-content-info .account-content .login-title p {
  color: #71717a;
  margin-bottom: 10px;
}

.login-content-info .account-content .login-title span {
  color: #040404;
  font-weight: 500;
}

.login-content-info .account-content .login-title span a {
  color: #0e82fd;
  text-decoration: underline;
}

.login-content-info .account-content .login-title span a:hover {
  text-decoration: none;
}

@media (max-width: 991.98px) {
  .login-content-info .account-content .login-title {
    padding-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  .login-content-info .account-content .login-title {
    padding-bottom: 20px;
  }
}

.login-content-info .account-content .login-or {
  font-size: 16px;
  color: #818181;
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}

.login-content-info .account-content .or-line {
  background-color: #efefef;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}

.login-content-info .account-content .span-or {
  background-color: #ffffff;
  display: block;
  position: absolute;
  top: -3px;
  left: 50%;
  margin-left: -20px;
  text-align: center;
  width: 42px;
}

.login-content-info .account-content .social-login-btn {
  margin-bottom: 20px;
}

.login-content-info .account-content .social-login-btn .btn {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  padding: 11px 15px;
  border-radius: 8px;
  box-shadow: inset 0 0 0 0 #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.login-content-info .account-content .social-login-btn .btn:hover {
  color: #ffffff;
  background: #0e82fd;
  border: 1px solid #0e82fd;
  box-shadow: inset 0 0 0 50px #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.login-content-info .account-content .social-login-btn .btn img {
  margin-right: 15px;
}

@media (max-width: 991.98px) {
  .login-content-info {
    margin: 0;
    padding: 40px 0 40px;
  }
}

.login-content-info .form-group {
  margin-bottom: 20px;
}

.login-content-info .form-group label {
  color: #000000;
  display: inline-block;
  margin-bottom: 10px;
}

.pass-group {
  position: relative;
}

.pass-group .toggle-password {
  font-size: 16px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  color: #797979;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.pass-group .toggle-password:hover {
  background-color: #f3f3f3;
}

.toggle-password-sub {
  font-size: 16px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  color: #797979;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.toggle-password-sub:hover {
  background-color: #f3f3f3;
}

#passwordInfo,
#passwordInfos {
  font-size: 12px;
  font-weight: normal;
  margin-top: 15px;
}

.poor-active span.active#poor,
.poor-active span.active#poors {
  background: #ff0000;
}

.avg-active span.active#poor,
.avg-active span.active#poors,
.avg-active span.active#weak,
.avg-active span.active#weaks {
  background: #ffb54a;
}

.strong-active span.active#poor,
.strong-active span.active#weak,
.strong-active span.active#strong,
.strong-active span.active#poors,
.strong-active span.active#weaks,
.strong-active span.active#strongs {
  background: #0e82fd;
}

.heavy-active span.active#poor,
.heavy-active span.active#weak,
.heavy-active span.active#strong,
.heavy-active span.active#heavy,
.heavy-active span.active#poors,
.heavy-active span.active#weaks,
.heavy-active span.active#strongs,
.heavy-active span.active#heavys {
  background: #159f46;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.password-strength {
  margin-top: 15px;
}

.password-strength span {
  width: 100%;
  height: 6px;
  background: #e4e6ef;
  margin-right: 10px;
  border-radius: 30px;
}

.password-strength span:last-child {
  margin-right: 0;
}

.weak-pass .password-strength span {
  background: #ff0000;
}

.pass-checked {
  position: absolute;
  top: 55%;
  right: 46px;
  transform: translateY(-50%);
  color: #4caf50;
  opacity: 0;
}

.pass-checked.active {
  opacity: 1;
}

.password-info {
  margin-top: 15px;
}

.password-info p {
  color: #9e9e9e;
  margin-bottom: 0;
}

.login-success-icon {
  padding-bottom: 18px;
}

.login-success-icon i {
  font-size: 40px;
  color: #00cc45;
}

.modal-open .main-wrapper {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.call-main-row {
  overflow: auto;
  padding-bottom: inherit;
  padding-top: inherit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.call-main-wrapper {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
}

.call-view {
  display: table-cell;
  height: 100%;
  float: none;
  position: static;
  vertical-align: top;
  width: 75%;
  padding: 0;
}

.call-window {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
}

.fixed-header {
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 15px;
}

.fixed-header .navbar {
  border: 0 none;
  min-height: auto;
  margin: 0;
  padding: 0;
}

.fixed-header .user-info a {
  color: #272b41;
  font-weight: 500;
}

.fixed-header .custom-menu {
  margin: 0 0 1px;
}

.typing-text {
  color: #20c0f3;
  font-size: 12px;
  text-transform: lowercase;
}

.last-seen {
  color: #888888;
  display: block;
  font-size: 12px;
}

.custom-menu {
  margin-top: 6px;
}

.custom-menu.nav > li > a {
  color: #bbb;
  font-size: 26px;
  line-height: 32px;
  margin-left: 15px;
  padding: 0;
}

.custom-menu.navbar-nav > li > a:hover,
.custom-menu.navbar-nav > li > a:focus {
  background-color: transparent;
}

.custom-menu .dropdown-menu {
  left: auto;
  right: 0;
}

.call-contents {
  display: table-row;
  height: 100%;
}

.call-content-wrap {
  height: 100%;
  position: relative;
  width: 100%;
}

.voice-call-avatar {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 2;
  flex: 2;
}

.voice-call-avatar .call-avatar {
  width: 150px;
  height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin: 15px;
  padding: 3px;
  border-radius: 100%;
}

@media (max-width: 767.98px) {
  .voice-call-avatar .call-avatar {
    height: 100px;
    width: 100px;
  }
}

@media (max-width: 575.98px) {
  .voice-call-avatar .call-avatar {
    height: 80px;
    width: 80px;
  }
}

.voice-call-avatar .call-timing-count {
  padding: 5px;
}

.voice-call-avatar .username {
  font-size: 20px;
  font-weight: 500;
}

.call-duration {
  display: inline-block;
  font-size: 30px;
  margin-top: 4px;
  position: absolute;
  left: 0;
}

@media (max-width: 767.98px) {
  .call-duration {
    font-size: 24px;
  }
}

@media (max-width: 575.98px) {
  .call-duration {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
    position: inherit;
  }
}

.call-footer {
  background-color: #ffffff;
  border-top: 1px solid #f0f0f0;
  padding: 15px;
}

.call-icons {
  text-align: center;
  position: relative;
}

.call-icons .call-items {
  list-style: none;
  display: inline-block;
  border-radius: 5px;
  margin: 0;
  padding: 0;
}

.call-icons .call-items .call-item {
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}

.call-icons .call-items .call-item:last-child {
  margin-right: 0;
}

.call-icons .call-items .call-item a {
  color: #777;
  border: 1px solid #dddddd;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  font-size: 20px;
  border-radius: 50px;
}

.call-icons .call-items .call-item a i {
  width: 18px;
  height: 18px;
}

.call-icons .call-items li.end-call-new {
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}

.call-icons .call-items li.end-call-new a {
  background-color: #f06060;
  color: #ffffff;
  display: inline-block;
  line-height: 10px;
  text-transform: uppercase;
  border-radius: 50px;
  padding: 8px 25px;
}

.user-video {
  overflow: auto;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.user-video img,
.user-video video {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.my-video {
  z-index: 99;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.my-video ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.my-video ul li {
  float: left;
  width: 120px;
  margin-right: 10px;
}

@media (max-width: 767.98px) {
  .my-video ul li {
    width: 50px;
  }
}

.my-video ul li img {
  border: 3px solid #ffffff;
  border-radius: 6px;
}

.end-call {
  position: absolute;
  top: 7px;
  right: 0;
}

@media (max-width: 575.98px) {
  .end-call {
    margin-top: 10px;
    position: inherit;
  }
}

.end-call a {
  background-color: #f06060;
  color: #ffffff;
  display: inline-block;
  line-height: 10px;
  text-transform: uppercase;
  padding: 8px 25px;
  border-radius: 50px;
}

.call-users {
  z-index: 99;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.call-users ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.call-users ul li {
  float: left;
  width: 80px;
  margin-left: 10px;
}

@media (max-width: 767.98px) {
  .call-users ul li {
    width: 50px;
  }
}

.call-users ul li img {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 2px;
}

.call-users ul li a:hover .call-mute {
  display: block;
}

.call-mute {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  color: #ffffff;
  display: none;
  border: 3px solid transparent;
  border-radius: 6px;
  position: absolute;
  top: 0;
}

@media (max-width: 767.98px) {
  .call-mute {
    font-size: 20px;
    height: 50px;
    line-height: 50px;
    width: 50px;
  }
}

.call-details {
  margin: 10px 0 0;
}

.call-info {
  margin-left: 10px;
  width: 100%;
}

.call-user-details,
.call-timing {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.call-description {
  white-space: nowrap;
  vertical-align: bottom;
}

.call-timing {
  color: #727272;
  font-size: 14px;
  margin-top: 1px;
  overflow: hidden;
  white-space: pre;
}

.content-full {
  height: 100%;
  position: relative;
  width: 100%;
}

.video-window .fixed-header {
  margin: 0;
  padding: 0;
}

.video-window .fixed-header .nav > li > a {
  padding: 18px 15px;
}

.user-img {
  display: inline-block;
  position: relative;
}

span.call-duration.float-end {
  display: inline-block;
  font-size: 22px;
  margin-top: 0px;
  color: #40dda4;
  position: absolute;
  right: 0;
  left: unset;
}

.toggle-voice-video {
  display: inline-block;
  font-size: 30px;
  margin-top: 4px;
  position: absolute;
  left: 0;
}

.toggle-voice-video .switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
}

.toggle-voice-video .switch input {
  display: none;
}

.toggle-voice-video .slider {
  cursor: pointer;
  background-color: #2196f3;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.toggle-voice-video .slider:before {
  content: "";
  height: 26px;
  width: 26px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

.toggle-voice-video .slider:after {
  content: "VOICE";
  color: #ffffff;
  display: block;
  font-size: 10px;
  font-family: Verdana, sans-serif;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.toggle-voice-video input:checked + .slider {
  background-color: #2ab934;
}

.toggle-voice-video input:checked + .slider:before {
  transform: translateX(55px);
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
}

.toggle-voice-video input:checked + .slider:after {
  content: "VIDEO";
}

.toggle-voice-video input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.map-page .footer {
  display: none;
}

.map-page .header {
  z-index: 1030;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.map-page .content {
  padding-top: 115px;
}

@media (max-width: 991.98px) {
  .map-page .content {
    padding-top: 100px;
  }
}

.map-right {
  height: 100%;
  min-height: 100%;
  padding-right: 30px;
  z-index: 1029;
  position: fixed;
  top: 115px;
  right: 0;
  bottom: 0;
}

@media (max-width: 1199.98px) {
  .map-right {
    height: 450px;
    position: static;
    min-height: inherit;
    margin-bottom: 30px;
    padding-right: 15px;
  }
}

.map-right .available-info {
  margin-bottom: 0;
}

.map-listing {
  height: calc(100vh - 145px);
  width: 100%;
}

@media (max-width: 1199.98px) {
  .map-listing {
    height: 450px;
  }
}

.map-listing .profile-widget {
  width: 200px !important;
  border: 0;
  margin-bottom: 0;
  padding: 0;
}

.view-icons {
  display: inline-flex;
  float: right;
}

.view-icons a {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  color: #212529;
  font-size: 20px;
  text-align: center;
  margin-left: 10px;
  width: 44px;
  height: 44px;
  border-radius: 4px;
  padding: 4px 10px;
}

.view-icons a:hover {
  border-color: #20c0f3;
  color: #20c0f3;
}

.view-icons a.active {
  background-color: #20c0f3;
  border-color: #20c0f3;
  color: #ffffff;
}

.breadcrumb-bar {
  background-color: #15558d;
  padding: 15px 0;
}

.page-breadcrumb ol {
  background-color: transparent;
  font-size: 12px;
  margin-bottom: 0;
  padding: 0;
}

.page-breadcrumb ol li a {
  color: #ffffff;
}

.page-breadcrumb ol li.active {
  color: #ffffff;
}

.page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: #ffffff;
  font-size: 10px;
}

.breadcrumb-bar-one {
  background: #f2f6f6;
  background-repeat: no-repeat;
  padding-top: 70px;
  min-height: 300px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.breadcrumb-bar-one::after {
  content: "";
  background: url(../images/banner-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.breadcrumb-bar-one .inner-banner {
  margin: 0;
  padding: 70px 0;
}

.breadcrumb-bar-one .inner-banner .breadcrumb-title {
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 6px;
}

@media (max-width: 991.98px) {
  .breadcrumb-bar-one .inner-banner .breadcrumb-title {
    font-size: 36px;
  }
}

@media (max-width: 767.98px) {
  .breadcrumb-bar-one .inner-banner .breadcrumb-title {
    font-size: 26px;
  }
}

@media (max-width: 767.98px) {
  .breadcrumb-bar-one .inner-banner {
    margin: 0;
    padding: 50px 0;
  }
}

@media (max-width: 991.98px) {
  .breadcrumb-bar-one {
    padding-top: 40px;
    min-height: 260px;
  }
}

@media (max-width: 767.98px) {
  .breadcrumb-bar-one {
    min-height: auto;
  }
}

.breadcrumb-bar-one .page-breadcrumb ol {
  background-color: transparent;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.breadcrumb-bar-one .page-breadcrumb ol li a {
  font-weight: 500;
  color: #4b5563;
}

.breadcrumb-bar-one .page-breadcrumb ol li.active {
  color: #1f2937;
}

@media (max-width: 767.98px) {
  .breadcrumb-bar-one .page-breadcrumb ol {
    font-size: 15px;
  }
}

.breadcrumb-bar-one .page-breadcrumb .breadcrumb a:hover {
  color: #09e5ab;
}

.breadcrumb-bar-one .page-breadcrumb .breadcrumb-item + .breadcrumb-item {
  font-weight: 500;
  color: #1f2937;
  padding-left: 10px;
}

.breadcrumb-bar-one .breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  font-family: "feather";
  float: left;
  padding-right: 10px;
  color: #9ca3af;
  font-size: 14px;
  font-weight: 600;
}

.sort-by {
  float: right;
}

.sort-title {
  color: #ffffff;
  font-size: 14px;
  margin-right: 10px;
}

.sortby-fliter {
  display: inline-block;
  width: 120px;
}

.cal-icon {
  position: relative;
  width: 100%;
}

.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  position: absolute;
  top: 10px;
  right: 15px;
}

.custom_check {
  color: #666;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 0.9375rem;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
}

.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom_check input:checked ~ .checkmark {
  background-color: #ffffff;
}

.custom_check input:checked ~ .checkmark:after {
  display: block;
}

.custom_check .checkmark {
  height: 20px;
  width: 20px;
  border: 1px solid #dcdcdc;
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.custom_check .checkmark::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: none;
  color: #0de0fe;
  font-size: 11px;
  position: absolute;
  top: 0;
  left: 4px;
}

.custom_radio {
  color: #555;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 15px;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
}

.custom_radio input {
  position: absolute;
  opacity: 0;
}

.custom_radio input:checked ~ .checkmark:after {
  opacity: 1;
}

.custom_radio .checkmark {
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.custom_radio .checkmark:after {
  display: block;
  content: "";
  opacity: 0;
  width: 12px;
  height: 12px;
  background: #ff9b44;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 3px;
  left: 3px;
}

.radio_input .custom_radio + .custom_radio {
  margin-left: 15px;
}

.filter-widget {
  margin-bottom: 20px;
}

.filter-widget h4 {
  font-size: 1rem;
  margin-bottom: 15px;
}

.filter-widget .custom_check {
  line-height: 18px;
}

.btn-search .btn {
  background-color: #0de0fe;
  border: 1px solid #0de0fe;
  color: #ffffff;
  height: 46px;
  font-weight: 500;
  font-size: 16px;
}

@media (max-width: 767.98px) {
  .doctor-widget {
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
}

.doctor-widget .doc-name {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 3px;
}

@media (max-width: 767.98px) {
  .doctor-widget .doc-name {
    font-size: 18px;
  }
}

.doctor-widget .rating i {
  font-size: 14px;
}

.doctor-widget .average-rating {
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 767.98px) {
  .doc-info-left {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.doc-info-right {
  margin-left: auto;
  -ms-flex: 0 0 200px;
  flex: 0 0 200px;
  max-width: 200px;
}

@media (max-width: 767.98px) {
  .doc-info-right {
    margin-left: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.doctor-img {
  -ms-flex: 0 0 150px;
  flex: 0 0 150px;
  margin-right: 20px;
  width: 150px;
}

@media (max-width: 767.98px) {
  .doctor-img {
    margin: 0 auto 20px;
  }
}

.doctor-img img {
  border-radius: 5px;
}

.doc-department {
  color: #20c0f3;
  font-size: 14px;
  margin-bottom: 8px;
}

.doc-department img {
  width: 19px;
  display: inline-block;
  margin-right: 10px;
}

.doc-location {
  color: #757575;
  font-size: 14px;
  margin-bottom: 25px;
}

.doc-location a {
  color: #09e5ab;
  font-weight: 500;
}

.doc-speciality {
  font-size: 14px;
  color: #757575;
  margin-bottom: 15px;
}

.clinic-details {
  margin-bottom: 15px;
}

.clinic-details h5 {
  font-weight: normal;
  color: #757575;
  margin-bottom: 25px;
}

.clinic-details ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.clinic-details ul li {
  display: inline-block;
  padding-right: 5px;
}

.clinic-details ul li:last-child {
  padding-right: 0;
}

.clinic-details ul li a {
  display: inline-block;
}

.clinic-details ul li a img {
  width: 40px;
  border-radius: 5px;
}

.clinic-services {
  color: #272b41;
  font-size: 13px;
}

@media (max-width: 767.98px) {
  .clinic-services {
    display: none;
  }
}

.clinic-services span {
  border: 1px solid #cccccc;
  display: inline-block;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 4px;
}

.clinic-services span + span {
  margin-left: 5px;
}

.clini-infos {
  margin-bottom: 15px;
}

.clini-infos ul {
  font-size: 14px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.clini-infos ul li {
  display: block;
  line-height: 30px;
  color: #4e4852;
}

.clini-infos ul li i {
  font-size: 15px;
  min-width: 20px;
}

.clinic-booking a {
  background-color: #ffffff;
  border: 2px solid #20c0f3;
  color: #20c0f3;
  display: block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
  padding: 10px 15px;
  border-radius: 4px;
}

.clinic-booking a + a {
  margin-top: 15px;
}

.clinic-booking a.view-pro-btn:hover,
.clinic-booking a.view-pro-btn:focus {
  background: #20c0f3;
  color: #ffffff;
}

.clinic-booking a.apt-btn {
  background: #20c0f3;
  color: #ffffff;
}

@media (max-width: 479.98px) {
  .clinic-booking a.apt-btn {
    width: 100%;
  }
}

.clinic-booking a.apt-btn:hover,
.clinic-booking a.apt-btn:focus {
  background-color: #0db9f2;
  border-color: #0db9f2;
  color: #ffffff;
}

@media (max-width: 479.98px) {
  .clinic-booking a.view-pro-btn {
    width: 100%;
    margin-bottom: 15px;
  }
}

.load-more {
  margin-bottom: 30px;
}

.filter-contents {
  border: 2px solid #f3f4f6;
  border-radius: 10px;
}

@media (max-width: 991.98px) {
  .filter-contents {
    margin-bottom: 24px;
  }
}

.filter-header {
  background: #f2f6f6;
  border-radius: 10px 10px 0px 0px;
  padding: 15px 20px;
}

.filter-header .filter-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.filter-details {
  padding: 20px;
}

.filter-grid {
  padding-bottom: 25px;
}

.filter-grid h4 {
  margin-bottom: 0;
}

.filter-grid h4 a {
  font-weight: 600;
  font-size: 16px;
  color: #1f2937;
  position: relative;
}

.filter-grid h4 a:hover {
  color: #272b41;
}

.filter-grid h4 a::before {
  content: "\e92e";
  font-family: "feather";
  color: #1f2937;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  right: 0;
  line-height: normal;
}

.filter-grid h4 a:not(.collapsed)::before {
  content: "\e931";
  font-family: "feather";
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.filter-collapse {
  padding-top: 20px;
}

.filter-collapse ul {
  padding: 0;
  margin: 0;
  list-style: 0;
}

.filter-collapse ul li {
  display: block;
  margin-bottom: 10px;
}

.filter-collapse ul li:last-child {
  margin-bottom: 0;
}

.filter-collapse ul li .custom_check {
  padding-left: 26px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #6b7280;
  margin-bottom: 0;
}

.filter-collapse ul li .custom_check.custom_check .checkmark {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  height: 17px;
  width: 17px;
  top: 3px;
}

.filter-collapse ul li .custom_check input:checked ~ .checkmark {
  background: #ffffff;
  border-color: #0e82fd;
}

.filter-collapse ul li .custom_check .checkmark::after {
  content: "";
  background: #0e82fd;
  width: 10px;
  height: 10px;
  top: 3px;
  left: 3px;
  border-radius: 3px;
}

.filter-collapse ul li .custom_check .online-icon {
  font-size: 20px;
  margin-right: 10px;
  margin-left: 5px;
}

.rating_custom_check {
  width: 100%;
}

.rating_custom_check .rating {
  width: 100%;
}

.rating_custom_check .rating .rating-count {
  font-weight: 500;
  float: right;
  color: #9ca3af;
}

.filter-content-slider p {
  color: #1f2937;
  margin-bottom: 15px;
}

.filter-content-slider p span {
  float: right;
}

.price-wrapper {
  margin: 15px 0 0;
  font-size: 14px;
}

.price-wrapper h6 {
  font-size: 14px;
  font-weight: 500;
  color: #6b7280;
  margin-bottom: 0;
}

.price-wrapper h6 span {
  font-weight: 600;
  color: #1f2937;
}

.slider-wrapper .ui-slider-handle {
  background: #0e82fd;
  border: 0;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(124, 124, 124, 0.25);
  width: 18px;
  height: 18px;
  box-shadow: 0 0px 0px 4px #0e82fd;
  width: 12px;
  height: 12px;
  background: #ffffff;
  margin: 0;
}

#price-range.ui-slider-horizontal {
  height: 6px;
  border: 0;
  border-radius: 25px;
  background: #e9ecf1;
}

.ui-widget-header {
  background: #0e82fd;
}

.filter-btn .btn {
  font-weight: 600;
  font-size: 15px;
  padding: 7px 15px;
  border-radius: 6px;
}

.doctor-filter-info {
  padding-bottom: 30px;
}

@media (max-width: 767.98px) {
  .doctor-filter-info {
    display: none;
  }
}

.doctors-found {
  padding-bottom: 12px;
}

.doctors-found p {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
}

.doctors-found p span {
  color: #1f2937;
}

@media (max-width: 1399.98px) {
  .doctors-found p {
    font-size: 14px;
  }
}

.doctor-filter-availability p {
  font-weight: 600;
  color: #4b5563;
  margin-right: 6px;
  margin-bottom: 0;
}

.doctor-filter-option {
  flex-shrink: 0;
}

.doctor-filter-sort {
  padding-right: 15px;
}

.doctor-filter-sort:last-child {
  padding-right: 0;
}

.doctor-filter-sort p {
  color: #8c97b0;
  margin-right: 15px;
  margin-bottom: 0;
}

.doctor-filter-sort .filter-today {
  margin-right: 0;
}

.doctor-filter-sort .filter-today i {
  font-size: 18px;
  margin-right: 10px;
}

.doctor-filter-sort .nav li {
  margin-right: 8px;
}

.doctor-filter-sort .nav li:last-child {
  margin-right: 0;
}

.doctor-filter-sort .nav li a {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 2px solid #f3f4f6;
  border-radius: 7px;
  color: #6b7280;
}

.doctor-filter-sort .nav li a:hover {
  background: #0e82fd;
  border: 2px solid #0e82fd;
  color: #ffffff;
}

.doctor-filter-sort .nav li a:hover img {
  filter: invert(1) brightness(100);
}

.doctor-filter-sort .nav li a.active {
  background: #0e82fd;
  border: 2px solid #0e82fd;
  color: #ffffff;
}

.doctor-filter-sort .nav li a.active img {
  filter: invert(1) brightness(100);
}

.doctor-filter-select {
  width: 94px;
}

.doctor-filter-select .select2-container .select2-selection--single {
  background-color: #f2f6f6;
  border: 1px solid #f3f4f6;
  height: 32px;
}

.doctor-filter-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 32px;
  padding-left: 10px;
  font-weight: 600;
  color: #1f2937;
}

.doctor-filter-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 30px;
}

.doctor-card {
  border: 2px solid #f3f4f6;
  border-radius: 10px;
}

.doctor-widget-one .doc-name {
  margin-bottom: 4px;
  font-size: 20px;
}

.doctor-widget-one .doc-name a {
  color: #1f2937;
}

.doctor-widget-one .doc-name i {
  margin-left: 8px;
  font-size: 16px;
  color: #22c550;
}

@media (max-width: 991.98px) {
  .doctor-widget-one .doc-name {
    font-size: 18px;
  }
}

@media (max-width: 767.98px) {
  .doctor-widget-one {
    text-align: center;
    flex-direction: column;
  }
}

@media (max-width: 767.98px) {
  .doctor-widget-one .doc-info-left {
    flex-direction: column;
  }
}

.doctor-widget-one .doctor-img {
  -ms-flex: 0 0 164px;
  flex: 0 0 164px;
  margin-right: 20px;
  width: 164px;
  height: 164px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.doctor-widget-one .doctor-img img {
  width: 164px;
  height: 164px;
  border-radius: 10px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.doctor-widget-one .doctor-img:hover img {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}

@media (max-width: 767.98px) {
  .doctor-widget-one .doctor-img {
    margin: 0 auto 20px;
    padding: 0;
  }
}

.doctor-widget-one .favourite-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.doctor-widget-one .favourite-btn .favourite-icon {
  background-color: #ffffff;
  border: 1px solid #edf1f1;
  width: 30px;
  height: 30px;
  font-size: 14px;
  border-radius: 50px;
  color: #9ca3af;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.doctor-widget-one .favourite-btn .favourite-icon:hover {
  color: #fb1612;
}

.doctor-widget-one .favourite-btn .favourite-icon.favourite {
  animation: size 0.4s;
  color: #ff3333;
}

.doctor-widget-one .doc-speciality {
  font-size: 12px;
  color: #9ca3af;
  margin-bottom: 20px;
}

.doctor-widget-one .clinic-details {
  padding-bottom: 14px;
}

.doctor-widget-one .doc-info-cont .reviews-ratings p {
  font-size: 12px;
}

.doctor-widget-one .doc-info-cont .reviews-ratings p span {
  font-size: 14px;
  margin: 0 4px 0 0;
  padding: 2px 5px;
}

.doctor-widget-one .doc-location {
  font-weight: 500;
  margin-bottom: 14px;
}

.doctor-widget-one .doc-location:last-child {
  margin-bottom: 0;
}

.doctor-widget-one .doc-location span {
  color: #1f2937;
  margin-right: 3px;
}

.doctor-widget-one .doc-location i {
  margin-right: 10px;
  font-size: 18px;
  color: #9ca3af;
}

@media (max-width: 767.98px) {
  .doctor-widget-one .doc-location i {
    margin: 0 0 5px 0;
    padding: 0;
  }
}

.doctor-widget-one .doc-location a {
  font-weight: 500;
  color: #0e82fd;
  margin-left: 8px;
}

.doctor-widget-one .doc-location a:hover {
  color: #000000;
}

@media (max-width: 767.98px) {
  .doctor-widget-one .doc-location {
    flex-direction: column;
  }
}

.doctor-widget-one .doc-info-right {
  margin-left: auto;
  -ms-flex: 0 0 240px;
  flex: 0 0 240px;
  max-width: 240px;
}

@media (max-width: 991.98px) {
  .doctor-widget-one .doc-info-right {
    -ms-flex: 0 0 270px;
    flex: 0 0 270px;
    max-width: 270px;
  }
}

@media (max-width: 767.98px) {
  .doctor-widget-one .doc-info-right {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
  }
}

.doctor-widget-one .doc-info-details {
  margin-left: auto;
  max-width: 230px;
}

@media (max-width: 767.98px) {
  .doctor-widget-one .doc-info-details {
    margin: 0 auto 0;
    padding: 0;
  }
}

.doctor-widget-one .clini-infos {
  margin-bottom: 15px;
}

.doctor-widget-one .clini-infos ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.doctor-widget-one .clini-infos ul li {
  color: #6b7280;
  margin-bottom: 8px;
}

.doctor-widget-one .clini-infos ul li:last-child {
  margin-bottom: 0;
}

.doctor-widget-one .clini-infos ul li .available-date {
  border-radius: 5px;
  padding: 3px 15px;
  font-weight: normal;
}

.doctor-widget-one .clini-infos ul li .available-today {
  background: #dcfce5;
  color: #22c550;
  font-weight: 500;
}

.doctor-widget-one .clini-infos ul li .available-tomorrow {
  background: #fff6d8;
  color: #f3db00;
}

.doctor-widget-one .clini-infos ul li .available-icon {
  font-size: 18px;
  color: #9ca3af;
  margin-right: 10px;
}

.doctor-widget-one .clini-infos ul li .votes {
  font-size: 12px;
  color: #9ca3af;
  margin-left: 5px;
}

.doctor-widget-one .clini-infos ul li .available-info-icon {
  font-size: 14px;
  color: #9ca3af;
  margin-left: 5px;
}

@media (max-width: 767.98px) {
  .doctor-widget-one .clini-infos ul li {
    display: block;
  }
}

@media (max-width: 767.98px) {
  .doctor-widget-one .clini-infos {
    margin: 20px 0 15px 0;
    padding: 0;
  }
}

.doctor-widget-one .clinic-booking .btn {
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid #09e5ab;
  padding: 6px 15px;
}

.doctor-widget-one .clinic-booking .btn-primary-light {
  color: #09e5ab;
  background: #ffffff;
  box-shadow: inset 0 0 0 0 #09e5ab;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
  margin-top: 8px;
}

.doctor-widget-one .clinic-booking .btn-primary-light:hover {
  color: #ffffff;
  background: #09e5ab;
  box-shadow: inset 0 0 0 50px #09e5ab;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.doctor-widget-one .clinic-booking.book-appoint .btn-primary {
  color: #fff;
  background-color: #0e82fd;
  border: 2px solid #0e82fd;
  box-shadow: inset 0 0 0 0 #fff;
}

.doctor-widget-one .clinic-booking.book-appoint .btn-primary:hover {
  color: #0e82fd;
  background-color: #ffffff;
  border-color: #0e82fd;
  box-shadow: inset 0 0 0 50px #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.doctor-widget-one .clinic-booking.book-appoint .btn-primary-light {
  color: #0e82fd;
  background: #ffffff;
  box-shadow: inset 0 0 0 0 #0e82fd;
  border: 2px solid #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.doctor-widget-one .clinic-booking.book-appoint .btn-primary-light:hover {
  color: #fff;
  background-color: #0e82fd;
  border: 2px solid #0e82fd;
  box-shadow: inset 0 0 0 0 #fff;
}

.doctor-profile-widget.doc-grid {
  margin-bottom: 24px;
}

.doctor-profile-widget.doc-grid .doc-pro-img .doctor-profile-img {
  margin: 15px 15px 0;
}

.doctor-profile-widget.doc-grid .doc-pro-img .doctor-profile-img img {
  border-radius: 10px;
}

.doctor-profile-widget.doc-grid .doc-pro-img .favourite-btn {
  top: 15px;
  right: 25px;
}

.doctor-profile-widget.doc-grid .doc-pro-img .reviews-ratings {
  position: absolute;
  top: 15px;
  left: 25px;
}

.doctor-profile-widget.doc-grid .doc-pro-img .reviews-ratings p span {
  min-width: 52px;
  font-size: 14px;
  margin: 0;
  padding: 2px 3px;
}

.doctor-profile-widget.doc-grid .doc-content {
  padding: 15px;
}

.doctor-profile-widget.doc-grid .doc-content .review-price p {
  font-weight: 500;
  font-size: 18px;
  color: #28283c;
}

.doctor-profile-widget.doc-grid .doc-content .review-price p span {
  font-size: 13px;
  color: #6b7280;
}

@media (max-width: 1399.98px) {
  .doctor-profile-widget.doc-grid .doc-content .review-price p {
    font-size: 14px;
  }
}

.doctor-profile-widget.doc-grid .doc-content .doc-pro-name {
  width: 100%;
  overflow: hidden;
}

.doctor-profile-widget.doc-grid .doc-content .doc-pro-name h4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 2px;
}

.doctor-profile-widget.doc-grid .doc-content .doc-pro-name h4 a {
  margin-right: 6px;
}

.doctor-profile-widget.doc-grid .doc-content .doc-pro-name h4 i {
  color: #22c550;
}

.doctor-profile-widget.doc-grid .doc-content .doc-pro-location p {
  margin-bottom: 10px;
  font-size: 14px;
}

.doctor-profile-widget.doc-grid .doc-content .doc-pro-location p:last-child {
  margin-bottom: 0;
}

.doctor-profile-widget.doc-grid .doc-content .doc-pro-location p i {
  color: #9ca3af;
  font-size: 18px;
}

.doctor-profile-widget.doc-grid .doc-content .doc-pro-location p span {
  color: #1f2937;
  margin-right: 2px;
}

.doctor-profile-widget.doc-grid .favourite-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.doctor-profile-widget.doc-grid .favourite-btn .favourite-icon {
  background-color: #ffffff;
  border: 1px solid #edf1f1;
  width: 30px;
  height: 30px;
  font-size: 14px;
  border-radius: 50px;
  color: #484848;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.doctor-profile-widget.doc-grid .favourite-btn .favourite-icon:hover {
  color: #fb1612;
}

.doctor-profile-widget.doc-grid .favourite-btn .favourite-icon.favourite {
  animation: size 0.4s;
  color: #ff3333;
}

.filter-collapse .more-view {
  color: #0e82fd;
}

.more-view {
  color: #0e82fd;
  font-size: 14px;
  font-weight: 500;
}

.fill-more {
  height: 97px;
  overflow: hidden;
  border-bottom: 1px solid #f3f4f6;
  margin-bottom: 10px !important;
}

.apply-btn .btn-primary {
  color: #fff;
  background-color: #0e82fd;
  border: 2px solid #0e82fd;
  box-shadow: inset 0 0 0 0 #ffffff;
  display: grid;
}

.apply-btn .btn-primary:hover {
  color: #0e82fd;
  background-color: #ffffff;
  border-color: #0e82fd;
  box-shadow: inset 0 0 0 50px #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.apply-btn .btn-outline-primary {
  color: #0e82fd;
  background: #ffffff;
  box-shadow: inset 0 0 0 0 #0e82fd;
  border: 2px solid #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
  display: grid;
}

.apply-btn .btn-outline-primary:hover {
  color: #ffffff;
  background-color: #0e82fd;
  border: 2px solid #0e82fd;
  box-shadow: inset 0 0 0 0 #ffffff;
}

.map-right {
  height: 100%;
  min-height: 100%;
  padding-right: 30px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1029;
  display: none;
}

.map-right .available-info {
  margin-bottom: 0;
}

@media (max-width: 1199.98px) {
  .map-right {
    position: static;
    height: 450px;
  }
}

.map-right.grid-list-map {
  display: block;
  padding-right: 0px;
  top: 80px;
}

.map-listing {
  height: calc(100vh - 0px);
  width: 100%;
}

.map-listing .profile-widget {
  width: 320px;
  padding: 0;
  border: 0;
  margin-bottom: 0;
}

@media (max-width: 1199.98px) {
  .map-listing {
    height: 450px;
  }
}

.info-widget {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.card-label > label {
  background-color: #ffffff;
  color: #959595;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  margin: 6px auto auto 8px;
  padding: 0 7px;
}

@media (max-width: 991.98px) {
  .card-label > label {
    font-size: 12px;
  }
}

.card-label > input {
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  display: block;
  height: 50px;
  margin-top: -13px;
  -webkit-transition: border-color 0.3s;
  -ms-transition: border-color 0.3s;
  transition: border-color 0.3s;
  width: 100%;
  border-radius: 4px;
  padding: 5px 15px 0;
}

.exist-customer a {
  color: #20c0f3;
  font-weight: 500;
}

.payment-widget .payment-list + .payment-list {
  margin-bottom: 15px;
}

.payment-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  color: #272b41;
  text-transform: capitalize;
}

.payment-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.payment-radio .checkmark {
  width: 19px;
  height: 19px;
  border: 2px solid #dddddd;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  margin: 3px 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
}

.payment-radio .checkmark::after {
  content: "";
  width: 9px;
  height: 9px;
  background-color: #20c0f3;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.1);
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  top: 3px;
  left: 3px;
}

.payment-radio input:checked ~ .checkmark {
  border-color: #20c0f3;
}

.payment-radio input:checked ~ .checkmark::after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
}

.booking-date {
  list-style: none;
  padding: 0;
}

.booking-date li {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #272b41;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.booking-date li span {
  float: right;
  color: #757575;
  font-weight: 400;
  font-size: 15px;
}

.booking-fee {
  list-style: none;
  padding: 0;
}

.booking-fee li {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #272b41;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.booking-fee li span {
  float: right;
  color: #757575;
  font-weight: 400;
  font-size: 15px;
}

.booking-total {
  border-top: 1px solid #e4e4e4;
  margin-top: 20px;
  padding-top: 20px;
}

.booking-total ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.booking-total ul li span {
  font-size: 18px;
  font-weight: 700;
  color: #272b41;
}

@media (max-width: 767.98px) {
  .booking-total ul li span {
    font-size: 14px;
  }
}

.booking-total ul li .total-cost {
  color: #20c0f3;
  font-size: 16px;
  float: right;
}

@media (max-width: 767.98px) {
  .booking-total ul li .total-cost {
    font-size: 14px;
  }
}

#calendar-events {
  background-color: #fcfcfc;
}

.calendar-events {
  border: 1px solid transparent;
  cursor: move;
  padding: 10px 15px;
}

.calendar-events:hover {
  border-color: #e9e9e9;
  background-color: #ffffff;
}

.calendar-events i {
  margin-right: 8px;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5rem;
}

@media (max-width: 479.98px) {
  .fc-toolbar.fc-header-toolbar .fc-right {
    margin-bottom: 10px;
  }
}

@media (max-width: 479.98px) {
  .fc-toolbar.fc-header-toolbar .fc-left {
    margin-bottom: 10px;
  }
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:hover,
.fc-toolbar button:focus {
  z-index: 0;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-day-grid-event .fc-time {
  font-family: "Roboto", sans-serif;
}

.fc-day {
  background: #ffffff;
}

.fc th.fc-widget-header {
  background: #eeeeee;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 10px 0;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f3f3f3;
}

.fc-unthemed .fc-today {
  background: #ffffff;
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
  padding: 2px 5px;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  height: auto !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #f3f3f3;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #20c0f3 !important;
  color: #ffffff !important;
  text-shadow: none !important;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-event {
  border: none;
  color: #ffffff !important;
  cursor: move;
  font-size: 13px;
  text-align: center;
  border-radius: 2px;
  margin: 1px 7px;
  padding: 5px 5px;
}

.event-form .input-group .form-control {
  height: 40px;
}

.comp-header {
  margin-bottom: 1.875rem;
}

.comp-header .comp-title {
  color: #272b41;
}

.line {
  background-color: #20c0f3;
  height: 2px;
  margin: 0;
  width: 60px;
}

.comp-buttons .btn {
  margin-bottom: 5px;
}

.pagination-box .pagination {
  margin-top: 0;
}

.comp-dropdowns .btn-group {
  margin-bottom: 5px;
}

.progress-example .progress {
  margin-bottom: 1.5rem;
}

.progress-xs {
  height: 4px;
}

.progress-sm {
  height: 15px;
}

@media (max-width: 575.98px) {
  .progress-lg .page-link {
    font-size: 16px;
    padding: 0.5rem 0.625rem;
  }
}

.progress.progress-sm {
  height: 6px;
}

.progress.progress-md {
  height: 8px;
}

.progress.progress-lg {
  height: 18px;
}

.row.row-sm {
  margin-left: -3px;
  margin-right: -3px;
}

.row.row-sm > div {
  padding-left: 3px;
  padding-right: 3px;
}

@media (max-width: 575.98px) {
  .pagination-lg .page-link {
    font-size: 16px;
    padding: 0.5rem 0.625rem;
  }
}

.custom-edit-service textarea.form-control {
  min-height: 200px;
}

.custom-edit-service .service-upload {
  border: 1px solid #dcdcdc;
  border-radius: 0.25rem;
  text-align: center;
  background-color: #ffffff;
  position: relative;
  margin: 0 0 30px;
  padding: 70px 0;
}

.custom-edit-service .service-upload i {
  font-size: 50px;
  color: #858585;
}

.custom-edit-service .service-upload span {
  font-size: 15px;
  color: #858585;
  margin-top: 14px;
  display: block;
}

.custom-edit-service .service-upload input[type="file"] {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}

.upload-wrap li {
  margin: 10px;
  list-style: none;
}

.upload-wrap li:first-child {
  margin-left: 0;
}

.invoice-content {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  margin-bottom: 30px;
  border-radius: 4px;
  padding: 30px;
}

@media (max-width: 575.98px) {
  .invoice-content {
    padding: 1.25rem;
  }
}

.invoice-item .invoice-logo {
  margin-bottom: 30px;
}

.invoice-item .invoice-logo img {
  width: auto;
  max-height: 52px;
}

.invoice-item .invoice-text {
  padding-top: 42px;
  padding-bottom: 36px;
}

.invoice-item .invoice-text h2 {
  color: #272b41;
  font-size: 36px;
  font-weight: 600;
}

.invoice-item .invoice-details {
  text-align: right;
  color: #757575;
  font-weight: 500;
}

@media (max-width: 767.98px) {
  .invoice-item .invoice-details {
    text-align: left;
  }
}

.invoice-item .invoice-details strong {
  color: #272b41;
}

.invoice-item .invoice-details-two {
  text-align: left;
}

.invoice-item .customer-text {
  font-size: 18px;
  color: #272b41;
  font-weight: 700;
  margin-bottom: 8px;
  display: block;
}

@media (max-width: 767.98px) {
  .invoice-item .customer-text {
    font-size: 16px;
  }
}

.invoice-info {
  margin-bottom: 30px;
}

.invoice-info p {
  margin-bottom: 0;
}

.invoice-info.invoice-info2 {
  text-align: right;
}

@media (max-width: 767.98px) {
  .invoice-info.invoice-info2 {
    text-align: left;
  }
}

.invoice-info h5 {
  font-size: 16px;
  font-weight: 500;
}

.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
  color: #272b41;
  font-weight: 700;
  line-height: inherit;
  padding: 10px 20px;
}

.invoice-table tr td,
.invoice-table-two tr td {
  color: #757575;
  font-weight: 500;
}

.invoice-table-two {
  margin-bottom: 0;
}

.invoice-table-two tr th,
.invoice-table-two tr td {
  border-top: 0;
}

.invoice-table-two tr td {
  text-align: right;
}

.other-info {
  margin-top: 10px;
}

.terms-text {
  margin-bottom: 20px;
}

.terms-text h4 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.terms-text p {
  color: #666;
  display: inline-block;
  font-size: 16px;
}

.account-card {
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  margin: 0 0 30px;
  padding: 10px;
}

.account-card span {
  font-size: 24px;
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.info-list {
  margin-bottom: 10px;
}

.info-list .title {
  color: #272b41;
  font-weight: 500;
}

.info-list .text {
  color: #757575;
  display: block;
  overflow: hidden;
}

.banner-wrapper {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 991.98px) {
  .banner-wrapper {
    max-width: 720px;
  }
}

.banner-wrapper .search-box-3 {
  background: #1e5c92;
  border-radius: 10px;
  position: absolute;
  margin: -50px 0 0;
  padding: 40px 20px;
}

@media (max-width: 991.98px) {
  .banner-wrapper .search-box-3 {
    display: none;
  }
}

.banner-wrapper .search-box-3 .search-location {
  -ms-flex: 0 0 240px;
  flex: 0 0 240px;
  margin-right: 12px;
  position: relative;
  width: 240px;
}

.banner-wrapper .search-box-3 .search-location .form-control {
  background: #ffffff url(../images/location.png) no-repeat 10px center;
}

.banner-wrapper .search-box-3 .form-group {
  margin-bottom: 0px;
}

.banner-wrapper .search-box-3 .form-control {
  border: 1px solid #cccccc;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding-left: 35px;
}

.banner-wrapper .search-box-3 .search-info {
  -ms-flex: 0 0 490px;
  flex: 0 0 490px;
  margin-right: 12px;
  position: relative;
  width: 490px;
}

.banner-wrapper .search-box-3 .search-info .form-control {
  background: #ffffff url(../images/search.png) no-repeat 10px center;
}

.banner-wrapper .search-box-3 .form-control {
  background: #ffffff url(../images/search.png) no-repeat 10px center;
}

.banner-wrapper .search-box-3 .search-btn {
  width: 46px;
  -ms-flex: 0 0 46px;
  flex: 0 0 46px;
  height: 46px;
}

.banner-wrapper .search-box-3 .search-btn span {
  display: none;
  font-weight: 500;
}

.btn-search {
  background: #ffffff;
  border: 1px solid #ffffff;
  color: #1e5c92;
}

.form-custom i {
  color: #dd2d4e;
  position: absolute;
  top: 35%;
  right: 15px;
}

.doctor-search-form select {
  border: 1px solid rgba(16, 222, 253, 0.1);
  box-shadow: 0px 4px 4px rgba(237, 237, 237, 0.25);
  border-radius: 5px;
}

.doctor-search-form input {
  border: 1px solid rgba(16, 222, 253, 0.1);
  box-shadow: 0px 4px 4px rgba(237, 237, 237, 0.25);
  border-radius: 5px;
}

.doctor-search-form input,
.doctor-search-form select {
  background: #ffffff;
  border-radius: 130px;
  min-height: 60px;
  border: 0;
}

.doctor-search-section {
  padding-top: 95px;
  position: relative;
  background: #ebfaec;
  background-position: 50% 0;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .doctor-search-section {
    padding-top: 85px;
  }
}

.doctor-search-section .doctor-search {
  padding-bottom: 15px;
}

@media (max-width: 991.98px) {
  .doctor-search-section .doctor-search {
    padding: 30px 0;
  }
}

.doctor-search-section .banner-header h2 {
  color: #2a9d36;
  font-size: 32px;
  font-weight: 600;
  line-height: 54px;
}

@media (max-width: 767.98px) {
  .doctor-search-section .banner-header h2 {
    font-size: 26px;
    line-height: 40px;
  }
}

.doctor-search-section .doctor-search-form select,
.doctor-search-section .doctor-search-form input {
  border: 1px solid rgba(16, 222, 253, 0.1);
  box-shadow: 0px 4px 4px rgba(237, 237, 237, 0.25);
  border-radius: 5px;
}

@media (max-width: 991.98px) {
  .doctor-search-section .dr-img {
    display: none;
  }
}

.clinics-section {
  padding: 60px 0 40px;
  background: #ffffff;
}

@media (max-width: 991.98px) {
  .clinics-section {
    padding: 40px 0 25px;
  }
}

@media (max-width: 767.98px) {
  .clinics-section {
    padding: 30px 0 10px;
  }
}

.clinics-section .clinic-item:hover .clinics-card .clinics-img:after {
  background: rgba(45, 226, 253, 0.9);
  transition: all 0.5s;
}

.clinics-section .clinic-item:hover .clinics-icon {
  transform: translate(0, -180%);
  -webkit-transform: translate(0, -180%);
  -ms-transform: translate(0, -180%);
}

.clinics-section .clinic-item:hover .clinics-icon i {
  color: #1c5b90;
  background: #ffffff;
}

.clinics-section .clinics-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.clinics-section .clinics-card .clinics-img {
  position: relative;
}

.clinics-section .clinics-card .clinics-img:after {
  content: "";
  background: rgba(28, 91, 144, 0.9);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.clinics-section .clinics-card .clinics-info {
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.clinics-section .clinics-card .clinics-info img {
  width: 60px !important;
  margin: auto;
  margin-bottom: 10px;
}

.clinics-section .clinics-card .clinics-info span {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}

.clinics-section .clinics-icon {
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.clinics-section .clinics-icon i {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 35px;
  background: #10defd;
  color: #ffffff;
  margin: auto;
  display: block;
}

.section-heading {
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .section-heading {
    margin-bottom: 15px;
  }
}

.section-heading h2 {
  font-weight: 700;
  font-size: 26px;
  color: #1c5b90;
}

@media (max-width: 767.98px) {
  .section-heading h2 {
    font-size: 20px;
  }
}

.section-heading p {
  font-size: 14px;
  color: #757578;
  margin-bottom: 0;
}

.section-heading.bg-area h2 {
  color: #ffffff;
}

.section-heading.bg-area p {
  color: #ffffff;
}

.specialities-section {
  padding: 80px 0 55px;
  position: relative;
  background: url(../images/specialist-bg.jpg);
  background-position: 50% 0;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .specialities-section {
    padding: 60px 0 35px;
  }
}

@media (max-width: 767.98px) {
  .specialities-section {
    padding: 40px 0 15px;
  }
}

.specialities-section:after {
  content: "";
  background: rgba(25, 69, 107, 0.9);
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.specialities-section .specialist-card {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(183, 183, 183, 0.25);
  border-radius: 163px;
  align-items: center;
  margin: 0 0 25px;
  padding: 15px;
}

.specialities-section .specialist-card:hover {
  background: #e0f2ff;
}

.specialities-section .specialist-card:hover .specialist-img {
  background: #10defd;
  transition: all 0.5s;
}

.specialities-section .specialist-card:hover .specialist-nav i {
  background: #10defd;
  color: #ffffff;
}

.specialities-section .specialist-card .specialist-img {
  background: #1b5d92;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin: 0 10px 0 0;
  padding: 10px;
}

.specialities-section .specialist-card .specialist-info p {
  font-weight: 500;
  color: #000000;
  margin-bottom: 0;
}

.specialities-section .specialist-card .specialist-info h4 {
  font-weight: 600;
  color: #274782;
  margin-bottom: 0;
}

.specialities-section .specialist-card .specialist-nav i {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 35px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(183, 183, 183, 0.25);
  color: #d84153;
  margin: auto;
  display: block;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.shapes {
  position: absolute;
  width: 100%;
}

@media (max-width: 991.98px) {
  .shapes {
    display: none;
  }
}

.shapes .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
}

.shapes .shape-2 {
  position: absolute;
  top: 0;
  right: 0;
}

.shapes .shape-3 {
  position: absolute;
  top: -110px;
  left: 0;
}

.shapes .shape-4 {
  position: absolute;
  top: -110px;
  right: 0;
}

.our-doctors-section {
  padding: 60px 0;
  background: #ffffff;
}

@media (max-width: 991.98px) {
  .our-doctors-section {
    padding: 40px 0;
  }
}

@media (max-width: 767.98px) {
  .our-doctors-section {
    padding: 30px 0;
  }
}

.our-doctors-section .our-doctors-card {
  background: rgba(232, 246, 255, 0.4);
  border: 1px solid rgba(231, 237, 255, 0.5);
  border-radius: 10px;
  padding: 10px;
}

.our-doctors-section .our-doctors-card .doctors-header {
  position: relative;
}

.our-doctors-section .our-doctors-card .doctors-header img {
  border-radius: 10px 10px 0 0;
}

.our-doctors-section .our-doctors-card .doctors-header .img-overlay {
  background: #1b5d92;
  border-radius: 110px;
  padding: 5px 10px;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.our-doctors-section .our-doctors-card .doctors-body {
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
  padding: 10px;
}

.our-doctors-section .our-doctors-card .doctors-body .rating {
  font-size: 12px;
}

.our-doctors-section .our-doctors-card .doctors-body .rating i.filled {
  color: #ffc001;
}

.our-doctors-section .our-doctors-card .doctors-body .rating .average-ratings {
  background: #1c5b90;
  border-radius: 70px;
  color: #ffffff;
  padding: 1px 15px;
  margin-left: 10px;
}

.our-doctors-section .our-doctors-card .doctors-body h4 {
  color: #1c1c1c;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.our-doctors-section .our-doctors-card .doctors-body h4:hover {
  color: #0e82fd;
}

.our-doctors-section .our-doctors-card .doctors-body p {
  font-weight: 500;
  margin-bottom: 0;
  font-size: 11px;
}

.our-doctors-section .our-doctors-card .doctors-body .location {
  padding: 20px 0;
}

.our-doctors-section .our-doctors-card .doctors-body .location p {
  font-size: 13px;
  color: #000000;
}

.our-doctors-section .our-doctors-card .doctors-body .location p i {
  color: #1c5b90;
}

.our-doctors-section .our-doctors-card .doctors-body .view-btn {
  background: #e8f6ff;
  border: 1px solid #e8f6ff;
  box-sizing: border-box;
  border-radius: 30px;
  color: rgba(41, 68, 129, 0.9);
  padding: 10px 0;
}

.our-doctors-section .our-doctors-card .doctors-body .view-btn:hover {
  background-color: #0e82fd;
  color: #ffffff;
}

.our-doctors-section .our-doctors-card .doctors-body .book-btn {
  background: #10defd;
  border-radius: 30px;
  padding: 10px 0;
  border: 0;
}

.our-doctors-section .our-doctors-card .doctors-body .book-btn:hover {
  background-color: #0e82fd;
}

.clinic-features-section {
  position: relative;
  background: url(../images/featured-bg.jpg);
  background-position: 50% 0;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 0;
}

@media (max-width: 991.98px) {
  .clinic-features-section {
    padding: 40px 0;
  }
}

@media (max-width: 767.98px) {
  .clinic-features-section {
    padding: 30px 0;
  }
}

.clinic-features-section .clinic-features {
  position: relative;
}

.clinic-features-section .clinic-features img {
  border-radius: 10px;
}

.clinic-features-section .clinic-feature-overlay {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 20px;
}

.clinic-features-section .clinic-feature-overlay .img-overlay {
  background: #20c0f3;
  border-radius: 60px;
  padding: 10px 15px;
  text-transform: uppercase;
  color: #e6f4fd;
  font-weight: 600;
  min-width: 150px;
  display: inline-block;
}

.clinic-features-section .clinic-feature-overlay .img-overlay:hover {
  background-color: #0e82fd;
}

.section-search-3 {
  position: relative;
  background: url(../images/banner-3.jpg);
  background-position: 50% 0;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  min-height: 450px;
  padding-top: 85px;
}

.section-search-3:after {
  content: "";
  background: rgba(24, 96, 202, 0.9);
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.section-search-3 .banner-info {
  padding: 130px 0 190px;
}

@media (max-width: 991.98px) {
  .section-search-3 .banner-info {
    padding: 60px 0 130px;
  }
}

@media (max-width: 767.98px) {
  .section-search-3 .banner-info {
    padding: 20px 0 100px;
  }
}

@media (max-width: 479.98px) {
  .section-search-3 .banner-info {
    padding: 20px 0 70px;
  }
}

.section-search-3 .banner-info .header-banner h2 {
  color: #ffffff;
  font-weight: 600;
  line-height: 40px;
}

.section-search-3 .banner-info .header-banner h2 .header-highlight {
  font-weight: 700;
  color: #ffffff;
}

.section-search-3 .banner-info .header-banner p {
  font-size: 17px;
  color: #ffffff;
}

.section-search-3 .doctor-search {
  padding-bottom: 15px;
}

@media (max-width: 991.98px) {
  .section-search-3 .doctor-search {
    padding: 30px 0;
  }
}

.section-search-3 .doctor-search .doctor-search-form.trans .form-control {
  color: #ffffff;
}

.section-search-3 .doctor-search .doctor-search-form.trans input,
.section-search-3 .doctor-search .doctor-search-form.trans select {
  background-color: transparent;
  border: 2px solid #c2d6f3 !important;
  border-radius: 130px;
  min-height: 60px;
  border: 0;
  box-shadow: 0px 4px 4px rgba(237, 237, 237, 0.25);
  border-radius: 5px;
}

.section-search-3 .doctor-search .doctor-search-form.trans input::placeholder,
.section-search-3 .doctor-search .doctor-search-form.trans select::placeholder {
  color: #ffffff;
}

.section-search-3 .doctor-search .doctor-search-form.trans .form-custom i {
  color: #ffffff;
}

.section-search-3 .doctor-search .doctor-search-form.trans .banner-btn {
  background-color: #13a5e5;
}

.section-search-3 .doctor-search .doctor-search-form.trans .banner-btn:hover {
  background-color: #2a9d36;
}

@media (max-width: 991.98px) {
  .section-search-3 .doctor-search .doctor-search-form.trans .banner-btn {
    padding: 18px 30px;
  }
}

.doctor-category {
  position: relative;
  margin-top: -130px;
  z-index: 9;
  padding: 60px 0;
}

@media (max-width: 767.98px) {
  .doctor-category {
    padding: 40px 0 10px;
    margin-top: -110px;
  }
}

.doctor-category .pop-box {
  background-color: #ffffff;
  padding: 15px 15px 0;
  border-radius: 5px;
  box-shadow: 0px 4px 44px rgba(219, 219, 219, 0.25);
}

@media (max-width: 767.98px) {
  .doctor-category .pop-box {
    margin-bottom: 30px;
  }
}

.doctor-category .pop-box .top-section {
  background-color: #e5f6fd;
  padding: 20px;
  min-height: 150px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.doctor-category .pop-box .top-section i {
  background-color: #ffffff;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  font-size: 30px;
  color: #20c0f3;
  border: 5px solid #f5f8f9;
}

.doctor-category .pop-box .top-section.three {
  background-color: #ffe0e7;
}

.doctor-category .pop-box .top-section.three i {
  color: #ffaabd;
}

.doctor-category .pop-box .top-section.two {
  background-color: #fff1c9;
}

.doctor-category .pop-box .top-section.two i {
  color: #ffbd07;
}

.doctor-category .pop-box .body-section {
  padding: 20px;
  min-height: 200px;
  flex-direction: column;
}

.doctor-category .pop-box .body-section h3 {
  color: #2467ca;
  font-weight: 600;
  font-size: 24px;
}

@media (max-width: 991.98px) {
  .doctor-category .pop-box .body-section h3 {
    font-size: 20px;
  }
}

@media (max-width: 767.98px) {
  .doctor-category .pop-box .body-section h3 {
    font-size: 18px;
  }
}

.doctor-category .pop-box .body-section p {
  color: #1c5b91;
  font-weight: 500;
}

.doctor-category .pop-box .body-section .book-btn {
  width: 150px;
  margin: 0 auto;
  border-radius: 30px;
  background: #2467ca;
  border: 1px solid #2467ca;
}

.doctor-category .pop-box .body-section .book-btn:hover {
  background: #1c5b91;
  border-color: #1c5b91;
}

.section-header-three {
  margin-bottom: 60px;
}

@media (max-width: 767.98px) {
  .section-header-three {
    margin-bottom: 40px;
  }
}

@media (max-width: 479.98px) {
  .section-header-three {
    margin-bottom: 30px;
  }
}

.section-header-three h2 {
  color: #2868c8;
  font-weight: 700;
  font-size: 30px;
  position: relative;
  display: inline-block;
}

@media (max-width: 991.98px) {
  .section-header-three h2 {
    font-size: 28px;
  }
}

@media (max-width: 767.98px) {
  .section-header-three h2 {
    font-size: 24px;
  }
}

@media (max-width: 479.98px) {
  .section-header-three h2 {
    font-size: 15px;
  }
}

.section-header-three h2:before {
  content: "";
  width: 13px;
  height: 13px;
  background: #1c5b91;
  border-radius: 50%;
  box-shadow: 15px 0px 0px -2px #1c5b91;
  position: absolute;
  top: 12px;
  left: -40px;
}

.section-header-three h2:after {
  width: 13px;
  height: 13px;
  content: "";
  background: #1c5b91;
  border-radius: 50%;
  box-shadow: -15px 0px 0px -2px #1c5b91;
  position: absolute;
  top: 12px;
  right: -40px;
}

.section-header-three h2 img {
  margin: 2px 5px 5px;
}

.section-header-three p {
  color: #757575;
  font-size: 16px;
  max-width: 600px;
  margin: 15px auto 0;
}

@media (max-width: 767.98px) {
  .section-header-three p {
    font-size: 14px;
  }
}

@media (max-width: 479.98px) {
  .section-header-three p {
    font-size: 11px;
  }
}

.clinic-specialities {
  background: url(../images/special-banner.jpg);
  padding: 60px 0;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 767.98px) {
  .clinic-specialities {
    padding: 40px 0;
  }
}

.clinic-specialities .special-box {
  background-color: #ffffff;
  border-radius: 10px;
  height: 160px;
  cursor: pointer;
  margin-bottom: 30px;
  transition: all 0.7s;
}

.clinic-specialities .special-box .special-body {
  padding: 20px;
  transition: all 0.5s;
}

.clinic-specialities .special-box .special-body img {
  width: 50px;
  margin-bottom: 15px;
}

.clinic-specialities .special-box .special-body h4 {
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}

@media (max-width: 479.98px) {
  .clinic-specialities .special-box .special-body h4 {
    font-size: 15px;
  }
}

.clinic-specialities .special-box .special-body .spc-book {
  background-color: #ffffff;
  padding: 10px 15px;
  min-width: 120px;
  color: #1c5b91;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  display: none;
}

.clinic-specialities .special-box .special-body .spc-book i {
  background-color: #1c5b91;
  color: #ffffff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  font-size: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.clinic-specialities .special-box .special-footer {
  border-top: 1px solid #eaeaea;
  width: 100%;
  margin: 0;
  padding: 15px;
}

.clinic-specialities .special-box .special-footer .doc-count {
  font-size: 14px;
  font-weight: 700;
  color: #1c71ef;
}

@media (max-width: 479.98px) {
  .clinic-specialities .special-box .special-footer .doc-count {
    font-size: 13px;
  }
}

.clinic-specialities .special-box .special-footer .doc-count span {
  color: #6b7280;
  font-weight: 500;
}

.clinic-specialities .special-box .special-footer .clin-count {
  font-size: 14px;
  font-weight: 700;
  color: #1c71ef;
}

@media (max-width: 479.98px) {
  .clinic-specialities .special-box .special-footer .clin-count {
    font-size: 13px;
  }
}

.clinic-specialities .special-box .special-footer .clin-count span {
  color: #6b7280;
  font-weight: 500;
}

.clinic-specialities .special-box:hover {
  background-color: #1c71ef;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}

.clinic-specialities .special-box:hover .special-body {
  height: 100%;
  flex-direction: column;
}

.clinic-specialities .special-box:hover .special-body h4 {
  color: #ffffff;
}

.clinic-specialities .special-box:hover .spc-book {
  margin-top: 10px;
  display: block;
}

.clinic-specialities .special-box:hover img {
  display: none;
}

.clinic-specialities .special-box:hover .special-footer {
  display: none;
}

.view-all-more .btn {
  background: #1b5d92;
  border-radius: 30px;
  border-color: #1b5d92;
  margin: 20px 0 0;
  padding: 9px 30px;
}

@media (max-width: 479.98px) {
  .view-all-more .btn {
    font-size: 14px;
    padding: 6px 20px;
  }
}

.view-all-more .btn:hover {
  background-color: #0e82fd;
  border: 1px solid #0e82fd;
}

.browse-section {
  background-color: #e5f6fd;
  padding: 60px 0;
}

@media (max-width: 767.98px) {
  .browse-section {
    padding: 40px 0;
  }
}

.browse-section .brower-box {
  text-align: center;
  border-radius: 10px;
  border: 6px solid #ffffff;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.browse-section .brower-box > div {
  border-radius: 15px;
  background-color: #e5f6fd;
  height: 100%;
  padding: 15px;
}

.browse-section .brower-box > div .brower-img {
  background-color: #1c71ef;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto 20px;
}

.browse-section .brower-box > div .brower-img img {
  width: auto;
}

.browse-section .brower-box > div h4 {
  font-size: 16px;
  font-weight: 600;
  color: #2264c5;
}

.browse-section .brower-box > div p {
  font-size: 14px;
  font-weight: 500;
  color: #e74e84;
  margin: 0;
}

@media (max-width: 767.98px) {
  .doctor-divison .d-flex {
    display: block !important;
  }
}

.doctor-divison .doc-background {
  background: url(../images/shapes/doctor-bg.jpg);
  background-position: center;
  min-height: 350px;
  background-size: cover;
  padding: 40px 30px;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .doctor-divison .doc-background {
    min-height: 250px;
  }
}

@media (max-width: 767.98px) {
  .doctor-divison .doc-background {
    padding: 20px 15px;
    min-height: 200px;
  }
}

.doctor-divison .doc-background::before {
  content: "";
  background: rgba(61, 59, 59, 0.84);
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.doctor-divison h3 {
  font-size: 28px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 20px;
}

@media (max-width: 991.98px) {
  .doctor-divison h3 {
    font-size: 20px;
  }
}

@media (max-width: 767.98px) {
  .doctor-divison h3 {
    font-size: 16px;
  }
}

@media (max-width: 479.98px) {
  .doctor-divison h3 {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.doctor-divison p {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {
  .doctor-divison p {
    font-size: 12px;
  }
}

@media (max-width: 767.98px) {
  .doctor-divison p {
    font-size: 11px;
  }
}

@media (max-width: 479.98px) {
  .doctor-divison p {
    font-size: 10px;
    margin-bottom: 20px;
  }
}

.doctor-divison .doc-bok-btn {
  font-size: 14px;
  padding: 6px 12px;
  color: #ffffff;
  background-color: #1c71ef;
  border-radius: 30px;
  width: 100px;
  text-align: center;
}

@media (max-width: 479.98px) {
  .doctor-divison .doc-bok-btn {
    font-size: 12px;
    padding: 5px 10px;
  }
}

.doctor-divison .doc-bok-btn:hover {
  color: #1c71ef;
  background: #ffffff;
}

.doctor-divison .pat-background {
  background: url(../images/shapes/doctor-right-bg.jpg);
  background-position: center;
  min-height: 350px;
  background-size: cover;
  padding: 40px 30px;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .doctor-divison .pat-background {
    min-height: 250px;
  }
}

@media (max-width: 767.98px) {
  .doctor-divison .pat-background {
    padding: 20px 15px;
    min-height: 200px;
  }
}

.doctor-divison .pat-background.pat-background::before {
  content: "";
  background: rgba(28, 113, 239, 0.9);
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.doctor-divison .pat-background .doc-bok-btn {
  background-color: #1b5d92;
}

.doctor-divison .pat-background .doc-bok-btn:hover {
  color: #1c71ef;
  background: #ffffff;
}

.book-section {
  background: url(../images/special-banner.jpg);
  padding: 60px 0;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 767.98px) {
  .book-section {
    padding: 40px 0;
  }
}

.book-section .book-best-doctors {
  margin-bottom: 30px;
}

.book-section .book-best-doctors .book-header img {
  border-radius: 10px 10px 0px 0px;
  width: 100%;
}

.book-section .book-best-doctors .book-header .img-overlay {
  background: #13a5e5;
  border-radius: 50%;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  right: 20px;
}

.book-section .book-best-doctors .book-header .img-overlay i {
  font-size: 12px;
}

.book-section .book-best-doctors .doctors-body {
  background-color: #ffffff;
  padding: 0;
  box-shadow: 0px -6px 54px rgba(152, 152, 152, 0.25);
  border-radius: 0;
}

.book-section .book-best-doctors .doctors-body .inner-section {
  padding: 15px 15px 0;
}

.book-section .book-best-doctors .doctors-body .inner-section > span {
  color: #e74e84;
  font-size: 14px;
}

@media (max-width: 767.98px) {
  .book-section .book-best-doctors .doctors-body .inner-section > span {
    font-size: 12px;
  }
}

.book-section .book-best-doctors .doctors-body .rating {
  font-size: 12px;
}

.book-section .book-best-doctors .doctors-body .rating i {
  font-size: 10px;
  color: #dfdfdf;
  margin-bottom: 10px;
}

.book-section .book-best-doctors .doctors-body .rating i.filled {
  color: #fed35d;
}

.book-section .book-best-doctors .doctors-body .rating .average-ratings {
  background-color: #fed35d;
  font-size: 11px;
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .book-section .book-best-doctors .doctors-body .rating .average-ratings {
    font-size: 10px;
    margin: 0 0 0 7px;
    padding: 1px 8px;
  }
}

.book-section .book-best-doctors .doctors-body h4 {
  color: #22396b;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  .book-section .book-best-doctors .doctors-body h4 {
    font-size: 18px;
  }
}

@media (max-width: 479.98px) {
  .book-section .book-best-doctors .doctors-body h4 {
    font-size: 16px;
  }
}

.book-section .book-best-doctors .doctors-body p {
  font-size: 12px;
  font-weight: 600;
}

.book-section .book-best-doctors .loc-details {
  background-color: #f6faff;
  margin: 15px 0 0;
  padding: 15px 10px 0;
}

.book-section .book-best-doctors .loc-details .d-flex {
  margin-bottom: 15px;
}

.book-section .book-best-doctors .loc-details img {
  color: #22396b;
  width: auto;
  margin-right: 10px;
}

@media (max-width: 479.98px) {
  .book-section .book-best-doctors .loc-details img {
    margin-right: 6px;
  }
}

.book-section .book-best-doctors .available-info {
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase;
  clear: both;
  display: block;
  margin: 0;
  color: #757575;
}

@media (max-width: 479.98px) {
  .book-section .book-best-doctors .available-info {
    font-size: 11px;
  }
}

.book-section .book-best-doctors .data-info {
  font-weight: 400;
  font-size: 11px;
  color: #2264c5;
}

@media (max-width: 479.98px) {
  .book-section .book-best-doctors .data-info {
    font-size: 10px;
  }
}

.book-section .book-best-doctors .amt-txt {
  color: #1c5b91;
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  .book-section .book-best-doctors .amt-txt {
    font-size: 16px;
  }
}

@media (max-width: 479.98px) {
  .book-section .book-best-doctors .amt-txt {
    font-size: 14px;
  }
}

.book-section .book-best-doctors .book-btn {
  font-size: 14px;
  padding: 6px 12px;
  color: #ffffff;
  background-color: #1c71ef;
  border-radius: 30px;
  width: 100px;
  text-align: center;
  float: right;
  border: none;
}

@media (max-width: 479.98px) {
  .book-section .book-best-doctors .book-btn {
    font-size: 12px;
    padding: 5px 10px;
    width: 80px;
  }
}

.book-section .book-best-doctors .book-btn:hover {
  background-color: #1c5b91;
}

.average-ratings {
  background: #1c5b90;
  border-radius: 70px;
  color: #ffffff;
  margin: 0 0 0 10px;
  padding: 1px 15px;
}

.latest-blog {
  background-color: #f4fcff;
  padding: 60px 0 30px;
}

@media (max-width: 767.98px) {
  .latest-blog {
    padding: 40px 0 10px;
  }
}

.latest-blog .blog-wrap {
  margin-bottom: 30px;
  background-color: #ffffff;
  border-radius: 10px 10px 0px 0px;
}

.latest-blog .blog-wrap img {
  border-radius: 10px;
  width: 100%;
}

.latest-blog .blog-wrap .blog-wrap-body {
  padding: 20px 15px;
  background-color: #ffffff;
}

.latest-blog .blog-wrap .blog-wrap-body h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

@media (max-width: 991.98px) {
  .latest-blog .blog-wrap .blog-wrap-body h3 {
    font-size: 18px;
  }
}

@media (max-width: 479.98px) {
  .latest-blog .blog-wrap .blog-wrap-body h3 {
    font-size: 16px;
  }
}

.latest-blog .blog-wrap .blog-wrap-body h3 a:hover {
  color: #1c5b91;
}

.latest-blog .blog-wrap .blog-wrap-body p {
  font-size: 14px;
  color: #737373;
}

@media (max-width: 479.98px) {
  .latest-blog .blog-wrap .blog-wrap-body p {
    font-size: 12px;
  }
}

.latest-blog .blog-wrap .blog-wrap-body .blog-footer {
  border-top: 1px solid #1b70ee;
  padding: 15px 0 0;
}

.latest-blog .blog-wrap .blog-wrap-body .blog-footer .read-txt {
  color: #1b70ee;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 479.98px) {
  .latest-blog .blog-wrap .blog-wrap-body .blog-footer .read-txt {
    font-size: 12px;
  }
}

.latest-blog .blog-wrap .blog-wrap-body .blog-footer .read-txt i {
  margin: 2px 6px;
  position: absolute;
  top: 3px;
}

.latest-blog .blog-wrap .blog-wrap-body .blog-footer .cal-txt {
  color: #1b70ee;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 479.98px) {
  .latest-blog .blog-wrap .blog-wrap-body .blog-footer .cal-txt {
    font-size: 12px;
  }
}

.latest-blog .blog-wrap .blog-wrap-body .blog-footer .cal-txt i {
  margin: 2px 6px;
}

.browse-section.brower-category {
  padding: 60px 0;
  background: url(../images/slider/banner-02.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 991.98px) {
  .browse-section.brower-category {
    padding: 40px 0 20px;
  }
}

.browse-section.brower-category .heading-wrapper h1 {
  color: #e5f6fd;
}

.browse-section.brower-category .brower-box {
  text-align: center;
  border-radius: 10px;
  border: 6px solid #ffffff;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.browse-section.brower-category .brower-box > div {
  background-color: #f2f7ff;
  border-radius: 20px 20px 0px 0px;
}

.browse-section.brower-category .brower-box > div .brower-img {
  background-color: #28daf4;
  margin-bottom: 40px;
}

.browse-section.brower-category .brower-box > div h4 {
  color: #28daf4;
}

.doctor-divison.provider-select .doc-background {
  background: url(../images/shapes/doctor-bg.jpg);
  background-size: cover;
  padding: 60px 30px;
}

.doctor-divison.provider-select .doc-background::before {
  background: rgba(242, 247, 255, 0.94);
}

@media (max-width: 991.98px) {
  .doctor-divison.provider-select .doc-background {
    padding: 40px 30px;
  }
}

.doctor-divison.provider-select .doc-background h3 {
  color: #2357a6;
}

.doctor-divison.provider-select .doc-background p {
  color: #4b4b4b;
}

.doctor-divison.provider-select .pat-background {
  background: url(../images/shapes/doctor-right-bg.jpg);
  background-size: cover;
  padding: 60px 30px;
}

.doctor-divison.provider-select .pat-background::before {
  background: rgba(40, 218, 244, 0.9);
}

@media (max-width: 991.98px) {
  .doctor-divison.provider-select .pat-background {
    padding: 40px 30px;
  }
}

.doctor-divison.provider-select .pat-background a {
  background-color: #0ce0ff;
}

.blogs-section.blog-container {
  padding: 60px 0;
}

@media (max-width: 991.98px) {
  .blogs-section.blog-container {
    padding: 40px 0 10px;
  }
}

.blogs-section.blog-container .blog-wrapper .date-cart {
  top: 50px;
  right: 25px;
}

.blogs-section.blog-container .blog-wrapper .wrap-content-body h3 a {
  color: #1860ca;
}

.blogs-section.blog-container .blog-wrapper .wrap-content-body .sp-title {
  color: #28daf4;
  font-size: 14px;
}

.main-wrapper.home-ten {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-ten {
    font-size: 15px;
  }
}

.main-wrapper.home-ten a:hover {
  color: #0e82fd;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .faq-img {
    display: none;
  }
}

.main-wrapper.home-ten h1,
.main-wrapper.home-ten h2,
.main-wrapper.home-ten h3,
.main-wrapper.home-ten h4,
.main-wrapper.home-ten h5,
.main-wrapper.home-ten h6 {
  text-transform: capitalize;
}

.main-wrapper.home-ten .row-gap {
  row-gap: 24px;
}

.main-wrapper.home-ten .banner-section {
  background: url(../images/bg/home-10-banner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 101%;
  background-clip: padding-box;
  padding: 10px 0 30px;
  height: 745px;
  overflow: visible;
}

.main-wrapper.home-ten .banner-section .banner-content {
  margin-top: 60px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .banner-section .banner-content {
    margin-top: 50px;
  }
}

@media (max-width: 575.98px) {
  .main-wrapper.home-ten .banner-section .banner-content {
    margin-top: 30px;
  }
}

.main-wrapper.home-ten .banner-section::after {
  display: none;
}

.main-wrapper.home-ten .banner-section h6 {
  font-size: 16px;
  margin-bottom: 0;
  text-transform: capitalize;
  color: #ffffff;
  font-weight: 400;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .banner-section h6 {
    margin-bottom: 10px;
  }
}

.main-wrapper.home-ten .banner-section h1 {
  color: #ffffff;
  max-width: 410px;
  line-height: 70px;
  font-weight: 400;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .banner-section h1 {
    max-width: 100%;
    font-size: 30px;
    line-height: normal;
  }
}

.main-wrapper.home-ten .banner-section h1 span {
  color: #ffffff;
  font-weight: 700;
  font-size: 60px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .banner-section h1 span {
    font-size: 34px;
  }
}

.main-wrapper.home-ten .banner-section p {
  color: #ccfbf1;
  max-width: 502px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .banner-section p {
    max-width: 100%;
  }
}

.main-wrapper.home-ten .banner-section .btn {
  border-radius: 35px;
  padding: 14px 40px;
  min-width: 205px;
  border: 3px solid #ffffff;
  font-size: 20px;
}

@media (max-width: 568px) {
  .main-wrapper.home-ten .banner-section .btn {
    border-radius: 35px;
    padding: 5px 10px;
    min-width: auto;
    border: 1px solid #ffffff;
    font-size: 14px;
  }
}

.main-wrapper.home-ten .banner-section .btn.plan-btn {
  background-color: #ffffff;
  margin-right: 10px;
  color: #0e82fd;
  box-shadow: inset 0 0 0 0 #0e82fd;
}

.main-wrapper.home-ten .banner-section .btn.plan-btn:hover {
  box-shadow: inset 0 0 0 50px #0e82fd;
  color: #ffffff;
}

.main-wrapper.home-ten .banner-section .btn:hover {
  border-color: #ffffff;
}

.main-wrapper.home-ten .banner-section .banner-img {
  position: relative;
}

.main-wrapper.home-ten .banner-section .banner-img img:nth-child(2) {
  position: absolute;
  left: -228px;
  z-index: -1;
  animation: beat 2.5s ease-in-out infinite;
}

@keyframes beat {
  from {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}

.main-wrapper.home-ten .banner-section .search-box-one.search-ten {
  border-radius: 35px;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

@media (max-width: 1399.98px) {
  .main-wrapper.home-ten .banner-section .search-box-one.search-ten {
    margin-top: -27px;
  }
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .banner-section .search-box-one.search-ten {
    margin-top: 0px;
  }
}

@media (max-width: 575.98px) {
  .main-wrapper.home-ten .banner-section .search-box-one.search-ten {
    margin-top: 0px;
  }
}

.main-wrapper.home-ten
  .banner-section
  .search-box-one.search-ten
  .search-title {
  padding-bottom: 24px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.main-wrapper.home-ten
  .banner-section
  .search-box-one.search-ten
  .search-title
  h5 {
  font-weight: 700;
}

.main-wrapper.home-ten
  .banner-section
  .search-box-one.search-ten
  .search-input-ten {
  display: flex;
  gap: 10px;
  align-items: center;
}

.main-wrapper.home-ten
  .banner-section
  .search-box-one.search-ten
  .search-input-ten
  .form-group {
  width: 100%;
}

.main-wrapper.home-ten
  .banner-section
  .search-box-one.search-ten
  .search-input-ten
  .form-group
  .form-control {
  min-height: 40px;
}

.main-wrapper.home-ten
  .banner-section
  .search-box-one.search-ten
  .search-input-ten
  span {
  width: 40px;
  height: 40px;
  background: #f0fdfa;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.main-wrapper.home-ten
  .banner-section
  .search-box-one.search-ten
  .form-search-btn.search-btn-ten {
  padding-top: 0;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten
    .banner-section
    .search-box-one.search-ten
    .form-search-btn.search-btn-ten {
    width: 100%;
  }
}

.main-wrapper.home-ten
  .banner-section
  .search-box-one.search-ten
  .form-search-btn.search-btn-ten
  .btn {
  min-width: 100%;
  padding: 10px 50px;
  font-size: 14px;
  border: 1px solid #0e82fd;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten
    .banner-section
    .search-box-one.search-ten
    .form-search-btn.search-btn-ten
    .btn {
    min-width: auto;
    padding: 10px 0;
    font-size: 14px;
    width: 100%;
  }
}

.main-wrapper.home-ten .section-header-one {
  margin-bottom: 50px;
  text-align: center;
}

.main-wrapper.home-ten .section-header-one h2 {
  font-size: 40px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0;
}

.main-wrapper.home-ten .section-header-one h2 span {
  color: #0e82fd;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .section-header-one h2 {
    font-size: 34px;
  }
}

@media (max-width: 767.98px) {
  .main-wrapper.home-ten .section-header-one h2 {
    font-size: 26px;
  }
}

.main-wrapper.home-ten .section-header-one .section-title {
  display: inline-block;
  position: relative;
}

.main-wrapper.home-ten .section-header-one .section-title::before {
  display: none !important;
}

.main-wrapper.home-ten .section-header-one h5 {
  font-weight: 600;
  font-size: 18px;
  color: #0e82fd;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .section-header-one {
    margin-bottom: 40px;
  }
}

.main-wrapper.home-ten .specialities-section-one {
  position: relative;
}

.main-wrapper.home-ten .specialities-section-one .floating-bg img {
  position: absolute;
  right: 10px;
  top: 50%;
  animation: beat 1.5s ease-in-out infinite;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .specialities-section-one .floating-bg img {
    display: none;
  }
}

.main-wrapper.home-ten .specialities-section-one .specialities-block ul {
  list-style: none;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
}

.main-wrapper.home-ten .specialities-section-one .specialities-block ul li {
  margin-right: 24px;
  width: calc(20% - 24px);
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .specialities-section-one .specialities-block ul li {
    width: 45%;
    margin: 0 10px 20px;
  }
}

@media (max-width: 568px) {
  .main-wrapper.home-ten .specialities-section-one .specialities-block ul li {
    width: 100%;
    margin: 0 0 40px;
  }
}

.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li:last-child {
  margin-right: 0;
}

.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-item {
  background: #ffffff;
  border: none;
  border-radius: 35px;
  height: 195px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.03);
  margin: 0;
  padding: 20px;
  cursor: pointer;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-item:hover {
  background: #ffffff;
  border-color: #0e82fd;
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.08);
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-item:hover
  .specialities-img
  .hexogen {
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-item:hover
  .specialities-img
  .hexogen
  img {
  transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
  -ms-transform: rotateY(360deg);
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}

.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-item
  p {
  font-weight: 600;
  font-size: 16px;
  color: #2f353c;
  margin-bottom: 0;
  max-width: 180px;
  text-align: center;
}

.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-img {
  padding-bottom: 18px;
}

.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-img
  .hexogen {
  background: #ffffff;
  border: none;
  width: 87px;
  height: 100px;
  background-image: url("../images/icons/hex-shape.png");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.main-wrapper.home-ten
  .specialities-section-one
  .specialities-block
  ul
  li
  .specialities-img
  .hexogen
  img {
  width: auto;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}

.main-wrapper.home-ten .service-section {
  padding: 80px 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.main-wrapper.home-ten .service-section::after {
  content: "";
  background: url(../images/bg/service-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.main-wrapper.home-ten .listing-card {
  background-color: #ffffff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.03);
  border-radius: 35px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.main-wrapper.home-ten .listing-card .listing-img {
  position: relative;
  margin: 0 0 20px 0;
  overflow: hidden;
  border-radius: 20px;
}

.main-wrapper.home-ten .listing-card .listing-img img {
  position: relative;
  border-radius: 20px;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  width: 100%;
}

.main-wrapper.home-ten .listing-card .listing-img:hover img {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}

.main-wrapper.home-ten .listing-card .listing-img:hover::before {
  -webkit-animation: circle 1s;
  animation: circle 1s;
}

.main-wrapper.home-ten .listing-card .listing-img::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  opacity: 0;
}

.main-wrapper.home-ten .listing-card .listing-img .fav-item {
  position: absolute;
  top: 10px;
  right: 10px;
}

.main-wrapper.home-ten .listing-card .listing-img .fav-item .fav-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-wrapper.home-ten .listing-card .listing-img .fav-item .fav-icon i {
  color: #a29fc0;
}

.main-wrapper.home-ten .listing-card .listing-img .fav-item.selected .fav-icon {
  background-color: #0e82fd;
}

.main-wrapper.home-ten
  .listing-card
  .listing-img
  .fav-item.selected
  .fav-icon
  i {
  color: #ffffff;
}

.main-wrapper.home-ten
  .listing-card
  .listing-content
  .listing-details
  .listing-title
  h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.main-wrapper.home-ten
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-ten
    .listing-card
    .listing-content
    .listing-details
    .listing-profile-details {
    align-items: start;
    flex-direction: column;
    gap: 15px;
  }
}

.main-wrapper.home-ten
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details
  .listing-user {
  display: flex;
  align-items: center;
}

.main-wrapper.home-ten
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details
  .listing-user
  .listing-profile-img {
  height: 48px;
  width: 48px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.main-wrapper.home-ten
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details
  .listing-user
  .listing-user-details
  span {
  color: #9ca3af;
  font-size: 14px;
}

.main-wrapper.home-ten
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details
  .listing-user
  .listing-user-details
  h6 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-ten
    .listing-card
    .listing-content
    .listing-details
    .listing-profile-details
    .listing-btn {
    width: 100%;
  }
}

.main-wrapper.home-ten
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details
  .listing-btn
  .consult-btn {
  padding: 6px 30px 6px 30px;
  background-color: #0e82fd;
  font-weight: 500;
  font-size: 16px;
  border-radius: 35px;
  box-shadow: inset 0 0 0 0 #ffffff;
  transition: all 0.7s;
  color: #ffffff;
}

.main-wrapper.home-ten
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details
  .listing-btn
  .consult-btn:hover {
  box-shadow: inset 0 0 0 50px #ffffff;
  color: #0e82fd;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten
    .listing-card
    .listing-content
    .listing-details
    .listing-profile-details
    .listing-btn
    .consult-btn {
    padding: 6px 12px 6px 12px;
    font-size: 17px;
  }
}

@media (max-width: 575.98px) {
  .main-wrapper.home-ten
    .listing-card
    .listing-content
    .listing-details
    .listing-profile-details
    .listing-btn
    .consult-btn {
    width: 100%;
  }
}

.main-wrapper.home-ten .need-to-know-section {
  padding: 80px 0;
  position: relative;
}

.main-wrapper.home-ten .need-to-know-section .floating-bg img {
  position: absolute;
  left: 10px;
  top: 50%;
  animation: beat 1.5s ease-in-out infinite;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .need-to-know-section .floating-bg {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .need-to-know-section {
    padding: 60px 0;
  }
}

.main-wrapper.home-ten .need-to-know-section .section-header-one {
  text-align: left;
  margin-bottom: 0;
}

.main-wrapper.home-ten .need-to-know-section .section-header-one ul {
  padding: 30px 0;
}

.main-wrapper.home-ten .need-to-know-section .section-header-one ul li {
  margin-right: 10px;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-ten .need-to-know-section .section-header-one ul li {
    margin: 0 10px 10px 0;
  }
}

.main-wrapper.home-ten
  .need-to-know-section
  .section-header-one
  ul
  li:last-child {
  margin-right: 0;
}

.main-wrapper.home-ten
  .need-to-know-section
  .section-header-one
  ul
  li
  .tag-list {
  padding: 10px 20px;
  border-radius: 35px;
  background: #f8fafc;
  font-weight: 600;
  color: #9ca3af;
  text-transform: capitalize;
}

.main-wrapper.home-ten
  .need-to-know-section
  .section-header-one
  ul
  li
  .tag-list.active {
  background: #f1f5f9;
  color: #0e82fd;
}

.main-wrapper.home-ten
  .need-to-know-section
  .section-header-one
  .need-to-know-content
  h3 {
  margin-bottom: 34px;
  font-weight: 600;
}

.main-wrapper.home-ten
  .need-to-know-section
  .section-header-one
  .need-to-know-content
  p {
  line-height: 29px;
  margin-bottom: 30px;
}

.main-wrapper.home-ten
  .need-to-know-section
  .section-header-one
  .need-to-know-content
  .need-to-know-content-btn {
  border-radius: 35px;
  padding: 10px 30px;
  border: 2px solid #0e82fd;
  font-size: 14px;
  color: #0e82fd;
  box-shadow: inset 0 0 0 0 #0e82fd;
  transition: all 0.7s;
}

.main-wrapper.home-ten
  .need-to-know-section
  .section-header-one
  .need-to-know-content
  .need-to-know-content-btn:hover {
  box-shadow: inset 0 0 0 50px #0e82fd;
  color: #ffffff;
}

.main-wrapper.home-ten .need-to-know-section .gallery-box-block {
  display: flex;
  gap: 10px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .need-to-know-section .gallery-box-block {
    display: none !important;
  }
}

.main-wrapper.home-ten .need-to-know-section .gallery-box-block .box-detail {
  width: 195px;
  height: 202px;
  border-radius: 35px;
  overflow: hidden;
  margin-bottom: 10px;
}

.main-wrapper.home-ten
  .need-to-know-section
  .gallery-box-block
  .box-detail:last-child {
  margin-bottom: 0;
}

.main-wrapper.home-ten
  .need-to-know-section
  .gallery-box-block
  .gallery-box-left {
  padding-top: 30px;
}

.main-wrapper.home-ten .need-to-know-section.steps-to-follow .floating-bg img {
  position: absolute;
  right: 10px;
  top: 50%;
  animation: beat 1.5s ease-in-out infinite;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .need-to-know-section.steps-to-follow .floating-bg {
    display: none;
  }
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .gallery-box-block {
  display: flex;
  gap: 24px;
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .gallery-box-block
  .box-detail {
  width: 240px;
  height: 223px;
  border-radius: 35px;
  overflow: hidden;
  margin-bottom: 24px;
  padding: 30px;
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1399.98px) {
  .main-wrapper.home-ten
    .need-to-know-section.steps-to-follow
    .gallery-box-block
    .box-detail {
    max-width: 210px;
    max-height: 203px;
  }
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .gallery-box-block
  .box-detail:last-child {
  margin-bottom: 0;
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .gallery-box-block
  .box-detail
  .steps-list-box {
  position: relative;
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .gallery-box-block
  .box-detail
  .steps-list-box
  .steps-list-img {
  width: 140px;
  height: 60px;
  margin: auto;
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .gallery-box-block
  .box-detail
  .steps-list-box
  h6 {
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .gallery-box-block
  .box-detail
  .steps-list-box
  p {
  text-align: center;
  margin-bottom: 0;
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .gallery-box-block
  .box-detail
  .steps-list-box
  span {
  color: #0e82fd;
  font-weight: 700;
  font-size: 30px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 12%);
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .gallery-box-block
  .gallery-box-left {
  padding-top: 30px;
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .section-header-one
  .need-to-know-content {
  margin-top: 50px;
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .section-header-one
  .need-to-know-content
  .need-to-know-content-btn {
  border-radius: 35px;
  padding: 10px 30px;
  border: 3px solid #0e82fd;
  font-size: 18px;
  font-weight: 500;
  color: #0e82fd;
  box-shadow: inset 0 0 0 0 #0e82fd;
  transition: all 0.7s;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten
    .need-to-know-section.steps-to-follow
    .section-header-one
    .need-to-know-content
    .need-to-know-content-btn {
    font-size: 16px;
  }
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .section-header-one
  .need-to-know-content
  .need-to-know-content-btn:hover {
  box-shadow: inset 0 0 0 50px #0e82fd;
  color: #ffffff;
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .section-header-one
  .need-to-know-content
  .need-to-know-content-btn.book-btn {
  background-color: #0e82fd;
  color: #ffffff;
  box-shadow: inset 0 0 0 0 #ffffff;
}

.main-wrapper.home-ten
  .need-to-know-section.steps-to-follow
  .section-header-one
  .need-to-know-content
  .need-to-know-content-btn.book-btn:hover {
  box-shadow: inset 0 0 0 50px #ffffff;
  color: #0e82fd;
}

.main-wrapper.home-ten .need-to-know-section.testimonial-section-ten {
  background-color: #f8fafc;
  background-image: url("../images/bg/map.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .floating-bg
  img {
  position: absolute;
  left: 10px;
  top: 50%;
  animation: beat 1.5s ease-in-out infinite;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten
    .need-to-know-section.testimonial-section-ten
    .floating-bg {
    display: none;
  }
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .section-header-one
  h2 {
  margin-bottom: 30px;
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .section-header-one
  .need-to-know-content
  p {
  margin-bottom: 30px;
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .section-header-one
  .need-to-know-content
  .patient-rating-block
  .patient-rating
  .circle-rating {
  margin-bottom: 12px;
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .section-header-one
  .need-to-know-content
  .patient-rating-block
  .patient-rating
  .circle-rating
  i {
  color: #ffca42;
  margin-right: 5px;
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .section-header-one
  .need-to-know-content
  .patient-rating-block
  .patient-rating
  .circle-rating
  i:last-child {
  margin-right: 0px;
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .section-header-one
  .need-to-know-content
  .patient-rating-block
  .patient-rating
  .circle-rating
  span {
  margin-left: 10px;
  color: #9ca3af;
  font-size: 14px;
  font-weight: 600;
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .section-header-one
  .need-to-know-content
  .patient-rating-block
  .patient-rating
  h5 {
  margin-bottom: 0;
  color: #1e293b;
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .testimonial-card {
  color: #ffffff;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.08);
  border-radius: 35px;
  margin-bottom: 24px;
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .testimonial-card:last-child {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten
    .need-to-know-section.testimonial-section-ten
    .testimonial-card:last-child {
    margin-bottom: 15px;
  }
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .testimonial-card
  .testimonial-user-details {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .testimonial-card
  .testimonial-user-details
  .testimonial-user-img {
  width: 85px;
  height: 85px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 50%;
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .testimonial-card
  .testimonial-user-details
  .testimonial-user-name
  .circle-rating
  i {
  color: #ffca42;
}

.main-wrapper.home-ten
  .need-to-know-section.testimonial-section-ten
  .testimonial-card
  .testmonial-user-coments
  p {
  color: #6b7280;
  font-size: 14px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.main-wrapper.home-ten .our-doctor-section {
  padding: 80px 0;
  position: relative;
  background: #f8fafc;
  z-index: 0;
}

.main-wrapper.home-ten .our-doctor-section .section-floating-bg {
  z-index: -2;
}

.main-wrapper.home-ten .our-doctor-section .section-floating-bg img {
  position: absolute;
}

.main-wrapper.home-ten
  .our-doctor-section
  .section-floating-bg
  img:nth-child(1) {
  top: 0;
  left: 0;
}

.main-wrapper.home-ten
  .our-doctor-section
  .section-floating-bg
  img:nth-child(2) {
  bottom: 0;
  right: 0;
}

.main-wrapper.home-ten .our-doctor-section .module-border-wrap {
  background: #ffffff;
  border-radius: 39px;
  padding: 3px;
  transition: all 0.5s;
  position: relative;
}

.main-wrapper.home-ten .our-doctor-section .module-border-wrap:hover {
  background-image: linear-gradient(to bottom, #8af0ff, #fff);
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.08);
}

.main-wrapper.home-ten .our-doctor-section .module-border-wrap .listing-card {
  background-color: #ffffff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.03);
  border-radius: 35px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-img {
  position: relative;
  margin: 0 0 20px 0;
  overflow: hidden;
  border-radius: 20px;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-img
  img {
  position: relative;
  border-radius: 20px;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  width: 100%;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-img:hover
  img {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-img:hover::before {
  -webkit-animation: circle 1s;
  animation: circle 1s;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-img::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  opacity: 0;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-img
  .fav-item {
  position: absolute;
  top: 10px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-img
  .fav-item
  .featured-rating {
  background: #fbbf24;
  border-radius: 6px;
  color: #ffffff;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-img
  .fav-item
  .featured-rating
  i,
.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-img
  .fav-item
  .featured-rating
  span {
  font-size: 13px;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-img
  .fav-item
  .fav-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-img
  .fav-item
  .fav-icon
  i {
  color: #a29fc0;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-img
  .fav-item.selected
  .fav-icon {
  background-color: #0e82fd;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-img
  .fav-item.selected
  .fav-icon
  i {
  color: #ffffff;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-title
  h3 {
  font-size: 20px;
  font-weight: 600;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-category {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten
    .our-doctor-section
    .module-border-wrap
    .listing-card
    .listing-content
    .listing-details
    .listing-category {
    flex-direction: column;
  }
}

@media (max-width: 575.98px) {
  .main-wrapper.home-ten
    .our-doctor-section
    .module-border-wrap
    .listing-card
    .listing-content
    .listing-details
    .listing-category {
    align-items: start;
  }
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-category
  .listing-category-tag {
  padding: 5px 10px;
  transition: all 0.5s;
  font-size: 14px;
  font-weight: 500;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-category
  .listing-category-tag:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.08);
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-category
  .listing-category-tag.tag-green {
  background: #ccfbf1;
  color: #14b8a6;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-category
  .listing-category-tag.tag-purple {
  background: #ddd6fe;
  color: #7c3aed;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-category
  .listing-category-tag.tag-red {
  background: #fdf2f8;
  color: #ec4899;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details
  .listing-floating-img {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 1399.98px) {
  .main-wrapper.home-ten
    .our-doctor-section
    .module-border-wrap
    .listing-card
    .listing-content
    .listing-details
    .listing-profile-details
    .listing-floating-img {
    display: none;
  }
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details
  .listing-user {
  display: flex;
  align-items: center;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details
  .listing-user
  .listing-profile-img {
  height: 48px;
  width: 48px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details
  .listing-user
  .listing-user-details
  span {
  color: #9ca3af;
  font-size: 14px;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details
  .listing-user
  .listing-user-details
  h6 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 20px;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-ten
    .our-doctor-section
    .module-border-wrap
    .listing-card
    .listing-content
    .listing-details
    .listing-profile-details
    .listing-user
    .listing-user-details
    h6 {
    font-size: 17px;
  }
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details
  .listing-btn
  .consult-btn {
  padding: 6px 30px 6px 30px;
  background-color: #0e82fd;
  font-weight: 500;
  font-size: 16px;
  border-radius: 35px;
  box-shadow: inset 0 0 0 0 #ffffff;
  transition: all 0.7s;
  color: #ffffff;
}

.main-wrapper.home-ten
  .our-doctor-section
  .module-border-wrap
  .listing-card
  .listing-content
  .listing-details
  .listing-profile-details
  .listing-btn
  .consult-btn:hover {
  box-shadow: inset 0 0 0 50px #ffffff;
  color: #0e82fd;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-ten
    .our-doctor-section
    .module-border-wrap
    .listing-card
    .listing-content
    .listing-details
    .listing-profile-details
    .listing-btn
    .consult-btn {
    font-size: 17px;
  }
}

.main-wrapper.home-ten .book-appointment-section {
  padding: 80px 0;
  position: relative;
}

.main-wrapper.home-ten .book-appointment-section .book-appointment-ten {
  padding: 50px;
  background: #0e82fd;
  border-radius: 35px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-ten .book-appointment-section .book-appointment-ten {
    padding: 20px;
  }
}

.main-wrapper.home-ten
  .book-appointment-section
  .book-appointment-ten
  .book-section-bg
  img {
  position: absolute;
}

.main-wrapper.home-ten
  .book-appointment-section
  .book-appointment-ten
  .book-section-bg
  img:nth-child(1) {
  left: 0;
  bottom: 0;
}

.main-wrapper.home-ten
  .book-appointment-section
  .book-appointment-ten
  .book-section-bg
  img:nth-child(2) {
  right: 0;
  bottom: 6%;
}

.main-wrapper.home-ten
  .book-appointment-section
  .book-appointment-ten
  .book-section-bg
  img:nth-child(3) {
  right: 160px;
  top: 100px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten
    .book-appointment-section
    .book-appointment-ten
    .book-section-bg {
    display: none;
  }
}

.main-wrapper.home-ten .book-appointment-section .book-appointment-ten h2 {
  color: #ffffff;
  margin-bottom: 24px;
}

.main-wrapper.home-ten .book-appointment-section .book-appointment-ten p {
  max-width: 725px;
  margin: auto;
  margin-bottom: 50px;
  color: #ffffff;
}

.main-wrapper.home-ten
  .book-appointment-section
  .book-appointment-ten
  .appointment-btn-group {
  display: flex;
  gap: 10px;
  justify-content: center;
}

@media (max-width: 568px) {
  .main-wrapper.home-ten
    .book-appointment-section
    .book-appointment-ten
    .appointment-btn-group {
    flex-direction: column;
  }
}

.main-wrapper.home-ten
  .book-appointment-section
  .book-appointment-ten
  .appointment-btn-group
  a {
  padding: 14px 40px;
  border-radius: 35px;
  border: 2px solid #ffffff;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  box-shadow: inset 0 0 0 0 #ffffff;
  transition: all 0.7s;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten
    .book-appointment-section
    .book-appointment-ten
    .appointment-btn-group
    a {
    padding: 14px 20px;
    border: 1px solid #ffffff;
    font-size: 15px;
  }
}

@media (max-width: 575.98px) {
  .main-wrapper.home-ten
    .book-appointment-section
    .book-appointment-ten
    .appointment-btn-group
    a {
    padding: 10px 20px;
  }
}

.main-wrapper.home-ten
  .book-appointment-section
  .book-appointment-ten
  .appointment-btn-group
  a:hover {
  box-shadow: inset 0 0 0 50px #ffffff;
  color: #0e82fd;
}

.main-wrapper.home-ten
  .book-appointment-section
  .book-appointment-ten
  .appointment-btn-group
  a.appointment-blue-btn {
  background-color: #ffffff;
  color: #0e82fd;
  box-shadow: inset 0 0 0 0 #0e82fd;
  border: 2px solid #ffffff;
}

.main-wrapper.home-ten
  .book-appointment-section
  .book-appointment-ten
  .appointment-btn-group
  a.appointment-blue-btn:hover {
  box-shadow: inset 0 0 0 50px #0e82fd;
  color: #ffffff;
}

.main-wrapper.home-ten .pricing-section.pricing-section-ten {
  position: relative;
  z-index: 1;
}

.main-wrapper.home-ten .pricing-section.pricing-section-ten::after {
  content: "";
  background: url(../images/bg/pricing-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .section-header-one
  h2 {
  margin-bottom: 24px;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .section-header-one
  .options-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 17px;
}

@media (max-width: 568px) {
  .main-wrapper.home-ten
    .pricing-section.pricing-section-ten
    .section-header-one
    .options-group {
    flex-direction: column;
  }
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .section-header-one
  .options-group
  span {
  color: #1f2937;
  font-weight: 500;
  font-size: 18px;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .section-header-one
  .options-group
  span
  .discount-plan {
  background: #ffc001;
  border-radius: 35px;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 14px;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .section-header-one
  .form-check {
  width: 30px;
  margin-bottom: 0;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .section-header-one
  .form-check.form-switch {
  padding: 0;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .section-header-one
  .form-check.form-switch
  .form-check-input {
  transition: all 0.5s;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .section-header-one
  .form-check
  .form-check-input {
  float: right !important;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .section-header-one
  .form-check
  .form-check-input:focus {
  box-shadow: none !important;
}

.main-wrapper.home-ten .pricing-section.pricing-section-ten .pricing-card {
  background: #ffffff;
  border-radius: 35px;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card
  .pricing-btn {
  margin-bottom: 30px;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card
  .pricing-btn
  .btn {
  color: #0e82fd;
  box-shadow: inset 0 0 0 0 #0e82fd;
  padding: 14px 40px;
  background: #ffffff;
  border-radius: 35px;
  transition: all 0.5s;
  border: 2px solid #0e82fd;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card
  .pricing-btn
  .btn:hover {
  box-shadow: inset 0 0 0 50px #0e82fd;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card
  .pricing-info
  .pricing-amount
  span {
  font-weight: 600;
  color: #9ca3af;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card
  .pricing-info
  .pricing-list {
  padding-bottom: 30px;
  border-bottom: 1px solid #e2e8f0;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card
  .pricing-info
  .pricing-list
  ul
  li::before {
  content: "\f21e";
  color: #0e82fd;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card
  .pricing-info
  .pricing-list.pricing-list-two {
  border-bottom: none;
  padding: 30px 0 0 0;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card
  .pricing-info
  .pricing-list.pricing-list-two
  ul
  li::before {
  content: "+";
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card.pricing-card-active {
  background: #0e82fd;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card.pricing-card-active
  .card-body
  .pricing-header
  .pricing-header-info {
  position: relative;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card.pricing-card-active
  .card-body
  .pricing-header
  .pricing-header-info
  .pricing-tag
  img {
  position: absolute;
  right: 0;
  top: 0;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card.pricing-card-active
  .card-body
  .pricing-header
  .pricing-header-info
  .pricing-title
  h4 {
  font-weight: 700;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card.pricing-card-active
  .card-body
  .pricing-info
  .pricing-amount
  span {
  color: #ffffff;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card.pricing-card-active
  .card-body
  .pricing-info
  .pricing-btn {
  margin-bottom: 30px;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card.pricing-card-active
  .card-body
  .pricing-info
  .pricing-btn
  .btn {
  color: #0e82fd;
  box-shadow: inset 0 0 0 0px #0e82fd;
  padding: 14px 40px;
  border-radius: 35px;
  transition: all 0.5s;
  border: 2px solid #ffffff;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card.pricing-card-active
  .card-body
  .pricing-info
  .pricing-btn
  .btn:hover {
  box-shadow: inset 0 0 0 50px #0e82fd;
  color: #ffffff;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card.pricing-card-active
  .card-body
  .pricing-info
  .pricing-list {
  padding-bottom: 30px;
  border-bottom: 1px solid #f1f5f9;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card.pricing-card-active
  .card-body
  .pricing-info
  .pricing-list
  ul
  li {
  font-weight: 500;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card.pricing-card-active
  .card-body
  .pricing-info
  .pricing-list
  ul
  li::before {
  content: "\f21e";
  color: #ffffff;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card.pricing-card-active
  .card-body
  .pricing-info
  .pricing-list.pricing-list-two {
  border-bottom: none;
  padding: 30px 0 0 0;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card.pricing-card-active
  .card-body
  .pricing-info
  .pricing-list.pricing-list-two
  ul
  li::before {
  content: "+";
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card:hover {
  background: #0e82fd;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card:hover
  .pricing-amount
  span {
  color: #ffffff;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card:hover
  .pricing-list {
  border-bottom: 1px solid #f1f5f9;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card:hover
  .pricing-list
  ul
  li::before {
  color: #ffffff;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card:hover
  .pricing-list.pricing-list-two {
  border-bottom: none;
  padding: 30px 0 0 0;
}

.main-wrapper.home-ten
  .pricing-section.pricing-section-ten
  .pricing-card:hover
  .pricing-list.pricing-list-two
  ul
  li::before {
  content: "+";
}

.main-wrapper.home-ten .faq-section {
  position: relative;
}

.main-wrapper.home-ten .faq-section .floating-bg img {
  position: absolute;
  right: 10px;
  top: 50%;
  animation: beat 1.5s ease-in-out infinite;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .faq-section .floating-bg {
    display: none;
  }
}

.main-wrapper.home-ten .faq-section.faq-section-ten {
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten .faq-section.faq-section-ten {
    padding: 60px 0;
  }
}

.main-wrapper.home-ten .faq-section.faq-section-ten .faq-img {
  border-radius: 35px;
  overflow: hidden;
}

.main-wrapper.home-ten .faq-section.faq-section-ten .faq-img img {
  width: 100%;
}

.main-wrapper.home-ten .faq-section.faq-section-ten .section-header-one {
  text-align: start;
}

.main-wrapper.home-ten .faq-section.faq-section-ten .faq-info .accordion-item {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 35px;
  margin: 0 0 24px;
  padding: 20px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-ten
    .faq-section.faq-section-ten
    .faq-info
    .accordion-item {
    padding: 10px;
  }
}

.main-wrapper.home-ten
  .faq-section.faq-section-ten
  .faq-info
  .accordion-item
  .accordion-content {
  padding-left: 45px;
}

.main-wrapper.home-ten
  .faq-section.faq-section-ten
  .faq-info
  .accordion-button {
  font-weight: 600;
}

.main-wrapper.home-ten
  .faq-section.faq-section-ten
  .faq-info
  .accordion-button::after {
  display: none;
}

.main-wrapper.home-ten
  .faq-section.faq-section-ten
  .faq-info
  .accordion-button::before {
  content: "\e9b1";
  font-family: "feather";
  font-size: 20px;
  background: none;
  color: #0e82fd;
  background: #ffffff;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #e2e8f0;
}

.main-wrapper.home-ten
  .faq-section.faq-section-ten
  .faq-info
  .accordion-button:not(.collapsed)::after {
  display: none;
}

.main-wrapper.home-ten
  .faq-section.faq-section-ten
  .faq-info
  .accordion-button:not(.collapsed)::before {
  content: "\e996";
  background: #ffffff;
}

.main-wrapper.home-ten
  .faq-section.faq-section-ten
  .faq-info
  .accordion-button
  .accordion-item {
  border-radius: 35px;
}

@media (max-width: 568px) {
  .content-btn-block {
    flex-wrap: wrap;
  }

  .content-btn-block .need-to-know-content-btn {
    margin-bottom: 10px !important;
    width: 100%;
    text-align: center;
  }

  .footer.footer-one.footer-ten .footer-bottom .copyright {
    flex-direction: column;
  }

  .need-to-know-content .patient-rating {
    margin-bottom: 15px;
  }
}

.body-eleven {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}

@media (max-width: 1199.98px) {
  .body-eleven {
    font-size: 15px;
  }
}

.doctor-search-section.doctor-search-eleven {
  background: url(../images/bg/banner-11-bg.png);
  padding: 115px 0 300px;
  background-position: bottom center;
}

.doctor-search-section.doctor-search-eleven .banner-header {
  margin: 0 0 90px;
}

.doctor-search-section.doctor-search-eleven .banner-header p {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin: 0 0 10px;
  padding: 0 0 0 30px;
  position: relative;
}

.doctor-search-section.doctor-search-eleven .banner-header p::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 27px;
  height: 2px;
  background-color: #ffffff;
}

@media (max-width: 767.98px) {
  .doctor-search-section.doctor-search-eleven .banner-header p {
    font-size: 14px;
  }
}

.doctor-search-section.doctor-search-eleven .banner-header h1 {
  font-size: 50px;
  color: #ffffff;
  font-weight: 700;
  margin: 0 0 25px;
}

@media (max-width: 1399.98px) {
  .doctor-search-section.doctor-search-eleven .banner-header h1 {
    font-size: 48px;
  }
}

@media (max-width: 991.98px) {
  .doctor-search-section.doctor-search-eleven .banner-header h1 {
    font-size: 36px;
  }
}

@media (max-width: 767.98px) {
  .doctor-search-section.doctor-search-eleven .banner-header h1 {
    font-size: 24px;
  }
}

@media (max-width: 991.98px) {
  .doctor-search-section.doctor-search-eleven .banner-header {
    margin: 0 0 60px;
  }
}

@media (max-width: 767.98px) {
  .doctor-search-section.doctor-search-eleven .banner-header {
    margin: 0 0 40px;
  }
}

.doctor-search-section.doctor-search-eleven .banner-users h6 {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 15px;
}

.doctor-search-section.doctor-search-eleven .banner-users h6 img {
  margin: 0 10px 0 0;
}

@media (max-width: 767.98px) {
  .doctor-search-section.doctor-search-eleven .banner-users h6 {
    font-size: 14px;
  }
}

.doctor-search-section.doctor-search-eleven .banner-users ul {
  margin: 0;
  padding: 0;
}

.doctor-search-section.doctor-search-eleven .banner-users ul li {
  display: inline-block;
  margin: 0 0 0 -23px;
}

.doctor-search-section.doctor-search-eleven .banner-users ul li:first-child {
  margin: 0;
}

.doctor-search-section.doctor-search-eleven .banner-users ul li img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border: 2px solid #ffffff;
}

@media (max-width: 767.98px) {
  .doctor-search-section.doctor-search-eleven .banner-users ul li img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 767.98px) {
  .doctor-search-section.doctor-search-eleven .ban-bg .bg-01,
  .doctor-search-section.doctor-search-eleven .ban-bg .bg-02 {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .doctor-search-section.doctor-search-eleven {
    padding: 115px 0 200px;
  }
}

@media (max-width: 767.98px) {
  .doctor-search-section.doctor-search-eleven {
    padding: 115px 0 150px;
  }
}

@media (max-width: 767.98px) {
  .ban-bg {
    display: none;
  }
}

.ban-bg img {
  position: absolute;
}

.ban-bg img.bg-01 {
  left: 0;
  top: 0;
  animation: bouncing alternate infinite 2s;
  z-index: -1;
}

.ban-bg img.bg-02 {
  right: 0;
  top: 0;
  animation: bounce alternate infinite 2s;
  z-index: -1;
}

.ban-bg img.bg-03 {
  right: 10%;
  bottom: 0;
  animation: bounceup alternate infinite 2s;
}

@media (max-width: 767.98px) {
  .ban-bg img.bg-03 {
    width: 150px;
  }
}

.ban-bg img.bg-04 {
  bottom: 17%;
  left: 2%;
  z-index: -1;
}

@media (max-width: 1399.98px) {
  .ban-bg img.bg-04 {
    left: 1%;
  }
}

.ban-bg img.bg-05 {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  z-index: -1;
}

@keyframes bouncing {
  to {
    transform: translateX(5px);
  }
}

@keyframes bounce {
  to {
    transform: translateX(-5px);
  }
}

@keyframes bounceup {
  to {
    transform: translateY(-10px);
  }
}

.btn-light-blue {
  background: #0ce0ff;
  color: #ffffff;
  box-shadow: inset 0 0 0 0 #ffffff;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  padding: 13px 24px;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}

.btn-light-blue:hover {
  background: #ffffff;
  color: #1c5b91;
  box-shadow: inset 0 60px 0 0 #ffffff;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}

@media (max-width: 767.98px) {
  .btn-light-blue {
    font-size: 15px;
    padding: 10px 20px;
  }
}

.sec-heading-eye {
  margin: 0 0 50px;
}

.sec-heading-eye img {
  margin: 0 0 15px;
}

.sec-heading-eye h2 {
  font-size: 40px;
  font-weight: 600;
  color: #1b5a90;
  margin: 0 0 15px;
}

.sec-heading-eye h2 span {
  color: #1f2937;
}

@media (max-width: 991.98px) {
  .sec-heading-eye h2 {
    font-size: 34px;
  }
}

@media (max-width: 767.98px) {
  .sec-heading-eye h2 {
    font-size: 26px;
  }
}

.sec-heading-eye p {
  font-size: 18px;
  color: #6b7280;
}

@media (max-width: 991.98px) {
  .sec-heading-eye p {
    font-size: 16px;
  }
}

@media (max-width: 991.98px) {
  .sec-heading-eye {
    margin-bottom: 30px;
  }
}

.special-section {
  position: relative;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .special-section {
    padding: 40px 0;
  }
}

.special-item {
  margin: 0 0 50px;
}

.special-item .special-icon {
  text-align: center;
  margin: 0 auto 15px;
  height: 45px;
}

.special-item .special-icon a {
  width: 110px;
  height: 110px;
  background-color: #1b5a90;
  border-radius: 100% 0px;
  transform: rotate(45deg);
  text-align: center;
  margin: -70px 0 auto;
}

.special-item .special-icon a i {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  color: #ffffff;
}

.special-item h6 {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  text-align: center;
  color: #1f2937;
}

.special-item h6 a {
  color: #1f2937;
}

.special-item h6 a:hover {
  color: #1b5a90;
}

.owl-them .owl-nav .owl-prev,
.owl-them .owl-nav .owl-next {
  width: 40px;
  height: 40px;
  background: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  position: absolute;
  top: 20%;
  z-index: 1;
}

.owl-them .owl-nav .owl-prev:hover,
.owl-them .owl-nav .owl-next:hover {
  background: #1c5b91 !important;
  color: #ffffff;
}

.owl-them .owl-nav .owl-prev {
  left: -20px;
}

@media (max-width: 991.98px) {
  .owl-them .owl-nav .owl-prev {
    left: -10px;
  }
}

.owl-them .owl-nav .owl-next {
  right: -20px;
}

@media (max-width: 991.98px) {
  .owl-them .owl-nav .owl-next {
    right: -10px;
  }
}

.owl-them .owl-dots {
  text-align: center;
}

.owl-them .owl-dots .owl-dot {
  margin: 0 5px 0 0;
}

.owl-them .owl-dots .owl-dot.active span {
  background: #1b5a90;
}

.owl-them .owl-dots .owl-dot span {
  width: 9px;
  height: 9px;
  line-height: 12px;
  margin: 0;
  background: #d1d5db;
  border-radius: 50%;
  display: block;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.center-section {
  position: relative;
  padding: 0 0 80px;
}

@media (max-width: 991.98px) {
  .center-section {
    padding: 0 0 40px;
  }
}

@media (max-width: 767.98px) {
  .center-section {
    padding: 0 0 16px;
  }
}

.center-img {
  position: relative;
  margin: 0 20px 0 0;
}

.center-img .center-service {
  position: absolute;
  right: 0;
  bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  padding: 15px;
  text-align: left;
}

.center-img .center-service span {
  width: 40px;
  height: 40px;
  background: #1b5a90;
  color: #ffffff;
  border-radius: 100px;
  margin: 0 15px 0 0;
  flex-shrink: 0;
}

.center-img .center-service h6 {
  font-size: 16px;
  font-weight: 500;
  color: #1f2937;
  margin: 0 0 5px;
}

.center-img .center-service p {
  margin: 0;
}

@media (max-width: 991.98px) {
  .center-img .center-service {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
  }
}

@media (max-width: 575.98px) {
  .center-img .center-service {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .center-img {
    text-align: center;
    margin: 0 0 24px 0;
  }
}

.center-info .sec-heading-eye {
  margin: 0 0 15px;
}

.center-info p {
  margin: 0 0 15px;
}

.center-info p::last-child {
  margin: 0 0 15px;
}

.care-box span img {
  margin: 0 0 15px;
}

.care-box h6 {
  font-size: 16px;
  font-weight: 500;
  color: #1f2937;
  margin: 0 0 15px;
}

.care-box a {
  font-size: 14px;
  font-weight: 500;
  color: #1b5a90;
}

.care-box a:hover {
  color: #0e82fd;
}

.care-box a i {
  margin: 0 0 0 5px;
  font-size: 12px;
}

@media (max-width: 991.98px) {
  .care-box {
    margin-bottom: 24px;
  }
}

.counter-section {
  background: linear-gradient(
    90deg,
    #1b5a90 0%,
    #13a0ca 66.9%,
    #0ce0ff 106.51%
  );
  padding: 80px 0 56px;
  position: relative;
}

@media (max-width: 991.98px) {
  .counter-section {
    padding: 40px 0 16px;
  }
}

.bg-06 {
  left: 0;
  bottom: 0;
}

.bg-07 {
  right: 0;
  bottom: 0;
}

.count-box {
  margin: 0 0 24px;
}

.count-box .count-icon {
  width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 100px;
  margin: 0 26px 0 0;
  flex-shrink: 0;
}

.count-box h3 {
  font-size: 36px;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 15px;
}

@media (max-width: 991.98px) {
  .count-box h3 {
    font-size: 30px;
  }
}

@media (max-width: 767.98px) {
  .count-box h3 {
    font-size: 24px;
  }
}

.count-box p {
  color: #ffffff;
  margin: 0;
}

.eyeclinics-section {
  padding: 80px 0;
  position: relative;
}

@media (max-width: 991.98px) {
  .eyeclinics-section {
    padding: 40px 0;
  }
}

.bg-08 {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.our-doctors-card.eye-doc {
  margin: 0 0 50px;
}

.our-doctors-card.eye-doc .doctors-header {
  overflow: hidden;
}

.our-doctors-card.eye-doc .doctors-header img {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.our-doctors-card.eye-doc .doctors-header:hover img {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}

.our-doctors-card.eye-doc .doctors-body {
  padding: 15px;
}

.our-doctors-card.eye-doc .doctors-body h4 {
  font-weight: 600;
  margin: 0 0 5px;
}

.our-doctors-card.eye-doc .doctors-body h4 a {
  color: #1f2937;
}

.our-doctors-card.eye-doc .doctors-body h4 a:hover {
  color: #1b5a90;
}

.our-doctors-card.eye-doc .doctors-body p {
  margin: 0 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.our-doctors-card.eye-doc .doctors-body h6 {
  font-size: 14px;
  margin: 0 0 15px;
}

.our-doctors-card.eye-doc .doctors-body .rating i.filled {
  color: #ffaf14;
}

.our-doctors-card.eye-doc .doctors-body .rating span {
  font-size: 14px;
  color: #000000;
  margin: 0 0 0 8px;
}

@media (max-width: 991.98px) {
  .our-doctors-card.eye-doc {
    margin: 0 0 30px;
  }
}

.facilities-section {
  padding: 80px 0 56px;
}

@media (max-width: 991.98px) {
  .facilities-section {
    padding: 40px 0 16px;
  }
}

.facility-box {
  margin: 0 0 24px;
}

.facility-box .facility-img {
  margin: 0 0 15px;
}

.facility-box .facility-img img {
  width: 100%;
}

.our-blog-section.eye-blog {
  background: linear-gradient(
    88.66deg,
    #d6eeff 1.08%,
    #f9fdff 26.56%,
    #ffffff 76.5%,
    #e6fcff 98.92%
  );
}

.our-blog-section.eye-blog .our-blogs {
  border-bottom: 0;
  border-radius: 0;
  margin: 0 0 50px;
}

.our-blog-section.eye-blog .our-blogs .blogs-img {
  overflow: hidden;
}

.our-blog-section.eye-blog .our-blogs .blogs-img .blog-inner-img {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.our-blog-section.eye-blog .our-blogs .blogs-img:hover .blog-inner-img {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}

.our-blog-section.eye-blog .our-blogs .blogs-img .blogs-overlay {
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 0;
  bottom: 0;
  width: 100%;
}

.our-blog-section.eye-blog .our-blogs .blogs-img .blogs-overlay .blog-name img {
  height: 40px;
  width: 40px !important;
  margin-right: 5px;
}

.our-blog-section.eye-blog .our-blogs .blogs-img .blogs-overlay .blog-name div {
  width: 60%;
}

.our-blog-section.eye-blog
  .our-blogs
  .blogs-img
  .blogs-overlay
  .blog-name
  div
  a {
  margin: 0 0 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: normal;
}

.our-blog-section.eye-blog
  .our-blogs
  .blogs-img
  .blogs-overlay
  .blog-name
  div
  a:hover {
  color: #0e82fd;
}

.our-blog-section.eye-blog
  .our-blogs
  .blogs-img
  .blogs-overlay
  .blog-name
  div
  a
  a {
  color: #1f2937;
}

.our-blog-section.eye-blog
  .our-blogs
  .blogs-img
  .blogs-overlay
  .blog-name
  div
  p {
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}

.our-blog-section.eye-blog .our-blogs .blogs-img .blogs-overlay .blog-cat {
  background: #1b5a90;
  font-weight: 500;
  font-size: 13px;
  padding: 4px 10px;
  color: #ffffff;
}

.our-blog-section.eye-blog .our-blogs .blogs-info h4 {
  margin: 0 0 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.our-blog-section.eye-blog .our-blogs .blogs-info h4 a {
  color: #1f2937;
}

.our-blog-section.eye-blog .our-blogs .blogs-info h4 a:hover {
  color: #1b5a90;
}

.our-blog-section.eye-blog .our-blogs .blogs-info p {
  font-size: 16px;
  color: #6b7280;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.our-blog-section.eye-blog .our-blogs .blogs-info .blogs-btn {
  font-weight: 500;
  font-size: 14px;
  color: #1b5a90;
}

.our-blog-section.eye-blog .our-blogs .blogs-info .blogs-btn i {
  margin: 0 0 0 5px;
}

.our-blog-section.eye-blog .our-blogs .blogs-info .blogs-btn:hover {
  color: #0e82fd;
}

.appointment-section {
  background-color: #1b5a90;
  position: relative;
  z-index: 1;
  padding-top: 325px;
}

@media (max-width: 767.98px) {
  .appointment-section {
    padding-top: 0;
  }
}

.appointment-section::before {
  position: absolute;
  top: -1px;
  left: 0;
  background: url(../images/bg/app-bg.jpg) no-repeat center top;
  content: "";
  width: 100%;
  height: 100%;
  top: -1px;
  z-index: -1;
}

@media (max-width: 767.98px) {
  .appointment-section::before {
    background: none;
  }
}

.appointment-form {
  padding: 80px 0;
  position: relative;
}

@media (max-width: 991.98px) {
  .appointment-form {
    padding: 40px 0;
  }
}

.bg-09 {
  bottom: 0;
  right: 15%;
}

.bg-10 {
  bottom: 0;
  left: 3%;
}

.appointment-form .doctor-search-form .form-group {
  margin: 0 0 15px;
}

.appointment-form .doctor-search-form .form-group .form-custom input,
.appointment-form .doctor-search-form .form-group .form-custom select {
  background: #2a9d36;
  box-shadow: none;
  color: #ffffff;
  border-radius: 0;
  min-height: 50px;
}

.appointment-form
  .doctor-search-form
  .form-group
  .form-custom
  input::placeholder,
.appointment-form
  .doctor-search-form
  .form-group
  .form-custom
  select::placeholder {
  color: #ffffff;
}

.appointment-form
  .doctor-search-form
  .form-group
  .form-custom
  .select2-container
  .select2-selection--single {
  height: 50px;
  background-color: #2a9d36;
  border-color: #2a9d36;
}

.appointment-form
  .doctor-search-form
  .form-group
  .form-custom
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  color: #ffffff;
}

.appointment-form
  .doctor-search-form
  .form-select
  .form-group
  .form-control
  .form-custom
  .select2-container
  .select2-selection--single
  .select2-selection__rendered
  .select2-results__option--highlighted[aria-selected] {
  background-color: #2a9d36;
}

.appointment-form .doctor-search-form .form-group .form-custom i {
  color: #ffffff;
}

.app-btn {
  border-radius: 100px;
  margin: 35px 0 0;
  padding: 11px 26px;
}

.faq-eye-section {
  padding: 80px 0;
  position: relative;
}

@media (max-width: 991.98px) {
  .faq-eye-section {
    padding: 40px 0;
  }
}

.faq-wrap ul {
  display: block;
  margin: 0;
  padding: 0;
}

.faq-wrap ul li {
  list-style: none;
}

.faq-wrap ul li a {
  background: #f0f4f6;
  margin: 0 0 15px;
  padding: 15px;
  color: #1f2937;
}

.faq-wrap ul li a span {
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 100px;
  margin: 0 10px 0 0;
  flex-shrink: 0;
  color: #1f2937;
}

.faq-wrap ul li a.active {
  background: #1b5a90;
  color: #ffffff;
}

.faq-content img {
  margin: 0 0 29px;
}

.faq-content p {
  margin: 0 0 15px;
}

.faq-content p:last-child {
  margin: 0;
}

.eye-testimonial-section {
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .eye-testimonial-section {
    padding: 40px 0;
  }
}

.testi-img {
  position: relative;
  margin: 43px 0 15px;
}

.testi-img img {
  padding: 0 43px 0 0;
}

.testi-img .testi-icon {
  width: 86px;
  height: 86px;
  background: #0ce0ff;
  border-radius: 100px;
  font-size: 16px;
  color: #ffffff;
  position: absolute;
  top: -43px;
  right: 0;
}

.testi-users {
  margin: 0 43px 0 0;
}

.testi-users ul {
  margin: 0;
  padding: 0;
}

.testi-users ul li {
  display: inline-block;
  margin: 0 0 0 -23px;
}

.testi-users ul li:first-child {
  margin: 0;
}

.testi-users ul li img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border: 2px solid #ffffff;
}

@media (max-width: 767.98px) {
  .testi-users ul li img {
    width: 50px;
    height: 50px;
  }
}

.testimonial-wrap {
  position: relative;
}

.testimonial-wrap h4 {
  margin: 0 0 30px;
}

.testimonial-wrap p {
  margin: 0 0 30px;
}

.testimonial-wrap .testimonial-user h6 {
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
  margin: 0 0 5px;
}

.testimonial-wrap .testimonial-user p {
  font-size: 14px;
  margin: 0;
}

.testimonial-wrap::before {
  content: "";
  background: url("../images/icons/test-line.svg") no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 84px;
  height: 14px;
}

.slide-11 button {
  background: transparent;
  border: 0;
  padding: 0;
}

.slide-11 button.owl-next {
  margin: 0 0 0 10px;
}

.store-section {
  padding: 80px 0;
  position: relative;
  background: #f0f4f7 url("../images/bg/store-bg.png") no-repeat;
  background-position: top right;
}

.store-tab {
  margin: 0 0 35px;
}

.store-tab li {
  display: inline-block;
  margin: 0 0 15px 15px;
}

.store-tab li a {
  padding: 10px 15px;
  background: #ffffff;
  border-radius: 100px;
  color: #1f2937;
  display: inline-block;
}

.store-tab li a.active,
.store-tab li a:hover {
  background: #1b5a90;
  color: #ffffff;
}

.store-item {
  margin: 0 0 50px;
  padding: 15px;
  background: #ffffff;
}

.store-item .store-img {
  position: relative;
  overflow: hidden;
  margin: 0 0 15px;
}

.store-item .store-img img {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.store-item .store-img .glass-cat {
  background: #0ce0ff;
  color: #ffffff;
  font-size: 14px;
  padding: 5px 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.store-item .store-img:hover img {
  transform: scale(1.1) rotate(0);
  -webkit-transform: scale(1.1) rotate(0);
  -ms-transform: scale(1.1) rotate(0);
}

.store-item .store-content .store-cat {
  background: #1b5a90;
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  margin: 0 0 5px;
  padding: 1px 5px;
}

.store-item .store-content h4 {
  font-weight: 600;
  margin: 0 0 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.store-item .store-content h4 a {
  color: #1f2937;
}

.store-item .store-content h4 a:hover {
  color: #1b5a90;
}

.store-item .store-content p {
  margin: 0 0 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.store-item .store-content .price-tag h5 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}

.store-item .store-content .price-tag .color-selection {
  margin: 0;
  padding: 0;
}

.store-item .store-content .price-tag .color-selection li {
  list-style: none;
  display: inline-block;
  margin-right: 5px;
}

.store-item .store-content .price-tag .color-selection li span i {
  font-size: 16px;
  outline: 1px solid #d9d9d9;
  outline-offset: 3px;
  border-radius: 50%;
}

.store-item .store-content .price-tag .color-selection li span.black-glass {
  color: #131218;
}

.store-item .store-content .price-tag .color-selection li span.blue-glass {
  color: #2369a6;
}

.store-item .store-content .price-tag .color-selection li span.gold-glass {
  color: #ddbc5b;
}

.store-item .store-content .price-tag .color-selection li span.grey-glass {
  color: #666666;
}

.store-item .store-content .price-tag .color-selection li span.sand-glass {
  color: #fdeec3;
}

.store-item .store-content .price-tag .color-selection li span.green-glass {
  color: #13c2c2;
}

.store-item .store-content .price-tag .color-selection li:last-child {
  margin-right: 0;
}

.offer-section {
  padding-top: 124px;
}

@media (max-width: 991.98px) {
  .offer-section {
    padding-top: 73px;
  }
}

.offer-wrap {
  background: radial-gradient(50% 50% at 50% 50%, #328cd9 0%, #1b5a90 100%);
  margin: 0;
  padding: 0 24px;
}

@media (max-width: 991.98px) {
  .offer-wrap {
    margin: 0 0 60px 0;
    padding: 20px 24px !important;
  }
}

.offer-wrap.flat-offer {
  background: radial-gradient(50% 50% at 50% 50%, #97f0ff 0%, #0cbcda 100%);
}

@media (max-width: 991.98px) {
  .offer-wrap.flat-offer {
    margin-bottom: 0;
  }
}

.offer-wrap.flat-offer .btn {
  background: #1c5c92;
}

.offer-wrap.flat-offer .btn:hover {
  color: #1c5c92;
}

.offer-wrap .off-img {
  margin: -45px 0 24px;
}

@media (max-width: 767.98px) {
  .offer-wrap .off-img {
    text-align: center;
  }
}

.offer-wrap .off-info {
  position: relative;
  text-align: center;
}

.offer-wrap .off-info h6 {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 5px;
}

.offer-wrap .off-info p {
  margin: 0 0 5px;
  padding: 0 17px;
  position: relative;
  color: #ffffff;
  display: inline-block;
}

.offer-wrap .off-info p::before {
  content: "";
  background: #ffffff;
  width: 15px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.offer-wrap .off-info p::after {
  content: "";
  background: #ffffff;
  width: 15px;
  height: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.offer-wrap .off-info h5 {
  color: #ffffff;
  margin: 0 0 15px;
}

.offer-wrap .off-info .btn {
  font-size: 16px;
  padding: 3px 42px;
  border-radius: 100px;
}

.offer-wrap .off-info::before,
.offer-wrap .off-info::after {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  height: 85%;
  border: 1px solid;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.offer-wrap .off-info::before {
  bottom: 0;
  left: 0;
  top: 8px;
  border-top-color: #ffffff;
  border-left-color: #ffffff;
  border-bottom-color: #ffffff;
  border-right-color: transparent;
}

.offer-wrap .off-info::after {
  bottom: 0;
  right: 0;
  top: 8px;
  border-top-color: #ffffff;
  border-left-color: transparent;
  border-bottom-color: #ffffff;
  border-right-color: #ffffff;
}

.main-wrapper.home-twelve {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-twelve {
    font-size: 15px;
  }
}

.main-wrapper.home-twelve .row-gap {
  row-gap: 24px;
}

.main-wrapper.home-twelve a:hover {
  color: #0e82fd;
}

.main-wrapper.home-twelve .banner-section-fourteen.banner-section-twelve {
  background: #f9f9f9;
  padding: 200px 0 80px 0;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve .banner-section-fourteen.banner-section-twelve {
    padding: 150px 0 50px 0;
  }
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve .banner-section-fourteen.banner-section-twelve {
    padding: 100px 0 50px 0;
  }
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-section-twelve-bg
  img {
  position: absolute;
  z-index: -1;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-section-twelve-bg
  img:nth-child(1) {
  left: 0;
  bottom: 0;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-section-twelve-bg
  img:nth-child(2) {
  right: 0;
  top: 100px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve
    .banner-section-fourteen.banner-section-twelve
    .banner-section-twelve-bg
    img:nth-child(2) {
    display: none;
  }
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-img.banner-img-twelve {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  position: relative;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-img.banner-img-twelve
  img:nth-child(2) {
  animation: movers 1s ease infinite alternate;
  animation-delay: 1s;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-img.banner-img-twelve
  .banner-banner-img-twelve-bg
  img {
  position: absolute;
  z-index: -1;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-img.banner-img-twelve
  .banner-banner-img-twelve-bg
  img:nth-child(1) {
  bottom: 9%;
  right: 46%;
  animation: gelatine 2s infinite;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-img.banner-img-twelve
  .banner-banner-img-twelve-bg
  img:nth-child(2) {
  left: 0;
  animation: grow 2s ease infinite alternate;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-img.banner-img-twelve
  .banner-banner-img-twelve-bg
  img:nth-child(3) {
  right: 48%;
  top: -92px;
  animation: movers 2s infinite alternate;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-img.banner-img-twelve
  .banner-banner-img-twelve-bg
  img:nth-child(4) {
  bottom: 22%;
  right: 30%;
  animation: movers 2s infinite alternate;
}

@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(0.9, 1.1);
  }

  50% {
    transform: scale(1.1, 0.9);
  }

  75% {
    transform: scale(0.95, 1.05);
  }
}

@keyframes grow {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve
    .banner-section-fourteen.banner-section-twelve
    .banner-img.banner-img-twelve {
    display: none;
  }
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-content-fourteen {
  margin: 0;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-content-fourteen
  h1 {
  color: #0e82fd;
  max-width: 460px;
  margin: 0 0 20px 0;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-content-fourteen
  h1
  span {
  color: #1f2937;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve
    .banner-section-fourteen.banner-section-twelve
    .banner-content-fourteen
    h1 {
    margin: auto;
  }
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-content-fourteen
  .banner-btns-fourteen
  .btn-primary {
  background: transparent;
  color: #0e82fd;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-content-fourteen
  .banner-btns-fourteen
  .btn-primary:hover {
  background: #0e82fd;
  color: #ffffff;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-content-fourteen
  .banner-btns-fourteen
  .btn-primary:nth-child(1) {
  background: #0e82fd;
  color: #ffffff;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-content-fourteen
  .banner-btns-fourteen
  .btn-primary:nth-child(1):hover {
  background: transparent;
  color: #0e82fd !important;
  border: 1px solid #0e82fd;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .banner-content-fourteen
  .banner-btns-fourteen
  .btn-primary:nth-child(2):hover {
  color: #ffffff;
  border-color: #0e82fd;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .search-box-fourteen {
  padding: 20px;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .search-box-fourteen
  .form-block {
  align-items: center;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .search-box-fourteen
  .form-block
  .search-input
  label {
  position: relative;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .search-box-fourteen
  .form-block
  .search-input
  label::after {
  position: absolute;
  content: "\f274";
  font-family: "Font Awesome 5 Free";
  color: #0e82fd;
  font-weight: 900;
  right: -24px;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .search-box-fourteen
  .form-block
  .search-input
  label.location-icon::after {
  position: absolute;
  content: "\f3c5";
  font-family: "Font Awesome 5 Free";
  color: #0e82fd;
  font-weight: 900;
  right: -24px;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .search-box-fourteen
  .form-block
  .search-input
  .form-control::placeholder {
  font-weight: 600;
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .search-box-fourteen
  .form-block
  .search-btn {
  margin: 0;
}

@media (max-width: 767.98px) {
  .main-wrapper.home-twelve
    .banner-section-fourteen.banner-section-twelve
    .search-box-fourteen
    .form-block
    .search-btn {
    width: 100%;
  }
}

.main-wrapper.home-twelve
  .banner-section-fourteen.banner-section-twelve
  .search-box-fourteen
  .form-block
  .search-btn
  .btn.btn-primary {
  padding: 14px 16px;
}

.main-wrapper.home-twelve .section-header-fourteen {
  text-align: center;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve .section-header-fourteen {
    text-align: center !important;
  }
}

.main-wrapper.home-twelve .section-header-fourteen .service-inner-fourteen {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve .section-header-fourteen .service-inner-fourteen {
    justify-content: center !important;
  }
}

.main-wrapper.home-twelve
  .section-header-fourteen
  .service-inner-fourteen
  .service-inner-fourteen-one {
  background: #0e82fd;
  content: "";
  width: 50px;
  height: 3px;
}

.main-wrapper.home-twelve
  .section-header-fourteen
  .service-inner-fourteen
  .service-inner-fourteen-two
  h3 {
  color: #0e82fd;
  font-size: 14px;
  margin: 0 0;
  padding: 10px 20px;
  border-radius: 100px;
  font-weight: 400;
  background: #e3f2f9;
  z-index: 99;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-twelve
    .section-header-fourteen
    .service-inner-fourteen
    .service-inner-fourteen-two
    h3 {
    font-size: 14px;
  }
}

.main-wrapper.home-twelve
  .section-header-fourteen
  .service-inner-fourteen
  .service-inner-fourteen-three {
  background: #0e82fd;
  content: "";
  width: 50px;
  height: 3px;
}

.main-wrapper.home-twelve .section-header-fourteen h2 {
  color: #1f2937;
  font-size: 40px;
  font-weight: 600;
  position: relative;
  margin: 0 0 10px 0;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-twelve .section-header-fourteen h2 {
    font-size: 32px;
  }
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve .section-header-fourteen h2 {
    margin: 0 0 10px 0;
  }
}

.main-wrapper.home-twelve .section-header-fourteen h2::before {
  position: absolute;
  flex-shrink: 0;
  width: 120px;
  height: 110px;
  content: "";
  background-image: url("../images/bg/header-after.png");
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.2s ease-in-out;
  top: -36px;
  transform: translate(-50%, 0);
  left: 50%;
  z-index: -1;
}

.main-wrapper.home-twelve .section-header-fourteen p {
  color: #6b7280;
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 50px 0;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve .section-header-fourteen p {
    margin: 0 0 35px 0;
    font-size: 16px;
  }
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve .section-header-fourteen p {
    margin: 0 0 30px 0;
  }
}

.main-wrapper.home-twelve .services-section-fourteen {
  background: #f9fafb;
  position: relative;
}

.main-wrapper.home-twelve .services-section-fourteen .floating-bg img {
  position: absolute;
}

.main-wrapper.home-twelve
  .services-section-fourteen
  .floating-bg
  img:nth-child(1) {
  right: 0;
  top: 150px;
}

.main-wrapper.home-twelve
  .services-section-fourteen
  .floating-bg
  img:nth-child(2) {
  left: 0;
  bottom: 100px;
}

.main-wrapper.home-twelve .services-section-fourteen .our-services-list {
  border-radius: 30px;
  padding: 30px;
  background: #ffffff;
  text-align: center;
  transition: all 0.5s;
}

.main-wrapper.home-twelve
  .services-section-fourteen
  .our-services-list
  .service-icon {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0e82fd;
  margin: auto;
  box-shadow: 0px 25px 50px rgba(14, 130, 253, 0.1);
  border-radius: 30px;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve
    .services-section-fourteen
    .our-services-list
    .service-icon {
    width: 120px;
    height: 120px;
  }
}

.main-wrapper.home-twelve .services-section-fourteen .our-services-list h4 {
  margin: 0;
  padding: 20px 0;
  font-size: 24px;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve .services-section-fourteen .our-services-list h4 {
    padding: 15px 0;
    font-size: 22px;
  }
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve .services-section-fourteen .our-services-list h4 {
    font-size: 20px;
  }
}

.main-wrapper.home-twelve .services-section-fourteen .our-services-list p {
  color: #6b7280;
  font-size: 14px;
  text-align: center;
  margin: 0;
}

.main-wrapper.home-twelve .services-section-fourteen .our-services-list:hover {
  cursor: pointer;
  background: #334155;
  box-shadow: 0px 20px 50px rgba(51, 65, 85, 0.05);
  transform: translateY(-10px);
}

.main-wrapper.home-twelve
  .services-section-fourteen
  .our-services-list:hover
  .service-icon {
  transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
  -ms-transform: rotateY(360deg);
  background: #ffffff;
}

.main-wrapper.home-twelve
  .services-section-fourteen
  .our-services-list:hover
  .service-icon
  img {
  filter: invert(1) brightness(100);
}

.main-wrapper.home-twelve
  .services-section-fourteen
  .our-services-list:hover
  h4,
.main-wrapper.home-twelve
  .services-section-fourteen
  .our-services-list:hover
  p {
  color: #ffffff;
}

.main-wrapper.home-twelve .blog-section-fourteen.our-doctor-twelve {
  background: #f3f4f6;
  position: relative;
  z-index: 1;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .floating-bg
  img {
  position: absolute;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .floating-bg
  img:nth-child(1) {
  right: 0;
  top: 150px;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .floating-bg
  img:nth-child(2) {
  left: 0;
  bottom: 100px;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all {
  border-radius: 40px;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main {
  padding: 15px;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt {
  text-align: center;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-right-img {
  position: relative;
  margin: 0 0 20px 0;
  overflow: hidden;
  border-radius: 30px;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-right-img
  img {
  position: relative;
  border-radius: 30px;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  width: 100%;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-right-img:hover
  img {
  transform: scale(1.2);
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-top-content {
  position: absolute;
  top: auto;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 8px 0;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50px;
  min-width: 180px;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-top-content
  img {
  width: 35px;
  height: 35px;
  border-radius: 50px;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-top-content
  span {
  font-size: 14px;
  color: #0e82fd;
  font-weight: 600;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-top-content.content-pricing {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  transform: none;
  min-width: 65px;
  border-radius: 8px;
  background: #ffffff;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-top-content.content-pricing
  span {
  color: #1f2937;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-right-fourt-care {
  display: block;
  color: #1f2937;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-right-fourt-care
  a:hover {
  color: #0e82fd;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-articles-list {
  margin: 0 0 20px 0;
  justify-content: center;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-articles-list
  li {
  color: #6b7280;
  font-size: 14px;
  margin: 0 0px 0 0;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-articles-list
  li
  i {
  color: #d1d5db;
  margin-right: 6px;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-articles-list-two {
  margin: 0 0 10px 0;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-articles-list-two
  li {
  background: #dbeffe;
  color: #0e82fd;
  margin: 0 10px 10px 0;
  padding: 10px 15px;
  border-radius: 8px;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-articles-list-two
  li:last-child {
  margin: 0 0 10px 0;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-list-ratings {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-list-ratings
  i {
  color: rgba(26, 84, 199, 0.15);
  margin-right: 2px;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-list-ratings
  i:last-child {
  margin-right: 0;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-list-ratings
  i.rated {
  color: #ffc001;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-list-ratings
  span {
  color: #9ca3af;
  font-size: 14px;
  margin-left: 3px;
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .btn-primary {
  background: #0e82fd;
  color: #ffffff;
  border: 1px solid #0e82fd;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve
    .blog-section-fourteen.our-doctor-twelve
    .blog-inner-fourt-all
    .blog-inner-fourt-main
    .blog-inner-right-fourt
    .btn-primary {
    width: 100%;
  }
}

.main-wrapper.home-twelve
  .blog-section-fourteen.our-doctor-twelve
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .btn-primary:hover {
  background: #ffffff;
  color: #0e82fd;
}

.main-wrapper.home-twelve .clients-section-fourteen {
  position: relative;
  z-index: 1;
  padding: 80px 0;
  background: #f9fafb;
}

.main-wrapper.home-twelve .clients-section-fourteen .floating-bg img {
  position: absolute;
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .floating-bg
  img:nth-child(1) {
  right: 10px;
  top: 50px;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-twelve .clients-section-fourteen {
    padding: 80px 0;
  }
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-twelve .clients-section-fourteen {
    padding: 50px 0;
  }
}

.main-wrapper.home-twelve .clients-section-fourteen .section-header-fourteen {
  text-align: start;
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .section-header-fourteen
  h2 {
  display: inline-block;
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .section-header-fourteen
  .service-inner-fourteen {
  justify-content: start;
}

.main-wrapper.home-twelve .clients-section-fourteen .client-inner-main {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve .clients-section-fourteen .client-inner-main {
    display: none;
  }
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-slider-fourteen
  .owl-nav {
  text-align: end;
  margin-top: 40px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve
    .clients-section-fourteen
    .feedback-slider-fourteen
    .owl-nav {
    text-align: center;
    margin-top: 10px;
  }
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-slider-fourteen
  .owl-nav
  .owl-prev {
  background: #e2e8f0;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  color: #94a3b8;
  text-shadow: none;
  font-size: 24px;
  border: none;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve
    .clients-section-fourteen
    .feedback-slider-fourteen
    .owl-nav
    .owl-prev {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-slider-fourteen
  .owl-nav
  .owl-prev:hover {
  background: #bfe0fe;
  color: #1d7ed8;
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-slider-fourteen
  .owl-nav
  .owl-next {
  background: #e2e8f0;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  color: #94a3b8;
  text-shadow: none;
  font-size: 24px;
  border: none;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve
    .clients-section-fourteen
    .feedback-slider-fourteen
    .owl-nav
    .owl-next {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-slider-fourteen
  .owl-nav
  .owl-next:hover {
  background: #bfe0fe;
  color: #1d7ed8;
}

.main-wrapper.home-twelve .clients-section-fourteen .feedback-card {
  border-radius: 30px;
  margin: 0 0 0 0;
  background: transparent;
  border: none;
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-card
  .feedback-card-body {
  padding: 0px;
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .feedback-inner-img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 50px 0;
  border: 2px solid #10b981;
  border-radius: 40px;
  padding: 20px;
  gap: 20px;
  max-width: 660px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve
    .clients-section-fourteen
    .feedback-card
    .feedback-card-body
    .feedback-inner-main
    .feedback-inner-img {
    margin: auto;
    margin-bottom: 30px;
  }
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .feedback-inner-img
  h6 {
  color: #1f2937;
  font-size: 16px;
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .feedback-inner-img
  img {
  width: 100px;
  height: 100px;
  border-radius: 20px;
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .feedback-inner-img
  h4 {
  color: #1f2937;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve
    .clients-section-fourteen
    .feedback-card
    .feedback-card-body
    .feedback-inner-main
    .feedback-inner-img
    h4 {
    font-size: 18px;
  }
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .feedback-inner-img
  h6 {
  color: #94a3b8;
  font-size: 16px;
  margin: 0 0 20px 0;
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .feedback-inner-img
  .rating-fourteen {
  margin: 0 0 0 0;
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .feedback-inner-img
  .rating-fourteen
  .filled {
  color: #ffc001;
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .feedback-inner-img
  .rating-fourteen
  i {
  font-size: 18px;
}

.main-wrapper.home-twelve
  .clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  p {
  color: #6b7280;
  font-size: 20px;
  margin: 0 0 0 0;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve
    .clients-section-fourteen
    .feedback-card
    .feedback-card-body
    .feedback-inner-main
    p {
    font-size: 20px;
    text-align: center;
  }
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve
    .clients-section-fourteen
    .feedback-card
    .feedback-card-body
    .feedback-inner-main
    p {
    font-size: 17px;
  }
}

.main-wrapper.home-twelve .home-twelve-pricing {
  position: relative;
  z-index: 1;
  padding: 80px 0 80px 0;
  background: #f3f4f6;
}

.main-wrapper.home-twelve .home-twelve-pricing .price-card-twelve {
  height: 605px;
  background: #ffffff;
  border-radius: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.7s;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-bg
  img {
  position: absolute;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-bg
  img:nth-child(1) {
  bottom: 0;
  left: 0;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-bg
  img:nth-child(2) {
  bottom: 0;
  right: 0;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-bg
  img:nth-child(3) {
  top: 0;
  right: 0;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-content
  .card-title {
  margin-bottom: 0;
  text-align: center;
  margin-bottom: 40px;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-content
  .card-title
  h3 {
  font-weight: 600;
  font-size: 30px;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-content
  .card-title
  span {
  color: #0e82fd;
  font-size: 14px;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-content
  .price {
  margin-bottom: 40px;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-content
  .price
  h2 {
  color: #0e82fd;
  font-style: 48px;
  font-weight: 600;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-content
  .price
  h2
  span {
  font-size: 14px;
  color: #6b7280;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-content
  .pack-details {
  margin-bottom: 40px;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-content
  .pack-details
  ul {
  list-style: none;
  padding: 0;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-content
  .pack-details
  ul
  li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #6b7280;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-content
  .pack-details
  ul
  li
  i {
  margin-right: 10px;
  color: #0e82fd;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-content
  .price-btn
  a {
  min-width: 198px;
  background: #0e82fd;
  color: #ffffff;
  border-radius: 6px;
  text-align: center;
  border: 1px solid #0e82fd;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve
  .price-content
  .price-btn
  a:hover {
  background: #ffffff;
  color: #0e82fd;
}

.main-wrapper.home-twelve .home-twelve-pricing .price-card-twelve:hover,
.main-wrapper.home-twelve .home-twelve-pricing .price-card-twelve.active {
  cursor: pointer;
  background: #1e293b;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve:hover
  .price-content
  .card-title
  h3,
.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve.active
  .price-content
  .card-title
  h3 {
  color: #ffffff;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve:hover
  .price
  h2,
.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve.active
  .price
  h2 {
  color: #ffffff;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve:hover
  .price
  h2
  span,
.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve.active
  .price
  h2
  span {
  color: #ffffff;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve:hover
  .pack-details,
.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve.active
  .pack-details {
  margin-bottom: 40px;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve:hover
  .pack-details
  ul
  li,
.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve.active
  .pack-details
  ul
  li {
  color: #ffffff;
}

.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve:hover
  .pack-details
  ul
  li
  i,
.main-wrapper.home-twelve
  .home-twelve-pricing
  .price-card-twelve.active
  .pack-details
  ul
  li
  i {
  color: #0e82fd;
}

.main-wrapper.home-twelve .blog-section-fourteen {
  background: #f9fafb;
  position: relative;
  z-index: 1;
}

.main-wrapper.home-twelve
  .blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-right-fourt-care {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.main-wrapper.home-twelve
  .blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-right-fourt-care:hover {
  color: #0e82fd;
}

.main-wrapper.home-twelve
  .blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-top-content {
  padding: 5px 15px 5px 5px;
}

.main-wrapper.home-twelve .blog-section-fourteen .blog-btn-sec .btn-view {
  border: 1px solid #0e82fd;
}

.main-wrapper.home-twelve .blog-section-fourteen .blog-btn-sec .btn-view:hover {
  box-shadow: inset 220px 0 0 0 #ffffff;
  color: #0e82fd;
}

.main-wrapper.home-twelve .choose-us-fourteen {
  background: #f3f4f6;
  position: relative;
  z-index: 1;
}

.main-wrapper.home-twelve .why-us-content .section-title {
  align-items: start;
}

.main-wrapper.home-twelve .why-us-content .section-title h2 {
  font-size: 60px;
  font-weight: 500;
}

.main-wrapper.home-twelve .why-us-content .section-title p {
  font-size: 18px;
  font-weight: 500;
  text-align: start;
}

@keyframes updown {
  100% {
    transform: translateY(-10px);
  }
}

.main-wrapper.home-twelve .us-title h6 {
  font-weight: 500;
  font-size: 20px;
}

.main-wrapper.home-twelve .accordion-item:first-of-type {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.main-wrapper.home-twelve .accordion-item {
  background-color: transparent;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 20px;
  margin: 5px 0px 15px 0px;
}

.main-wrapper.home-twelve .accordion-header button {
  font-weight: 600;
  font-size: 20px;
  color: #333333;
}

.main-wrapper.home-twelve .accordion-header {
  margin-bottom: 0;
}

.main-wrapper.home-twelve .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve .accordion-item:first-of-type .accordion-button {
    font-size: 16px;
  }
}

.main-wrapper.home-twelve .accordion-item .accordion-button {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve .accordion-item .accordion-button {
    font-size: 16px;
  }
}

.main-wrapper.home-twelve .accordion-button:not(.collapsed) {
  background-color: #ffffff;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.main-wrapper.home-twelve .accordion-button:not(.collapsed) {
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.13);
}

.main-wrapper.home-twelve .shade {
  box-shadow: -8px 13px 80px rgba(27, 41, 80, 0.1);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.main-wrapper.home-twelve .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.main-wrapper.home-twelve .accordion-button::after {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  color: #0e82fd;
  font-weight: 900;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  right: 15px;
  background-size: auto;
  background-image: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dbeffe;
  border-radius: 8px;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve .accordion-button::after {
    width: 30px;
    height: 30px;
  }
}

.main-wrapper.home-twelve .accordion-button:not(.collapsed)::after {
  transform: rotateX(180deg);
  background-color: #0e82fd;
  color: #ffffff;
}

.main-wrapper.home-twelve .accordion-button:focus {
  z-index: 3;
  border-color: #fff;
  outline: 0;
  box-shadow: none;
}

.main-wrapper.home-twelve .accordion-body {
  padding: 0rem 6.25rem 1rem 6.26rem;
  background: #ffffff;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve .accordion-body {
    padding: 0rem 2rem 1rem 6.26rem;
  }
}

.main-wrapper.home-twelve .accordion-body h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6b7280;
}

.main-wrapper.home-twelve .accordion-button::before {
  flex-shrink: 0;
  width: 42px;
  height: 42px;
  content: "";
  background-image: url("../images/check.png");
  margin-right: 30px;
  background-repeat: no-repeat;
  transition: transform 0.2s ease-in-out;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve .accordion-button::before {
    margin-right: 15px;
  }
}

.main-wrapper.home-twelve .slide-nav-16 button.owl-next,
.main-wrapper.home-twelve .slide-nav-16 button.owl-prev,
.main-wrapper.home-twelve .slide-nav-15 button.owl-next,
.main-wrapper.home-twelve .slide-nav-15 button.owl-prev {
  background: #e2e8f0;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  color: #94a3b8;
  text-shadow: none;
  font-size: 24px;
  border: none;
  position: absolute;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve .slide-nav-16 button.owl-next,
  .main-wrapper.home-twelve .slide-nav-16 button.owl-prev,
  .main-wrapper.home-twelve .slide-nav-15 button.owl-next,
  .main-wrapper.home-twelve .slide-nav-15 button.owl-prev {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin: 0 10px 30px 0;
  }
}

.main-wrapper.home-twelve .slide-nav-16 button.owl-next:hover,
.main-wrapper.home-twelve .slide-nav-16 button.owl-prev:hover,
.main-wrapper.home-twelve .slide-nav-15 button.owl-next:hover,
.main-wrapper.home-twelve .slide-nav-15 button.owl-prev:hover {
  background: #bfe0fe;
  color: #1d7ed8;
}

.main-wrapper.home-twelve .slide-nav-16 button.owl-next,
.main-wrapper.home-twelve .slide-nav-15 button.owl-next {
  right: 15px;
  top: 50%;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve .slide-nav-16 button.owl-next,
  .main-wrapper.home-twelve .slide-nav-15 button.owl-next {
    z-index: 1;
  }
}

.main-wrapper.home-twelve .slide-nav-16 button.owl-prev,
.main-wrapper.home-twelve .slide-nav-15 button.owl-prev {
  left: 15px;
  top: 50%;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve .slide-nav-16 button.owl-prev,
  .main-wrapper.home-twelve .slide-nav-15 button.owl-prev {
    z-index: 1;
  }
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve .slide-nav-15 button.owl-next,
  .main-wrapper.home-twelve .slide-nav-15 button.owl-prev {
    top: 43%;
  }
}

.main-wrapper.home-twelve.home-thirteen .row-gap {
  row-gap: 24px;
}

.main-wrapper.home-twelve.home-thirteen a:hover {
  color: #0e82fd;
}

.main-wrapper.home-twelve.home-thirteen .common-padding {
  margin: 0;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen .common-padding {
    margin: 0;
    padding: 50px 0;
  }
}

.main-wrapper.home-twelve.home-thirteen .banner-section-thirteen {
  position: relative;
  background-image: url("../images/bg/home-13-banner.png");
  background-repeat: no-repeat;
  background-size: 100% 80%;
  background-position: top;
  padding: 165px 0 0px 0;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen .banner-section-thirteen {
    background-image: none;
    background-color: #0e82fd;
    padding: 135px 0 0px 0;
  }
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .banner-center-img
    img {
    display: none;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-content {
  padding-bottom: 0;
  text-align: center;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .banner-content {
    padding-bottom: 40px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-content
  h4 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 20px;
}

@media (max-width: 767.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .banner-content
    h4 {
    font-size: 16px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-content
  h1 {
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 20px;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-content
  p {
  font-size: 20px;
  color: #ffffff;
  max-width: 570px;
  margin: auto auto 20px auto;
}

@media (max-width: 767.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .banner-content
    p {
    font-size: 16px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-content
  .banner-btns-fourteen
  .btn-primary {
  padding: 20px 30px;
  background: #ffffff;
  color: #0e82fd;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.7s;
  box-shadow: inset 0 0 0 0 #ffffff;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .banner-content
    .banner-btns-fourteen
    .btn-primary {
    padding: 15px 20px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-content
  .banner-btns-fourteen
  .btn-primary:hover {
  background: #1c5b91;
  color: #ffffff;
  box-shadow: inset 0 100px 0 0 #1c5b91;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen {
  margin: 0;
  padding: 20px;
  box-shadow: 0px 19.9607px 87.3282px rgba(32, 36, 138, 0.06);
  border-radius: 10px;
  min-width: 100%;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-title {
  margin-bottom: 15px;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-title
  h5 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .search-box-one.search-box-thirteen
    .search-title
    h5 {
    font-size: 20px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-input {
  margin-right: 24px;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .search-box-one.search-box-thirteen
    .search-input {
    margin-right: 17px;
  }
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .search-box-one.search-box-thirteen
    .search-input {
    margin-bottom: 15px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-input.search-line-thirteen {
  width: 20%;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .search-box-one.search-box-thirteen
    .search-input.search-line-thirteen {
    width: 100%;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-input.search-map-line-thirteen {
  width: 19%;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .search-box-one.search-box-thirteen
    .search-input.search-map-line-thirteen {
    width: 100%;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-input.search-calendar-line-thirteen {
  width: 18%;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .search-box-one.search-box-thirteen
    .search-input.search-calendar-line-thirteen {
    width: 100%;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-input.search-users-line-thirteen {
  width: 18%;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .search-box-one.search-box-thirteen
    .search-input.search-users-line-thirteen {
    width: 100%;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-input
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-left: 46px;
  line-height: 56px;
  font-size: 16px;
  font-weight: 400;
  color: #6b7280;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .search-box-one.search-box-thirteen
    .search-input
    .select2-container
    .select2-selection--single
    .select2-selection__rendered {
    font-size: 14px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-input
  .select2-container--default
  .select2-selection--single {
  background: #f8fbff;
  border: none;
  border-radius: 4px;
  height: 54px;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-input
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 5px;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-input
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #6b7280 transparent transparent;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-input
  i {
  left: 20px;
  z-index: 1;
  font-size: 14px;
  color: #d1d5db;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-input
  input {
  padding: 0 10px 0 46px;
  background: #f8fbff;
  height: 54px;
  min-height: 54px;
  font-size: 15px;
  color: #6b7280;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .search-box-one.search-box-thirteen
    .search-input
    input {
    font-size: 14px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-input
  input:hover,
.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .search-input
  input:focus {
  background-color: #f8fbff !important;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .form-search-btn {
  padding-top: 0;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .search-box-one.search-box-thirteen
    .form-search-btn {
    float: none;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .search-box-one.search-box-thirteen
  .form-search-btn
  .btn {
  min-width: auto;
  padding: 14px 43px;
  border-radius: 4px;
  font-size: 14px;
}

@media (max-width: 1399.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .search-box-one.search-box-thirteen
    .form-search-btn
    .btn {
    padding: 14px 23px;
  }
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .search-box-one.search-box-thirteen
    .form-search-btn
    .btn {
    padding: 14px 14px;
  }
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .search-box-one.search-box-thirteen
    .form-search-btn
    .btn {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .banner-section-thirteen-bg {
    display: none;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-one {
  position: absolute;
  right: 50px;
  bottom: 250px;
  animation: elastic-spin 1s infinite ease;
  z-index: -1;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-two {
  position: absolute;
  bottom: 350px;
  left: 100px;
  animation: elastic-spin 1s infinite ease;
  z-index: -1;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-three {
  position: absolute;
  top: 350px;
  left: 130px;
  animation: elastic-spin 1s infinite ease;
  z-index: -1;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-four {
  position: absolute;
  right: 130px;
  bottom: 350px;
  animation: elastic-spin 1s infinite ease;
  z-index: -1;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-five {
  position: absolute;
  top: 600px;
  right: 130px;
  animation: elastic-spin 1s infinite ease;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-six {
  position: absolute;
  top: 500px;
  right: 50%;
  animation: elastic-spin 1s infinite ease;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-seven {
  position: absolute;
  top: 500px;
  left: 80%;
  animation: pulse 1s infinite ease-in-out alternate;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-eight {
  position: absolute;
  top: 350px;
  left: 30%;
  animation: pulse 1s infinite ease-in-out alternate;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-nine {
  position: absolute;
  top: 200px;
  left: 15%;
  animation: spin 3000ms linear infinite;
  z-index: -1;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-ten {
  position: absolute;
  top: 150px;
  right: 23%;
  animation: elastic-spin 1s infinite ease;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-eleven {
  position: absolute;
  top: 20%;
  right: 23%;
  animation: spin 3000ms linear infinite;
  z-index: -1;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-thirteen {
  position: absolute;
  top: 6%;
  right: 5%;
  animation: spin 3000ms linear infinite;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-fourteen {
  position: absolute;
  top: 8%;
  right: 5%;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .banner-section-thirteen
    .banner-section-thirteen-bg
    .banner-section-thirteen-bg-fourteen {
    display: none;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-fifteen {
  position: absolute;
  top: 17%;
  right: 0;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-sixteen {
  position: absolute;
  top: 12%;
  left: 0;
  z-index: -1;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-seventeen
  a {
  position: absolute;
  top: 65%;
  left: 20%;
  padding: 25px 30px;
  background: #00e3ff;
  color: #ffffff;
  box-shadow: 0px 19.9607px 87.3282px rgba(32, 36, 138, 0.06);
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-eighteen
  a {
  position: absolute;
  top: 45%;
  left: 23%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.4);
  color: #ffffff;
  backdrop-filter: blur(8px);
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-ninteen
  a {
  position: absolute;
  top: 30%;
  right: 24%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.4);
  color: #ffffff;
  backdrop-filter: blur(8px);
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  z-index: -1;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bg-twenty {
  position: absolute;
  top: 56%;
  left: 17%;
  z-index: -1;
}

.main-wrapper.home-twelve.home-thirteen
  .banner-section-thirteen
  .banner-section-thirteen-bg
  .banner-section-thirteen-bgs {
  position: absolute;
  top: 56%;
  right: 17%;
}

.main-wrapper.home-twelve.home-thirteen .services-section-thirteen {
  padding: 80px 0 56px 0;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen .services-section-thirteen {
    margin: 0;
    padding: 50px 0 26px 0;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .services-section-thirteen
  .service-thirteen-main {
  border-radius: 20px;
  margin: 0 0 24px 0;
  padding: 8px;
  transition: 0.5s;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .services-section-thirteen
    .service-thirteen-main {
    text-align: center;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .services-section-thirteen
  .service-thirteen-main
  .service-thirteen-all {
  padding: 20px;
  border-radius: 20px;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .services-section-thirteen
    .service-thirteen-main
    .service-thirteen-all {
    display: block;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .services-section-thirteen
  .service-thirteen-main
  .service-thirteen-all
  .service-img-thirteen {
  margin-right: 15px;
  position: relative;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .services-section-thirteen
    .service-thirteen-main
    .service-thirteen-all
    .service-img-thirteen {
    margin: 0 0 10px 0;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .services-section-thirteen
  .service-thirteen-main
  .service-thirteen-all
  .service-img-thirteen
  img {
  max-width: 120px;
  height: 120px;
  border-radius: 100%;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .services-section-thirteen
    .service-thirteen-main
    .service-thirteen-all
    .service-img-thirteen
    img {
    width: 120px;
  }
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .services-section-thirteen
    .service-thirteen-main
    .service-thirteen-all
    .service-img-thirteen
    img {
    width: 120px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .services-section-thirteen
  .service-thirteen-main
  .service-thirteen-all
  .service-img-thirteen
  .service-inner-img-third {
  position: absolute;
  top: 0;
  right: 0;
}

.main-wrapper.home-twelve.home-thirteen
  .services-section-thirteen
  .service-thirteen-main
  .service-thirteen-all
  .service-inner-contents
  h5 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #1f2937;
  text-transform: capitalize;
}

.main-wrapper.home-twelve.home-thirteen
  .services-section-thirteen
  .service-thirteen-main
  .service-thirteen-all
  .service-inner-contents
  p {
  margin-bottom: 0;
  font-size: 14px;
  color: #6b7280;
}

.main-wrapper.home-twelve.home-thirteen
  .services-section-thirteen
  .service-thirteen-main:hover {
  background: #ffffff;
  box-shadow: 0px 11px 21px 10px rgba(14, 130, 253, 0.08);
  transition: 0.5s;
  transform: translateY(-10px);
}

.main-wrapper.home-twelve.home-thirteen
  .services-section-thirteen
  .service-thirteen-main:hover
  .service-thirteen-all {
  background: #ffffff;
  border: 1px dashed #0e82fd;
}

.main-wrapper.home-twelve.home-thirteen .our-doctor-thirteen {
  background: url(../images/cloud-bg.png);
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen .our-doctor-thirteen {
    background-color: #f8fbff;
    background-image: none;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-thirteen-img
  img {
  height: 438px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .our-doctor-thirteen
    .our-doctor-thirteen-all
    .our-doctor-thirteen-img
    img {
    width: 100%;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content {
  background: #ffffff;
  box-shadow: 0px 19.9607px 87.3282px rgba(32, 36, 138, 0.06);
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  a {
  display: inline-block;
  color: #1f2937;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  a:hover {
  color: #0e82fd;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  .our-doctor-content-inner {
  margin-bottom: 20px;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  .our-doctor-content-inner
  span {
  padding: 5px 10px;
  background: rgba(14, 130, 253, 0.1);
  border-radius: 5px;
  font-weight: 600;
  color: #0e82fd;
  font-size: 14px;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  .our-doctor-content-inner
  .reviews-ratings
  p {
  color: #d1d5db;
  font-size: 12px;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  .our-doctor-content-inner
  .reviews-ratings
  p
  span {
  background: none;
  color: #6b7280;
  font-size: 16px;
  font-weight: 400;
  margin: 0 4px 0 0;
  padding: 0;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  .our-doctor-content-inner
  .reviews-ratings
  p
  span
  i {
  color: #fbbf24;
  background: transparent;
  width: auto;
  height: auto;
  border-radius: 0;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  h6 {
  color: #6b7280;
  font-weight: 600;
  margin-bottom: 10px;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  p {
  color: #6b7280;
  font-size: 14px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  p
  i {
  width: 24px;
  height: 24px;
  background: rgba(154, 154, 154, 0.1);
  border-radius: 5px;
  margin-right: 5px;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-all
  .our-doctor-content
  .our-doctor-thirteen-imgone {
  position: absolute;
  right: 0;
  bottom: 0;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-one
  img {
  position: absolute;
  z-index: 0;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-one
  img:nth-child(1) {
  left: 3%;
  top: 45%;
  animation: spin 2000ms linear infinite;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-one
  img:nth-child(2) {
  right: 7%;
  bottom: 40%;
  animation: spin 2000ms linear infinite;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-one
  img:nth-child(3) {
  right: 4%;
  top: 20%;
  animation: spin 2000ms linear infinite;
}

.main-wrapper.home-twelve.home-thirteen
  .our-doctor-thirteen
  .our-doctor-thirteen-one
  img:nth-child(4) {
  left: 5%;
  bottom: 30%;
  animation: spin 2000ms linear infinite;
}

.main-wrapper.home-twelve.home-thirteen .choose-us-section-thirteen {
  background: #f8fbff;
}

.main-wrapper.home-twelve.home-thirteen
  .choose-us-section-thirteen
  .choose-us-inner-all
  .choose-us-thirteen-img {
  text-align: center;
  position: relative;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .choose-us-section-thirteen
    .choose-us-inner-all
    .choose-us-thirteen-img {
    display: none;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .choose-us-section-thirteen
  .choose-us-inner-all
  .choose-us-thirteen-img
  img {
  border-radius: 20px;
}

.main-wrapper.home-twelve.home-thirteen
  .choose-us-section-thirteen
  .choose-us-inner-all
  .choose-us-thirteen-img
  .choose-us-thirteen-imgone
  img {
  position: absolute;
  z-index: 0;
}

.main-wrapper.home-twelve.home-thirteen
  .choose-us-section-thirteen
  .choose-us-inner-all
  .choose-us-thirteen-img
  .choose-us-thirteen-imgone
  img:nth-child(1) {
  right: 4%;
  bottom: 0%;
}

.main-wrapper.home-twelve.home-thirteen
  .choose-us-section-thirteen
  .choose-us-inner-all
  .choose-us-thirteen-img
  .choose-us-thirteen-imgone
  img:nth-child(2) {
  left: -5%;
  top: 7%;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .choose-us-section-thirteen
    .choose-us-inner-all
    .choose-us-thirteen-img
    .choose-us-thirteen-imgone
    img:nth-child(2) {
    left: -20%;
  }
}

.main-wrapper.home-twelve.home-thirteen .choose-us-section-thirteen ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-wrapper.home-twelve.home-thirteen .choose-us-section-thirteen ul li {
  margin-bottom: 20px;
  transition: 0.5s;
  padding: 15px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen .choose-us-section-thirteen ul li {
    padding: 20px;
    margin-bottom: 20px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .choose-us-section-thirteen
  ul
  li:last-child {
  margin-bottom: 0;
}

.main-wrapper.home-twelve.home-thirteen
  .choose-us-section-thirteen
  ul
  li
  .choose-us-content-thirteen {
  display: flex;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .choose-us-section-thirteen
    ul
    li
    .choose-us-content-thirteen {
    display: block;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .choose-us-section-thirteen
  ul
  li
  .choose-us-content-thirteen
  .chooseus-contents {
  min-width: 50px;
  height: 50px;
  background: rgba(160, 240, 253, 0.2);
  border-radius: 10.8333px;
  font-size: 24px;
  color: #0e82fd;
  margin-right: 16px;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .choose-us-section-thirteen
    ul
    li
    .choose-us-content-thirteen
    .chooseus-contents {
    margin-bottom: 10px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .choose-us-section-thirteen
  ul
  li
  .choose-us-content-thirteen
  .chooseus-content-ryt
  h5 {
  font-size: 20px;
  color: #1f2937;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 16px;
}

.main-wrapper.home-twelve.home-thirteen
  .choose-us-section-thirteen
  ul
  li
  .choose-us-content-thirteen
  .chooseus-content-ryt
  p {
  font-weight: 400;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  margin-bottom: 0;
}

.main-wrapper.home-twelve.home-thirteen
  .choose-us-section-thirteen
  ul
  li:hover {
  transition: 0.5s;
  transform: translateY(-10px);
  box-shadow: 0px 11px 21px 10px rgba(14, 130, 253, 0.08);
}

.main-wrapper.home-twelve.home-thirteen .client-us-section-thirteen {
  position: relative;
}

.main-wrapper.home-twelve.home-thirteen
  .client-us-section-thirteen
  .client-says-all {
  display: flex;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .client-us-section-thirteen
    .client-says-all {
    display: block;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .client-us-section-thirteen
  .client-says-all
  .clients-says-content {
  width: 50%;
  padding: 70px 50px 50px 50px;
  background: #0e82fd;
  border-radius: 35px 0px 0px 35px;
  position: relative;
  z-index: -1;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .client-us-section-thirteen
    .client-says-all
    .clients-says-content {
    width: 100%;
    border-radius: 0;
    padding: 50px 50px 50px 50px;
  }
}

@media (max-width: 767.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .client-us-section-thirteen
    .client-says-all
    .clients-says-content {
    padding: 25px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .client-us-section-thirteen
  .client-says-all
  .clients-says-content
  p {
  color: #ffffff;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 24px;
}

@media (max-width: 1399.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .client-us-section-thirteen
    .client-says-all
    .clients-says-content
    p {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 13px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .client-us-section-thirteen
  .client-says-all
  .clients-says-content
  h4 {
  letter-spacing: 0.2px;
  text-transform: capitalize;
  color: #c4f7ff;
  font-size: 20px;
  font-weight: 600;
}

.main-wrapper.home-twelve.home-thirteen
  .client-us-section-thirteen
  .client-says-all
  .clients-says-content
  .location-thirteen {
  color: #f0f0f0;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 0;
}

.main-wrapper.home-twelve.home-thirteen
  .client-us-section-thirteen
  .client-says-all
  .clients-says-content
  .client-says-imagesone
  img {
  position: absolute;
  z-index: -1;
}

.main-wrapper.home-twelve.home-thirteen
  .client-us-section-thirteen
  .client-says-all
  .clients-says-content
  .client-says-imagesone
  img:nth-child(1) {
  right: -3%;
  bottom: -12%;
  width: auto;
}

.main-wrapper.home-twelve.home-thirteen
  .client-us-section-thirteen
  .client-says-all
  .clients-says-content
  .client-says-imagesone
  img:nth-child(2) {
  left: 0%;
  top: 0%;
  width: auto;
}

.main-wrapper.home-twelve.home-thirteen
  .client-us-section-thirteen
  .client-says-all
  .client-says-images {
  width: 50%;
}

.main-wrapper.home-twelve.home-thirteen
  .client-us-section-thirteen
  .client-says-all
  .client-says-images
  img {
  width: auto;
  border-radius: 0 35px 35px 0;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .client-us-section-thirteen
    .client-says-all
    .client-says-images {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .client-us-section-thirteen
    .client-us-section-thirteenone {
    display: none;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .client-us-section-thirteen
  .client-us-section-thirteenone
  img {
  position: absolute;
  z-index: 0;
}

.main-wrapper.home-twelve.home-thirteen
  .client-us-section-thirteen
  .client-us-section-thirteenone
  img:nth-child(1) {
  left: 0%;
  top: 15%;
  width: auto;
}

.main-wrapper.home-twelve.home-thirteen
  .client-us-section-thirteen
  .client-us-section-thirteenone
  img:nth-child(2) {
  right: -6%;
  top: 22%;
  width: auto;
}

.main-wrapper.home-twelve.home-thirteen .latest-articles-thirteen {
  padding: 80px 0 56px 0;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen .latest-articles-thirteen {
    margin: 0;
    padding: 50px 0 26px 0;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .latest-articles-thirteen
  .articles-thirteen {
  margin-bottom: 24px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .latest-articles-thirteen
    .articles-thirteen {
    text-align: center;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .latest-articles-thirteen
  .articles-thirteen
  .articles-thirteen-img {
  margin-bottom: 24px;
  overflow: hidden;
  border-radius: 20px;
}

.main-wrapper.home-twelve.home-thirteen
  .latest-articles-thirteen
  .articles-thirteen
  .articles-thirteen-img
  img {
  border-radius: 20px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.main-wrapper.home-twelve.home-thirteen
  .latest-articles-thirteen
  .articles-thirteen
  .articles-thirteen-img:hover
  img {
  border-radius: 20px;
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}

.main-wrapper.home-twelve.home-thirteen
  .latest-articles-thirteen
  .articles-thirteen
  .articles-thirteen-content
  h6 {
  padding: 2px 8px;
  border-radius: 20px;
  background: #eef6ff;
  color: #0e82fd;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 10px;
  display: inline-block;
}

.main-wrapper.home-twelve.home-thirteen
  .latest-articles-thirteen
  .articles-thirteen
  .articles-thirteen-content
  a {
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: #1f2937;
  margin-bottom: 10px;
  display: block;
}

.main-wrapper.home-twelve.home-thirteen
  .latest-articles-thirteen
  .articles-thirteen
  .articles-thirteen-content
  a:hover {
  color: #0e82fd;
}

.main-wrapper.home-twelve.home-thirteen
  .latest-articles-thirteen
  .articles-thirteen
  .articles-thirteen-content
  p {
  color: #6b7280;
  font-weight: 400;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: 100%;
  overflow: hidden;
}

.main-wrapper.home-twelve.home-thirteen .feedback-section-thirteen {
  background: url(../images/feedback-bg.png);
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen .feedback-section-thirteen {
    background-image: none;
    background-color: #f8fbff;
  }
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .feedback-section-thirteen
    .feedback-all-img {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .feedback-section-thirteen
    .feedback-main-content {
    text-align: center;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .feedback-section-thirteen
  .feedback-main-content
  h2 {
  color: #1f2937;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .feedback-section-thirteen
    .feedback-main-content
    h2 {
    font-size: 34px;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .feedback-section-thirteen
  .feedback-main-content
  p {
  font-weight: 400;
  color: #6b7280;
  margin-bottom: 40px;
}

.main-wrapper.home-twelve.home-thirteen
  .feedback-section-thirteen
  .feedback-main-content
  .feedback-inner-img {
  display: flex;
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .feedback-section-thirteen
    .feedback-main-content
    .feedback-inner-img {
    justify-content: center;
  }
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .feedback-section-thirteen
    .feedback-main-content
    .feedback-inner-img {
    display: block;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .feedback-section-thirteen
  .feedback-main-content
  .feedback-inner-img
  .feedback-inner-imgone {
  margin-right: 26px;
}

@media (max-width: 575.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .feedback-section-thirteen
    .feedback-main-content
    .feedback-inner-img
    .feedback-inner-imgone {
    margin: 0 0 10px 0;
  }
}

@media (max-width: 991.98px) {
  .main-wrapper.home-twelve.home-thirteen
    .feedback-section-thirteen
    .feedback-bg-icons {
    display: none;
  }
}

.main-wrapper.home-twelve.home-thirteen
  .feedback-section-thirteen
  .feedback-bg-icons
  img {
  position: absolute;
  z-index: 0;
}

.main-wrapper.home-twelve.home-thirteen
  .feedback-section-thirteen
  .feedback-bg-icons
  img:nth-child(1) {
  right: 6%;
  top: 40%;
  width: auto;
  animation: spin 3000ms linear infinite;
}

.main-wrapper.home-twelve.home-thirteen
  .feedback-section-thirteen
  .feedback-bg-icons
  img:nth-child(2) {
  right: 11%;
  top: 55%;
  width: auto;
  animation: spin 3000ms linear infinite;
}

.main-wrapper.home-twelve.home-thirteen
  .feedback-section-thirteen
  .feedback-bg-icons
  img:nth-child(3) {
  right: 8%;
  bottom: 10%;
  width: auto;
  animation: spin 3000ms linear infinite;
}

.main-wrapper.home-twelve.home-thirteen
  .feedback-section-thirteen
  .feedback-bg-icons
  img:nth-child(4) {
  left: 15%;
  bottom: 10%;
  width: auto;
  animation: spin 3000ms linear infinite;
}

.main-wrapper.home-twelve.home-thirteen
  .feedback-section-thirteen
  .feedback-bg-icons
  img:nth-child(5) {
  left: 6%;
  top: 25%;
  width: auto;
  animation: spin 3000ms linear infinite;
}

.section-header-thirteen {
  margin-bottom: 50px;
}

@media (max-width: 991.98px) {
  .section-header-thirteen {
    margin-bottom: 40px;
  }
}

.section-header-thirteen .section-inner-thirteen {
  margin-right: 15px;
}

.section-header-thirteen h2 {
  font-size: 40px;
  color: #1f2937;
  font-weight: 700;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .section-header-thirteen h2 {
    font-size: 34px;
  }
}

@media (max-width: 767.98px) {
  .section-header-thirteen h2 {
    font-size: 28px;
  }
}

.our-slider-thirteen .owl-dots,
.client-says-thirteen .owl-dots {
  text-align: center;
  margin-top: 50px;
}

@media (max-width: 991.98px) {
  .our-slider-thirteen .owl-dots,
  .client-says-thirteen .owl-dots {
    margin-top: 30px;
  }
}

.our-slider-thirteen .owl-dots .owl-dot,
.client-says-thirteen .owl-dots .owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #f3f3f3;
  margin-right: 15px;
}

@media (max-width: 991.98px) {
  .our-slider-thirteen .owl-dots .owl-dot,
  .client-says-thirteen .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
  }
}

.our-slider-thirteen .owl-dots .owl-dot.active,
.client-says-thirteen .owl-dots .owl-dot.active {
  width: 45px;
  background: #0e82fd;
  border-radius: 20px;
}

@keyframes pulse {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1.2);
  }
}

@keyframes elastic-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(720deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.main-wrapper.home-fourteen {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-fourteen {
    font-size: 15px;
  }
}

.banner-section-fourteen {
  position: relative;
  padding: 100px 0 0 0;
  background: #0e82fd;
  z-index: 1;
}

@media (max-width: 1199.98px) {
  .banner-section-fourteen {
    padding: 100px 0 30px 0;
  }
}

@media (max-width: 991.98px) {
  .banner-section-fourteen {
    padding: 100px 0 50px 0;
  }
}

.banner-section-fourteen .banner-content-fourteen {
  margin: 116px 0 0 0;
  padding: 0 0 40px 0;
}

@media (max-width: 1399.98px) {
  .banner-section-fourteen .banner-content-fourteen {
    margin: 50px 0 0 0;
    padding: 0 0 40px 0;
  }
}

@media (max-width: 1199.98px) {
  .banner-section-fourteen .banner-content-fourteen {
    margin: 10px 0 0 0;
    padding: 0 0 40px 0;
  }
}

.banner-section-fourteen .banner-content-fourteen h1 {
  color: #ffffff;
  letter-spacing: 0.005rem;
  font-weight: 700;
  width: 586px;
  margin: 0 0 40px 0;
}

@media (max-width: 1199.98px) {
  .banner-section-fourteen .banner-content-fourteen h1 {
    width: auto;
  }
}

.banner-section-fourteen .banner-content-fourteen .banner-btns-fourteen {
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 991.98px) {
  .banner-section-fourteen .banner-content-fourteen .banner-btns-fourteen {
    justify-content: center;
  }
}

@media (max-width: 479.98px) {
  .banner-section-fourteen .banner-content-fourteen .banner-btns-fourteen {
    flex-direction: column;
  }
}

.banner-section-fourteen
  .banner-content-fourteen
  .banner-btns-fourteen
  .btn-primary {
  background: #ffffff;
  color: #0e82fd;
  padding: 15px 20px;
}

@media (max-width: 991.98px) {
  .banner-section-fourteen
    .banner-content-fourteen
    .banner-btns-fourteen
    .btn-primary {
    padding: 15px 15px;
  }
}

@media (max-width: 479.98px) {
  .banner-section-fourteen
    .banner-content-fourteen
    .banner-btns-fourteen
    .btn-primary {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 15px 15px;
  }
}

.banner-section-fourteen
  .banner-content-fourteen
  .banner-btns-fourteen
  .btn-primary:hover {
  background: transparent;
  box-shadow: none;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.banner-section-fourteen
  .banner-content-fourteen
  .banner-btns-fourteen
  .btn-primarytwo {
  background: transparent;
  box-shadow: none;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 15px 20px;
}

@media (max-width: 991.98px) {
  .banner-section-fourteen
    .banner-content-fourteen
    .banner-btns-fourteen
    .btn-primarytwo {
    padding: 15px 15px;
  }
}

.banner-section-fourteen
  .banner-content-fourteen
  .banner-btns-fourteen
  .btn-primarytwo:hover {
  background: #ffffff;
  color: #0e82fd;
}

.banner-section-fourteen .search-box-fourteen {
  background: #ffffff;
  box-shadow: -8px 6px 100px rgba(36, 58, 101, 0.1);
  border-radius: 20px;
  padding: 30px 41px 20px 41px;
  gap: 10px;
}

.banner-section-fourteen .search-box-fourteen .form-block {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .banner-section-fourteen .search-box-fourteen .form-block {
    flex-direction: column;
  }
}

.banner-section-fourteen .search-box-fourteen .form-block .search-input {
  margin: 0 15px 0 0;
}

@media (max-width: 768px) {
  .banner-section-fourteen .search-box-fourteen .form-block .search-input {
    width: 100%;
  }
}

.banner-section-fourteen .search-box-fourteen .form-block .search-input input {
  min-height: 40px;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

.banner-section-fourteen
  .search-box-fourteen
  .form-block
  .search-input
  input::placeholder {
  color: #1f2937;
  font-weight: 600;
}

.banner-section-fourteen
  .search-box-fourteen
  .form-block
  .search-input
  .form-group {
  margin-bottom: 0;
}

.banner-section-fourteen .search-box-fourteen .form-block .search-input label {
  color: #6b7280;
}

.banner-section-fourteen .search-box-fourteen .search-btn {
  width: auto;
  margin: -8px 0 0 0;
}

@media (max-width: 768px) {
  .banner-section-fourteen .search-box-fourteen .search-btn {
    width: 100%;
    margin: 0 0 0 0;
  }
}

.banner-section-fourteen .search-box-fourteen .search-btn .btn-primary {
  background: #0e82fd;
  border: 1px solid #0e82fd;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
  padding: 14px 16px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .banner-section-fourteen .search-box-fourteen .search-btn .btn-primary {
    width: 100%;
  }
}

.banner-section-fourteen .search-box-fourteen .search-btn .btn-primary:hover {
  background: #1c5b91;
  box-shadow: inset 0 0 0 50px #1c5b91;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #1c5b91;
}

.banner-section-fourteen .banner-img-fourteen .banner-imgfourteen {
  left: 30px;
}

@media (max-width: 1199.98px) {
  .banner-section-fourteen .banner-img-fourteen .banner-imgfourteen {
    top: 50px;
  }
}

.banner-section-fourteen .banner-img-fourteen .banner-imgfourteen3 {
  top: 380px;
}

@media (max-width: 1199.98px) {
  .banner-section-fourteen .banner-img-fourteen .banner-imgfourteen3 {
    top: 250px;
  }
}

@media (max-width: 1199.98px) {
  .banner-section-fourteen .banner-img-fourteen .banner-img2 {
    top: 100px;
  }
}

.banner-section-fourteen .banner-img-fourteen .banner-imgfourteen4 {
  position: absolute;
  right: -120px;
  bottom: 100px;
  animation: spin 3000ms linear infinite;
}

@media (max-width: 1199.98px) {
  .banner-section-fourteen .banner-img-fourteen .banner-imgfourteen4 {
    top: 250px;
  }
}

.banner-section-fourteen .banner-imgfourteen5 {
  position: absolute;
  top: 250px;
  left: 86px;
  animation: spin 3000ms linear infinite;
}

.banner-section-fourteen .banner-imgfourteen6 {
  position: absolute;
  bottom: 50px;
  left: 65px;
  animation: spin 3000ms linear infinite;
  z-index: -1;
}

.services-section-fourteen {
  padding: 80px 0;
  background: #f8fafc;
}

@media (max-width: 1199.98px) {
  .services-section-fourteen {
    padding: 50px 0;
  }
}

.services-section-fourteen .service-inner-fourt-all {
  border-radius: 20px;
  margin: 0 0 24px 0;
}

.services-section-fourteen .service-inner-fourt-all .service-inner-fourt-main {
  padding: 20px;
  border-radius: 20px;
}

.services-section-fourteen
  .service-inner-fourt-all
  .service-inner-fourt-main
  .service-inner-right-fourt
  .service-inner-right-img {
  position: relative;
  margin: 0 0 13px 0;
  overflow: hidden;
  border-radius: 20px;
}

.services-section-fourteen
  .service-inner-fourt-all
  .service-inner-fourt-main
  .service-inner-right-fourt
  .service-inner-right-img
  img {
  position: relative;
  border-radius: 20px;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  width: 100%;
}

.services-section-fourteen
  .service-inner-fourt-all
  .service-inner-fourt-main
  .service-inner-right-fourt
  .service-inner-right-img:hover
  img {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}

.services-section-fourteen
  .service-inner-fourt-all
  .service-inner-fourt-main
  .service-inner-right-fourt
  .service-inner-right-img:hover::before {
  -webkit-animation: circle 1s;
  animation: circle 1s;
}

.services-section-fourteen
  .service-inner-fourt-all
  .service-inner-fourt-main
  .service-inner-right-fourt
  .service-inner-right-img::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  opacity: 0;
}

.services-section-fourteen
  .service-inner-fourt-all
  .service-inner-fourt-main
  .service-inner-right-fourt
  .service-inner-right-fourt-care {
  color: #1f2937;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 13px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

@media (max-width: 1199.98px) {
  .services-section-fourteen
    .service-inner-fourt-all
    .service-inner-fourt-main
    .service-inner-right-fourt
    .service-inner-right-fourt-care {
    font-size: 18px;
  }
}

.services-section-fourteen
  .service-inner-fourt-all
  .service-inner-fourt-main
  .service-inner-right-fourt
  .service-inner-right-fourt-care:hover {
  color: #1c5b91;
}

.services-section-fourteen
  .service-inner-fourt-all
  .service-inner-fourt-main
  .service-inner-right-fourt
  p {
  color: #6b7280;
  font-size: 14px;
  margin: 0 0 10px 0;
}

.services-section-fourteen
  .service-inner-fourt-all
  .service-inner-fourt-main
  .service-inner-right-fourt
  .service-inner-right-fourt-caretwo {
  color: #0e82fd;
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 1199.98px) {
  .services-section-fourteen
    .service-inner-fourt-all
    .service-inner-fourt-main
    .service-inner-right-fourt
    .service-inner-right-fourt-caretwo {
    font-size: 16px;
  }
}

.services-section-fourteen
  .service-inner-fourt-all
  .service-inner-fourt-main
  .service-inner-right-fourt
  .service-inner-right-fourt-caretwo
  i {
  font-weight: 600;
}

.services-section-fourteen
  .service-inner-fourt-all
  .service-inner-fourt-main
  .service-inner-right-fourt
  .service-inner-right-fourt-caretwo:hover {
  color: #1c5b91;
}

.services-section-fourteen .service-inner-img-fourt-all {
  position: relative;
}

@media (max-width: 991.98px) {
  .services-section-fourteen
    .service-inner-img-fourt-all
    .service-inner-img-fourt {
    display: none;
  }
}

.services-section-fourteen
  .service-inner-img-fourt-all
  .service-inner-img-fourtone {
  position: absolute;
  top: 0;
  right: 65px;
  animation: spin 3000ms linear infinite;
}

.services-section-fourteen
  .service-inner-img-fourt-all
  .service-inner-img-fourttwo {
  position: absolute;
  bottom: 0;
  animation: spin 3000ms linear infinite;
}

.services-section-fourteen
  .service-inner-img-fourt-all
  .service-inner-img-fourtthree {
  position: absolute;
  top: 0;
  left: 30px;
  animation: spin 3000ms linear infinite;
}

.services-section-fourteen .btn-sec-fourteen {
  text-align: end;
}

@media (max-width: 991.98px) {
  .services-section-fourteen .btn-sec-fourteen {
    text-align: center;
  }
}

.services-section-fourteen .btn-sec-fourteen a {
  padding: 15px 20px;
  font-size: 15px;
  color: #ffffff;
  background: #0e82fd;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: inset 0 0 0 0 #0e82fd;
  border: 1px solid #0e82fd;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  .services-section-fourteen .btn-sec-fourteen a {
    padding: 12px 13px;
  }
}

.services-section-fourteen .btn-sec-fourteen a:hover {
  box-shadow: inset 165px 0 0 0 #1c5b91;
  border-color: #1c5b91;
}

.team-section-fourteen {
  position: relative;
  padding: 80px 0 56px 0;
}

@media (max-width: 1199.98px) {
  .team-section-fourteen {
    padding: 50px 0;
  }
}

.team-section-fourteen .articles-grid-fourteen {
  background: #f8fafc;
  border: 1px solid #e5e7eb;
  border-radius: 30px;
}

@media (max-width: 1199.98px) {
  .team-section-fourteen .articles-grid-fourteen .articles-info {
    text-align: start;
  }
}

@media (max-width: 991.98px) {
  .team-section-fourteen .articles-grid-fourteen .articles-info {
    display: flex;
    text-align: center;
    flex-direction: column;
  }
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-left
  .articles-img-fourteen {
  width: 206px;
  height: 220px;
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-left
  .articles-img-fourteen
  img {
  height: 100%;
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-right
  .articles-content-fourteen
  a {
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-right
  .articles-content-fourteen
  a:hover {
  color: #1c5b91;
}

@media (max-width: 1199.98px) {
  .team-section-fourteen
    .articles-grid-fourteen
    .articles-info
    .articles-right
    .articles-content-fourteen
    .articles-list {
    justify-content: start;
    -webkit-justify-content: start;
  }
}

@media (max-width: 991.98px) {
  .team-section-fourteen
    .articles-grid-fourteen
    .articles-info
    .articles-right
    .articles-content-fourteen
    .articles-list {
    justify-content: center;
    -webkit-justify-content: center;
  }
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-right
  .articles-content-fourteen
  .articles-list
  .Qualified-doctors-fourteen {
  background: #dbeffe;
  color: #0e82fd;
  padding: 10px 15px;
  border-radius: 8px;
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-right
  .articles-content-fourteen
  .articles-list
  .Qualified-doctors-fourteentwo {
  color: #6b7280;
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-right
  .articles-content-fourteen
  .articles-list
  .Qualified-doctors-fourteenthree {
  background: #f3f4f6;
  padding: 10px 12px;
  border-radius: 6px;
  color: #1f2937;
  font-weight: 600;
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-right
  .articles-content-fourteen
  .articles-list
  .Qualified-doctors-fourteenfour
  a {
  border-radius: 6px;
  background: #0e82fd;
  color: #ffffff;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: inset 0 0 0 0 #0e82fd;
  font-size: 15px;
  font-weight: 600;
  display: block;
  margin-bottom: 0;
  padding: 10px 12px;
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-right
  .articles-content-fourteen
  .articles-list
  .Qualified-doctors-fourteenfour
  a:hover {
  box-shadow: inset 120px 0 0 0 #1c5b91;
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-right
  .articles-content-fourteen
  .rating-fourteen
  .filled {
  color: #ffc001;
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-right
  .articles-content-fourteen
  .rating-fourteen
  i {
  font-size: 18px;
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-right
  .articles-content-fourteen
  .rating-fourteen
  .average-rating {
  font-size: 14px;
  color: #d1d5db;
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-right
  .articles-content-fourteen
  p {
  color: #6b7280;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-right
  .articles-content-fourteen
  p
  i {
  color: #6b7280;
  margin: 0 7px 0 0;
}

.team-section-fourteen
  .articles-grid-fourteen
  .articles-info
  .articles-right
  .articles-content-fourteen
  p
  span {
  color: #1f2937;
  font-weight: 500;
}

.team-section-fourteen .banner-imgfourteenseven {
  position: absolute;
  bottom: 50px;
  left: 30px;
  animation: spin 3000ms linear infinite;
}

.team-section-fourteen .banner-imgfourteeneight {
  position: absolute;
  top: 70px;
  right: 50%;
  animation: spin 3000ms linear infinite;
}

@media (max-width: 991.98px) {
  .team-section-fourteen .banner-imgfourteeneight {
    display: none;
  }
}

.team-section-fourteen .banner-imgfourteennine {
  position: absolute;
  right: 50px;
  bottom: 70px;
  animation: spin 3000ms linear infinite;
}

.blog-section-fourteen {
  position: relative;
  padding: 80px 0;
  background: #f2f6f6;
}

@media (max-width: 1199.98px) {
  .blog-section-fourteen {
    padding: 50px 0;
  }
}

.blog-section-fourteen .blog-inner-fourt-all {
  border-radius: 30px;
}

.blog-section-fourteen .blog-inner-fourt-all .blog-inner-fourt-main {
  padding: 20px;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-right-img {
  position: relative;
  margin: 0 0 20px 0;
  overflow: hidden;
  border-radius: 20px;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-right-img
  .blog-inner-right-inner {
  position: relative;
  border-radius: 20px;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  width: 100%;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-right-img
  .blog-inner-right-inner:hover
  img {
  border-radius: 20px;
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-top-content {
  position: absolute;
  top: 19px;
  left: 19px;
  padding: 5px 10px 5px 5px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50px;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-top-content
  img {
  width: 35px;
  height: 35px;
  border-radius: 50px;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-top-content
  span {
  font-size: 14px;
  color: #1f2937;
  font-weight: 600;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-right-fourt-care {
  display: block;
  color: #1f2937;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 18px 0;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-inner-right-fourt-care:hover {
  color: #1c5b91;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-articles-list {
  margin: 0 0 20px 0;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-articles-list
  li {
  color: #6b7280;
  font-size: 16px;
  margin: 0 20px 0 0;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-articles-list
  li
  i {
  color: #d1d5db;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-articles-list-two {
  margin: 0 0 10px 0;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-articles-list-two
  li {
  background: #dbeffe;
  color: #0e82fd;
  margin: 0 10px 10px 0;
  padding: 10px 15px;
  border-radius: 8px;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  .blog-articles-list-two
  li:last-child {
  margin: 0 0 10px 0;
}

.blog-section-fourteen
  .blog-inner-fourt-all
  .blog-inner-fourt-main
  .blog-inner-right-fourt
  p {
  color: #6b7280;
  font-size: 14px;
  margin: 0 0 0 0;
}

.blog-section-fourteen .blog-btn-sec {
  margin: 24px 0 0 0;
}

.blog-section-fourteen .blog-btn-sec .btn-view {
  padding: 15px 20px;
  font-size: 15px;
  color: #ffffff;
  background: #0e82fd;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: inset 0 0 0 0 #0e82fd;
  font-weight: 600;
  border: 1px solid #0e82fd;
}

@media (max-width: 991.98px) {
  .blog-section-fourteen .blog-btn-sec .btn-view {
    padding: 12px 13px;
  }
}

.blog-section-fourteen .blog-btn-sec .btn-view:hover {
  box-shadow: inset 220px 0 0 0 #1c5b91;
  border-color: #1c5b91;
}

.choose-us-fourteen {
  position: relative;
  padding: 80px 0;
}

@media (max-width: 1199.98px) {
  .choose-us-fourteen {
    padding: 50px 0;
  }
}

.choose-us-fourteen .you-get-list ul {
  list-style: none;
  padding: 0;
}

.choose-us-fourteen .you-get-list ul li {
  margin-bottom: 30px;
}

.choose-us-fourteen .you-get-list ul li:last-child {
  margin: 0;
}

.choose-us-fourteen .you-get-list ul li .get-list-content {
  background-image: url(../images/chooseus-ryt-1.png);
  background-repeat: no-repeat;
  background-size: contain;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 0.5s;
}

@media (max-width: 1399.98px) {
  .choose-us-fourteen .you-get-list ul li .get-list-content {
    padding: 22px 30px 30px 30px;
  }
}

@media (max-width: 1199.98px) {
  .choose-us-fourteen .you-get-list ul li .get-list-content {
    background: #f2f6f6;
    border-radius: 30px;
  }
}

@media (max-width: 991.98px) {
  .choose-us-fourteen .you-get-list ul li .get-list-content {
    background: #f2f6f6;
    border-radius: 30px;
  }
}

@media (max-width: 575.98px) {
  .choose-us-fourteen .you-get-list ul li .get-list-content {
    flex-direction: column;
  }
}

.choose-us-fourteen .you-get-list ul li .get-list-content.second-bg {
  background-image: url(../images/chooseus-ryt-2.png);
}

@media (max-width: 1199.98px) {
  .choose-us-fourteen .you-get-list ul li .get-list-content.second-bg {
    background: #f2f6f6;
    border-radius: 30px;
  }
}

@media (max-width: 991.98px) {
  .choose-us-fourteen .you-get-list ul li .get-list-content.second-bg {
    background: #f2f6f6;
    border-radius: 30px;
  }
}

.choose-us-fourteen .you-get-list ul li .get-list-content:hover .get-icon {
  background: #0e82fd;
  transition: 0.5s;
}

.choose-us-fourteen .you-get-list ul li .get-list-content:hover .get-icon img {
  filter: invert(0) brightness(100);
  transition: 0.5s;
}

.choose-us-fourteen .you-get-list ul li .get-list-content .get-icon {
  margin: 0 20px 0 0;
  padding: 25px;
  background: #ffffff;
  border-radius: 20px;
  transition: 0.5s;
}

@media (max-width: 575.98px) {
  .choose-us-fourteen .you-get-list ul li .get-list-content .get-icon {
    margin: 0 0 20px 0;
    padding: 25px;
  }
}

@media (max-width: 575.98px) {
  .choose-us-fourteen .you-get-list ul li .get-list-content .get-icon-right {
    text-align: center;
  }
}

.choose-us-fourteen .you-get-list ul li .get-list-content .get-icon-right h3 {
  font-weight: 600;
  margin: 0 0 5px 0;
}

.choose-us-fourteen .you-get-list ul li .get-list-content .get-icon-right p {
  color: #6b7280;
  font-size: 14px;
  margin: 0;
  max-width: 412px;
}

.choose-us-fourteen .choose-us-right-main {
  position: relative;
}

@media (max-width: 991.98px) {
  .choose-us-fourteen .choose-us-right-main {
    display: none;
  }
}

.choose-us-fourteen .choose-us-right-main .banner-imgfourteenten {
  position: absolute;
  top: 150px;
  right: 180px;
  animation: spin 3000ms linear infinite;
}

@media (max-width: 1199.98px) {
  .choose-us-fourteen .choose-us-right-main .banner-imgfourteenten {
    right: 120px;
  }
}

.clients-section-fourteen {
  position: relative;
  padding: 80px 0 150px 0;
  background: #f2f6f6;
}

@media (max-width: 1199.98px) {
  .clients-section-fourteen {
    padding: 80px 0 100px 0;
  }
}

@media (max-width: 1199.98px) {
  .clients-section-fourteen {
    padding: 50px 0;
  }
}

.clients-section-fourteen .client-inner-main {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}

@media (max-width: 991.98px) {
  .clients-section-fourteen .client-inner-main {
    display: none;
  }
}

.clients-section-fourteen .feedback-slider-fourteen .owl-nav {
  text-align: end;
  margin-top: 40px;
}

@media (max-width: 991.98px) {
  .clients-section-fourteen .feedback-slider-fourteen .owl-nav {
    text-align: center;
    margin-top: 10px;
  }
}

.clients-section-fourteen .feedback-slider-fourteen .owl-nav .owl-prev {
  background: #e2e8f0;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  color: #94a3b8;
  text-shadow: none;
  font-size: 24px;
  border: none;
}

@media (max-width: 991.98px) {
  .clients-section-fourteen .feedback-slider-fourteen .owl-nav .owl-prev {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

.clients-section-fourteen .feedback-slider-fourteen .owl-nav .owl-prev:hover {
  background: #bfe0fe;
  color: #1d7ed8;
}

.clients-section-fourteen .feedback-slider-fourteen .owl-nav .owl-next {
  background: #e2e8f0;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  color: #94a3b8;
  text-shadow: none;
  font-size: 24px;
  border: none;
}

@media (max-width: 991.98px) {
  .clients-section-fourteen .feedback-slider-fourteen .owl-nav .owl-next {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

.clients-section-fourteen .feedback-slider-fourteen .owl-nav .owl-next:hover {
  background: #bfe0fe;
  color: #1d7ed8;
}

.clients-section-fourteen .feedback-card {
  border-radius: 30px;
  margin: 60px 0 0 0;
}

.clients-section-fourteen .feedback-card .feedback-card-body {
  padding: 30px;
}

.clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .feedback-inner-img {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: -85px 0 10px 0;
}

.clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .feedback-inner-img
  h6 {
  color: #1f2937;
  font-size: 16px;
}

.clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .feedback-inner-img
  img {
  width: 100px;
  height: 100px;
  border-radius: 20px;
}

.clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .rating-fourteen {
  margin: 0 0 15px 0;
}

.clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .rating-fourteen
  .filled {
  color: #ffc001;
}

.clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  .rating-fourteen
  i {
  font-size: 18px;
}

.clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  p {
  color: #6b7280;
  font-size: 16px;
  margin: 0 0 20px 0;
}

.clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  h4 {
  color: #1f2937;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

@media (max-width: 991.98px) {
  .clients-section-fourteen
    .feedback-card
    .feedback-card-body
    .feedback-inner-main
    h4 {
    font-size: 18px;
  }
}

.clients-section-fourteen
  .feedback-card
  .feedback-card-body
  .feedback-inner-main
  h6 {
  color: #94a3b8;
  font-size: 16px;
  margin: 0;
}

.contact-section-fourteen {
  background: #f2f6f6;
  margin: 0 0 -200px 0;
}

.contact-section-fourteen .contact-schedule-main {
  background: #0e82fd;
  border-radius: 30px;
  position: relative;
  box-shadow: 0px 30px 60px rgba(49, 46, 129, 0.2);
}

.contact-section-fourteen .contact-schedule-main .scheduling-left-main {
  padding: 95px 80px 95px 110px;
}

@media (max-width: 1399.98px) {
  .contact-section-fourteen .contact-schedule-main .scheduling-left-main {
    padding: 75px 80px 75px 90px;
  }
}

@media (max-width: 1199.98px) {
  .contact-section-fourteen .contact-schedule-main .scheduling-left-main {
    padding: 25px 40px 25px 50px;
  }
}

@media (max-width: 991.98px) {
  .contact-section-fourteen .contact-schedule-main .scheduling-left-main {
    padding: 30px 10px 30px 10px;
    text-align: center;
  }
}

.contact-section-fourteen .contact-schedule-main .scheduling-left-main h2 {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  margin: 0 0 10px 0;
}

@media (max-width: 991.98px) {
  .contact-section-fourteen .contact-schedule-main .scheduling-left-main h2 {
    font-size: 36px;
  }
}

@media (max-width: 767.98px) {
  .contact-section-fourteen .contact-schedule-main .scheduling-left-main h2 {
    font-size: 28px;
  }
}

.contact-section-fourteen .contact-schedule-main .scheduling-left-main p {
  font-size: 24px;
  color: #ffffff;
  margin: 0 0 55px 0;
}

@media (max-width: 991.98px) {
  .contact-section-fourteen .contact-schedule-main .scheduling-left-main p {
    font-size: 20px;
    margin: 0 0 30px 0;
  }
}

.contact-section-fourteen .contact-schedule-main .scheduling-left-main ul {
  margin: 0;
  padding: 0;
}

@media (max-width: 991.98px) {
  .contact-section-fourteen .contact-schedule-main .scheduling-left-main ul {
    justify-content: center;
  }
}

@media (max-width: 575.98px) {
  .contact-section-fourteen .contact-schedule-main .scheduling-left-main ul {
    flex-direction: column;
    align-items: center;
  }
}

.contact-section-fourteen .contact-schedule-main .scheduling-left-main ul li {
  list-style: none;
  margin: 0 20px 0 0;
}

.contact-section-fourteen
  .contact-schedule-main
  .scheduling-left-main
  ul
  li:last-child {
  margin: 0;
}

@media (max-width: 575.98px) {
  .contact-section-fourteen .contact-schedule-main .scheduling-left-main ul li {
    margin: 0 0 20px 0;
    width: 100%;
  }
}

.contact-section-fourteen .contact-schedule-main .scheduling-left-main ul li a {
  background: #ffffff;
  padding: 15px 20px;
  border-radius: 6px;
  color: #0e82fd;
  border: 2px solid #ffffff;
  display: inline-block;
  font-weight: 600;
}

@media (max-width: 1399.98px) {
  .contact-section-fourteen
    .contact-schedule-main
    .scheduling-left-main
    ul
    li
    a {
    padding: 13px 14px;
  }
}

@media (max-width: 1199.98px) {
  .contact-section-fourteen
    .contact-schedule-main
    .scheduling-left-main
    ul
    li
    a {
    padding: 13px 8px;
  }
}

@media (max-width: 575.98px) {
  .contact-section-fourteen
    .contact-schedule-main
    .scheduling-left-main
    ul
    li
    a {
    width: 100%;
  }
}

.contact-section-fourteen
  .contact-schedule-main
  .scheduling-left-main
  ul
  li
  a:hover {
  background: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
}

.contact-section-fourteen .contact-schedule-main .scheduling-right-main {
  position: relative;
  margin: -95px 0 0 0;
}

@media (max-width: 1399.98px) {
  .contact-section-fourteen .contact-schedule-main .scheduling-right-main {
    margin: -53px 27px 0 0;
  }
}

@media (max-width: 991.98px) {
  .contact-section-fourteen .contact-schedule-main .scheduling-right-main {
    display: none;
  }
}

.contact-section-fourteen
  .contact-schedule-main
  .scheduling-right-main
  .scheduling-right-img {
  position: absolute;
  top: 40%;
  left: 14px;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}

@media (max-width: 991.98px) {
  .contact-section-fourteen
    .contact-schedule-main
    .scheduling-right-main
    .scheduling-right-img {
    display: none;
  }
}

.contact-section-fourteen
  .contact-schedule-main
  .scheduling-right-main
  .scheduling-right-imgtwo {
  position: absolute;
  right: 15px;
  bottom: 10%;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}

@media (max-width: 991.98px) {
  .contact-section-fourteen
    .contact-schedule-main
    .scheduling-right-main
    .scheduling-right-imgtwo {
    display: none;
  }
}

.contact-section-fourteen .contact-schedule-main .contact-inner-image {
  position: absolute;
  top: 0;
  left: 135px;
}

@media (max-width: 991.98px) {
  .contact-section-fourteen .contact-schedule-main .contact-inner-image {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .section-header-fourteen {
    text-align: center !important;
  }
}

.section-header-fourteen .service-inner-fourteen {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .section-header-fourteen .service-inner-fourteen {
    justify-content: center !important;
  }
}

.section-header-fourteen .service-inner-fourteen .service-inner-fourteen-one {
  background: #0e82fd;
  content: "";
  width: 50px;
  height: 3px;
}

.section-header-fourteen
  .service-inner-fourteen
  .service-inner-fourteen-two
  h3 {
  color: #0e82fd;
  font-size: 30px;
  margin: 0 20px;
}

@media (max-width: 1199.98px) {
  .section-header-fourteen
    .service-inner-fourteen
    .service-inner-fourteen-two
    h3 {
    font-size: 26px;
  }
}

@media (max-width: 767.98px) {
  .section-header-fourteen
    .service-inner-fourteen
    .service-inner-fourteen-two
    h3 {
    font-size: 22px;
  }
}

.section-header-fourteen .service-inner-fourteen .service-inner-fourteen-three {
  background: #0e82fd;
  content: "";
  width: 50px;
  height: 3px;
}

.section-header-fourteen h2 {
  color: #1f2937;
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 50px 0;
}

@media (max-width: 1199.98px) {
  .section-header-fourteen h2 {
    font-size: 32px;
  }
}

@media (max-width: 991.98px) {
  .section-header-fourteen h2 {
    margin: 0 0 40px 0;
    font-size: 28px;
  }
}

@media (max-width: 767.98px) {
  .section-header-fourteen h2 {
    font-size: 26px;
  }
}

.slide-nav-14 button.owl-next,
.slide-nav-14 button.owl-prev,
.slide-nav-15 button.owl-next,
.slide-nav-15 button.owl-prev {
  background: #e2e8f0;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  color: #94a3b8;
  text-shadow: none;
  font-size: 24px;
  border: none;
}

@media (max-width: 991.98px) {
  .slide-nav-14 button.owl-next,
  .slide-nav-14 button.owl-prev,
  .slide-nav-15 button.owl-next,
  .slide-nav-15 button.owl-prev {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin: 0 10px 30px 0;
  }
}

.slide-nav-14 button.owl-next:hover,
.slide-nav-14 button.owl-prev:hover,
.slide-nav-15 button.owl-next:hover,
.slide-nav-15 button.owl-prev:hover {
  background: #bfe0fe;
  color: #1d7ed8;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.main-wrapper.home-fifteen {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-fifteen {
    font-size: 15px;
  }
}

.banner-section-fifteen {
  position: relative;
  padding: 85px 0 0 0;
  background-color: #ffffff;
  z-index: 1;
  background-image: url(../images/banner-fifteen.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 36% 100%;
}

@media (max-width: 991.98px) {
  .banner-section-fifteen {
    background-image: none;
  }
}

.banner-section-fifteen .banner-content-fifteen {
  margin: 110px 0 0 0;
  padding: 0 0 47px 0;
}

@media (max-width: 991.98px) {
  .banner-section-fifteen .banner-content-fifteen {
    margin: 35px 0 0 0;
    padding: 0 0 47px 0;
  }
}

.banner-section-fifteen .banner-content-fifteen h2 {
  color: #1f2937;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  .banner-section-fifteen .banner-content-fifteen h2 {
    font-size: 20px;
  }
}

@media (max-width: 767.98px) {
  .banner-section-fifteen .banner-content-fifteen h2 {
    font-size: 18px;
  }
}

.banner-section-fifteen .banner-content-fifteen h1 {
  color: #0e82fd;
  margin-bottom: 15px;
}

.banner-section-fifteen .banner-content-fifteen .banner-btns-fifteen {
  margin: 0 0 35px 0;
}

.banner-section-fifteen
  .banner-content-fifteen
  .banner-btns-fifteen
  .btn-primary {
  font-weight: 600;
  background: #0e82fd;
  position: relative;
  padding: 15px 30px;
  margin-bottom: 15px;
}

@media (max-width: 479.98px) {
  .banner-section-fifteen
    .banner-content-fifteen
    .banner-btns-fifteen
    .btn-primary {
    width: 100%;
  }
}

.banner-section-fifteen
  .banner-content-fifteen
  .banner-btns-fifteen
  .btn-primary:hover {
  background: #0ce0ff;
  box-shadow: inset 0 100px 0 0 #0ce0ff;
  color: #ffffff;
  border: 1px solid #0ce0ff;
}

.banner-section-fifteen
  .banner-content-fifteen
  .banner-btns-fifteen
  .btns-primarytwo {
  background: #0ce0ff;
  border: 1px solid #0ce0ff;
}

.banner-section-fifteen
  .banner-content-fifteen
  .banner-btns-fifteen
  .btns-primarytwo:hover {
  background: #0e82fd;
  box-shadow: inset 0 100px 0 0 #0e82fd;
  border: 1px solid #0e82fd;
}

.banner-section-fifteen .banner-content-fifteen .aboutus-companyimg {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
}

.banner-section-fifteen .banner-content-fifteen .aboutus-companyimg::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 56, 115, 0.25);
  z-index: 1;
  border-radius: 10px;
}

.banner-section-fifteen .banner-content-fifteen .aboutus-companyimg a img {
  border-radius: 10px;
}

.banner-section-fifteen
  .banner-content-fifteen
  .aboutus-companyimg
  a
  .playicon {
  position: absolute;
  top: 38%;
  right: 44%;
  z-index: 2;
}

.banner-section-fifteen
  .banner-content-fifteen
  .aboutus-companyimg
  a
  .playicon
  span {
  width: 33px;
  height: 33px;
  background: #0e82fd;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  color: #ffffff;
  font-size: 10px;
}

.banner-section-fifteen .banner-content-fifteen .watch-video-fifteen {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

@media (max-width: 991.98px) {
  .banner-section-fifteen .banner-content-fifteen .watch-video-fifteen {
    justify-content: center;
  }
}

.banner-section-fifteen .banner-content-fifteen .watch-video-fifteen img {
  margin-right: 7px;
}

.banner-section-fifteen .banner-content-fifteen .watch-video-fifteen h6 {
  color: #1f2937;
  margin-bottom: 0;
}

.banner-section-fifteen .banner-content-fifteen .support-consult-main {
  display: flex;
}

@media (max-width: 991.98px) {
  .banner-section-fifteen .banner-content-fifteen .support-consult-main {
    justify-content: center;
  }
}

.banner-section-fifteen
  .banner-content-fifteen
  .support-consult-main
  .support-consult {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

@media (max-width: 479.98px) {
  .banner-section-fifteen
    .banner-content-fifteen
    .support-consult-main
    .support-consult {
    display: block;
  }
}

.banner-section-fifteen
  .banner-content-fifteen
  .support-consult-main
  .support-consult:last-child {
  margin-right: 0;
}

@media (max-width: 479.98px) {
  .banner-section-fifteen
    .banner-content-fifteen
    .support-consult-main
    .support-consult
    .support-consult-img {
    margin-bottom: 5px;
  }
}

.banner-section-fifteen
  .banner-content-fifteen
  .support-consult-main
  .support-consult
  .support-consult-img
  img {
  padding: 10px;
  background: #0e82fd;
  border-radius: 5px;
  margin-right: 7px;
}

.banner-section-fifteen
  .banner-content-fifteen
  .support-consult-main
  .support-consult
  .support-consult-right
  h6 {
  color: #1f2937;
  margin-bottom: 0;
}

.banner-section-fifteen
  .banner-content-fifteen
  .support-consult-main
  .support-consult
  .support-consult-right
  .rating-fifteen {
  margin-bottom: 0;
}

.banner-section-fifteen
  .banner-content-fifteen
  .support-consult-main
  .support-consult
  .support-consult-right
  .rating-fifteen
  i.filled {
  color: #ffc001;
}

.banner-section-fifteen
  .banner-content-fifteen
  .support-consult-main
  .support-consult
  .support-consult-right
  span {
  font-size: 14px;
  color: #6b7280;
}

.banner-section-fifteen .banner-right-fifteen {
  position: relative;
  margin-top: 73px;
}

@media (max-width: 1399.98px) {
  .banner-section-fifteen .banner-right-fifteen {
    margin-top: 150px;
  }
}

@media (max-width: 991.98px) {
  .banner-section-fifteen .banner-right-fifteen {
    display: none;
  }
}

.banner-section-fifteen .banner-right-fifteen .banner-right-fifteenone {
  position: absolute;
  top: 100px;
  left: -130px;
  animation: mover 1s infinite alternate;
}

@media (max-width: 1399.98px) {
  .banner-section-fifteen .banner-right-fifteen .banner-right-fifteenone {
    top: 130px;
  }
}

@media (max-width: 1199.98px) {
  .banner-section-fifteen .banner-right-fifteen .banner-right-fifteenone {
    display: none;
  }
}

.banner-section-fifteen .banner-right-fifteen .banner-right-fifteentwo {
  position: absolute;
  top: 77px;
  left: 40px;
  animation: spin 3000ms linear infinite;
}

.banner-section-fifteen .banner-right-fifteen .banner-right-fifteenthree {
  position: absolute;
  top: 250px;
  right: -50px;
  animation: spin 3000ms linear infinite;
}

.banner-section-fifteen .banner-right-fifteen .banner-right-fifteenfour {
  position: absolute;
  right: -150px;
  bottom: 100px;
}

.banner-section-fifteen .banner-right-fifteen .banner-right-fifteenfive {
  position: absolute;
  top: 10px;
  right: -50px;
  animation: mover 1s infinite alternate;
}

.banner-section-fifteen .banner-right-fifteen .banner-right-fifteensix {
  position: absolute;
  bottom: 0;
  left: -30px;
}

@media (max-width: 1199.98px) {
  .banner-section-fifteen .banner-right-fifteen .banner-right-fifteensix {
    display: none;
  }
}

.banner-section-fifteen .banner-right-fifteen .banner-right-fifteenseven {
  position: absolute;
  top: 150px;
  right: -70px;
}

.banner-section-fifteen .banner-right-fifteen .banner-right-fifteeneight {
  position: absolute;
  bottom: 40px;
  left: 210px;
  animation: mover 1s infinite alternate;
}

.banner-section-fifteen .banner-right-fifteennine {
  position: absolute;
  bottom: 40px;
  left: 210px;
}

.services-section-fifteen {
  position: relative;
  padding: 80px 0 56px 0;
}

@media (max-width: 991.98px) {
  .services-section-fifteen {
    padding: 26px 0;
    background: #f4f4f4;
  }
}

.services-section-fifteen .service-bg-icon {
  position: absolute;
  top: 250px;
  right: 40px;
}

.services-section-fifteen .doctor-profile-widget-fifteen {
  margin-bottom: 24px;
}

.services-section-fifteen
  .doctor-profile-widget-fifteen
  .doc-pro-img
  .doctor-profile-img {
  border-radius: 10px;
  margin-bottom: 13px;
}

.services-section-fifteen
  .doctor-profile-widget-fifteen
  .doc-content-bottom
  .doc-content-split {
  margin-bottom: 15px;
}

.services-section-fifteen
  .doctor-profile-widget-fifteen
  .doc-content-bottom
  .doc-content-split
  a {
  color: #1f2937;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.services-section-fifteen
  .doctor-profile-widget-fifteen
  .doc-content-bottom
  .doc-content-split
  a:hover {
  color: #1b5d92;
}

.services-section-fifteen
  .doctor-profile-widget-fifteen
  .doc-content-bottom
  .doc-content-split
  span {
  background: #0e82fd;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 600;
  font-size: 14px;
  color: #ffffff;
}

.services-section-fifteen .doctor-profile-widget-fifteen .doc-content-bottom p {
  color: #707070;
  margin-bottom: 0;
}

.Patients-section-fifteen {
  position: relative;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .Patients-section-fifteen {
    padding: 50px 0;
    text-align: center;
  }
}

.Patients-section-fifteen .patient-fifteen-icon {
  position: absolute;
  top: 100px;
  left: 0;
  z-index: -1;
}

.Patients-section-fifteen .section-header-fifteenpatient {
  margin-bottom: 25px;
}

.Patients-section-fifteen p {
  margin-bottom: 25px;
}

.Patients-section-fifteen .service-patient-inflict {
  text-align: center;
}

@media (max-width: 575.98px) {
  .Patients-section-fifteen .service-patient-inflict {
    margin-bottom: 15px;
  }
}

.Patients-section-fifteen .service-patient-inflict .service-patient-inflictimg {
  background: #e3fbff;
  width: 196px;
  height: 120px;
  border-radius: 10px;
  margin: auto auto 15px auto;
}

@media (max-width: 1399.98px) {
  .Patients-section-fifteen
    .service-patient-inflict
    .service-patient-inflictimg {
    width: 175px;
  }
}

@media (max-width: 1199.98px) {
  .Patients-section-fifteen
    .service-patient-inflict
    .service-patient-inflictimg {
    width: 150px;
  }
}

.Patients-section-fifteen .service-patient-inflict h6 {
  color: #6b7280;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

.Patients-section-fifteen .service-patient-inflict .clients-fifteen-span {
  margin-bottom: 5px;
}

.Patients-section-fifteen .service-patient-inflict .clients-fifteen-span h3 {
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0;
}

.Patients-section-fifteen .service-patient-inflict .clients-fifteen-span span {
  font-weight: 600;
  font-size: 24px;
  color: #1f2937;
}

.Patients-section-fifteen .patients-left-front {
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
}

@media (max-width: 991.98px) {
  .Patients-section-fifteen .patients-left-front {
    display: none;
  }
}

.Patients-section-fifteen .patients-left-front.patients-left-img {
  animation-name: bounce-1;
  animation-timing-function: linear;
}

.doctors-section-fifteen {
  position: relative;
  padding: 80px 0;
  background: #f4f4f4;
}

@media (max-width: 991.98px) {
  .doctors-section-fifteen {
    padding: 50px 0;
  }
}

.doctors-section-fifteen .doctor-fifteen-icon {
  position: absolute;
  top: 100px;
  right: 0;
}

.doctors-section-fifteen .doctors-fifteen-icon {
  position: absolute;
  right: 200px;
  bottom: 0;
}

.doc-content-fift {
  padding: 15px;
}

.doc-content-fift a {
  color: #1f2937;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
  display: inline-block;
}

.doc-content-fift a:hover {
  color: #0ce0ff;
}

.doc-content-fift p {
  color: #6b7280;
  margin-bottom: 10px;
}

.doc-content-fift p span {
  color: #1f2937;
}

.doc-content-fift .rate-fifteen {
  margin-bottom: 15px;
}

.doc-content-fift .rate-fifteen .rate-four {
  padding: 5px 10px;
  background: #0ce0ff;
  border-radius: 5px;
  color: #ffffff;
}

.doc-content-fift .rate-fifteen ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.doc-content-fift .rate-fifteen ul li {
  width: 31px;
  height: 27px;
  background: #eeeef3;
  color: #1f2937;
  border-radius: 5px;
  margin-right: 5px;
}

.doc-content-fift .rate-fifteen ul li:last-child {
  margin-right: 0;
}

.doc-content-fift .rate-fifteen ul.fift-rate li {
  padding: 5px;
  width: auto;
  height: auto;
}

.doc-content-fift .fift-bottom-content h3 {
  color: #1f2937;
  font-weight: 600;
  margin-bottom: 0;
}

.doc-content-fift .fift-bottom-content h3 span {
  color: #6b7280;
  text-decoration: line-through;
  font-size: 18px;
}

.doc-content-fift .fift-bottom-content a {
  padding: 10px 25px;
  background: #0e82fd;
  color: #ffffff;
  border-radius: 5px;
  margin-bottom: 0;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: inset 0 0 0 0 #0e82fd;
  font-size: 16px;
}

@media (max-width: 1199.98px) {
  .doc-content-fift .fift-bottom-content a {
    padding: 10px 5px;
    font-size: 14px;
  }
}

.doc-content-fift .fift-bottom-content a:hover {
  box-shadow: inset 0 0 0 30px #2dd4bf;
}

.item.item-fifteen .doctor-profile-widget {
  border: none;
}

.item.item-fifteen .doctor-profile-widget.doctor-profile-widget-fift {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  margin: 10px 0;
}

.item.item-fifteen
  .doctor-profile-widget.doctor-profile-widget-fift:first-child {
  margin-left: 10px;
}

.item.item-fifteen
  .doctor-profile-widget.doctor-profile-widget-fift:last-child {
  margin-right: 10px;
}

.item.item-fifteen .doctor-profile-widget .doc-pro-img .item-info {
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.item.item-fifteen .doctor-profile-widget .doc-pro-img .item-info h6 {
  margin-bottom: 0;
  background: #0e82fd;
  border-radius: 5px;
  padding: 10px;
  color: #ffffff;
}

.item.item-fifteen
  .doctor-profile-widget
  .doc-pro-img
  .doctor-amount
  .fav-icon {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
  font-size: 17px;
}

.item.item-fifteen
  .doctor-profile-widget
  .doc-pro-img
  .doctor-amount
  .fav-icon.fav-icon-fifteen {
  background: #eeeef3;
}

.item.item-fifteen
  .doctor-profile-widget
  .doc-pro-img
  .doctor-amount
  .fav-icon.selected {
  background: #0ce0ff;
  color: #ffffff;
}

.item.item-fifteen .doctor-profile-widget .doc-pro-img.doc-pro-img-fifteen {
  padding: 56px 0;
  border-bottom: 1px solid #e5e5eb;
}

.item.item-fifteen
  .doctor-profile-widget
  .doc-pro-img.doc-pro-img-fifteen
  .doctor-profile-img-fifteen {
  width: 208px;
  height: 208px;
  margin: auto;
}

.pharmacy-section-fifteen {
  position: relative;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .pharmacy-section-fifteen {
    padding: 50px 0;
  }
}

.feedback-section-fifteen {
  position: relative;
  padding: 40px 0 80px 0;
}

@media (max-width: 1199.98px) {
  .feedback-section-fifteen {
    padding: 0 0 80px 0;
  }
}

@media (max-width: 991.98px) {
  .feedback-section-fifteen {
    padding: 0 0 50px 0;
  }
}

.feedback-section-fifteen .feedback-schedule-all {
  background: linear-gradient(92.16deg, #61aeff 0.85%, #0e82fd 76.47%);
  border-radius: 10px;
}

.feedback-section-fifteen .feedback-schedule-all .feedback-inner-main {
  margin: -45px 0 0 30px;
}

@media (max-width: 1399.98px) {
  .feedback-section-fifteen .feedback-schedule-all .feedback-inner-main {
    margin: 0 0 0 30px;
  }
}

@media (max-width: 991.98px) {
  .feedback-section-fifteen .feedback-schedule-all .feedback-inner-main {
    display: none;
  }
}

.feedback-section-fifteen .feedback-schedule-all .feedback-fifteen-content {
  padding: 50px 0;
}

@media (max-width: 991.98px) {
  .feedback-section-fifteen .feedback-schedule-all .feedback-fifteen-content {
    text-align: center;
  }
}

.feedback-section-fifteen
  .feedback-schedule-all
  .feedback-fifteen-content
  .feedback-fift-img {
  width: 66px;
  height: 17px;
  margin-bottom: 15px;
}

@media (max-width: 991.98px) {
  .feedback-section-fifteen
    .feedback-schedule-all
    .feedback-fifteen-content
    .feedback-fift-img {
    margin: auto auto 15px auto;
  }
}

.feedback-section-fifteen .feedback-schedule-all .feedback-fifteen-content h3 {
  color: #ffffff;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
}

@media (max-width: 767.98px) {
  .feedback-section-fifteen
    .feedback-schedule-all
    .feedback-fifteen-content
    h3 {
    font-size: 26px;
  }
}

.feedback-section-fifteen .feedback-schedule-all .feedback-fifteen-content p {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 15px;
}

.feedback-section-fifteen .feedback-schedule-all .feedback-fifteen-content a {
  padding: 15px 43px;
  background: #0ce0ff;
  border-radius: 5px;
  display: inline-block;
  color: #ffffff;
  transition: color 0.5s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: inset 0 0 0 0 #0e82fd;
}

.feedback-section-fifteen
  .feedback-schedule-all
  .feedback-fifteen-content
  a:hover {
  box-shadow: inset 0 0 0 30px #2dd4bf;
}

.appointment-section-fifteen {
  position: relative;
  padding: 80px 0;
  background-color: #0e82fd;
  background-image: url(../images/book-appointment-bg.png);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 58% 100%;
}

@media (max-width: 991.98px) {
  .appointment-section-fifteen {
    padding: 50px 0;
    background-image: none;
    text-align: center;
  }
}

.appointment-section-fifteen .appointment-schedule-main h2 {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .appointment-section-fifteen .appointment-schedule-main h2 {
    font-size: 34px;
  }
}

@media (max-width: 767.98px) {
  .appointment-section-fifteen .appointment-schedule-main h2 {
    font-size: 28px;
  }
}

.appointment-section-fifteen .appointment-schedule-main h6 {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 25px;
}

.appointment-section-fifteen .appointment-schedule-main p {
  color: #b0d6ff;
}

.appointment-section-fifteen .appointment-schedule-main ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media (max-width: 991.98px) {
  .appointment-section-fifteen .appointment-schedule-main ul {
    justify-content: center;
  }
}

@media (max-width: 575.98px) {
  .appointment-section-fifteen .appointment-schedule-main ul {
    flex-wrap: wrap;
  }
}

.appointment-section-fifteen .appointment-schedule-main ul li {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 25px 25px 0;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 151px;
}

@media (max-width: 575.98px) {
  .appointment-section-fifteen .appointment-schedule-main ul li {
    width: 50%;
    margin-right: 1px;
  }
}

.appointment-section-fifteen .appointment-schedule-main ul li:last-child {
  margin-right: 0;
}

.appointment-section-fifteen
  .appointment-schedule-main
  ul
  li
  .appointment-schedule-img {
  position: relative;
  margin-bottom: 20px;
}

.appointment-section-fifteen
  .appointment-schedule-main
  ul
  li
  .appointment-schedule-img
  .appoint-inner-img {
  position: absolute;
  right: -20px;
  bottom: -10px;
}

.appointment-section-fifteen .appointment-schedule-main ul li span {
  font-weight: 400;
  color: #1f2937;
  display: inline-block;
  font-size: 14px;
}

.appointment-section-fifteen .appointment-schedule-main a {
  background: #0ce0ff;
  padding: 15px 25px;
  border-radius: 5px;
  display: inline-block;
  color: #ffffff;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: inset 0 0 0 0 #0e82fd;
}

.appointment-section-fifteen .appointment-schedule-main a:hover {
  box-shadow: inset 0 0 0 50px #2dd4bf;
}

.appointment-section-fifteen .appointment-right-image {
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
}

@media (max-width: 991.98px) {
  .appointment-section-fifteen .appointment-right-image {
    display: none;
  }
}

.appointment-section-fifteen .appointment-right-image.appoint-fift-img {
  animation-name: bounce-1;
  animation-timing-function: linear;
}

.about-us-section-fifteen {
  position: relative;
  padding: 80px 0;
  background: #f4f4f4;
}

@media (max-width: 991.98px) {
  .about-us-section-fifteen {
    padding: 50px 0;
  }
}

.about-us-section-fifteen .aboutus-img-main {
  position: relative;
}

@media (max-width: 991.98px) {
  .about-us-section-fifteen .aboutus-img-main {
    display: none;
  }
}

.about-us-section-fifteen .aboutus-img-main .aboutus-img-one {
  position: absolute;
  right: 45px;
  bottom: 15px;
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.15);
  animation: mover 1s infinite alternate;
}

.about-us-section-fifteen .vertical-slider {
  position: static;
}

.about-us-section-fifteen .vertical-slider .aboutus-fifteen-main {
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  padding: 15px;
  border-radius: 10px;
  margin: 0 0 15px 0;
}

.about-us-section-fifteen
  .vertical-slider
  .aboutus-fifteen-main
  .aboutus-profile-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 15px 0;
}

.about-us-section-fifteen
  .vertical-slider
  .aboutus-fifteen-main
  .aboutus-profile-left
  .aboutus-image {
  margin-right: 7px;
}

.about-us-section-fifteen
  .vertical-slider
  .aboutus-fifteen-main
  .aboutus-profile-left
  .aboutus-image
  img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.about-us-section-fifteen
  .vertical-slider
  .aboutus-fifteen-main
  .aboutus-profile-left
  h6 {
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 7px;
}

.about-us-section-fifteen
  .vertical-slider
  .aboutus-fifteen-main
  .aboutus-profile-left
  span {
  color: #6b7280;
  font-size: 14px;
  margin-bottom: 0;
}

.about-us-section-fifteen .vertical-slider .aboutus-fifteen-main p {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.about-us-section-fifteen .vertical-slider .slick-list {
  padding: 0 !important;
}

.about-us-section-fifteen .vertical-slider .slick-dots {
  position: absolute;
  bottom: 3%;
  left: -42%;
}

@media (max-width: 991.98px) {
  .about-us-section-fifteen .vertical-slider .slick-dots {
    display: none !important;
  }
}

.about-us-section-fifteen .vertical-slider .slick-dots li {
  margin: 0;
  height: 6px;
}

.about-us-section-fifteen .vertical-slider .slick-dots li.slick-active button {
  background-color: #4c58a0;
}

.about-us-section-fifteen .vertical-slider .slick-arrow {
  position: absolute;
  bottom: 0;
}

.about-us-section-fifteen .vertical-slider .slick-prev {
  position: absolute;
  top: 95%;
  left: 84%;
  width: 45px;
  height: 45px;
  border-radius: 10px;
}

@media (max-width: 1399.98px) {
  .about-us-section-fifteen .vertical-slider .slick-prev {
    left: 80%;
  }
}

@media (max-width: 1199.98px) {
  .about-us-section-fifteen .vertical-slider .slick-prev {
    left: 76%;
  }
}

@media (max-width: 991.98px) {
  .about-us-section-fifteen .vertical-slider .slick-prev {
    top: 50%;
    left: auto;
  }
}

.about-us-section-fifteen .vertical-slider .slick-prev::before {
  content: "\f053";
  font-family: "FontAwesome";
  font-weight: 900;
  font-size: 13px;
}

.about-us-section-fifteen .vertical-slider .slick-prev:hover {
  background: #1c5b91;
}

.about-us-section-fifteen .vertical-slider .slick-next {
  top: 95%;
  width: 45px;
  height: 45px;
  border-radius: 10px;
}

@media (max-width: 991.98px) {
  .about-us-section-fifteen .vertical-slider .slick-next {
    top: 50%;
  }
}

.about-us-section-fifteen .vertical-slider .slick-next::before {
  content: "\f054";
  font-family: "FontAwesome";
  font-weight: 900;
  font-size: 13px;
}

.about-us-section-fifteen .vertical-slider .slick-next:hover {
  background: #1c5b91;
}

.about-us-section-fifteen .vertical-slider .slick-current.aboutus-fifteen-main {
  background: #0e82fd;
}

.about-us-section-fifteen
  .vertical-slider
  .slick-current.aboutus-fifteen-main
  .aboutus-profile-left
  h6 {
  color: #ffffff;
}

.about-us-section-fifteen
  .vertical-slider
  .slick-current.aboutus-fifteen-main
  .aboutus-profile-left
  span {
  color: #ffffff;
}

.about-us-section-fifteen
  .vertical-slider
  .slick-current.aboutus-fifteen-main
  p {
  color: #ffffff;
}

.frequently-section-fifteen {
  position: relative;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .frequently-section-fifteen {
    padding: 50px 0;
  }
}

.frequently-section-fifteen .items-fift {
  border: 1px solid #e5e5eb;
  border-radius: 10px;
  padding: 30px;
}

.frequently-section-fifteen .items-fift a {
  color: #1f2937;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  display: block;
}

.frequently-section-fifteen .items-fift p {
  color: #6b7280;
  font-weight: 400;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  width: 100%;
  overflow: hidden;
}

.frequently-section-fifteen .items-fift .line-arrow {
  display: flex;
  align-items: center;
  color: #0e82fd;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}

.frequently-section-fifteen .items-fift .line-arrow:hover {
  color: #0ce0ff;
}

.frequently-section-fifteen .frequent-slider-fifteen .owl-nav {
  text-align: end;
}

.frequently-section-fifteen .frequent-slider-fifteen .owl-nav .owl-prev,
.frequently-section-fifteen .frequent-slider-fifteen .owl-nav .owl-next {
  margin-top: 24px;
}

.frequently-section-fifteen .frequent-slider-fifteen .owl-dots {
  position: absolute;
  bottom: 0;
  left: 0;
}

.frequently-section-fifteen .frequent-slider-fifteen .owl-dots .owl-dot {
  width: 50px;
  height: 6px;
  background: #d9d9d9;
}

.frequently-section-fifteen
  .frequent-slider-fifteen
  .owl-dots
  .owl-dot:first-child {
  border-radius: 10px 0 0 10px;
}

.frequently-section-fifteen
  .frequent-slider-fifteen
  .owl-dots
  .owl-dot:last-child {
  border-radius: 0 10px 10px 0;
}

.frequently-section-fifteen .frequent-slider-fifteen .owl-dots .owl-dot.active {
  background: #4c58a0;
}

@media (max-width: 575.98px) {
  .frequently-section-fifteen .frequent-slider-fifteen .owl-dots {
    display: none;
  }
}

.section-header-fifteen {
  margin-bottom: 50px;
}

.section-header-fifteen h2 {
  color: #1f2937;
  font-size: 40px;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  .section-header-fifteen h2 {
    font-size: 34px;
  }
}

@media (max-width: 767.98px) {
  .section-header-fifteen h2 {
    font-size: 28px;
  }
}

.section-header-fifteen h2 span {
  color: rgb(14, 130, 253);
}

.section-header-fifteen p {
  color: #707070;
  margin-bottom: 0;
}

.doctor-slider-fifteen .owl-nav {
  text-align: center;
}

.doctor-slider-fifteen .owl-nav button.owl-prev,
.doctor-slider-fifteen .owl-nav button.owl-next {
  width: 45px;
  height: 45px;
  background: #ffffff;
  font-size: 13px;
  border-radius: 10px;
  margin-top: 50px;
}

.doctor-slider-fifteen .owl-nav button.owl-prev:hover,
.doctor-slider-fifteen .owl-nav button.owl-next:hover {
  background: #eeeef3;
}

@media (max-width: 767.98px) {
  .doctor-slider-fifteen .owl-nav button.owl-prev,
  .doctor-slider-fifteen .owl-nav button.owl-next {
    width: 35px;
    height: 35px;
  }
}

.pharmacy-slider-fifteen .owl-nav,
.frequent-slider-fifteen .owl-nav {
  text-align: center;
}

.pharmacy-slider-fifteen .owl-nav button.owl-prev,
.pharmacy-slider-fifteen .owl-nav button.owl-next,
.frequent-slider-fifteen .owl-nav button.owl-prev,
.frequent-slider-fifteen .owl-nav button.owl-next {
  width: 45px;
  height: 45px;
  background: #eeeef3;
  font-size: 13px;
  border-radius: 10px;
  margin-top: 50px;
}

.pharmacy-slider-fifteen .owl-nav button.owl-prev:hover,
.pharmacy-slider-fifteen .owl-nav button.owl-next:hover,
.frequent-slider-fifteen .owl-nav button.owl-prev:hover,
.frequent-slider-fifteen .owl-nav button.owl-next:hover {
  background: #0ce0ff;
}

@media (max-width: 767.98px) {
  .pharmacy-slider-fifteen .owl-nav button.owl-prev,
  .pharmacy-slider-fifteen .owl-nav button.owl-next,
  .frequent-slider-fifteen .owl-nav button.owl-prev,
  .frequent-slider-fifteen .owl-nav button.owl-next {
    width: 35px;
    height: 35px;
  }
}

.playicon {
  position: absolute;
  right: 61%;
  top: 37%;
}

.playicon span {
  width: 48px;
  height: 48px;
  background: #ff008a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.modal-content-video {
  padding: 0 !important;
}

.modal-content-video .modal-content {
  background: #000;
}

.modal-content-video .modal-header {
  border: 0;
}

.modal-content-video .modal-header button.close {
  color: #fff;
  opacity: 1;
}

.video-home video {
  width: 100%;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes movers {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0);
  }
}

.main-wrapper.home-sixteen {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}

@media (max-width: 1199.98px) {
  .main-wrapper.home-sixteen {
    font-size: 15px;
  }
}

.banner-section-sixteen {
  position: relative;
  padding: 85px 0 0 0;
}

.banner-section-sixteen .banner-content-sixteen {
  padding: 100px 0 0 0;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .banner-section-sixteen .banner-content-sixteen {
    padding: 50px 0;
  }
}

.banner-section-sixteen .banner-content-sixteen span {
  font-size: 14px;
  color: #0e82fd;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5px 10px;
  background: rgba(14, 130, 253, 0.1);
  margin-bottom: 10px;
  display: inline-block;
}

.banner-section-sixteen .banner-content-sixteen h1 {
  color: #515151;
  margin-bottom: 26px;
}

.banner-section-sixteen .banner-content-sixteen p {
  color: #9f9f9f;
  font-size: 16px;
  margin-bottom: 26px;
}

.banner-section-sixteen
  .banner-content-sixteen
  .banner-btns-sixteen
  .btn-primary {
  padding: 10px 25px;
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
}

.banner-section-sixteen
  .banner-content-sixteen
  .banner-btns-sixteen
  .btn-primary:hover {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
}

.banner-section-sixteen .banner-image-sixteen {
  padding: 41px 0;
  background-image: url(../images/banner-sixteen-bg.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 75% 100%;
}

.banner-section-sixteen .banner-image-sixteen .banner-inner-img-sixteen {
  position: relative;
  z-index: 1;
}

.banner-section-sixteen .banner-image-sixteen .banner-inner-img-sixteen img {
  margin-bottom: 20px;
}

.banner-section-sixteen
  .banner-image-sixteen
  .banner-inner-img-sixteen
  img:last-child {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .banner-section-sixteen .banner-image-sixteen {
    display: none;
  }
}

.banner-section-sixteen .ban-sixteen-imgone {
  position: absolute;
  top: 100px;
  right: 100px;
  z-index: 0;
}

.banner-section-sixteen .ban-sixteen-imgtwo {
  position: absolute;
  top: 90px;
  left: 0;
  z-index: 0;
}

.services-section-sixteen {
  position: relative;
  padding: 80px 0;
  background: #f8fbff;
}

@media (max-width: 991.98px) {
  .services-section-sixteen {
    padding: 50px 0;
  }
}

.services-section-sixteen .discover-you-main {
  text-align: center;
}

.services-section-sixteen .discover-you-main .discover-you-image {
  width: 200px;
  height: 200px;
  background: rgba(14, 130, 253, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin: auto auto 20px auto;
}

.services-section-sixteen .discover-you-main .discover-you-image img {
  width: auto;
}

.services-section-sixteen .discover-you-main a {
  color: #1f2937;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.services-section-sixteen .discover-you-main a:hover {
  color: #1c5b91;
}

.services-section-sixteen .discover-you-main p {
  font-size: 14px;
  color: #a2a2a2;
  margin-bottom: 15px;
}

.services-section-sixteen .discover-you-main .discov-innner {
  color: #0e82fd;
  font-size: 14px;
  font-weight: 500;
}

.services-section-sixteen .discover-you-main .discov-innner i {
  font-size: 10px;
}

.services-section-sixteen .discover-you-main .discov-innner:hover {
  color: #09dca4;
}

.services-section-sixteen .service-sixteen-icon {
  position: absolute;
  top: 75px;
  left: 45px;
  animation: mover 1s infinite alternate;
}

.treatment-section-sixteen {
  position: relative;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .treatment-section-sixteen {
    padding: 50px 0;
  }
}

.treatment-section-sixteen .item.item-sixteen .doctor-profile-widget {
  border: none;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.03);
  border-radius: 0;
}

.treatment-section-sixteen
  .item.item-sixteen
  .doctor-profile-widget
  .doc-pro-img
  .doctor-profile-img {
  border-radius: 6px;
}

.treatment-section-sixteen
  .item.item-sixteen
  .doctor-profile-widget
  .doc-pro-img
  .doctor-amount {
  position: absolute;
  top: 10px;
  left: 10px;
}

.treatment-section-sixteen
  .item.item-sixteen
  .doctor-profile-widget
  .doc-pro-img
  .doctor-amount
  span {
  padding: 5px 10px;
  border-radius: 0;
  color: #0e82fd;
  font-size: 14px;
  font-weight: 600;
}

.treatment-section-sixteen
  .item.item-sixteen
  .doctor-profile-widget
  .doc-content
  .doc-pro-info {
  align-items: start;
  padding-bottom: 10px;
}

.treatment-section-sixteen
  .item.item-sixteen
  .doctor-profile-widget
  .doc-content
  .doc-pro-info
  .doc-pro-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.treatment-section-sixteen
  .item.item-sixteen
  .doctor-profile-widget
  .doc-content
  .doc-pro-info
  .reviews-ratings
  p
  span {
  background: none;
  color: #ffbc11;
  font-size: 14px;
  margin: 0 5px 0 0;
}

.treatment-section-sixteen
  .item.item-sixteen
  .doctor-profile-widget
  .doc-content
  .doc-pro-info
  .reviews-ratings
  p
  span
  i {
  margin-right: 4px;
}

.treatment-section-sixteen
  .item.item-sixteen
  .doctor-profile-widget
  .doc-content
  .doc-pro-location
  p
  i {
  font-size: 17px;
}

.treatment-section-sixteen
  .item.item-sixteen
  .doctor-profile-widget
  .btn-primary {
  width: 100%;
  border-radius: 0;
  padding: 15px 20px;
  background: rgba(14, 130, 253, 0.1);
  color: #0e82fd;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  border: none;
  box-shadow: inset 0 0 0 0 #ffffff;
}

@media (max-width: 767.98px) {
  .treatment-section-sixteen
    .item.item-sixteen
    .doctor-profile-widget
    .btn-primary {
    padding: 10px 20px;
    font-size: 18px;
  }
}

.treatment-section-sixteen
  .item.item-sixteen
  .doctor-profile-widget
  .btn-primary:hover {
  background: #2dd4bf;
  box-shadow: inset 0 100px 0 0 #2dd4bf;
  color: #ffffff;
}

.treatment-section-sixteen .service-sixteen-icontwo {
  position: absolute;
  right: 25px;
  bottom: 150px;
  animation: mover 1s infinite alternate;
}

.features-section-sixteen {
  position: relative;
  padding: 80px 0;
  background: #f8fbff;
}

@media (max-width: 991.98px) {
  .features-section-sixteen {
    padding: 50px 0;
  }
}

.features-section-sixteen .feature-sixteen-main .feature-six-img {
  position: relative;
}

.features-section-sixteen .feature-sixteen-main .feature-six-img::after {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  transition: 0.5s;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.42) 100%
  );
}

.features-section-sixteen
  .feature-sixteen-main
  .feature-six-img
  .feature-content-six {
  position: absolute;
  bottom: 27px;
  text-align: center;
  width: 100%;
  z-index: 1;
  top: 82%;
  transition: all 0.5s ease-out;
}

.features-section-sixteen
  .feature-sixteen-main
  .feature-six-img
  .feature-content-six
  .feature-content-one {
  display: block;
}

.features-section-sixteen
  .feature-sixteen-main
  .feature-six-img
  .feature-content-six
  .feature-content-one
  h5 {
  font-size: 20px;
  color: #ffffff;
}

.features-section-sixteen
  .feature-sixteen-main
  .feature-six-img
  .feature-content-six
  .feature-content-one
  span {
  color: #ffffff;
}

.features-section-sixteen
  .feature-sixteen-main
  .feature-six-img
  .feature-content-six
  .feature-content-two {
  display: none;
}

.features-section-sixteen
  .feature-sixteen-main
  .feature-six-img
  .feature-content-six
  .feature-content-two
  p {
  color: #ffffff;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: -0.36px;
  padding: 10px;
}

@media (max-width: 353.98px) {
  .features-section-sixteen
    .feature-sixteen-main
    .feature-six-img
    .feature-content-six
    .feature-content-two
    p {
    line-height: 20px;
  }
}

.features-section-sixteen
  .feature-sixteen-main
  .feature-six-img
  .feature-content-six
  .feature-content-two
  span {
  color: #ffffff;
}

.features-section-sixteen .feature-sixteen-main .feature-six-img:hover::after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.features-section-sixteen
  .feature-sixteen-main
  .feature-six-img:hover
  .feature-content-six {
  top: 30%;
}

@media (max-width: 1399.98px) {
  .features-section-sixteen
    .feature-sixteen-main
    .feature-six-img:hover
    .feature-content-six {
    top: 10%;
  }
}

.features-section-sixteen
  .feature-sixteen-main
  .feature-six-img:hover
  .feature-content-six
  .feature-content-one {
  display: none;
}

.features-section-sixteen
  .feature-sixteen-main
  .feature-six-img:hover
  .feature-content-six
  .feature-content-two {
  display: block;
}

.facts-section-sixteen {
  position: relative;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .facts-section-sixteen {
    padding: 50px 0;
  }
}

.facts-section-sixteen .facts-section-all {
  display: flex;
}

@media (max-width: 991.98px) {
  .facts-section-sixteen .facts-section-all {
    display: block;
  }
}

.facts-section-sixteen .facts-section-all .facts-main-sixteen {
  background: #0e82fd;
  padding: 100px 30px;
  width: 50%;
}

@media (max-width: 991.98px) {
  .facts-section-sixteen .facts-section-all .facts-main-sixteen {
    width: 100%;
    padding: 60px 30px;
    text-align: center;
  }
}

.facts-section-sixteen .facts-section-all .facts-main-sixteen span {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.3);
  margin-bottom: 17px;
  display: inline-block;
}

.facts-section-sixteen .facts-section-all .facts-main-sixteen h2 {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 40px;
}

@media (max-width: 991.98px) {
  .facts-section-sixteen .facts-section-all .facts-main-sixteen h2 {
    font-size: 34px;
  }
}

@media (max-width: 767.98px) {
  .facts-section-sixteen .facts-section-all .facts-main-sixteen h2 {
    font-size: 28px;
  }
}

.facts-section-sixteen .facts-section-all .facts-main-sixteen p {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 15px;
}

.facts-section-sixteen .facts-section-all .facts-main-sixteen a {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}

.facts-section-sixteen .facts-section-all .facts-main-sixteen a i {
  font-size: 10px;
}

.facts-section-sixteen .facts-section-all .facts-main-sixteen a:hover {
  color: #09dca4;
}

.facts-section-sixteen .facts-section-all .facts-content-all {
  width: 50%;
}

@media (max-width: 991.98px) {
  .facts-section-sixteen .facts-section-all .facts-content-all {
    width: 100%;
  }
}

.facts-section-sixteen .facts-section-all .facts-content-all ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.facts-section-sixteen .facts-section-all .facts-content-all ul li {
  width: 50%;
  height: 167px;
  padding: 20px 0;
  border: 2px solid #f8fbff;
  overflow: hidden;
  position: relative;
}

@media (max-width: 575.98px) {
  .facts-section-sixteen .facts-section-all .facts-content-all ul li {
    width: 100%;
  }
}

.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-sixteen-img {
  flex-direction: column;
  transition: all 0.5s;
}

.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-sixteen-img
  img {
  margin-bottom: 25px;
}

.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-sixteen-img
  .facts-content-sixteen
  .facts-content-one
  h5 {
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  color: #626262;
  margin-bottom: 0;
}

.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-sixteen-img
  .facts-content-sixteen
  .facts-content-one
  h5
  i {
  font-size: 14px;
  color: #0e82fd;
}

.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-content-two {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.25s;
}

.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-content-two
  p {
  color: #7d7d7d;
  font-size: 14px;
  letter-spacing: -0.36px;
  padding: 0 20px;
  text-align: center;
  line-height: 30px;
  margin-bottom: 0;
}

.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-content-two:hover {
  opacity: 1;
}

.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-content-two:hover
  > * {
  transform: translateY(0);
}

.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-content-two
  > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.facts-section-sixteen
  .facts-section-all
  .facts-content-all
  ul
  li
  .facts-content-two.facts-content-two-solid {
  background: #ffffff;
}

.experts-section-sixteen {
  position: relative;
  padding: 80px 0;
  background: linear-gradient(
    115.57deg,
    #464646 12.99%,
    rgba(0, 0, 0, 0.58) 66.18%
  );
}

@media (max-width: 991.98px) {
  .experts-section-sixteen {
    padding: 50px 0;
  }
}

.pricing-section-sixteen {
  position: relative;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .pricing-section-sixteen {
    padding: 50px 0;
  }
}

.testimonal-section-sixteen {
  position: relative;
  padding: 80px 0;
  background: #f8fbff;
}

@media (max-width: 991.98px) {
  .testimonal-section-sixteen {
    padding: 50px 0;
  }
}

.feedback-section-sixteen {
  position: relative;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .feedback-section-sixteen {
    padding: 50px 0;
  }
}

.feedback-section-sixteen .feedback-schedule-all {
  background: linear-gradient(90.79deg, #0e82fd 0%, #2cd9c5 100%);
  backdrop-filter: blur(30.5px);
  border-radius: 15px;
  padding: 19px;
}

@media (max-width: 991.98px) {
  .feedback-section-sixteen .feedback-schedule-all .feedback-inner-main {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .feedback-section-sixteen .feedback-schedule-all .feedback-fifteen-content {
    text-align: center;
  }
}

.feedback-section-sixteen .feedback-schedule-all .feedback-fifteen-content h3 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .feedback-section-sixteen
    .feedback-schedule-all
    .feedback-fifteen-content
    h3 {
    font-size: 34px;
  }
}

@media (max-width: 767.98px) {
  .feedback-section-sixteen
    .feedback-schedule-all
    .feedback-fifteen-content
    h3 {
    font-size: 28px;
  }
}

.feedback-section-sixteen .feedback-schedule-all .feedback-fifteen-content p {
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {
  .feedback-section-sixteen .feedback-schedule-all .feedback-fifteen-content p {
    font-size: 16px;
  }
}

.feedback-section-sixteen
  .feedback-schedule-all
  .feedback-fifteen-content
  .feedback-btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 991.98px) {
  .feedback-section-sixteen
    .feedback-schedule-all
    .feedback-fifteen-content
    .feedback-btns {
    justify-content: center;
  }
}

@media (max-width: 575.98px) {
  .feedback-section-sixteen
    .feedback-schedule-all
    .feedback-fifteen-content
    .feedback-btns {
    display: block;
    width: 100%;
  }
}

.feedback-section-sixteen
  .feedback-schedule-all
  .feedback-fifteen-content
  .feedback-btns
  .btn-primary {
  padding: 10px 25px;
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background: #ffffff;
  color: #0e82fd;
  font-size: 16px;
  font-weight: 700;
  border: none;
  transition: 0.5s;
  margin-right: 10px;
}

.feedback-section-sixteen
  .feedback-schedule-all
  .feedback-fifteen-content
  .feedback-btns
  .btn-primary:hover {
  background: #1c5b91;
  color: #ffffff;
  transition: 0.5s;
}

@media (max-width: 575.98px) {
  .feedback-section-sixteen
    .feedback-schedule-all
    .feedback-fifteen-content
    .feedback-btns
    .btn-primary {
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.feedback-section-sixteen
  .feedback-schedule-all
  .feedback-fifteen-content
  .feedback-btns
  .btn-primarytwo {
  padding: 10px 25px;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 600;
  color: #ffffff;
  border-radius: 5px;
  transition: 0.5s;
}

.feedback-section-sixteen
  .feedback-schedule-all
  .feedback-fifteen-content
  .feedback-btns
  .btn-primarytwo:hover {
  background: #1c5b91;
  color: #ffffff;
  transition: 0.5s;
}

@media (max-width: 575.98px) {
  .feedback-section-sixteen
    .feedback-schedule-all
    .feedback-fifteen-content
    .feedback-btns
    .btn-primarytwo {
    width: 100%;
  }
}

.section-header-sixteen {
  margin-bottom: 50px;
}

.section-header-sixteen.section-header-sixteentwo h2 {
  color: #ffffff;
}

.section-header-sixteen p {
  color: #0e82fd;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.section-header-sixteen h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 0;
  color: #1f2937;
  text-transform: capitalize;
}

@media (max-width: 991.98px) {
  .section-header-sixteen h2 {
    font-size: 34px;
  }
}

@media (max-width: 767.98px) {
  .section-header-sixteen h2 {
    font-size: 28px;
  }
}

.discover-slider .owl-dots,
.treatment-sixteen-slider .owl-dots,
.features-slider-sixteen .owl-dots {
  text-align: center;
  margin-top: 60px;
}

.discover-slider .owl-dots .owl-dot,
.treatment-sixteen-slider .owl-dots .owl-dot,
.features-slider-sixteen .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d9d9d9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  margin-right: 7px;
}

.discover-slider .owl-dots .owl-dot.active,
.treatment-sixteen-slider .owl-dots .owl-dot.active,
.features-slider-sixteen .owl-dots .owl-dot.active {
  background: #0e82fd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.testi-img {
  position: relative;
  margin: 43px 0 15px;
}

.testi-img img {
  padding: 0 43px 0 0;
}

.testi-img .testi-icon {
  width: 86px;
  height: 86px;
  background: #0ce0ff;
  border-radius: 100px;
  font-size: 16px;
  color: #ffffff;
  position: absolute;
  top: -43px;
  right: 0;
}

.testi-users {
  margin: 0 43px 0 0;
}

.testi-users ul {
  margin: 0;
  padding: 0;
}

.testi-users ul li {
  display: inline-block;
  margin: 0 0 0 -23px;
}

.testi-users ul li:first-child {
  margin: 0;
}

.testi-users ul li img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border: 2px solid #ffffff;
}

@media (max-width: 767.98px) {
  .testi-users ul li img {
    width: 50px;
    height: 50px;
  }
}

.testimonial-wrap {
  position: relative;
}

.testimonial-wrap h4 {
  margin: 0 0 30px;
}

.testimonial-wrap p {
  margin: 0 0 30px;
}

.testimonial-wrap .testimonial-user h6 {
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
  margin: 0 0 5px;
}

.testimonial-wrap .testimonial-user p {
  font-size: 14px;
  margin: 0;
}

.testimonial-wrap::before {
  content: "";
  background: url("../images/icons/test-line.svg") no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 84px;
  height: 14px;
}

.slide-11 button {
  background: transparent;
  border: 0;
  padding: 0;
}

.slide-11 button.owl-next {
  margin: 0 0 0 10px;
}

.slider.slider-sixteen {
  position: relative;
}

.slider.slider-sixteen .testimonal-contents {
  text-align: center;
  background: #ffffff;
  padding: 20px 0;
  border-radius: 0px 0px 5px 5px;
  opacity: 0.3;
}

.slider.slider-sixteen .testimonal-contents h5 {
  color: #1f2937;
  font-size: 20px;
  font-weight: 500;
}

.slider.slider-sixteen .testimonal-contents span {
  color: #0e82fd;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.005em;
}

.main-reviewimages {
  height: auto;
  width: 100%;
}

.main-reviewimages img {
  opacity: 0.3;
  border-radius: 5px px 0px 0px;
}

#slide-experts .active.center .main-reviewimages img,
#slide-experts .active.center .testimonal-contents {
  opacity: 1;
}

.test_img {
  position: relative;
  opacity: 1;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
  margin: 0 -48px;
  margin-top: 40px;
}

.slider.slider.slider-sixteen {
  width: 100%;
  margin: 0 auto;
}

#slide-testimonal .center .test_img:before {
  content: none;
  transition: 0.7s ease-in-out;
}

.test_img img {
  border-radius: 24px;
  overflow: hidden;
  margin: 0 auto;
  height: 100%;
  object-fit: cover;
}

.custome_slides.owl-carousel .owl-item.active.center {
  transform: scale(1);
  margin-top: 0 !important;
  position: relative;
  z-index: 999;
}

.custome_slides.owl-carousel .owl-item {
  transform: scale(0.7);
  padding: 10px 0px;
  transition: all 0.5s;
}

.full-slide-home {
  background: #e3e3e3;
}

.full-slide-home .slick-prev {
  left: 30px;
}

.full-slide-home .slick-next {
  right: 30px;
}

.full-slide-home .slick-slide .slide-image {
  width: 100%;
  min-height: 358px;
  justify-content: center;
  flex-direction: column;
  padding: 70px 50px 70px 100px;
  vertical-align: middle;
  background-position: right;
  background-size: contain;
}

.full-slide-home .slick-slide .slide-image h2 {
  font-size: 36px;
  font-weight: 500;
}

.full-slide-home .slick-slide .slide-image .slide-ul li {
  color: #757575;
  font-size: 13px;
}

.full-slide-home .slick-slide .slide-image .slide-ul li:before {
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  padding-right: 6px;
  vertical-align: middle;
  content: "\f192";
  color: #a2a1a1;
  font-size: 10px;
}

.section-search-pharmacy {
  min-height: inherit !important;
  background: #ffffff;
  padding: 40px 0;
}

.section-search-pharmacy .banner-wrapper2 {
  margin: 0 auto;
  max-width: 680px;
  width: 100%;
}

.section-search-pharmacy .banner-wrapper2 .search-box1 .search-location1 {
  margin-right: 12px;
  position: relative;
  width: 400px;
  margin-bottom: 0;
}

.section-search-pharmacy .banner-wrapper2 .search-box1 .form-control {
  border: 1px solid #cccccc;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding-left: 35px;
}

.section-search-pharmacy .banner-wrapper2 .search-box1 .search-detect {
  padding: 5px 9px 5px 28px;
  font-size: 13px;
  color: #757575;
  border-radius: 5px;
  background: #e3e3e3 url(../images/google-map.png) no-repeat 9px center;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 5px;
}

.section-search-pharmacy .banner-wrapper2 .search-box1 .search-btn {
  width: 46px;
  -ms-flex: 0 0 46px;
  flex: 0 0 46px;
  height: 46px;
  margin: 0;
}

.section-search-pharmacy .banner-wrapper2 .search-box1 .search-btn span {
  display: none;
  font-weight: 500;
}

.doctor-img1 {
  margin-right: 20px;
}

.doc-info-right .clinic-booking a {
  padding: 10px 15px;
}

.pharmacy-location,
.pharmacy-title {
  color: #2e3842;
}

.custom-short-by .sort-title {
  color: #2e3842;
}

.custom-short-by > div {
  margin-right: 20px;
}

.custom-short-by > div:first-child {
  margin-right: 0;
}

.product-custom span.price {
  font-size: 20px;
  font-weight: 500;
}

.product-custom span.price-strike {
  font-weight: 400;
  text-decoration: line-through;
  color: #999;
}

.product-custom .cart-icon {
  font-size: 18px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  float: right;
}

.product-custom .profile-widget:hover .cart-icon {
  color: #ffffff;
  background-color: #0de0fe;
}

.product-description .doctor-img1 {
  width: 40%;
}

.product-description .doc-info-cont {
  width: 60%;
}

.clini-infos h2 b.strike {
  text-decoration: line-through;
  font-weight: 500;
}

.feature-product span {
  padding-bottom: 10px;
  display: inline-block;
}

.feature-product ul li {
  padding: 3px 0;
}

ul.benifits-col li i {
  font-size: 20px;
  margin-right: 20px;
  color: #20c0f3;
}

.booking-date li span {
  text-align: right;
}

.booking-date li span a {
  color: #20c0f3;
  font-size: 13px;
  width: 100%;
  display: inline-block;
}

.doctor-content {
  background-color: #ffffff;
  margin: 0;
  padding: 140px 0 60px;
}

@media (max-width: 991.98px) {
  .doctor-content {
    margin: 0;
    padding: 100px 0 40px;
  }
}

@media (max-width: 767.98px) {
  .doctor-content {
    margin: 0;
    padding: 100px 0 20px;
  }
}

.doctor-content.content {
  margin: 0;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .doctor-content.content {
    margin: 0;
    padding: 40px 0;
  }
}

@media (max-width: 767.98px) {
  .doctor-content.content {
    margin: 0;
    padding: 20px 0;
  }
}

.back-link {
  padding-bottom: 30px;
}

.back-link a {
  font-size: 15px;
  color: #959595;
}

.back-link a:hover {
  color: #09e5ab;
}

.back-link a i {
  margin-right: 10px;
}

@media (max-width: 767.98px) {
  .back-link {
    padding-bottom: 25px;
  }
}

.paitent-header {
  padding-bottom: 40px;
}

.paitent-header .paitent-title {
  font-size: 22px;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .paitent-header .paitent-title {
    font-size: 20px;
  }
}

@media (max-width: 767.98px) {
  .paitent-header .paitent-title {
    font-size: 18px;
  }
}

@media (max-width: 991.98px) {
  .paitent-header {
    padding-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  .paitent-header {
    padding-bottom: 20px;
  }
}

.booking-header {
  padding-bottom: 25px;
}

.booking-header .booking-title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .booking-header {
    padding-bottom: 20px;
  }
}

.payment-form-group {
  margin-bottom: 10px;
}

.payments-form .custom_radio {
  font-weight: 500;
  color: #000000;
  padding-left: 26px;
  margin-bottom: 0;
}

.payments-form .custom_radio input:checked ~ .checkmark {
  border: 5px solid #0e82fd;
}

.payments-form .custom_radio .checkmark {
  top: inherit;
  border: 2px solid #9a9a9a;
}

.payments-form .custom_radio .checkmark:after {
  top: -1px;
  left: -1px;
  background: #ffffff;
  width: 0;
  height: 0;
}

.card-label > label {
  background-color: #ffffff;
  color: #ababab;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  margin: 6px auto auto 8px;
  padding: 0 7px;
}

.card-label > .form-control {
  margin: -13px 0 0;
  padding: 6px 15px 0;
  min-height: 48px;
}

.card-label > .form-control::placeholder {
  font-weight: 500;
  color: #3c4758;
}

.booking-btn .btn {
  min-width: 123px;
  font-weight: 500;
  margin: 0;
  padding: 8px 10px;
  border-radius: 5px;
}

.booking-btn .btn i {
  font-size: 18px;
  color: #ffffff;
  position: relative;
  left: 8px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.booking-btn .btn:hover i {
  color: #0e82fd;
  left: 12px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

@media (max-width: 767.98px) {
  .booking-btn .btn {
    min-width: 100px;
    margin: 0;
    padding: 7px 10px;
  }
}

@media (max-width: 991.98px) {
  .booking-btn {
    padding-bottom: 25px;
  }
}

.booking-card {
  border: 2px solid #f4f4f4;
  border-radius: 8px;
  margin-bottom: 25px;
}

.booking-card .booking-card-body {
  padding: 15px;
}

@media (max-width: 991.98px) {
  .booking-card {
    margin-bottom: 20px;
  }
}

.booking-doctor-img {
  width: 45px;
  height: 45px;
  margin-right: 15px;
  border-radius: 50%;
}

.booking-doctor-img img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.booking-doctor-info h4 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.booking-doctor-info h4 a:hover {
  color: #0e82fd;
}

.booking-doctor-info p {
  color: #888888;
  margin-bottom: 0;
}

.booking-doctor-right p {
  margin-bottom: 0;
}

.booking-doctor-right i {
  margin-right: 18px;
  font-size: 20px;
  color: #28a745;
}

.booking-doctor-right a {
  font-weight: 500;
  text-decoration: underline;
  color: #0e82fd;
}

.booking-doctor-right a:hover {
  color: #000000;
}

.booking-list-body {
  padding-bottom: 5px !important;
}

.booking-list-body .booking-doctor-right {
  padding-bottom: 10px;
}

.booking-date-list {
  width: 300px;
}

.booking-date-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.booking-date-list ul li {
  font-weight: 500;
  color: #494949;
  padding-bottom: 10px;
}

.booking-date-list ul li span {
  font-weight: normal;
  color: #777777;
  float: right;
  text-align: right;
}

.booking-date-list ul li span i {
  font-size: 16px;
  margin-right: 6px;
}

@media (max-width: 1399.98px) {
  .booking-date-list {
    width: 265px;
  }
}

.booking-date-list.consultation-date-list {
  width: 100%;
}

.proceed-btn {
  text-align: right;
}

.proceed-btn .btn {
  margin: 0;
  padding: 8px 15px;
  min-width: 209px;
}

@media (max-width: 767.98px) {
  .proceed-btn .btn {
    min-width: 200px;
  }
}

@media (max-width: 991.98px) {
  .proceed-btn {
    padding-bottom: 0;
  }
}

.custom_radio {
  color: #8d8d8d;
  position: relative;
  font-size: 14px;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
}

.custom_radio input {
  position: absolute;
  opacity: 0;
}

.custom_radio input:checked ~ .checkmark {
  border: 1px solid #0e82fd;
}

.custom_radio input:checked ~ .checkmark:after {
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.custom_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border: 1px solid #aeaeb2;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.custom_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  opacity: 0;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #0e82fd;
  visibility: hidden;
  transform: scale(0.1);
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.radio_input .custom_radio + .custom_radio {
  margin-left: 15px;
}

.pricing-section {
  background-color: #ffffff;
  margin: 0;
  padding: 80px 0 55px;
}

@media (max-width: 991.98px) {
  .pricing-section {
    margin: 0;
    padding: 40px 0 15px;
  }
}

@media (max-width: 767.98px) {
  .pricing-section {
    margin: 0;
    padding: 40px 0 20px;
  }
}

.pricing-inner-header {
  margin-bottom: 80px;
}

@media (max-width: 991.98px) {
  .pricing-inner-header {
    margin-bottom: 40px;
  }
}

.plan-choose-info {
  padding-top: 15px;
}

.plan-choose-info label {
  margin-bottom: 0;
}

.plan-choose-info .monthly-plan {
  color: #0a0b0d;
}

.plan-choose-info .yearly-plan {
  color: #6b7280;
}

.plan-choose-info .status-toggle {
  margin: 0 20px;
  padding: 0;
}

.plan-choose-info .checktoggle {
  background-color: #0e82fd;
  border: 1px solid #0e82fd;
}

.plan-choose-info .check:checked + .checktoggle {
  background-color: #ffffff;
  border: 1px solid #0e82fd;
}

.plan-choose-info .check:checked + .checktoggle:after {
  background-color: #0e82fd;
}

.plan-choose-info .monthly-plan,
.plan-choose-info .yearly-plan {
  font-weight: 500;
  font-size: 18px;
}

@media (max-width: 767.98px) {
  .plan-choose-info .monthly-plan,
  .plan-choose-info .yearly-plan {
    font-size: 16px;
  }
}

.pricing-card {
  background: #f2f6f6;
  border: 1px solid #e5e7eb;
  border-radius: 24px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card:hover {
  background: #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card:hover .pricing-icon span {
  background: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.pricing-card:hover .pricing-icon span img {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card:hover .pricing-title p {
  color: #eff0f7;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card:hover .pricing-title h4 {
  color: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card:hover .pricing-header-text p {
  color: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card:hover .pricing-amount h2 {
  color: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card:hover .pricing-amount h2 span {
  color: #d9dbe9;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card:hover .pricing-amount h6 {
  color: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card:hover .pricing-list ul li {
  color: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card:hover .pricing-list ul li:before {
  color: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card:hover .pricing-btn .btn {
  color: #ffffff;
  border: 1px solid #3b9cf6;
  box-shadow: inset 0 0 0 50px #3b9cf6;
}

.pricing-card .card-body {
  margin: 0;
  padding: 30px;
}

@media (max-width: 991.98px) {
  .pricing-card .card-body {
    margin: 0;
    padding: 20px;
  }
}

@media (max-width: 767.98px) {
  .pricing-card .card-body {
    margin: 0;
    padding: 15px;
  }
}

.pricing-card-active {
  background: #0e82fd;
}

.pricing-card-active .pricing-icon span {
  background: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.pricing-card-active .pricing-icon span img {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card-active .pricing-title p {
  color: #eff0f7;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card-active .pricing-title h4 {
  color: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card-active .pricing-tag {
  margin-left: auto;
}

.pricing-card-active .pricing-tag span {
  background: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  font-weight: 500;
  border-radius: 10px;
  padding: 12px 13px;
}

.pricing-card-active .pricing-header-text p {
  color: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card-active .pricing-amount h2 {
  color: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card-active .pricing-amount h2 span {
  color: #d9dbe9;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card-active .pricing-amount h6 {
  color: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card-active .pricing-list ul li {
  color: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card-active .pricing-list ul li:before {
  color: #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-card-active .pricing-btn .btn {
  color: #ffffff;
  border: 1px solid #3b9cf6;
  box-shadow: inset 0 0 0 50px #3b9cf6;
}

.pricing-header {
  padding-bottom: 15px;
}

.pricing-header-info {
  padding-bottom: 15px;
}

.pricing-icon {
  margin-right: 15px;
}

.pricing-icon span {
  min-width: 70px;
  height: 70px;
  background: #ffffff;
  border-radius: 16px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.pricing-icon span img {
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-title p {
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  margin-bottom: 0;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

@media (max-width: 767.98px) {
  .pricing-title p {
    font-size: 15px;
  }
}

.pricing-title h4 {
  font-size: 24px;
  color: #1f2937;
  margin-bottom: 0;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

@media (max-width: 767.98px) {
  .pricing-title h4 {
    font-size: 20px;
  }
}

.pricing-header-text p {
  color: #6b7280;
  margin-bottom: 0;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-amount {
  padding-bottom: 20px;
}

.pricing-amount h2 {
  font-weight: 700;
  font-size: 30px;
  color: #1f2937;
  margin-bottom: 8px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-amount h2 span {
  font-weight: 400;
  font-size: 14px;
  color: #9ca3af;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

@media (max-width: 991.98px) {
  .pricing-amount h2 {
    font-size: 40px;
  }
}

@media (max-width: 767.98px) {
  .pricing-amount h2 {
    font-size: 30px;
  }
}

.pricing-amount h6 {
  font-weight: 500;
  font-size: 20px;
  color: #1f2937;
  margin-bottom: 0;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

@media (max-width: 767.98px) {
  .pricing-amount h6 {
    font-size: 16px;
  }
}

.pricing-list {
  padding-bottom: 50px;
}

.pricing-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pricing-list ul li {
  position: relative;
  font-size: 16px;
  color: #6b7280;
  padding-left: 25px;
  margin-bottom: 10px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.pricing-list ul li:last-child {
  margin-bottom: 0;
}

.pricing-list ul li:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  content: "\f058";
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 900;
  color: #0e82fd;
  border: 0;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

@media (max-width: 767.98px) {
  .pricing-list ul li {
    font-size: 14px;
  }
}

@media (max-width: 991.98px) {
  .pricing-list {
    padding-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  .pricing-list {
    padding-bottom: 20px;
  }
}

.pricing-btn {
  text-align: center;
}

.pricing-btn .btn {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  padding: 11px 15px;
  min-width: 100%;
  display: inline-block;
  color: #ffffff;
  border: 1px solid #0e82fd;
  background: #0e82fd;
}

@media (max-width: 767.98px) {
  .pricing-btn .btn {
    margin: 0;
    padding: 8px 15px;
    font-size: 14px;
  }
}

.breadcrumb-bar-two {
  background: #f2f6f6;
  background-repeat: no-repeat;
  padding-top: 70px;
  min-height: 300px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.breadcrumb-bar-two .inner-banner {
  margin: 0;
  padding: 70px 0;
}

.breadcrumb-bar-two .inner-banner .breadcrumb-title {
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 6px;
}

@media (max-width: 991.98px) {
  .breadcrumb-bar-two .inner-banner .breadcrumb-title {
    font-size: 36px;
  }
}

@media (max-width: 767.98px) {
  .breadcrumb-bar-two .inner-banner .breadcrumb-title {
    font-size: 26px;
  }
}

@media (max-width: 767.98px) {
  .breadcrumb-bar-two .inner-banner {
    margin: 0;
    padding: 50px 0;
  }
}

@media (max-width: 991.98px) {
  .breadcrumb-bar-two {
    padding-top: 40px;
    min-height: 260px;
  }
}

@media (max-width: 767.98px) {
  .breadcrumb-bar-two {
    min-height: auto;
  }
}

.breadcrumb-bar-two .page-breadcrumb ol {
  background-color: transparent;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.breadcrumb-bar-two .page-breadcrumb ol li a {
  font-weight: 500;
  color: #4b5563;
}

.breadcrumb-bar-two .page-breadcrumb ol li.active {
  color: #1f2937;
}

@media (max-width: 767.98px) {
  .breadcrumb-bar-two .page-breadcrumb ol {
    font-size: 15px;
  }
}

.breadcrumb-bar-two .page-breadcrumb .breadcrumb a:hover {
  color: #2a9d36;
}

.breadcrumb-bar-two .page-breadcrumb .breadcrumb-item + .breadcrumb-item {
  font-weight: 500;
  color: #1f2937;
  padding-left: 10px;
}

.breadcrumb-bar-two .breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  font-family: "feather";
  float: left;
  padding-right: 10px;
  color: #9ca3af;
  font-size: 14px;
  font-weight: 600;
}

.about-section {
  background-color: #ffffff;
  margin: 0;
  padding: 80px 0;
}

.about-section .about-img-info {
  padding-right: 30px;
}

.about-section .about-img-info .about-img {
  border-radius: 8px;
  margin-bottom: 25px;
}

.about-section .about-img-info .about-img:last-child {
  margin-bottom: 0;
}

.about-section .about-img-info .about-img img {
  border-radius: 8px;
}

.about-section .about-img-info .about-box {
  background: #0e82fd;
  border-radius: 8px;
  margin: 15px 0 25px;
  padding: 45px 10px;
  text-align: center;
}

.about-section .about-img-info .about-box h4 {
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  max-width: 208px;
  margin: 0 auto 0;
  padding: 0;
}

@media (max-width: 991.98px) {
  .about-section .about-img-info {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .about-section {
    margin: 0;
    padding: 40px 0;
  }
}

.section-inner-header {
  margin-bottom: 40px;
}

.section-inner-header h6 {
  font-weight: 500;
  font-size: 16px;
  color: #2a9d36;
  margin-bottom: 15px;
}

@media (max-width: 767.98px) {
  .section-inner-header h6 {
    margin-bottom: 10px;
  }
}

.section-inner-header h2 {
  font-weight: 600;
  font-size: 40px;
  color: #1f2937;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .section-inner-header h2 {
    font-size: 32px;
    max-width: inherit;
  }
}

@media (max-width: 767.98px) {
  .section-inner-header h2 {
    font-size: 22px;
  }
}

@media (max-width: 991.98px) {
  .section-inner-header {
    margin-bottom: 40px;
  }
}

.about-inner-header {
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .about-inner-header {
    margin-bottom: 20px;
  }
}

.about-content-details {
  padding-bottom: 25px;
}

.about-content-details p {
  font-size: 16px;
  color: #6b7280;
  margin-bottom: 25px;
}

.about-content-details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .about-content-details p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}

.about-contact-icon {
  margin-right: 15px;
}

.about-contact-icon span {
  width: 47px;
  height: 47px;
  border-radius: 50px;
  color: #ffffff;
  background: #0e82fd;
}

.about-contact-text p {
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  margin-bottom: 5px;
}

@media (max-width: 767.98px) {
  .about-contact-text p {
    font-size: 14px;
  }
}

.about-contact-text h4 {
  color: #27272a;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .about-contact-text h4 {
    font-size: 18px;
  }
}

.why-choose-section {
  background-color: #ffffff;
  margin: 0;
  padding: 0 0 55px;
}

@media (max-width: 991.98px) {
  .why-choose-section {
    margin: 0;
    padding: 20px 0 15px;
  }
}

@media (max-width: 767.98px) {
  .why-choose-section {
    margin: 0;
    padding: 0 0 20px;
  }
}

.why-choose-card {
  border: 1px solid #f3f4f6;
  border-radius: 8px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.why-choose-card:hover {
  box-shadow: 0px 0px 111px rgba(0, 0, 0, 0.09);
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.why-choose-card:hover .why-choose-icon span img {
  transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
  -ms-transform: rotateY(360deg);
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.why-choose-card .card-body {
  margin: 0;
  padding: 25px;
}

@media (max-width: 991.98px) {
  .why-choose-card .card-body {
    margin: 0;
    padding: 20px;
  }
}

@media (max-width: 767.98px) {
  .why-choose-card .card-body {
    margin: 0;
    padding: 15px;
  }
}

.why-choose-icon {
  padding-bottom: 24px;
}

.why-choose-icon span {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  color: #ffffff;
  background: #0e82fd;
}

.why-choose-icon span img {
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

@media (max-width: 767.98px) {
  .why-choose-icon {
    padding-bottom: 20px;
  }
}

.why-choose-content h4 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 12px;
}

.why-choose-content p {
  font-size: 16px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .why-choose-content p {
    font-size: 14px;
  }
}

.way-section {
  background-color: #ffffff;
  margin: 0;
  padding: 0 0 80px;
}

.way-section .way-bg {
  background: #0e82fd;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
}

.way-section .way-bg .way-shapes-img {
  position: relative;
}

.way-section .way-bg .way-shapes-img .way-shapes-left {
  position: absolute;
  top: 60px;
  right: 200px;
}

.way-section .way-bg .way-shapes-img .way-shapes-right {
  position: absolute;
  right: 0;
}

@media (max-width: 991.98px) {
  .way-section .way-bg .way-shapes-img {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .way-section .way-bg {
    border-radius: 10px;
  }
}

@media (max-width: 991.98px) {
  .way-section {
    margin: 0;
    padding: 0 0 40px;
  }
}

.way-inner-header {
  margin: 0;
  padding: 60px;
}

.way-inner-header h2 {
  color: #ffffff;
  margin-bottom: 29px;
}

.way-inner-header p {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 40px;
}

@media (max-width: 767.98px) {
  .way-inner-header p {
    margin-bottom: 15px;
  }
}

.way-inner-header .btn {
  font-weight: 600;
  font-size: 15px;
  padding: 11px 16px;
  color: #3b9cf6;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: inset 0 0 0 0 #0e82fd;
}

.way-inner-header .btn:hover {
  color: #ffffff;
  border: 1px solid #ffffff;
  background: #0e82fd;
  box-shadow: inset 0 0 0 50px #0e82fd;
}

@media (max-width: 767.98px) {
  .way-inner-header .btn {
    font-size: 14px;
    margin: 0;
    padding: 10px 15px;
  }
}

@media (max-width: 991.98px) {
  .way-inner-header {
    margin: 0;
    padding: 30px;
  }
}

@media (max-width: 767.98px) {
  .way-inner-header {
    margin: 0;
    padding: 20px;
  }
}

.way-img {
  position: relative;
  top: 10px;
}

@media (max-width: 991.98px) {
  .way-img {
    display: none;
  }
}

.professional-section {
  margin: 0;
  padding: 0 0 55px;
  background: #ffffff;
}

.professional-section .doctor-profile-widget {
  margin-bottom: 25px;
}

@media (max-width: 767.98px) {
  .professional-section .doctor-profile-widget {
    margin-bottom: 20px;
  }
}

@media (max-width: 991.98px) {
  .professional-section {
    margin: 0;
    padding: 0 0 15px;
  }
}

@media (max-width: 767.98px) {
  .professional-section {
    margin: 0;
    padding: 0 0 20px;
  }
}

.testimonial-section {
  background: #f2f6f6;
  margin: 0;
  padding: 80px 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.testimonial-section::after {
  content: "";
  background: url(../images/bg/doctor-shape-img1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
  top: 0;
  left: 0;
  width: 213px;
  height: 335px;
  opacity: 0.5;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .testimonial-section::after {
    display: none;
  }
}

.testimonial-section::before {
  content: "";
  background: url(../images/bg/doctor-shape-img2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  width: 213px;
  height: 335px;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .testimonial-section::before {
    display: none;
  }
}

.testimonial-section .testimonial-shape-img {
  position: relative;
}

.testimonial-section .testimonial-shape-img .testimonial-shape-left {
  position: absolute;
  top: -30px;
  left: -40px;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

.testimonial-section .testimonial-shape-img .testimonial-shape-left img {
  width: 100px;
}

.testimonial-section .testimonial-shape-img .testimonial-shape-right {
  position: absolute;
  top: 60px;
  right: 0;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

.testimonial-section .testimonial-shape-img .testimonial-shape-right img {
  width: 100px;
}

@media (max-width: 991.98px) {
  .testimonial-section .testimonial-shape-img {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .testimonial-section {
    margin: 0;
    padding: 40px 0;
  }
}

@media (max-width: 767.98px) {
  .testimonial-info {
    flex-direction: column;
  }
}

.testimonial-img {
  border-radius: 50%;
  margin-right: 50px;
  width: 220px;
  -ms-flex: 0 0 220px;
  flex: 0 0 220px;
}

@media (max-width: 767.98px) {
  .testimonial-img {
    margin: 0 0 30px 0;
    padding: 0;
    width: 200px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

.testimonial-img img {
  border-radius: 50%;
  width: 100%;
}

.testimonial-header {
  margin-bottom: 25px;
}

.testimonial-details p {
  font-size: 16px;
  margin-bottom: 15px;
}

@media (max-width: 767.98px) {
  .testimonial-details p {
    font-size: 14px;
  }
}

.testimonial-details h6 {
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 0;
  color: #9ca3af;
}

.testimonial-details h6 span {
  font-weight: 500;
  font-size: 16px;
  color: #1f2937;
}

.testimonial-grid {
  margin: 0;
  padding: 0 60px;
}

@media (max-width: 767.98px) {
  .testimonial-grid {
    margin: 0;
    padding: 0 25px;
  }
}

.testimonial-slider .slick-prev,
.testimonial-slider .slick-next {
  width: 44px;
  height: 44px;
  font-size: 0;
  color: #1e3259;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
}

.testimonial-slider .slick-prev:hover,
.testimonial-slider .slick-next:hover {
  color: #ffffff;
  background: #0e82fd;
}

.testimonial-slider .slick-prev:focus,
.testimonial-slider .slick-next:focus {
  color: #ffffff;
  background: #0e82fd;
  opacity: 1;
}

.testimonial-slider .slick-prev:before {
  font-family: "Font Awesome 5 Free";
  content: "\f053";
  font-size: 16px;
  font-weight: 900;
}

.testimonial-slider .slick-next:before {
  font-family: "Font Awesome 5 Free";
  content: "\f054";
  font-size: 16px;
  font-weight: 900;
}

.faq-section-inner {
  margin: 0;
  padding: 80px 0 80px;
}

@media (max-width: 1199.98px) {
  .faq-section-inner {
    margin: 0;
    padding: 80px 0 80px;
  }
}

@media (max-width: 991.98px) {
  .faq-section-inner {
    margin: 0;
    padding: 40px 0;
  }
}

.faq-inner-page {
  background-color: #ffffff;
  margin: 0;
  padding: 80px 0 55px;
}

.faq-inner-info .accordion-item {
  margin-bottom: 25px;
}

.faq-inner-info .accordion-item:last-child {
  margin-bottom: 25px;
}

.onboard-page {
  overflow: hidden;
}

.right-panel-title {
  background: #0c4f8a;
  padding: 10px;
  display: none;
}

.onboard-wrapper {
  overflow: hidden;
  height: 100vh;
  background: #ffffff;
}

.left-panel {
  background-color: #0c4f8a;
  width: 25%;
  padding: 45px;
  height: 100vh;
  overflow: auto;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.left-panel::-webkit-scrollbar {
  display: none;
}

@media (max-width: 991.98px) {
  .left-panel {
    display: none;
  }
}

.right-panel {
  width: 75%;
  height: 100vh;
  overflow: auto;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  flex-wrap: wrap;
}

.right-panel .select2-container .select2-selection--single {
  border: 1px solid #e6e9f4;
  height: 54px;
  background: #f5f6fa;
}

.right-panel .select2-container .select2-selection--single:focus {
  border-color: #09e5ab;
  background-color: #ffffff;
}

.right-panel
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 14px;
  font-weight: 400;
  font-weight: normal;
  line-height: 54px;
  color: #585858;
}

.right-panel
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 54px;
  right: 7px;
}

.right-panel .accordion-item:first-of-type {
  margin-bottom: 16px;
  border-radius: 6px;
  overflow: hidden;
}

.right-panel .accordion-item:not(:first-of-type) {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 16px;
  border-radius: 6px;
  overflow: hidden;
}

.right-panel .accordion-button {
  padding: 20px;
}

.right-panel .accordion-button::after {
  transform: rotate(-90deg);
}

.right-panel .accordion-button:not(.collapsed)::after {
  transform: rotate(0deg);
}

.right-panel .accordion-button:focus {
  box-shadow: 8px 7px 14px -15px rgba(0, 0, 0, 0.4);
}

.right-panel .accordion-button:not(.collapsed) {
  background-color: #ffffff;
  color: #000000;
}

.right-panel .accordion-body {
  padding: 1rem 1rem;
}

@media (max-width: 991.98px) {
  .right-panel {
    width: 100%;
    height: auto;
    padding: 0px;
  }
}

.onboard-img {
  margin: auto;
  padding: 70px 0px 20px 0px;
  animation: mymove 1s ease-in-out infinite alternate;
  text-align: center;
  width: 240px;
}

.onboard-content h3 {
  color: #ffffff;
  font-weight: 600;
  font-size: 21px;
}

.onboard-content p {
  color: #ffffff;
  font-weight: 200;
  font-size: 13px;
}

.onboarding-slider {
  margin-top: 10px;
}

#onboard-slider .owl-dots {
  margin-top: 0px;
  justify-content: center;
}

#onboard-slider .owl-nav {
  display: none;
}

#onboard-slider.owl-theme .owl-dots .owl-dot span {
  background-color: #09e5ab;
  width: 15px;
  height: 6px;
  border-radius: 50px;
  transition: 0.5s all;
  margin: 5px 3px 0;
  display: block;
  position: relative;
}

.owl-theme .owl-dots .owl-dot span {
  display: none !important;
}

#onboard-slider.owl-theme .owl-dots .owl-dot.active span {
  background-color: #ffffff;
  width: 50px;
  border-radius: 50px;
}

.on-board-wizard {
  position: sticky;
  top: 65px;
}

.on-board-wizard ul {
  list-style: none;
  padding: 0px;
}

.on-board-wizard ul li a {
  align-items: center;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 4px 4px rgba(212, 237, 255, 0.25);
  border-radius: 4px;
  margin: 0 0 18px 0;
  padding: 13px 5px 13px 8px;
  transition: 0.1s ease-out;
}

.on-board-wizard ul li a:hover {
  box-shadow: 8px 7px 14px -15px rgba(0, 0, 0, 0.4);
}

.onboarding-list h6 {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.onboarding-list p {
  font-weight: 300;
  font-size: 13px;
  color: #585858;
  margin-bottom: 0px;
}

li:hover .onboarding-list h6 {
  color: #000000;
}

.onboarding-progress {
  margin-right: 20px;
  min-width: 35px;
  height: 35px;
  background: #f4f4f4;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  color: #000000;
}

.onboarding-progress.active {
  background: #10916f;
  color: #ffffff;
}

.onboarding-progress span {
  font-size: 13px;
}

.onboarding-title h2 {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 10px;
}

.onboarding-title h2 span {
  color: #ff0202;
}

.onboarding-title h6 {
  font-weight: 400;
  font-size: 15px;
  color: #585858;
  margin-bottom: 25px;
  line-height: 1.5;
}

.onboarding-title h6 span {
  font-weight: 600;
}

.onboarding-title.profile-title {
  margin-bottom: 35px;
}

.onboarding-title.pass-title {
  margin-bottom: 32px;
}

.onboarding-content-box {
  margin-top: 20px;
  margin: 20px 0 0 0;
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 24px 24px rgba(212, 237, 255, 0.3);
  border-radius: 4px;
  border: 1px solid #e6e9f4;
}

.onboarding-content-box.content-wrap {
  flex-direction: column;
  min-height: 580px;
}

.onboarding-content-box.lock-box {
  padding: 70px;
}

.onboarding-btn a {
  background: #09e5ab;
  border-radius: 6px;
  padding: 15px 50px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  border: 1px solid #09e5ab;
  transition: all 0.5s;
}

.onboarding-btn a:hover {
  box-shadow: inset 0 60px 0 0 #10916f;
  border-color: #10916f;
}

.onboarding-btn.Personalize-btn {
  margin-top: 30px;
}

.onboarding-btn.Consultation-btn {
  margin-top: 70px;
}

.onboarding-btn.Consultation-btn a:nth-child(2) {
  background: #ffffff;
  border-radius: 6px;
  padding: 15px 50px;
  color: #0c4f8a;
  border: none;
}

.onboarding-btn.Consultation-btn a:nth-child(2):hover {
  box-shadow: none;
  color: #09e5ab;
}

.onboarding-btn.Consultation-btn.cost-btns {
  margin-top: 40px;
}

.onboarding-btn.prefer-btn {
  margin-top: 70px;
}

.onboarding-btn.pop-btn {
  margin-top: 4px;
}

.passcode-wrap input,
.passcode-wrap select,
.passcode-wrap textarea {
  background-color: #f5f6fa;
  border: 1px solid #e6e9f4;
  border-radius: 5px;
  width: 60px;
  height: 54px;
  text-align: center;
  color: #354052;
  font-size: 20px;
  font-weight: 500;
  margin-right: 23px;
}

.passcode-wrap input:focus {
  color: #354052;
  border-color: #09e5ab;
  background-color: #ffffff;
  outline: none;
}

.passcode-wrap.mail-box input {
  width: 100%;
  text-align: unset;
  font-size: 14px;
  height: 54px;
}

.passcode-wrap.mail-box select {
  width: 100%;
  text-align: unset;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #e6e9f4 !important;
  outline: none;
  color: #585858;
  padding: 0px 10px;
}

.passcode-wrap.mail-box textarea {
  width: 100%;
  height: 145px;
  text-align: unset;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #e6e9f4;
  outline: none;
  resize: none;
}

.passcode-wrap select:focus {
  color: #354052;
  border-color: #09e5ab !important;
  background-color: #ffffff;
  outline: none;
}

.passcode-wrap.form-focus.focused .focus-label {
  opacity: 1;
  top: -31px;
  font-size: 12px;
  z-index: 1;
  background: #ffffff;
  padding: 0px 5px;
  color: #5ec6cd;
  font-weight: 400;
}

.passcode-wrap.form-focus .focus-label {
  top: -4px;
  color: #585858;
}

.passcode-wrap textarea:focus {
  color: #354052;
  border-color: #09e5ab;
  background-color: #ffffff;
  outline: none;
}

.passcode-wrap.form-floating > label {
  color: #585858;
  font-size: 14px;
}

.mail-icon {
  background: url("../images/mail-box.png") no-repeat 99% 50%;
}

.form-focus .focus-label span {
  color: #f0142f;
}

.placeholder-style::placeholder {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #585858;
  opacity: 1;
}

.placeholder-style:-ms-input-placeholder {
  color: #585858;
}

.placeholder-style::-ms-input-placeholder {
  color: #585858;
}

.onboarding-lable {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
}

.onboarding-lable span {
  color: #ff0202;
}

.upload-pic {
  align-items: center;
  border-radius: 10px;
  height: 163px;
  width: 183px;
  position: relative;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  border: 1px dashed #8f9bba;
}

.upload-pic img {
  max-width: 100%;
  border-radius: 10px;
}

.upload-pic h6 {
  margin-top: 12px;
  font-weight: 500;
  font-size: 16px;
  color: #8f9bba;
}

.upload-pic.patient-photo-upload {
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  width: 265px;
  height: 235px;
  background: #f5f6fa;
  border: none;
}

.upload-pics input[type="file"] {
  opacity: 0;
  width: 40px;
  height: 63px;
  position: absolute;
  cursor: pointer;
  top: -15px;
  right: 8px;
  margin-right: 0px;
}

.photo-norms h5 {
  color: #8f9bba;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 35px;
}

.btn-profile img {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 11px;
  right: 8px;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.tog-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f5f6fa;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tog-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  bottom: 4px;
  background-color: #008f64;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  top: 4px;
  left: 4px;
}

.tog-slider.round {
  border-radius: 30px;
  width: 56px;
  height: 28px;
  border: 2px solid #f3f3f3;
}

.tog-slider.round:before {
  border-radius: 50%;
}

input:checked + .tog-slider {
  background-color: #008f64;
}

input:checked + .tog-slider:before {
  -webkit-transform: translateX(32px);
  -ms-transform: translateX(32px);
  transform: translateX(32px);
  background: #ffffff;
  left: -1px;
}

.save-card {
  margin-left: 22px;
}

.save-card h5 {
  font-weight: 500;
  font-size: 14px;
}

.save-card h6 {
  font-weight: 400;
  font-size: 14px;
  color: #8f9bba;
}

.save-detail {
  padding: 25px 0 0;
}

.payment-icon {
  position: relative;
}

.payment-icon img {
  position: absolute;
  top: 250px;
}

.avail-content {
  margin-left: 15px;
  margin-top: 4px;
}

.avail-content h6 {
  color: #10916f;
}

.weekDays-selector input {
  display: none !important;
}

.weekDays-selector input[type="checkbox"] + label {
  display: inline-block;
  border-radius: 4px;
  background: #ffffff;
  padding: 8px 0px;
  width: 60px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #e6e9f4;
  font-weight: 400;
  font-size: 20px;
}

.weekDays-selector input[type="checkbox"]:checked + label {
  background: #f5f6fa;
  color: #000000;
}

.consult-option {
  position: relative;
}

.call-option {
  position: relative;
  width: 33%;
  margin-right: 10px;
}

.call-option.file-option {
  margin-right: 0px;
  background: #f5f6fa;
  border-radius: 10px;
  border: 2px dashed #e6e9f4;
  width: 100%;
}

.call-option.verify-account {
  min-width: 242px;
}

.option-radio {
  appearance: none;
  display: none;
}

.option-radio:checked + .call-lable {
  box-shadow: 0 0 0 60px #0c4f8a inset;
  color: #ffffff;
}

.option-radio:checked + .call-lable img {
  filter: brightness(0) invert(1);
}

.call-lable {
  align-items: center;
  justify-content: center;
  position: relative;
  transition-duration: 0.5s;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  min-height: 75px;
  text-align: center;
  border: 1px solid #e6e9f4;
  cursor: pointer;
}

.call-lable img {
  margin-right: 6px;
  margin-bottom: 5px;
}

.call-lable.verify-lable.verify-lable {
  width: 100%;
  height: 170px;
  font-size: 14px;
  font-weight: 400;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.call-lable.verify-lable.verify-options {
  height: 105px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
  border-radius: 5px;
}

.accordion-body .onboarding-title h2 {
  font-size: 16px;
}

.accordion-body .onboarding-title h6 {
  font-size: 14px;
}

.prefer-box {
  border-radius: 4px;
  box-shadow: 0px 4px 24px rgba(227, 227, 227, 0.25);
  width: 100%;
  padding: 36px;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #e6e9f4;
}

.prefer-box .right-sec i {
  font-size: 16px;
  color: #433489;
}

.prefer-box:hover {
  box-shadow: 0px 0px 10px 3px rgb(212, 237, 255);
}

.verify-item {
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.8;
}

.verify-item::marker {
  color: #0c4f8a;
  font-size: 120%;
}

.verify-list {
  padding: 0 0 0 20px;
  margin-bottom: 0px;
}

.verify-list li:last-child {
  margin-bottom: 0px;
}

.upload-sucess-note h6 {
  color: #10906f;
  font-weight: 400;
  font-size: 16px;
}

.upload-sucess-note h6:nth-child(2) {
  color: #3e45eb;
  font-weight: 400;
  font-size: 16px;
  margin-top: 18px;
}

.upload-sucess-note h6:nth-child(2) i {
  margin-left: 4px;
  cursor: pointer;
}

.verify-box {
  background: #fafdff;
  padding: 16px;
}

.modal-content.id-pop-content {
  border-radius: 10px;
}

.modal-header.id-pop-header {
  border-bottom: none;
  padding: 2rem 1rem 1rem 1rem;
}

.modal-footer.id-pop-footer {
  border-top: none;
  padding: 0.75rem 0rem 2rem 0rem;
}

.modal-body.id-pop-body {
  padding: 1rem 3rem;
}

.modal-body.id-pop-body h2 {
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 14px;
}

.modal-body.id-pop-body p {
  font-weight: 400;
  font-size: 15px;
  color: #585858;
}

.modal-body.id-pop-body h3 {
  font-weight: 500;
  font-size: 20px;
}

.modal-body.id-pop-body span {
  font-weight: 500;
  font-size: 20px;
  color: #228fe0;
  padding-bottom: 30px;
  display: block;
}

.modal.show .modal-dialog.id-upload {
  max-width: 600px;
}

.fade.fade-custom {
  background: rgba(0, 0, 0, 0.8);
}

.set-path {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.lock-content img {
  margin-bottom: 20px;
}

.lock-content h2 {
  font-weight: 500;
  font-size: 24px;
}

.lock-content h6 {
  margin: 15px 0px 30px 0px;
  font-weight: 400;
  font-size: 16px;
  color: #585858;
}

.lock-content a {
  color: #ff0202;
  font-weight: 600;
  font-size: 16px;
}

.lock-content a:hover {
  color: #000000;
}

.mail-icon2 {
  background: url(../images/icons/phone.png) no-repeat 99% 50%;
}

.pass-icon {
  background: url(../images/icons/password-duotone.svg) no-repeat 99% 50%;
}

.pass-icon-1 {
  background: url(../images/icons/fluent-password.svg) no-repeat 99% 50%;
}

.form-group.pass-group {
  margin-bottom: 2rem;
}

.method-btn a {
  color: #0c4f8a;
  font-weight: 500;
  font-size: 14px;
}

.method-btn a:hover {
  color: #09e5ab;
}

.code-title {
  background: rgba(76, 218, 180, 0.1);
  border-radius: 4px;
  margin: 30px 0px;
  padding: 12px 15px;
}

.code-title h6 {
  color: #10916f;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0px;
}

.opt-resend {
  padding-top: 30px;
  position: relative;
}

.opt-resend a {
  font-weight: 500;
  font-size: 16px;
  position: absolute;
  left: 0px;
}

.upload-patient-btn {
  position: relative;
  margin-bottom: 15px;
}

.upload-patient-btn:hover .pic-upload-btn {
  color: #09e5ab;
}

#patient-verified .modal-body.id-pop-body p {
  font-size: 16px;
  max-width: 280px;
  margin: auto;
}

.upload-patient-pic {
  padding: 10px 0px 30px 0px;
}

.upload-patient-pic input[type="file"] {
  opacity: 0;
  max-width: 468px;
  min-height: 60px;
  cursor: pointer;
  top: 0px;
  left: 87px;
  position: absolute;
}

.pic-upload-btn {
  margin: auto;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 4px 10px rgba(225, 225, 225, 0.25);
  border-radius: 5px;
  cursor: pointer;
  display: block;
  max-width: 475px;
  text-align: center;
  z-index: 99999;
}

.onboarding-contents {
  justify-content: center;
}

.uploads-report {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploads-report a:nth-child(1) {
  margin-bottom: 20px;
}

.select-gender-option [type="radio"]:checked,
.select-gender-option [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.select-gender-option [type="radio"]:checked + label,
.select-gender-col [type="radio"]:not(:checked) + label {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.select-gender-option label {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(194, 194, 194, 0.25);
  border-radius: 5px;
  padding: 28px;
  width: 100%;
  text-align: center;
  position: relative;
}

.select-gender-option [type="radio"]:not(:checked) + label:after {
  opacity: 0;
}

.select-gender-option [type="radio"]:checked + label:after,
.select-gender-option [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(212, 237, 255, 0.25);
  border: 2px solid #228fe0;
  box-shadow: 0px 4px 24px rgba(34, 143, 224, 0.04);
  border-radius: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: 2px solid #0de0fe;
}

.login-header .form-focus .focus-label {
  top: -6px;
  color: #585858;
}

.relative-form {
  position: relative;
  background: #f5f6fa;
  border: 1px solid #e6e9f4;
  border-radius: 5px;
  min-height: 54px;
  padding: 6px 15px;
  align-items: center;
}

.relative-form span {
  color: #131523;
}

.relative-form .form-control::placeholder {
  color: #131523;
  font-weight: 400;
  font-size: 14px;
}

.relative-form .btn {
  border-radius: 6px;
  padding: 5px 15px;
  position: absolute;
  top: 7px;
  right: 7px;
}

.relative-file-upload {
  color: #000;
  padding: 11px 35px;
  font-size: 14px;
  margin-bottom: 0;
  cursor: pointer;
  position: absolute;
  right: 4px;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  background: #eeeeee;
  border-radius: 4px;
}

.relative-file-upload:hover {
  color: #ffffff;
  background: #bdb8b8;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.relative-file-upload input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: #ffffff;
  cursor: inherit;
  display: block;
}

.onboarding-content .checklist-col .custom_check .checkmark {
  width: 35px;
}

.onboarding-content .checklist-col .custom_check .checkmark:after {
  left: 10px;
  top: 3px;
}

.onboarding-content
  .checklist-col.pregnant-col
  .remember-me-col
  .custom_check
  .checkmark {
  border-color: #228fe0;
}

.onboarding-content .checklist-col .remember-me-col {
  border-bottom: 1px solid #f4f4f4;
}

.onboarding-content .custom_check .checkmark {
  left: -10px;
}

.onboarding-content .custom_check .checkmark::after {
  color: #ffffff;
}

.onboarding-content .custom_check input:checked ~ .checkmark {
  background-color: #228fe0;
}

.onboarding-content .increment-decrement .input-groups input[type="button"] {
  background-color: transparent;
  font-size: 20px;
  border: 1px solid #228fe0;
  border-radius: 4px;
  height: 24px;
  width: 35px;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin-top: 0px;
  line-height: 0;
  padding: 0px;
  color: #228fe0;
}

.modal-footer.id-pop-footer.text-center {
  justify-content: center;
}

@keyframes mymove {
  to {
    transform: translateY(-5px);
  }
}

@media (max-width: 991.98px) {
  .right-panel-title {
    display: block;
  }

  .right-panel-title img {
    max-width: 140px;
  }

  .onboarding-content-box.content-wrap {
    min-height: auto;
  }

  .onboarding-list h6 {
    font-size: 15px;
  }

  .onboarding-list p {
    font-size: 13px;
  }

  .onboarding-progress span {
    font-size: 12px;
  }

  .onboarding-title h2 {
    font-weight: 450;
    font-size: 22px;
  }

  .onboarding-title h6 {
    font-size: 14px;
  }

  .onboarding-lable {
    font-size: 13px;
  }

  .passcode-wrap.mail-box input {
    font-size: 14px;
    height: 50px;
  }

  .passcode-wrap.mail-box textarea {
    height: 100px;
  }

  .passcode-wrap.form-focus .focus-label {
    top: -8px;
  }

  .passcode-wrap input {
    width: 77px;
    height: 50px;
    font-size: 24px;
    font-weight: 400;
  }

  .right-panel .select2-container .select2-selection--single {
    height: 50px;
  }

  .right-panel
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 50px;
  }

  .right-panel
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    height: 50px;
  }

  .onboarding-btn {
    margin-top: 75px;
  }

  .onboarding-btn a {
    padding: 13px 45px;
    font-size: 14px;
  }

  .onboarding-btn.step-2-btn {
    margin-top: 50px;
  }

  .onboarding-btn.Consultation-btn.cost-btns {
    margin-top: 10px;
  }

  .onboarding-content-box {
    margin-top: 0px;
    padding: 30px;
  }

  .method-btn a {
    font-size: 12px;
  }

  .code-title {
    padding: 10px 12px;
    margin: 22px 0px;
  }

  .code-title h6 {
    font-size: 14px;
  }

  .weekDays-selector input[type="checkbox"] + label {
    font-size: 16px;
    padding: 14px 22px;
  }

  .onboard-wrapper {
    height: auto;
  }

  .modal.show .modal-dialog.id-upload {
    max-width: 400px;
  }

  .modal-header.id-pop-header {
    padding: 5px;
  }

  .modal-body.id-pop-body {
    padding: 5px;
  }

  .modal-body.id-pop-body h2 {
    font-size: 20px;
  }

  .modal-body.id-pop-body p {
    font-size: 12px;
  }

  .modal-footer.id-pop-footer {
    border-top: none;
    padding: 2px 0px 5px 0px;
  }

  .call-lable {
    font-size: 14px;
    width: 172px;
  }

  .call-lable.verify-lable.verify-lable {
    height: 120px;
  }

  .upload-sucess-note h6 {
    font-size: 14px;
  }

  .upload-sucess-note h6:nth-child(2) {
    font-size: 14px;
  }

  .payment-icon img {
    top: -168px;
    right: 10px;
  }

  .tog-slider:before {
    height: 12px;
    width: 12px;
  }

  .tog-slider.round {
    width: 50px;
    height: 22px;
  }

  .save-card h6 {
    font-size: 13px;
    margin-bottom: 0px;
  }

  .prefer-box {
    padding: 24px;
    font-size: 14px;
  }

  .call-option {
    width: max-content;
  }

  .onboard-page {
    overflow: auto;
  }

  .on-board-wizard {
    padding-top: 25px;
    top: 50px;
  }
}

@media (max-width: 575.98px) {
  .onboarding-progress span {
    font-size: 13px;
  }

  .onboarding-title {
    text-align: center;
  }

  .onboarding-title h2 {
    font-weight: 500;
    font-size: 18px;
  }

  .onboarding-title h6 {
    font-size: 14px;
  }

  .verify-box {
    padding: 5px;
  }

  .verify-item {
    font-size: 14px;
    margin-bottom: 2px;
  }

  .onboarding-content-box {
    padding: 20px;
  }

  .btn-profile img {
    height: 30px;
    width: 30px;
  }

  .upload-pic {
    height: 100px;
    width: 120px;
  }

  .upload-pic h6 {
    font-size: 14px;
  }

  .passcode-wrap.mail-box input {
    font-size: 10px;
    height: 40px;
  }

  .passcode-wrap.mail-box textarea {
    height: 90px;
  }

  .passcode-wrap.form-focus .focus-label {
    top: -9px;
  }

  .passcode-wrap input {
    width: 48px;
    height: 43px;
    font-size: 19px;
    font-weight: 400;
  }

  .onboarding-btn {
    margin-top: 10px;
    text-align: center;
  }

  .onboarding-btn.Personalize-btn {
    margin-top: 15px;
    text-align: center;
  }

  .onboarding-btn a {
    padding: 10px 30px;
    font-size: 14px;
    margin-top: 15px;
  }

  .onboarding-btn.step-2-btn {
    margin-top: 25px;
  }

  .onboarding-btn.prefer-btn {
    margin-top: 10px;
  }

  .onboarding-btn.Consultation-btn {
    margin-top: 15px;
  }

  .on-board-wizard {
    padding-left: 0px;
  }

  .right-panel .select2-container .select2-selection--single {
    height: 43px;
  }

  .right-panel
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 40px;
    font-size: 14px;
  }

  .right-panel
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    height: 42px;
  }

  .right-panel .accordion-button {
    padding: 20px;
  }

  .select2-results__option[aria-selected] {
    font-size: 11px;
  }

  .consult-option {
    flex-direction: column;
    align-items: center;
  }

  .call-option {
    margin-bottom: 20px;
    margin-right: 0px;
  }

  .call-lable {
    font-size: 14px;
  }

  .call-lable.verify-lable.verify-options {
    width: 253px;
    height: 118px;
  }

  .call-lable.verify-lable.verify-lable {
    height: 100px;
  }

  .code-title {
    margin: 10px 0px;
  }

  .code-title h6 {
    font-size: 12px;
  }

  .weekDays-selector input[type="checkbox"] + label {
    padding: 12px 22px;
    font-size: 14px;
  }

  .modal-body.id-pop-body h2 {
    font-size: 18px;
  }

  .modal-body.id-pop-body p {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .modal-body.id-pop-body span {
    font-size: 18px;
    padding-bottom: 10px;
  }

  .upload-pics input[type="file"] {
    height: 60px;
  }

  .photo-norms h5 {
    margin-bottom: 0px;
  }

  .upload-sucess-note h6 {
    font-size: 13px;
  }

  .payment-icon img {
    top: -210px;
  }

  .prefer-box {
    padding: 12px;
    font-size: 14px;
  }

  .relative-file-upload {
    padding: 9px 10px;
  }

  .opt-resend {
    padding-top: 12px;
    padding-bottom: 30px;
  }

  .opt-resend a {
    font-size: 14px;
    left: auto;
    left: 0px;
  }

  .select-gender-option {
    flex-direction: column;
  }
}

.signup-option-btns .signup-btn-info {
  width: 100%;
  background: #f5f5f5;
  border: 1px solid #e4e4e8;
  border-radius: 8px;
  margin: 0;
  padding: 12px 15px;
  margin-bottom: 20px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.signup-option-btns .signup-btn-info:hover {
  background: #ffffff;
  border: 1px solid #0e82fd;
  box-shadow: 0px 22px 38px rgba(0, 0, 0, 0.12);
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.signup-option-btns .signup-btn-info:hover .signup-arrow {
  background: #0e82fd;
  color: #ffffff;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}

.signup-option-btns .signup-btn-info:last-child {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .signup-option-btns .signup-btn-info {
    margin: 0;
    padding: 10px;
    margin-bottom: 20px;
  }
}

.signup-info .signup-icon {
  margin-right: 16px;
}

.signup-content {
  text-align: left;
}

.signup-content h4 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 6px;
}

@media (max-width: 991.98px) {
  .signup-content h4 {
    font-size: 18px;
  }
}

.signup-content p {
  color: #71717a;
  margin-bottom: 0;
  font-size: 14px;
}

.signup-arrow {
  width: 37px;
  height: 37px;
  background: #ffffff;
  color: #000000;
  font-size: 16px;
  border-radius: 50px;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}

.terms-check-box label {
  color: #838383;
}

.terms-check-box label a {
  color: #c70e2a;
  text-decoration: underline;
}

.terms-check-box label a:hover {
  color: #c70e2a;
}

.account-signup {
  text-align: center;
}

.account-signup p {
  color: #696969;
  margin-bottom: 0;
}

.account-signup p a {
  font-size: 14px;
  font-weight: 500;
  color: #0e82fd;
  text-decoration: underline;
}

.account-signup p a:hover {
  color: #0e82fd;
  text-decoration: none;
}

.login-verify-img {
  padding-bottom: 50px;
}

@media (max-width: 991.98px) {
  .login-verify-img {
    padding-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  .login-verify-img {
    padding-bottom: 20px;
  }
}

.otp-box .form-group {
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .otp-box .form-group {
    margin-bottom: 20px;
  }
}

.otp-box input {
  width: 74px;
  height: 74px;
  background: #f9f9f9;
  border: 2px solid #acacac;
  border-radius: 8px;
  text-align: center;
  margin-right: 15px;
  font-weight: 700;
  font-size: 26px;
  color: #000000;
  outline: none;
}

.otp-box input:last-child {
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .otp-box input {
    width: 68px;
    height: 68px;
  }
}

@media (max-width: 767.98px) {
  .otp-box input {
    width: 60px;
    height: 60px;
    font-size: 24px;
  }
}

.otp-info {
  padding-bottom: 20px;
}

@media (max-width: 767.98px) {
  .otp-info {
    padding-bottom: 0;
  }
}

.otp-code p {
  color: #5b5b5b;
  margin-bottom: 8px;
}

.otp-code a {
  font-weight: 500;
  color: #0e82fd;
  text-decoration: underline;
}

.otp-code a:hover {
  text-decoration: none;
}

.otp-sec p {
  background: #f9f9f9;
  border-radius: 4px;
  color: #717171;
  padding: 4px 7px;
}

.otp-sec p i {
  font-size: 16px;
  margin-right: 6px;
}

.forgot-link {
  font-weight: 500;
  text-decoration: underline;
  color: #c70e2a;
  margin-bottom: 10px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.forgot-link:hover {
  color: #000000;
  text-decoration: underline;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.signup-code {
  font-size: 11px;
  color: #9e9e9e;
  margin: 7px 0 0 0;
  padding: 0;
}

.multistep-form #first {
  display: block;
}

.multistep-form fieldset {
  display: none;
  width: 100%;
}

.widget-setcount {
  padding-bottom: 40px;
}

.widget-setcount ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widget-setcount ul li {
  margin-right: 0;
  margin-bottom: 10px;
  position: relative;
}

.widget-setcount ul li:last-child {
  margin-right: 0;
}

.widget-setcount ul li:last-child:before {
  display: none;
}

.widget-setcount ul li:before {
  position: absolute;
  top: 50%;
  right: -66px;
  content: "";
  width: 50px;
  height: 2px;
  background: #e0e0e0;
}

.widget-setcount ul .progress-activated:before {
  background: #0e82fd;
}

.widget-setcount .step-icon {
  width: 45px;
  height: 45px;
  background: #c9e3fe;
  border-radius: 50px;
  margin-right: 15px;
}

.widget-setcount .step-content p {
  color: #e0e0e0;
  margin-bottom: 5px;
}

.widget-setcount .step-content h4 {
  font-weight: 500;
  font-size: 16px;
  color: #e0e0e0;
  margin-bottom: 0;
}

.widget-setcount .progress-activated .step-icon {
  background: #0e82fd;
}

.widget-setcount .progress-activated .step-content p {
  color: #858585;
}

.widget-setcount .progress-activated .step-content h4 {
  color: #000000;
}

.widget-setcount .progress-active .step-icon {
  background: #0e82fd;
}

.widget-setcount .progress-active .step-content p {
  color: #858585;
}

.widget-setcount .progress-active .step-content h4 {
  color: #000000;
}

.form-check-box .custom_check {
  margin-bottom: 0;
}

.form-check-box .custom_check .checkmark::after {
  content: "";
  background: #0e82fd;
  width: 12px;
  height: 12px;
  top: 3px;
  left: 3px;
  border-radius: 3px;
}

/*.custom_check {
	color: $__congo_brown;
	display: $__inline__block;
	position: $__relative;
	font-size: $__font__size__14;
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: $__pointer;
	user-select: $__none
}
.custom_check input {
	position: $__absolute;
	opacity: 0;
	cursor: $__pointer;
}
.custom_check input:checked ~ .checkmark {
	background-color: $__white;
	border-color: $__misty_rose;
}
.custom_check .checkmark {
	@include position($__absolute, 0, null, null, 0);
	height: 20px;
	width: 20px;
	border: 1px solid $__white_shade;
	background-color: $__white;
	border-radius: 3px;
	@include transition(all 0.3s ease-in-out);
}
.custom_check .checkmark::after {
    content: "\f00c";
    font-family: $__fontawesome__family;
    font-weight: $__bolder;
	@include position($__absolute, 50%, null, null, 4px);
    display: $__none;
    color: $__blue__14;
    font-size: $__font__size__12;
}
.custom_check input:checked ~ .checkmark:after {
	display: $__block;
}
*/
.contact-section {
  margin: 0;
  padding: 80px 0 55px;
}

@media (max-width: 991.98px) {
  .contact-section {
    margin: 0;
    padding: 40px 0 15px;
  }
}

@media (max-width: 767.98px) {
  .contact-section {
    margin: 0;
    padding: 40px 0 20px;
  }
}

.contact-inner-header {
  margin-bottom: 40px;
}

@media (max-width: 767.98px) {
  .contact-inner-header {
    margin-bottom: 30px;
  }
}

.contact-card {
  border: 2px solid #e9eaf6;
  border-radius: 0;
}

.contact-card .card-body {
  margin: 0;
  padding: 30px;
}

@media (max-width: 991.98px) {
  .contact-card .card-body {
    margin: 0;
    padding: 20px;
  }
}

@media (max-width: 767.98px) {
  .contact-card .card-body {
    margin: 0;
    padding: 15px;
  }
}

.contact-icon {
  color: #2a9d36;
  font-size: 44px;
  margin-right: 20px;
}

@media (max-width: 991.98px) {
  .contact-icon {
    font-size: 40px;
    margin-right: 15px;
  }
}

.contact-details h4 {
  font-weight: 600;
  font-size: 20px;
  color: #0a0b0d;
  margin-bottom: 8px;
}

@media (max-width: 991.98px) {
  .contact-details h4 {
    font-size: 18px;
  }
}

.contact-details p {
  font-size: 16px;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .contact-details p {
    font-size: 14px;
  }
}

.contact-form-card {
  background: #f8fbff;
  border-radius: 10px;
  border: 0;
}

.contact-form-card .card-body {
  margin: 0;
  padding: 30px;
}

@media (max-width: 991.98px) {
  .contact-form-card .card-body {
    margin: 0;
    padding: 20px;
  }
}

@media (max-width: 767.98px) {
  .contact-form-card .card-body {
    margin: 0;
    padding: 15px;
  }
}

.contact-form-card .form-group {
  margin-bottom: 25px;
}

.contact-form-card .form-group label {
  font-size: 12px;
  color: #0a0b0d;
  margin-bottom: 8px;
}

.contact-form-card .form-group .form-control {
  font-weight: 500;
  color: #6b7280;
}

.contact-form-card .form-group .form-control::placeholder {
  font-weight: 500;
  color: #6b7280;
}

.contact-form-card .form-group textarea.form-control {
  min-height: 150px;
  resize: none;
}

.contact-form-card .form-group-btn {
  margin-top: 35px;
}

.contact-form-card .form-group-btn .btn {
  border-radius: 5px;
  min-width: 150px;
  margin: 0;
  padding: 10px 15px;
  font-weight: 500;
}

.contact-map iframe {
  width: 100%;
  border: none;
  height: 450px;
}

.error-section {
  margin: 0;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .error-section {
    margin: 0;
    padding: 40px 0;
  }
}

.error-img {
  padding-bottom: 50px;
}

@media (max-width: 767.98px) {
  .error-img {
    padding-bottom: 25px;
  }
}

.error-404-img {
  position: relative;
}

.error-404-content {
  width: 100%;
  position: absolute;
  bottom: 30px;
}

@media (max-width: 1399.98px) {
  .error-404-content {
    bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .error-404-content {
    position: static;
    margin-top: 20px;
  }
}

.error-content h2 {
  font-weight: 600;
  font-size: 38px;
  color: #0a0b0d;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .error-content h2 {
    font-size: 32px;
  }
}

@media (max-width: 767.98px) {
  .error-content h2 {
    font-size: 22px;
  }
}

.error-content p {
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  margin-bottom: 40px;
}

@media (max-width: 767.98px) {
  .error-content p {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.error-content .btn {
  min-width: 246px;
  font-size: 18px;
  display: inline-block;
  margin: 0;
  padding: 13px 15px;
}

@media (max-width: 991.98px) {
  .error-content .btn {
    min-width: 230px;
    font-size: 16px;
    margin: 0;
    padding: 10px 15px;
  }
}

@media (max-width: 767.98px) {
  .error-content .btn {
    min-width: 150px;
    font-size: 14px;
    margin: 0;
    padding: 8px 15px;
  }
}

.coming-soon-count {
  width: 104px;
  height: 104px;
  margin: 0 16px 0 0;
  padding: 13px;
  border-radius: 50px;
  background: #0ce0ff;
  flex-shrink: 0;
}

.coming-soon-count:last-child {
  margin-right: 0;
}

.coming-soon-count h4 {
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .coming-soon-count h4 {
    font-size: 20px;
  }
}

.coming-soon-count p {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .coming-soon-count p {
    font-size: 13px;
  }
}

@media (max-width: 767.98px) {
  .coming-soon-count {
    width: 66px;
    height: 66px;
    margin: 0 10px 0 0;
    padding: 8px;
  }
}

.terms-section {
  margin: 0;
  padding: 80px 0;
}

@media (max-width: 991.98px) {
  .terms-section {
    margin: 0;
    padding: 40px 0;
  }
}

.terms-section .terms-content {
  padding-bottom: 50px;
}

.terms-section .terms-content .terms-text {
  padding-bottom: 24px;
}

.terms-section .terms-content .terms-text:last-child {
  padding-bottom: 0;
}

.terms-section .terms-content .terms-text p {
  font-size: 16px;
  color: #6b7280;
  margin-bottom: 24px;
}

.terms-section .terms-content .terms-text p:last-child {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .terms-section .terms-content .terms-text p {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.terms-section .terms-content .terms-text.terms-list p {
  margin-bottom: 10px;
}

.terms-section .terms-content .terms-text.terms-list p i {
  position: relative;
  top: 4px;
  margin-right: 8px;
  color: #0e82fd;
}

@media (max-width: 991.98px) {
  .terms-section .terms-content {
    padding-bottom: 30px;
  }
}

.terms-btn .btn-right-now {
  font-weight: 500;
  font-size: 16px;
  margin: 0 15px 0 0;
  padding: 5px 15px;
  min-width: 149px;
  border-radius: 8px;
  color: #3f3f3f;
  background: #f3f3f3;
  border: 1px solid #f3f3f3;
  box-shadow: inset 0 0 0 0 #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.terms-btn .btn-right-now:hover {
  color: #ffffff;
  background: #0e82fd;
  border-color: #0e82fd;
  box-shadow: inset 0 0 0 50px #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

@media (max-width: 767.98px) {
  .terms-btn .btn-right-now {
    font-size: 14px;
    min-width: 130px;
    margin-right: 0;
  }
}

.terms-btn .btn-primary {
  padding: 5px 15px;
  min-width: 166px;
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .terms-btn .btn-primary {
    min-width: 150px;
  }
}

@media (max-width: 767.98px) {
  .terms-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.consultation-info {
  padding-bottom: 25px;
}

.consultation-info p {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-bottom: 20px;
}

@media (max-width: 991.98px) {
  .consultation-info p {
    margin-bottom: 15px;
  }
}

.consultation-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.consultation-list ul li {
  display: block;
  margin-bottom: 15px;
}

.consultation-list ul li:last-child {
  margin-bottom: 0;
}

.consultation-types a {
  font-weight: 500;
  font-size: 16px;
  color: #6b6b6b;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 6px;
  min-width: 413px;
  margin: 0;
  padding: 10px 15px;
}

.consultation-types a:hover {
  color: #0e82fd;
  background: #ffffff;
  border: 1px solid #0e82fd;
}

.consultation-types a i {
  font-size: 18px;
  margin-right: 10px;
}

@media (max-width: 991.98px) {
  .consultation-types a {
    min-width: 300px;
  }
}

@media (max-width: 767.98px) {
  .consultation-types a {
    font-size: 14px;
    min-width: 250px;
    margin: 0;
    padding: 7px 15px;
  }
}

.consultation-types span {
  margin-left: 18px;
  font-size: 20px;
  color: #28a745;
  display: none;
}

.consultation-types.active a {
  color: #0e82fd;
  background: #ffffff;
  border: 1px solid #0e82fd;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.07);
}

.consultation-types.active span {
  display: block;
}

.booking-device-img {
  margin-right: 15px;
}

.booking-doctor-info h3 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.booking-doctor-info .device-text {
  margin-bottom: 12px;
}

.booking-doctor-info .btn {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  padding: 4px 10px;
  min-width: 109px;
  color: #0e82fd;
  background: #ffffff;
  border: 2px solid #0e82fd;
  box-shadow: inset 0 0 0 0 #0e82fd;
  border-radius: 6px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.booking-doctor-info .btn:hover {
  color: #ffffff;
  background: #0e82fd;
  box-shadow: inset 0 0 0 50px #0e82fd;
  border: 2px solid #0e82fd;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.app-images a {
  margin-right: 8px;
}

.app-images a:last-child {
  margin-right: 0;
}

.booking-date {
  padding-bottom: 30px;
}

.booking-date p {
  color: #8c8c8c;
  margin: 0 10px 0 0;
  padding: 0;
  margin-right: 10px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .booking-date p {
    margin: 0 0 10px 0;
    padding: 0;
  }
}

@media (max-width: 767.98px) {
  .booking-date {
    display: block;
    padding-bottom: 20px;
  }
}

@media (max-width: 767.98px) {
  .time-slot-card-body {
    padding-bottom: 0 !important;
  }
}

.booking-range .btn {
  background: #ffffff;
  border: 1px solid #e4e4e8;
  border-radius: 6px;
  font-size: 14px;
  font-weight: normal;
  color: #4a4a4a;
  margin: 0;
  padding: 10px 15px;
}

@media (max-width: 767.98px) {
  .booking-range .btn {
    font-size: 13px;
    margin: 0;
    padding: 10px;
    position: relative;
  }
}

.booking-range img {
  margin-right: 10px;
}

.booking-range i {
  font-size: 16px;
  margin-left: 10px;
  color: #5b5b5b;
}

.booking-card {
  border: 2px solid #f4f4f4;
  border-radius: 8px;
  margin-bottom: 25px;
}

.booking-date-slider {
  padding-bottom: 30px;
}

.date-slider {
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.date-slider .slick-slide {
  display: block;
  margin: 0;
  padding: 0 5px;
}

.date-slider li h4 {
  font-weight: 500;
  font-size: 16px;
  color: #b9b9b9;
  margin-bottom: 5px;
}

@media (max-width: 1199.98px) {
  .date-slider li h4 {
    font-size: 13px;
  }
}

@media (max-width: 991.98px) {
  .date-slider li h4 {
    font-size: 16px;
  }
}

.date-slider li p {
  color: #d4d4d4;
  margin-bottom: 0;
}

.date-slider li.active h4 {
  color: #000000;
}

.date-slider li.active p {
  color: #aaaaaa;
}

.date-slider .slick-prev:before {
  content: " \e92f";
}

.date-slider .slick-next:before {
  content: " \e930";
}

.date-slider .slick-prev,
.date-slider .slick-next {
  background: transparent;
  color: #000000;
  line-height: normal;
}

.date-slider .slick-prev:hover,
.date-slider .slick-prev:focus,
.date-slider .slick-next:hover,
.date-slider .slick-next:focus {
  background-color: transparent;
  color: #09e5ab;
  opacity: 1;
}

.date-slider .slick-prev:hover:before,
.date-slider .slick-prev:focus:before,
.date-slider .slick-next:hover:before,
.date-slider .slick-next:focus:before {
  color: #09e5ab;
}

.date-slider .slick-prev:before,
.date-slider .slick-next:before {
  font-family: "feather";
  font-size: 26px;
}

.time-slot {
  text-align: center;
}

.time-slot h4 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}

.time-slot ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.time-slot ul li {
  display: block;
  margin-bottom: 15px;
}

.time-slot li:last-child {
  margin-bottom: 0;
}

.time-slot li .timing {
  background: #f5f5f5;
  border: 2px solid #f3f3f3;
  border-radius: 8px;
  color: #595959;
  margin: 0;
  padding: 10px 15px;
  text-align: center;
  position: relative;
  box-shadow: inset 0 0 0 0 #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.time-slot li .timing:hover {
  background: #ffffff;
  box-shadow: inset 0 0 0 50px #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

.time-slot li .timing.active {
  background: #09e5ab;
  border: 2px solid #09e5ab;
  color: #ffffff;
  box-shadow: inset 0 0 0 0 #09e5ab;
}

.time-slot li .timing.active:hover {
  background: #09e5ab;
  border: 2px solid #09e5ab;
  color: #ffffff;
  box-shadow: inset 0 0 0 50px #09e5ab;
}

.time-slot li .timing i {
  margin-right: 4px;
}

.time-slot li .load-more-timings a {
  font-weight: 500;
  text-decoration: underline;
  color: #09e5ab;
}

.time-slot li .load-more-timings a:hover {
  color: #000000;
}

.time-slot .time-slot-open {
  display: none;
}

@media (max-width: 991.98px) {
  .time-slot {
    padding-bottom: 15px;
  }
}

.success-content {
  text-align: center;
  padding-bottom: 40px;
}

.success-content h4 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .success-content h4 {
    font-size: 16px;
  }
}

@media (max-width: 991.98px) {
  .success-content {
    padding-bottom: 30px;
  }
}

.success-icon {
  padding-bottom: 15px;
}

.success-icon i {
  font-size: 50px;
  color: #28a745;
}

@media (max-width: 991.98px) {
  .success-icon i {
    font-size: 36px;
  }
}

.booking-success-info {
  padding-bottom: 20px;
}

.booking-success-info .booking-doctor-img {
  width: 90px;
  height: 90px;
  border-radius: 10px;
  margin-right: 15px;
}

.booking-success-info .booking-doctor-img img {
  width: 90px;
  height: 90px;
  border-radius: 10px;
}

.booking-success-info .booking-doctor-info p {
  margin-bottom: 15px;
}

.success-btn {
  text-align: center;
  padding-bottom: 25px;
}

.success-btn .btn {
  font-weight: 500;
  padding: 8px 18px;
}

@media (max-width: 767.98px) {
  .success-btn .btn {
    display: flex;
  }
}

.success-btn .btn-primary {
  margin-right: 15px;
}

@media (max-width: 767.98px) {
  .success-btn .btn-primary {
    margin: 0 0 10px 0;
    padding: 8px 18px;
  }
}

.success-btn .btn-light {
  color: #7c7c7c;
  background: #f5f5f5;
}

.success-dashboard-link {
  text-align: center;
}

.success-dashboard-link a {
  font-weight: 500;
  font-size: 15px;
  color: #959595;
}

.success-dashboard-link a:hover {
  color: #0e82fd;
}

.success-dashboard-link a i {
  margin-right: 12px;
  font-size: 16px;
}
.click .select2-selection.select2-selection--single {
  min-width: 140px;
}

@media (max-width: 767.98px) {
  .success-dashboard-link a {
    font-size: 14px;
  }
}
svg.feather {
  width: 16px;
  height: 16px;
}

.footer.footer-fifteen
  .footer-top-fifteen
  .footer-right-fifteen
  .footer-fift-selects
  .footer-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  right: 1px;
  left: 108px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  display: none;
}
.form-check-inline.visits.me-0 {
  margin-left: 5px !important;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type="text"],
.intl-tel-input.allow-dropdown input[type="tel"] {
  padding-right: 113px;
  padding-left: 52px;
  margin-left: 0;
  padding-top: 13px;
  padding-bottom: 13px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
}

.modal.fade {
  z-index: 10000000 !important;
}
.table > :not(:first-child) {
  border-top: none !important;
}
